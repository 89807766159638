import React from 'react'

import AuthWrapper from '../authWrapper'
import LoginForm from './loginForm'

const Login = () => (
  <AuthWrapper>
    <LoginForm />
  </AuthWrapper>
)

export default Login
