import React, { useEffect, useState, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Typography, Button, Dialog, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import styled from 'styled-components'

import { GlobalContext } from 'routes'
import { deleteUserAPI } from 'services/helpers/apis/user'
import { unlinkUserSupplierAPI } from 'services/helpers/apis/supplier'
import StyledButton from 'components/common/button'
import adminActions from 'services/redux/admin/actions'

import EmptyUserProfileForm from './emptyUserProfileForm'
import EditUser from '../../user/edit'

const { resetUserData } = adminActions

const UserProfileFormContainer = styled.div`
  .contact-actions {
    margin-top: 20px;
    button {
      margin-right: 10px;
      margin-bottom: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
`

const DialogContent = styled.div`
  padding: 40px;
  position: relative;

  .btn-close {
    position: absolute;
    right: 0;
    top: 0;
  }
`

const UserProfileForm = ({ linkedUser, uuid }) => {
  const [connectedUser, setConnectedUser] = useState(
    linkedUser
      ? {
          ...linkedUser,
          name: `${linkedUser.first_name} ${linkedUser.last_name}`,
        }
      : null
  )
  const context = useContext(GlobalContext)
  const [isEdit, setIsEdit] = useState(false)
  const userData = useSelector((state) => state.admin.userData)
  const dispatch = useDispatch()

  useEffect(() => {
    if (userData && isEdit) {
      setConnectedUser({
        ...userData,
        name: `${userData.first_name} ${userData.last_name}`,
      })
    }
  }, [userData])

  const onDeleteContact = async (user) => {
    try {
      await deleteUserAPI(user.id)

      setConnectedUser(null)
      dispatch(resetUserData())
    } catch (e) {
      context.onApiError(e)
    }
  }

  const onUnlinkContact = async () => {
    try {
      await unlinkUserSupplierAPI(uuid)
      setConnectedUser(null)
      dispatch(resetUserData())
    } catch (e) {
      context.onApiError(e)
    }
  }

  const onEditContact = () => {
    setIsEdit(true)
  }

  const onUserConnected = (user) => {
    setConnectedUser(user)
  }

  const onCloseEditUser = () => {
    setIsEdit(false)
  }

  return (
    <UserProfileFormContainer>
      {connectedUser ? (
        <div>
          <Typography variant='h6' gutterBottom>
            {connectedUser.name}
          </Typography>
          <Typography variant='body1' gutterBottom>
            {connectedUser.email}
          </Typography>
          <Typography variant='body1' gutterBottom>
            {connectedUser.phone}
          </Typography>
          <div className='contact-actions'>
            <Button
              variant='contained'
              color='secondary'
              onClick={() => onDeleteContact(connectedUser)}
            >
              Delete Contact
            </Button>
            <StyledButton className='btn-orange' onClick={onUnlinkContact}>
              Unlink Contact
            </StyledButton>
            <Button
              variant='contained'
              color='primary'
              onClick={() => onEditContact(connectedUser)}
            >
              Edit Contact
            </Button>
          </div>
          <Dialog
            aria-labelledby='simple-dialog-title'
            onClose={onCloseEditUser}
            open={isEdit}
            maxWidth='lg'
            fullWidth
          >
            <DialogContent>
              <IconButton
                aria-label='close'
                className='btn-close'
                onClick={onCloseEditUser}
              >
                <CloseIcon />
              </IconButton>
              <EditUser userName={connectedUser.username} />
            </DialogContent>
          </Dialog>
        </div>
      ) : (
        <EmptyUserProfileForm uuid={uuid} onUserConnected={onUserConnected} />
      )}
    </UserProfileFormContainer>
  )
}

export default UserProfileForm
