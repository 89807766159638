import React from 'react'

import AuthWrapper from '../authWrapper'
import ChangePasswordForm from './changePasswordForm'

const ChangePassword = () => (
  <AuthWrapper>
    <ChangePasswordForm />
  </AuthWrapper>
)

export default ChangePassword
