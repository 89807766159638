import React from 'react'
import { withRouter } from 'react-router'

import AuthWrapper from '../authWrapper'
import ForgotPasswordForm from './forgotPasswordForm'

const ForgotPassword = ({ location }) => (
  <AuthWrapper>
    <ForgotPasswordForm initialValues={{ userName: location.state.userName }} />
  </AuthWrapper>
)

export default withRouter(ForgotPassword)
