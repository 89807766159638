import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import TextField from '@material-ui/core/TextField'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import green from '@material-ui/core/colors/green'
import { BrowserView, MobileView } from 'react-device-detect'

import AuthWrapper from '../authWrapper'

const styles = (theme) => ({
  close: {
    padding: theme.spacing(0.5),
  },
  success: {
    backgroundColor: green[600],
  },
})

const authenticatorLink = (props) => (
  <Link to="/googleAuthenticatorCode" {...props} />
)

class QRCode extends Component {
  static propTypes = {
    auth: PropTypes.shape().isRequired,
  }

  state = {
    value: this.props.auth.loginStatus.data.mfa_codes.key,
    copied: false,
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    this.setState({ copied: false })
  }

  copiedSnackBar = () => (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={this.state.copied}
      autoHideDuration={3000}
      onClose={this.handleClose}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      message={<span id="message-id">QRKey is copied</span>}
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={this.props.classes.close}
          onClick={this.handleClose}
        >
          <CloseIcon />
        </IconButton>,
      ]}
    />
  )

  render() {
    const { auth } = this.props
    const qrImage = auth.loginStatus.data.mfa_codes.qr_code
    console.log('qrImage')

    return (
      <AuthWrapper>
        <div noprops="true">
          <BrowserView>
            <img src={qrImage} alt="QR code" />
          </BrowserView>
          <Typography variant="body1" align="left" gutterBottom>
            Scan this code with the Google Authenticator App
          </Typography>
          <MobileView>
            <CopyToClipboard
              text={this.state.value}
              onCopy={() => this.setState({ copied: true })}
            >
              <TextField value={this.state.value} />
            </CopyToClipboard>
          </MobileView>
          {this.copiedSnackBar()}
          <div className="card-actions">
            <Button
              component={authenticatorLink}
              variant="contained"
              color="primary"
              disabled={!qrImage}
            >
              Next
            </Button>
          </div>
        </div>
      </AuthWrapper>
    )
  }
}

export default withStyles(styles)(
  connect((state) => ({
    auth: state.auth,
  }))(QRCode)
)
