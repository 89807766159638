import styled from 'styled-components'

const ReadModalContainer = styled.div`
  position: relative;

  .apexcharts-tooltip-text-value {
    display: none;
  }

  > .btn-close {
    position: absolute;
    right: 0;
    top: 0;
  }

  .title {
    margin-bottom: ${(props) => props.theme.spacing()}px;
    h3 {
      margin-right: 20px;
    }
  }

  .card {
    height: 100%;

    .card-content {
      height: 100%;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
    }
  }
`

export default ReadModalContainer
