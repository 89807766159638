import React, { useState, useEffect, createContext } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import {
  Drawer,
  CssBaseline,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
  Hidden,
  Divider,
  AppBar,
  Toolbar,
  IconButton,
  Badge,
} from '@material-ui/core'
import HomeIcon from '@material-ui/icons/Home'
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import ListIcon from '@material-ui/icons/List'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import SettingsIcon from '@material-ui/icons/Settings'
import MenuIcon from '@material-ui/icons/Menu'
import { useLocation, useHistory } from 'react-router-dom'

import logo from 'assets/images/small_white_turnerfox_logo.svg'
import authActions from 'services/redux/auth/actions'
import adminActions from 'services/redux/admin/actions'
import { userReadAPI } from 'services/helpers/apis/user'

import PageWrapperStyle from './pageWrapper.style'

const { logout } = authActions
const { onApiError } = adminActions

const drawerWidth = 150
const mobileDrawerWidth = 240

export const PageWrapperContext = createContext()

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundImage:
      'linear-gradient(90deg, rgba(0,52,152,1) 0%, rgba(0,52,152,0.9) 6%, rgba(0,74,193,1) 100%, rgba(0,52,152,1) 003498%)',
    justifyContent: 'space-between',
    borderRight: 'none',
  },
  mobileDrawerPaper: {
    width: mobileDrawerWidth,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    width: '100%',
  },
  toolbar: theme.mixins.toolbar,
}))

const dashboardPages = [
  {
    name: 'home',
    route: '/home',
  },
  {
    name: 'requisitions',
    route: '/requisitions/list',
  },
  {
    name: 'rfqs',
    route: '/rfq/list',
  },
  {
    name: 'pos',
    route: '/po/list',
  },
  {
    name: 'shipments',
    route: '/shipment/list',
  },
  {
    name: 'settings',
    route: '/admin/user/list',
  },
]

const PageWrapper = ({
  isLoggedIn,
  username,
  children,
  logout,
  loading,
  onApiError,
  ...props
}) => {
  const { window } = props
  const classes = useStyles()
  const [selectedPageIndex, setSelectedPageIndex] = useState(0)
  const [userData, setUserData] = useState(null)
  const [apiMetaData, setApiMetaData] = useState(null)
  const [selectedPageName, setSelectedPageName] = useState('')
  const location = useLocation()
  const history = useHistory()
  const container =
    window !== undefined ? () => window().document.body : undefined
  const theme = useTheme()
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const fetchUser = async () => {
    try {
      const res = await userReadAPI(username)
      setUserData(res.data.data)
      setApiMetaData(res.data.api_metadata)
    } catch (e) {
      onApiError(e)
    }
  }

  useEffect(() => {
    const currentPath = location.pathname
    const pageRoute = currentPath.split('/')[1]

    switch (pageRoute) {
      case 'home':
        setSelectedPageIndex(0)
        setSelectedPageName(dashboardPages[0].name)
        break
      case 'requisitions':
        setSelectedPageIndex(1)
        setSelectedPageName(dashboardPages[1].name)
        break
      case 'rfq':
        setSelectedPageIndex(2)
        setSelectedPageName(dashboardPages[2].name)
        break
      case 'po':
        setSelectedPageIndex(3)
        setSelectedPageName(dashboardPages[3].name)
        break
      case 'shipment':
        setSelectedPageIndex(4)
        setSelectedPageName(dashboardPages[4].name)
        break
      case 'admin':
        setSelectedPageIndex(5)
        setSelectedPageName(dashboardPages[5].name)
        break
      default:
        break
    }
  }, [location])

  useEffect(() => {
    if (username) {
      fetchUser()
    }
  }, [username])

  const onSelectPage = (index, route) => {
    setSelectedPageIndex(index)
    setSelectedPageName(dashboardPages[index].name)
    history.push(route)
  }

  const drawerList = (hasLogOutButton = false) => (
    <List>
      {dashboardPages.map((page, i) => (
        <ListItem
          button
          key={i}
          onClick={() => onSelectPage(i, page.route)}
          selected={selectedPageIndex === i}
        >
          {page.name === 'home' && (
            <ListItemIcon>
              <Badge color='secondary' badgeContent={4}>
                <HomeIcon style={{ fontSize: 40 }} />
              </Badge>
            </ListItemIcon>
          )}
          {page.name === 'requisitions' && (
            <ListItemIcon>
              <ListIcon style={{ fontSize: 40 }} />
            </ListItemIcon>
          )}
          {page.name === 'rfqs' && (
            <ListItemIcon>
              <ChatBubbleOutlineIcon style={{ fontSize: 40 }} />
            </ListItemIcon>
          )}
          {page.name === 'pos' && (
            <ListItemIcon>
              <ShoppingCartIcon style={{ fontSize: 40 }} />
            </ListItemIcon>
          )}
          {page.name === 'shipments' && (
            <ListItemIcon>
              <LocalShippingIcon style={{ fontSize: 40 }} />
            </ListItemIcon>
          )}
          {page.name === 'settings' && (
            <ListItemIcon>
              <SettingsIcon style={{ fontSize: 40 }} />
            </ListItemIcon>
          )}

          <ListItemText primary={page.name} />
        </ListItem>
      ))}
      {hasLogOutButton && (
        <ListItem>
          <ListItemIcon>
            <ExitToAppIcon style={{ fontSize: 40 }} />
          </ListItemIcon>
          <ListItemText primary='Log out' />
        </ListItem>
      )}
    </List>
  )

  const mobileDrawer = (
    <div>
      <div className={classes.toolbar}></div>
      <Divider />
      {drawerList(true)}
    </div>
  )

  return isLoggedIn ? (
    <PageWrapperContext.Provider value={{ userData }}>
      <PageWrapperStyle className={classes.root}>
        <Hidden mdUp implementation='css'>
          <AppBar position='fixed' className={classes.appBar}>
            <Toolbar>
              <IconButton
                color='inherit'
                aria-label='open drawer'
                edge='start'
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
              <div className='toolbar-logo'>
                <img src={logo} alt='TurnerFox' />
              </div>
            </Toolbar>
          </AppBar>
        </Hidden>
        <CssBaseline />
        <Hidden mdUp implementation='css'>
          <Drawer
            container={container}
            variant='temporary'
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.mobileDrawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {mobileDrawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation='css'>
          <Drawer
            className={classes.drawer}
            variant='permanent'
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor='left'
          >
            <div className='drawer-top'>
              <div className='logo'>
                <img src={logo} alt='TurnerFox' />
              </div>
              {drawerList()}
            </div>
            <div className='drawer-bottom'>
              <List>
                <ListItem button onClick={logout} disabled={loading}>
                  <ListItemIcon>
                    <ExitToAppIcon style={{ fontSize: 40 }} />
                  </ListItemIcon>
                  <ListItemText primary='Log out' />
                </ListItem>
              </List>
            </div>
          </Drawer>
        </Hidden>
        <main className={classes.content}>
          <div className='dashboard-main'>
            <div className='dashboard-content'>
              <Typography variant='h4' gutterBottom>
                {selectedPageName}
              </Typography>
              {children}
            </div>
            {userData && apiMetaData && (
              <div className='dashboard-footer'>
                <p>
                  API {apiMetaData.api_version} Build | ©2020 Turner & Fox Ltd.
                </p>
                <p>
                  Welcome back, {userData.first_name} {userData.last_name}
                </p>
              </div>
            )}
          </div>
        </main>
      </PageWrapperStyle>
    </PageWrapperContext.Provider>
  ) : (
    <div>{children}</div>
  )
}

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
  username: state.auth.username,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logout,
      onApiError,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(PageWrapper)
