import styled from 'styled-components'

const UpdateContentContainer = styled.div`
  .form-action {
    margin-top: 10px;
    text-align: right;
  }

  .select-user-type {
    width: 200px;
  }
`

export const ModalContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  outline: none;
  padding: 20px;
  width: 300px;

  .mfa-form {
    text-align: center;
  }

  .modal-action {
    text-align: center;
    margin-top: 20px;

    button {
      margin: 0 5px;
    }
  }
`

export default UpdateContentContainer
