import React, { useEffect, useState, useContext } from 'react'
import { Button, Fab } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

import {
  fetchShipmentAPI,
  fetchShipmentTrackingAPI,
  // fetchCarriersListAPI,
} from 'services/helpers/apis/other'
import { updatePoStatusAPI } from 'services/helpers/apis/po'
import ShipmentsStatus from 'components/shipmentsStatus'
import LoadingSpinner from 'components/common/loadingSpinner'
import { GlobalContext } from 'routes'

import AddShipmentModal from './components/addShipmentModal'
import ShipmentsContainer from './shipments.style'

const ShipmentsNew = ({
  uuid,
  isStatusUpdated,
  onStatusUpdated,
  resetIsStatusUpdated,
}) => {
  const context = useContext(GlobalContext)
  const [shipmentData, setShipmentData] = useState(null)
  const [shipmentTracks, setShipmentTracks] = useState(null)
  const [changeStatus, setChangeStatus] = useState(false)
  const [openAddShipmentModal, setOpenAddShipmentModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const fetchApis = async () => {
    setIsLoading(true)
    try {
      const resShipment = await fetchShipmentAPI(uuid)
      setShipmentData(resShipment.data.data)

      const { shipments } = resShipment.data.data

      if (shipments && shipments.length > 0) {
        let shipmentInfos = []
        await Promise.all(
          shipments.map(async (shipment) => {
            try {
              const shipmentRes = await fetchShipmentTrackingAPI(shipment, uuid)
              shipmentInfos = [...shipmentInfos, shipmentRes.data.data]
            } catch (e) {
              console.log(e)
            }
          })
        )

        setShipmentTracks([...shipmentInfos])
      }
    } catch (e) {
      context.onApiError(e)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchApis()
  }, [])

  useEffect(() => {
    if (isStatusUpdated && changeStatus) {
      resetIsStatusUpdated()
      setChangeStatus(false)
    }
    if (isStatusUpdated && !changeStatus) {
      resetIsStatusUpdated()
      fetchApis()
    }
  }, [isStatusUpdated])

  const onDisplayMessageButtonClicked = async (statusId) => {
    try {
      await updatePoStatusAPI(uuid, statusId)
      fetchApis()
      onStatusUpdated()
      setChangeStatus(true)
    } catch (e) {
      context.onApiError(e)
    }
  }

  const openModal = () => {
    setOpenAddShipmentModal(true)
  }

  const closeModal = () => {
    setOpenAddShipmentModal(false)
  }

  const onAddedShipment = () => {
    closeModal()
    fetchApis()
  }

  return !isLoading ? (
    <ShipmentsContainer>
      {shipmentData && (
        <>
          {shipmentData.purchase_order.permissions.can_view_shipment &&
            shipmentTracks && (
              <div className='shipment-tables'>
                <ShipmentsStatus shipments={shipmentTracks} />
                {shipmentData.purchase_order.permissions
                  .can_create_shipment && (
                  <Fab
                    size='medium'
                    color='secondary'
                    aria-label='add'
                    className='btn-add-shipment'
                    onClick={openModal}
                  >
                    <AddIcon />
                  </Fab>
                )}
              </div>
            )}

          {shipmentData.purchase_order.permissions.can_create_shipment &&
            shipmentData.shipments.length === 0 && (
              <div className='empty-state'>
                <p>There are no shipments to view.</p>
                <Button variant='contained' color='primary' onClick={openModal}>
                  Create a shipment
                </Button>
              </div>
            )}
          {!shipmentData.purchase_order.permissions.can_view_shipment && (
            <div className='empty-state'>
              <p>{shipmentData.display_message.message}</p>
              {shipmentData.display_message.button.display && (
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() =>
                    onDisplayMessageButtonClicked(
                      shipmentData.display_message.button.status_id
                    )
                  }
                >
                  {shipmentData.display_message.button.language}
                </Button>
              )}
            </div>
          )}

          <AddShipmentModal
            remainingLines={shipmentData.remaining_lines}
            handleClose={closeModal}
            open={openAddShipmentModal}
            onAddedShipment={onAddedShipment}
          />
        </>
      )}
    </ShipmentsContainer>
  ) : (
    <LoadingSpinner />
  )
}

export default ShipmentsNew
