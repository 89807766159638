import React, { useContext } from 'react'
import { Box, Avatar } from '@material-ui/core'

import { RequisitionListContext } from 'pages/requisitions/list'

const RequisitionQuickGlance = ({ row }) => {
  const requisitionListContext = useContext(RequisitionListContext)
  const handlePopoverOpen = (event, name) => {
    requisitionListContext.handleGlancePopoverOpen(event, {
      ...row[name],
      type: name,
      title:
        name === 'sp_icon'
          ? 'Supplier Preference Rules'
          : 'Supplier Part Number Rules',
    })
  }

  return (
    <Box>
      <Box display='flex'>
        <Box mr={1}>
          <Avatar
            style={{
              backgroundColor: row.pn_icon.icon_color,
              cursor: 'pointer',
            }}
            onClick={(e) => handlePopoverOpen(e, 'pn_icon')}
          >
            PN
          </Avatar>
        </Box>
        <Box>
          <Avatar
            style={{
              backgroundColor: row.sp_icon.icon_color,
              cursor: 'pointer',
            }}
            onClick={(e) => handlePopoverOpen(e, 'sp_icon')}
          >
            SP
          </Avatar>
        </Box>
      </Box>
    </Box>
  )
}

export default RequisitionQuickGlance
