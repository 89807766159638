import styled from 'styled-components'

const TableContainer = styled.div`
  .input-quantity {
    width: 50px;
    outline: none;
    font-size: 14px;
    border: 1px solid rgba(0, 0, 0, 0.87);

    &.error {
      background-color: rgba(240, 60, 127, 0.5);
    }
  }
`

export default TableContainer
