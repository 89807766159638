import React, { Component } from 'react'
import ReactTable from 'react-table'
import { Link } from 'react-router-dom'

class LineTable extends Component {
  state = {
    quantity: 0,
  }

  componentDidMount() {
    let quantity = 0
    this.props.data.map((item) => {
      quantity += parseInt(item.shipped_quantity, 10)
      return quantity
    })

    this.setState(
      {
        quantity,
      },
      () => {
        this.props.setTotalPieces(this.state.quantity)
      }
    )
  }

  render() {
    const { name, data } = this.props
    const { quantity } = this.state

    const columns = [
      {
        Header: 'Quantity',
        accessor: 'shipped_quantity',
      },
      {
        Header: 'Requisition Number',
        accessor: 'requisition.order_id',
        Cell: (props) => (
          <Link to={`/requisitions/${props.value}`}>{props.value}</Link>
        ),
      },
      {
        Header: 'Purchase Order',
        accessor: 'po_id',
        Cell: (props) => (
          <Link to={`/po/${props.value}`}>PO {props.value}</Link>
        ),
      },
    ]

    return (
      <div className='lineItem'>
        <h2>{name}</h2>
        <ReactTable
          data={data}
          columns={columns}
          minRows={1}
          showPagination={false}
        />
        <p>Line Total: {quantity}</p>
      </div>
    )
  }
}

export default LineTable
