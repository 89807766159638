import styled from 'styled-components'

const ShipmentReadContainer = styled.div`
  .shipment-information {
    .status {
      text-align: right;

      p {
        display: inline-flex;
        align-items: center;
        color: white;
        padding: 5px 10px;

        span {
          margin-right: 5px;
        }
      }
    }
  }

  .tab-content {
    padding: 20px;

    .timeline {
      height: 100%;
      display: flex;
      align-items: center;
    }

    .shipment-summary {
      text-align: center;
      background-color: #eee;
      padding: 20px;

      img {
        width: 100px;
      }

      h3 {
        margin-top: 0;
      }

      h4 {
        margin: 0;
      }
    }

    .status-text {
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        margin-right: 5px;
      }
    }

    .card {
      height: 100%;

      .card-content {
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        h5.title {
          text-decoration: underline;
        }

        .summary {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          img {
            width: 100%;
          }
        }
      }
    }

    .total-pieces {
      margin-top: 50px;
      text-align: right;
    }
  }

  .lineItem {
    .ReactTable {
      .rt-tbody {
        text-align: center;
      }
    }
  }
`

export default ShipmentReadContainer
