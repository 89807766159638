import { client } from '../apiConfig'

const getPartNumberRulesListAPI = (query) => {
  return client.get(
    query ? `/part_number_rules/list?${query}` : '/part_number_rules/list'
  )
}

const getPartNumberRulesClassListAPI = () => {
  return client.get('/part_number_rules/part_number_class/list')
}

const getPartNumberRulesSequenceListAPI = () => {
  return client.get('/part_number_rules/sequence/list')
}

const updatePartNumberRulesSequenceUpdateAPI = (sequence) => {
  return client.put('/part_number_rules/sequence/update', {
    sequence,
  })
}

const deletePartNumberRulesAPI = (uuids) => {
  return client.delete(`/part_number_rules/delete/${uuids}`)
}

const searchPartNumberRulesAPI = (term, supplierIds, partNumberClassIds) => {
  return client.get(
    `/part_number_rules/search?term=${term}&supplier_id=${supplierIds}&part_number_class_id=${partNumberClassIds}`
  )
}

const readPartNumberRulesAPI = (uuid) => {
  return client.get(`/part_number_rules/read/${uuid}`)
}

const getStringEvaluationTypesAPI = () => {
  return client.get('/part_number_rules/string_evaluation_types/list')
}

const updatePartNumberRuleAPI = (uuid, body) => {
  return client.put(`/part_number_rules/update/${uuid}`, body)
}

const partNumberRuleProcessAPI = (uuid, partNumber) => {
  return client.get(
    `/part_number_rules/process/${uuid}?part_number=${partNumber}`
  )
}

const createPartNumberRuleAPI = (body) => {
  return client.post('/part_number_rules/create', body)
}

export {
  getPartNumberRulesListAPI,
  getPartNumberRulesClassListAPI,
  getPartNumberRulesSequenceListAPI,
  updatePartNumberRulesSequenceUpdateAPI,
  deletePartNumberRulesAPI,
  searchPartNumberRulesAPI,
  readPartNumberRulesAPI,
  getStringEvaluationTypesAPI,
  updatePartNumberRuleAPI,
  partNumberRuleProcessAPI,
  createPartNumberRuleAPI,
}
