import React from 'react'

import AuthWrapper from '../authWrapper'
import AuthenticatorForm from './authenticatorForm'

const GoogleAuthenticator = () => (
  <AuthWrapper>
    <AuthenticatorForm />
  </AuthWrapper>
)

export default GoogleAuthenticator
