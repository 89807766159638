import 'date-fns'
import React from 'react'
import Grid from '@material-ui/core/Grid'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers'

function ConfirmedDateTime({ date, disabled, onUpdateDate }) {
  // The first commit of Material-UI
  const [selectedDate, setSelectedDate] = React.useState(
    new Date(date.timestamp)
  )

  const handleDateChange = (date) => {
    setSelectedDate(date)
    onUpdateDate(date)
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify='space-around' spacing={2}>
        <Grid item>
          <KeyboardDatePicker
            margin='normal'
            id='date-picker-dialog'
            label='Date Confirmed'
            format='MM/dd/yyyy'
            value={selectedDate}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            disabled={disabled}
            disableFuture
          />
        </Grid>
        <Grid item>
          <KeyboardTimePicker
            margin='normal'
            id='time-picker'
            label='Time Confirmed'
            value={selectedDate}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change time',
            }}
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  )
}

export default ConfirmedDateTime
