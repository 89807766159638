import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { IconButton, TextField } from '@material-ui/core'
import {
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
} from '@material-ui/icons'
import styled from 'styled-components'

const TablePaginationActionsWrapper = styled.div`
  flex-shrink: 0;

  .input_page_number {
    width: 80px;
    input {
      text-align: center;
    }
  }
`

const TablePaginationActions = (props) => {
  const { count, page, rowsPerPage, onChangePage } = props
  const [pageNumber, setPageNumber] = useState(page + 1)

  useEffect(() => {
    setPageNumber(page + 1)
  }, [page])

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0)
  }

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1)
  }

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1)
  }

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  const onChangePageNumber = (e) => {
    const inputValue = parseInt(e.target.value, 10)
    if (inputValue > 0 && inputValue <= Math.ceil(count / rowsPerPage)) {
      setPageNumber(inputValue)
      onChangePage(e, parseInt(e.target.value, 10) - 1)
    }
  }

  return (
    <TablePaginationActionsWrapper>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPage />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <TextField
        id="outlined-number"
        label="Number"
        type="number"
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        size="small"
        className="input_page_number"
        value={pageNumber}
        onChange={onChangePageNumber}
      />
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPage />
      </IconButton>
    </TablePaginationActionsWrapper>
  )
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
}

export default TablePaginationActions
