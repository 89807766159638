import React, { useState, useContext, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'

import { searchAPI as searchSupplierNameVariantAPI } from 'services/helpers/apis/supplierNameVariant'
import { searchAPI as searchManufacturerNameVariantAPI } from 'services/helpers/apis/manufacturerNameVariant'

import { GlobalContext } from 'routes'

const WAIT_INTERVAL = 1000
let timer

const AutocompleteSearch = ({ onChangeTerm, tableFor }) => {
  const context = useContext(GlobalContext)
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  useEffect(() => {
    timer = null
  }, [])

  const onChangeSearchField = async (e) => {
    clearTimeout(timer)
    setSearchValue(e.target.value)
    const termValue = e.target.value

    timer = setTimeout(async () => {
      setLoading(true)
      try {
        const res =
          tableFor === 'supplier'
            ? await searchSupplierNameVariantAPI(termValue)
            : await searchManufacturerNameVariantAPI(termValue)

        const terms = res.data.data.search.map((term) => `${term}`)
        setOptions(terms)
      } catch (e) {
        context.onApiError(e)
      } finally {
        setLoading(false)
      }
    }, WAIT_INTERVAL)
  }

  const onChangeValue = (event, value) => {
    onChangeTerm(value)
  }

  return (
    <Autocomplete
      id='asynchronous-demo'
      style={{ width: 200 }}
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      getOptionSelected={(option, value) => option === value}
      options={options}
      loading={loading}
      onChange={onChangeValue}
      renderInput={(params) => (
        <TextField
          {...params}
          label='Search'
          variant='outlined'
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color='inherit' size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          value={searchValue}
          onChange={onChangeSearchField}
        />
      )}
    />
  )
}

export default AutocompleteSearch
