import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import Button from '@material-ui/core/Button'

import {
  ReduxTextField,
  ReduxCheckBoxField,
} from '../../../../../components/reduxFormFields'
import UpdateContentContainer from './index.style'

class ChangePasswordForm extends Component {
  componentDidMount() {}

  render() {
    const { handleSubmit, self, pristine } = this.props

    return (
      <UpdateContentContainer>
        <form onSubmit={handleSubmit}>
          {self && (
            <Field
              name="oldPassword"
              label="Old Password"
              component={ReduxTextField}
              type="password"
              fullWidth
            />
          )}

          <Field
            name="newPassword"
            label="New Password"
            component={ReduxTextField}
            type="password"
            fullWidth
          />
          {self && (
            <Field
              name="confirmPassword"
              label="Retype New Password"
              component={ReduxTextField}
              fullWidth
              type="password"
            />
          )}

          {!self && (
            <Field
              name="forceChangePassword"
              label="Force User to Change Password"
              component={ReduxCheckBoxField}
              fullWidth
            />
          )}

          {!self && (
            <Field
              name="emailUserNewPassword"
              label="Email user new password"
              component={ReduxCheckBoxField}
              fullWidth
            />
          )}

          <div className="form-action">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="btn-submit"
              disabled={pristine}
            >
              Change
            </Button>
          </div>
        </form>
      </UpdateContentContainer>
    )
  }
}

const validate = (values) => {
  const errors = {}
  const strongRegex = new RegExp(
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'
  )

  if (values.oldPassword) {
    if (!values.confirmPassword) {
      errors.confirmPassword = 'Confirm password is required'
    } else if (values.newPassword !== values.confirmPassword) {
      errors.confirmPassword = 'Confirm password does not match'
    }
  }

  if (!values.newPassword) {
    errors.newPassword = 'New password is required'
  } else if (!strongRegex.test(values.newPassword)) {
    errors.newPassword =
      'The password does not meet the minimum password requirements'
  } else if (values.newPassword === values.oldPassword) {
    errors.newPassword = 'New password must be different than old password'
  }

  return errors
}

const ChangePasswordReduxForm = reduxForm({
  form: 'changePasswordForm',
  validate,
})(ChangePasswordForm)

export default ChangePasswordReduxForm
