import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _isEqual from 'lodash/isEqual'
import axios from 'axios'

import { getRequisitionPriceHistoryAPI } from 'services/helpers/apis/requisition'
import requisitionActions from 'services/redux/requisition/actions'

import PurchaseHistoryTable from 'components/purchaseHistoryTable'

const { setPriceHistory } = requisitionActions

class TabPricing extends Component {
  signal = axios.CancelToken.source()

  state = {
    priceHistory: null,
  }

  async componentDidMount() {
    const { uuid, setPriceHistory } = this.props

    try {
      const res = await getRequisitionPriceHistoryAPI(uuid, this.signal.token)

      this.setState(
        {
          priceHistory: _isEqual(res.data.data.price_history, [])
            ? null
            : res.data.data.price_history,
        },
        () => {
          setPriceHistory(res.data.data)
        }
      )
    } catch (err) {
      console.log(err)
    }
  }

  componentWillUnmount() {
    this.signal.cancel('Api is being canceled')
  }

  render() {
    const { priceHistory } = this.state

    return (
      <div>
        <h2>Purchase History</h2>
        {priceHistory !== [] && (
          <PurchaseHistoryTable priceHistory={priceHistory} />
        )}
        {!priceHistory && <p>No Purchase History Found</p>}
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setPriceHistory,
    },
    dispatch
  )

export default connect(null, mapDispatchToProps)(TabPricing)
