import styled from 'styled-components'

import EditableInputContainer from './editableInput.style'

const NewTableContainer = styled.div`
  .icon_grey {
    filter: grayscale(100%);
  }

  .quoted-items {
    margin-bottom: 20px;

    .quoted-item {
      border: 1px solid grey;
      border-radius: 10px;
      padding: 20px;
      position: relative;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }

      .item-values {
        display: flex;
        flex-wrap: wrap;

        .field {
          margin-right: 20px;
          width: 200px;

          &.custom-field {
            width: initial;
          }

          .editable-select,
          ${EditableInputContainer} {
            width: 100%;
          }

          p {
            margin: 0;
          }

          .MuiCheckbox-root {
            padding: 0;
          }
        }
      }

      .associated-pos {
        display: flex;
        flex-wrap: wrap;
        padding-right: 100px;

        a {
          margin-right: 10px;
          margin-bottom: 5px;
          background: lightgrey;
          padding: 5px 15px;
          border-radius: 30px;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .row-actions {
        position: absolute;
        right: 20px;
        bottom: 20px;
        display: flex;
        align-items: center;

        svg,
        span {
          cursor: pointer;
          margin-left: 5px;
        }
      }
    }
  }

  .align-right {
    display: flex;
    justify-content: flex-end;
  }
`

export default NewTableContainer
