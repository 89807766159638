import styled from 'styled-components'
import { Box } from '@material-ui/core'

const Container = styled(Box)`
  .field-group {
    margin-right: 20px;
    width: 200px;
  }
`

export default Container
