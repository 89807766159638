import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputAdornment from '@material-ui/core/InputAdornment'

import { updateRfqQuoteAPI } from 'services/helpers/apis/rfq'
import EditableInputContainer from './editableInput.style'

let timer
const WAIT_INTERVAL = 1000

const EditableInput = ({ quoteLineUUID, inputValue, inputType, label }) => {
  const [value, setValue] = useState(inputValue)
  const [fieldError, setFieldError] = useState(null)

  useEffect(() => {
    timer = null
  }, [])

  const onUpdateInput = async (e) => {
    clearTimeout(timer)
    // const inputPrevValue = value;
    const inputUpdatedValue = e.target.value
    setValue(inputUpdatedValue)

    timer = setTimeout(async () => {
      if (inputUpdatedValue === '') {
        setFieldError('field must be not empty')
      } else {
        try {
          await updateRfqQuoteAPI(quoteLineUUID, {
            [inputType]: inputUpdatedValue,
          })

          setFieldError(null)
        } catch (error) {
          setFieldError(error.response.data.message)
          // setValue(inputPrevValue);
        }
      }
    }, WAIT_INTERVAL)
  }

  return (
    <EditableInputContainer>
      {inputType === 'price' ? (
        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-adornment-amount">Price</InputLabel>
          <OutlinedInput
            id="outlined-adornment-amount"
            value={value}
            onChange={onUpdateInput}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            labelWidth={60}
          />
        </FormControl>
      ) : (
        <TextField
          required
          id="outlined-required"
          label={label}
          value={value}
          onChange={onUpdateInput}
          variant="outlined"
        />
      )}

      {fieldError && <p className="field-error">{fieldError}</p>}
    </EditableInputContainer>
  )
}

export default EditableInput
