import React, { useContext, useEffect, useState } from 'react'
import Paper from '@material-ui/core/Paper'
import { toast } from 'react-toastify'

import { fetchSuppliersAPI } from 'services/helpers/apis/supplier'
import {
  createRfqLineAPI,
  createRfqAPI,
  getRfqMessageTemplateAPI,
} from 'services/helpers/apis/rfq'
import { GlobalContext } from 'routes'

import AddRfqForm from '../rfq/addRfqForm'
import SendRfqModal from '../rfq/sendRfqModal'

const AddToRfq = ({ partNumbers, requisition, onRfqSent }) => {
  const [suppliersList, setSuppliersList] = useState([])
  const [createdRfqId, setCreatedRfqId] = useState(null)
  const [rfqFormValues, setRfqFormValues] = useState(null)
  const [openRfqModal, setOpenRfqModal] = useState(false)
  const [messageTemplate, setMessageTemplate] = useState(null)
  const [createdRfqLineUuid, setCreatedRfqLineUuid] = useState(null)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const context = useContext(GlobalContext)

  useEffect(() => {
    const fetchSuppliers = async () => {
      try {
        const res = await fetchSuppliersAPI()

        setSuppliersList(res.data.data)
      } catch (e) {
        context.onApiError(e)
      }
    }

    fetchSuppliers()
  }, [])

  const createRfqLine = async (rfqId) => {
    try {
      let lineBody = {
        quantity: rfqFormValues.quantity,
        part_number: rfqFormValues.partNumber,
        rfq_id: rfqId,
        requisition_uuid: requisition.requisition.uuid,
      }
      if (rfqFormValues.manufacturer_id) {
        lineBody = {
          ...lineBody,
          manufacturer_id: rfqFormValues.manufacturer_id,
        }
      }
      const createRfqLine = await createRfqLineAPI(lineBody)
      const getRfqMessageTemplate = await getRfqMessageTemplateAPI(rfqId)

      setOpenRfqModal(true)
      setCreatedRfqId(rfqId)
      setMessageTemplate(getRfqMessageTemplate.data.data)
      setCreatedRfqLineUuid(createRfqLine.data.data.uuid)
    } catch (e) {
      context.onApiError(e)
    }
  }

  const createRfq = async (supplierId) => {
    try {
      const res = await createRfqAPI(supplierId)
      const rfqId = res.data.data.rfq_id

      setCreatedRfqId(rfqId)
      createRfqLine(rfqId)
    } catch (e) {
      context.onApiError(e)
    }
  }

  const onAddRfqFormSubmit = (values) => {
    const formValues = values
    setRfqFormValues(formValues)
    setFormSubmitted(true)
  }

  useEffect(() => {
    if (formSubmitted) {
      if (rfqFormValues.rfqId === 'new_rfq') {
        createRfq(rfqFormValues.supplierId)
      } else {
        createRfqLine(rfqFormValues.rfqId)
      }

      setFormSubmitted(false)
    }
  }, [rfqFormValues, formSubmitted])

  const onCloseAddRfqModal = () => {
    setOpenRfqModal(false)
  }

  const saveRfq = () => {
    setOpenRfqModal(false)
    onRfqSent()
    toast.success(`RFQ ${createdRfqId} Created`)
  }

  return (
    <Paper className='paper'>
      {partNumbers && (
        <AddRfqForm
          requisition={requisition}
          onSubmit={onAddRfqFormSubmit}
          partNumbers={partNumbers}
          suppliersList={suppliersList}
        />
      )}
      <SendRfqModal
        open={openRfqModal}
        close={onCloseAddRfqModal}
        messageTemplate={messageTemplate}
        saveRfq={saveRfq}
        rfqId={createdRfqId}
        rfqLineUuid={createdRfqLineUuid}
        onRfqSent={onRfqSent}
      />
    </Paper>
  )
}

export default AddToRfq
