import styled from 'styled-components'
import grey from '@material-ui/core/colors/grey'

const MessageContainer = styled.div`
  position: fixed;
  right: 20px;
  bottom: 0;
  width: 1000px;
  background: white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  z-index: 999;

  &:before {
    content: '';
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: -1;
  }

  .box-header {
    background-color: ${grey[900]};
    color: white;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .box-actions {
      span {
        cursor: pointer;
      }
    }
  }

  .message-modal-content {
    background-color: white;

    .DraftEditor-root {
      border: 1px solid grey;

      .public-DraftEditor-content {
        max-height: 250px;
        overflow: auto;
        padding: 0.5rem;
      }
    }

    .btn-show-quote {
      padding: 0;
      min-width: inherit;
      line-height: 1;
      height: 15px;
    }

    .reply-content {
      margin-bottom: 1rem;
    }

    &.read-message-content {
      .messages {
        max-height: 400px;
        min-height: 300px;
        overflow: auto;

        h2,
        p {
          margin: 0;
        }
      }

      .message-actions {
        padding: 10px;
        display: flex;
        justify-content: flex-end;

        button {
          margin: 0 5px;

          &:last-child {
            margin: 0;
          }
        }
      }
    }

    form {
      padding: 0 10px;

      .field {
        padding: 8px 0;
        border-bottom: 1px solid lightgrey;

        &:last-child {
          border-bottom: none;
        }

        input {
          border: none;
          outline: none;
          width: 100%;
          color: #222;
          font-size: 0.85em;
        }

        &.select-type {
          display: flex;
          align-items: center;

          span {
            margin-right: 10px;
          }
        }
      }

      .form-actions {
        text-align: right;
        padding: 10px 0;
      }
    }
  }
`

export default MessageContainer
