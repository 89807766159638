import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _isEqual from 'lodash/isEqual'

import adminActions from 'services/redux/admin/actions'

import AssignSelector from './selector'

const { updateAssignee } = adminActions

class AssignDropdown extends Component {
  state = {
    assignedTo: null,
  }

  componentDidMount() {
    this.setAssignedTo()
  }

  componentWillReceiveProps(nextProps) {
    if (!_isEqual(nextProps.selected.assignee, this.props.selected.assignee)) {
      this.props = nextProps
      this.setAssignedTo()
    }
  }

  setAssignedTo = () => {
    const { selected, permissions } = this.props
    const { assignee } = selected

    let assignedTo = {
      type: '',
      value: '',
    }

    if (!permissions.assign) {
      if (assignee) {
        assignedTo = {
          type: 'read-only',
          value: 'Assigned',
        }
      } else {
        assignedTo = {
          type: 'read-only',
          value: 'Unassigned',
        }
      }
    } else if (permissions.assign === 'self') {
      assignedTo = {
        type: 'self',
        value: '',
      }
    } else if (permissions.assign === true) {
      if (assignee !== null) {
        assignedTo = {
          type: 'multiple',
          value: assignee.user_id,
        }
      } else {
        assignedTo = {
          type: 'multiple',
          value: '',
        }
      }
    }

    this.setState({
      assignedTo,
    })
  }

  onGrab = async (uuid) => {
    const { updateFor } = this.props

    this.props.updateAssignee(uuid, updateFor, { assignee: 'self' })
  }

  render() {
    const { selected, userList, componentType, updateFor } = this.props
    const { assignedTo } = this.state

    const { uuid } = selected

    return (
      <div>
        {assignedTo && (
          <span>
            {assignedTo.type === 'read-only' && assignedTo.value}
            {assignedTo.type === 'self' && (
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => this.onGrab(uuid)}
              >
                Grab
              </Button>
            )}
            {assignedTo.type === 'multiple' && userList.length > 0 && (
              <AssignSelector
                assignedTo={assignedTo}
                userList={userList}
                uuid={uuid}
                updateAssignee={this.props.updateAssignee}
                componentType={componentType}
                updateFor={updateFor}
              />
            )}
          </span>
        )}
      </div>
    )
  }
}

AssignDropdown.defaultProps = {
  componentType: 'selector',
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateAssignee,
    },
    dispatch
  )

export default connect(null, mapDispatchToProps)(AssignDropdown)
