import { client } from '../apiConfig'

const getPoListAPI = (status, supplierId) => {
  return client.get(`/po/list?status=${status}&supplier_id=${supplierId}`)
}

const createBatchPoLineAPI = (rfqLines, poId) => {
  return client.post('/po/line/batch/create', {
    rfq_quote_lines: rfqLines,
    po: poId,
  })
}

const createPoLineAPI = (poLineBody) => {
  return client.post('/po/line/create', poLineBody)
}

const updatePoLineAPI = (poLineUUID, poLineBody) => {
  return client.put(`/po/line/update/${poLineUUID}`, poLineBody)
}

const deletePoLineAPI = (poLineUUID) => {
  return client.delete(`/po/line/delete/${poLineUUID}`)
}

const getPoHistoryAPI = (pouuid) => {
  return client.get(`/po/history/${pouuid}`)
}

const readPoAPI = (uuid) => {
  return client.get(`po/read/${uuid}`)
}

const getPoCorrespondenceAPI = (uuid) => {
  return client.get(`/po/correspondence/${uuid}`)
}

const createPoCorrespondenceAPI = (uuid) => {
  return client.post(`/po/correspondence/create/${uuid}`)
}

const updatePoCorrespondenceAPI = (messageUuid, { type, subject, body }) => {
  return client.put(`/po/correspondence/update/${messageUuid}`, {
    type,
    subject,
    body,
  })
}

const updatePoAPI = (uuid, params) => {
  if (uuid) {
    return client.put(`/po/update/${uuid}`, params)
  }
  return client.put(`/po/update`, params)
}

const deletePoAPI = (uuid) => {
  return client.delete(`/po/delete/${uuid}`)
}

const confirmPoAPI = (poId, messageBody) => {
  return client.put('/po/confirm', {
    po_id: poId,
    message_body: messageBody,
  })
}

const getPoMessageTemplateAPI = (uuid) => {
  return client.get(`/po/message_template/${uuid}`)
}

const updatePoStatusAPI = (uuid, statusId) => {
  return client.put(`/po/status/update/${uuid}`, {
    status_id: statusId,
  })
}

const batchConfirmPoAPI = (poIds) => {
  return client.put('/po/confirm/batch', {
    id: poIds.toString(),
  })
}

const batchUpdatePoStatusAPI = (uuids, statusId) => {
  return client.put('/po/status/update/batch', {
    status_id: statusId,
    po_uuids: uuids,
  })
}

const getPoSearchAPI = (term, assignee, supplierId) => {
  return client.get(
    `/po/search/?term=${term}&assignee=${assignee}&supplier_id=${supplierId}`
  )
}

export {
  getPoListAPI,
  createBatchPoLineAPI,
  createPoLineAPI,
  updatePoLineAPI,
  deletePoLineAPI,
  getPoHistoryAPI,
  readPoAPI,
  updatePoAPI,
  getPoCorrespondenceAPI,
  createPoCorrespondenceAPI,
  updatePoCorrespondenceAPI,
  deletePoAPI,
  confirmPoAPI,
  getPoMessageTemplateAPI,
  updatePoStatusAPI,
  batchConfirmPoAPI,
  batchUpdatePoStatusAPI,
  getPoSearchAPI,
}
