import React, { useState, useEffect } from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import AppBar from '@material-ui/core/AppBar'
import { withRouter } from 'react-router'

import TabContainer, { a11yProps } from 'components/tabContainer'

import TabPricing from './tabPricing'
import TabShipments from './tabShipments'
import TabInventory from './tabInventory'
import TabRfq from './tabRfq'
import TabHistory from './tabHistory'

const RequisitionTabs = ({ uuid, orderId, selectedTab, history }) => {
  const [tabValue, setTabValue] = useState(0)

  useEffect(() => {
    switch (selectedTab) {
      case 'pricing':
        setTabValue(0)
        break
      case 'inventory':
        setTabValue(1)
        break
      case 'rfqpo':
        setTabValue(2)
        break
      case 'shipments':
        setTabValue(3)
        break
      case 'history':
        setTabValue(4)
        break
      default:
        break
    }
  }, [])

  const handleTabChange = (event, newValue) => {
    switch (newValue) {
      case 0:
        history.push(`/requisitions/${orderId}/pricing`)
        break
      case 1:
        history.push(`/requisitions/${orderId}/inventory`)
        break
      case 2:
        history.push(`/requisitions/${orderId}/rfqpo`)
        break
      case 3:
        history.push(`/requisitions/${orderId}/shipments`)
        break
      case 4:
        history.push(`/requisitions/${orderId}/history`)
        break
      default:
        break
    }

    setTabValue(newValue)
  }

  return (
    <div>
      <AppBar position="static" color="default">
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="Shipment Tabs"
        >
          <Tab label="Pricing" {...a11yProps(0)} />
          <Tab label="Inventory" {...a11yProps(1)} />
          <Tab label="RFQ/PO" {...a11yProps(2)} />
          <Tab label="Shipments" {...a11yProps(3)} />
          <Tab label="History" {...a11yProps(4)} />
        </Tabs>
      </AppBar>
      {tabValue === 0 && (
        <TabContainer>
          <TabPricing uuid={uuid} />
        </TabContainer>
      )}
      {tabValue === 1 && (
        <TabContainer>
          <TabInventory uuid={uuid} />
        </TabContainer>
      )}
      {tabValue === 2 && (
        <TabContainer>
          <TabRfq uuid={uuid} />
        </TabContainer>
      )}
      {tabValue === 3 && (
        <TabContainer>
          <TabShipments uuid={uuid} />
        </TabContainer>
      )}
      {tabValue === 4 && (
        <TabContainer>
          <TabHistory uuid={uuid} />
        </TabContainer>
      )}
    </div>
  )
}

export default withRouter(RequisitionTabs)
