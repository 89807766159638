import React from 'react'
import styled from 'styled-components'
import { Icon, Box } from '@material-ui/core'

const ShipmentStatusBarContainer = styled.div`
  background: #888;
  position: relative;

  .progress-bar {
    position: absolute;
    left: 0;
    height: 100%;
    z-index: 9;
  }

  .status-text {
    position: relative;
    z-index: 10;
  }
`

const ShipmentStatusBar = ({ status }) => {
  return (
    <ShipmentStatusBarContainer>
      <span
        style={{
          backgroundColor: `#${status.color}`,
          width: `${status.progress_bar * 100}%`,
        }}
        className='progress-bar'
      ></span>
      <Box
        display='flex'
        alignItems='center'
        style={{ color: `#${status.text_color}` }}
        className='status-text'
      >
        <Icon>{status.icon}</Icon>
        {status.term}
      </Box>
    </ShipmentStatusBarContainer>
  )
}

export default ShipmentStatusBar
