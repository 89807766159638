import React, { useEffect, useState, createContext, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router'
import { Box, Fab } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { toast } from 'react-toastify'

import { GlobalContext } from 'routes'
import adminActions from 'services/redux/admin/actions'
import rfqActions from 'services/redux/rfq/actions'
import { client } from 'services/helpers/apiConfig'
import storageActions from 'services/redux/storage/actions'
import Dialog from 'components/common/dialog'
import LoadingSpinner from 'components/common/loadingSpinner'

import RfqTable from './tableRfq'
import RfqReadModal from './readModal'
import CreateRfqModal from './createRfqModal'
import TableBulkActions from './components/tableBulkActions'
import RfqListContainer from './index.style'

const {
  setCorrespondenceThreadUUID,
  setCorrespondenceMessageUUID,
} = adminActions
const { setRfqTableSettings } = storageActions
const { unsetRfqData, onRfqDeleteConfirmed, onRfqSendConfirmed } = rfqActions
export const RfqListContext = createContext()

const RfqList = ({ history, match }) => {
  const context = useContext(GlobalContext)
  const rfqTableSettings = useSelector(
    (state) => state.storage.rfqTableSettings
  )
  const deletedRfq = useSelector((state) => state.rfq.deleted_rfq)
  const sentRfqId = useSelector((state) => state.rfq.sent_rfq_id)
  const [userList, setUserList] = useState([])
  const [statusList, setStatusList] = useState(null)
  const [suppliersList, setSuppliersList] = useState(null)
  const [rfqList, setRfqList] = useState([])
  const [filterValues, setFilterValues] = useState(
    (rfqTableSettings && rfqTableSettings.filter) || {
      statuses: [],
      suppliers: [],
      users: [],
    }
  )
  const [filterChanged, setFilterChanged] = useState(false)
  const [rfqModalOpen, setRfqModalOpen] = useState(false)
  const [isRfqModalOpenFromURL, setIsRfqModalOpenFromURL] = useState(false)
  const [selectedRfq, setSelectedRfq] = useState(null)
  const [rfqModalSelectedTab, setRfqModalSelectedTab] = useState('lines')
  const [tableViewInfo, setTableViewInfo] = useState(
    (rfqTableSettings && rfqTableSettings.tableViewInfo) || {
      totalRows: 0,
      pageIndex: 1,
      rowsCount: 25,
    }
  )
  const [checkedRfqs, setCheckedRfqs] = useState([])
  const [isCreateRfq, setIsCreateRfq] = useState(false)

  const dispatch = useDispatch()

  const fetchRfqList = async (
    viewInfo = tableViewInfo,
    filters = filterValues,
    searchTerm = null,
    openModal = false
  ) => {
    try {
      let res
      if (!searchTerm) {
        res = await client.get(
          `rfq/list?${
            filters
              ? `supplier_id=${filters.suppliers.join()}&status_id=${filters.statuses.join()}&assignee=${filters.users.join()}`
              : ''
          }&page=${viewInfo.pageIndex}&rows=${viewInfo.rowsCount}`
        )
      } else {
        res = await client.get(
          `rfq/list?${
            filters
              ? `supplier_id=${filters.suppliers.join()}&status_id=${filters.statuses.join()}&assignee=${filters.users.join()}`
              : ''
          }&rows=${viewInfo.rowsCount}&search=${searchTerm}`
        )
      }

      setRfqList([...res.data.data.rfq])

      setTableViewInfo({
        pageIndex: viewInfo.pageIndex,
        rowsCount: viewInfo.rowsCount,
        totalRows: res.data.data.total_rows,
      })

      dispatch(
        setRfqTableSettings({
          filter: filters,
          tableViewInfo: {
            pageIndex: viewInfo.pageIndex,
            rowsCount: viewInfo.rowsCount,
            totalRows: res.data.data.total_rows,
          },
        })
      )

      if (openModal) {
        const selectedRfq = res.data.data.rfq[0]
        setSelectedRfq(selectedRfq || null)
        setRfqModalOpen(!!selectedRfq)
        setRfqModalSelectedTab(
          selectedRfq && match.params.tabName ? match.params.tabName : 'lines'
        )

        if (
          match.params.tabName === 'correspondence' &&
          match.params.threadUUID
        ) {
          dispatch(setCorrespondenceThreadUUID(match.params.threadUUID))
        }

        if (
          match.params.tabName === 'correspondence' &&
          match.params.messageUUID
        ) {
          dispatch(setCorrespondenceMessageUUID(match.params.messageUUID))
        }

        if (!selectedRfq) {
          toast.error(`RFQ ${match.params.id} is not available`)
        }
      }
    } catch (e) {
      context.onApiError(e)
    }
  }

  const handleRfqModalOpenFromUrl = (rfqId) => {
    fetchRfqList(
      {
        pageIndex: 1,
        rowsCount: 25,
        totalRows: 1,
      },
      filterValues,
      `RFQ+${rfqId}`,
      true
    )
    setIsRfqModalOpenFromURL(true)
  }

  useEffect(() => {
    const fetchApis = async () => {
      try {
        if (match.params.id) {
          handleRfqModalOpenFromUrl(match.params.id)
        } else {
          fetchRfqList(tableViewInfo)
        }
        const getUserList = await client.get('user/assign/rfq')
        const getStatusList = await client.get('rfq/status/list')
        const getSuppliersList = await client.get('suppliers/list')

        setUserList(getUserList.data.data)
        setStatusList(getStatusList.data.data)
        setSuppliersList(getSuppliersList.data.data)
      } catch (e) {
        context.onApiError(e)
      }
    }

    fetchApis()
  }, [])

  useEffect(() => {
    if (match.params.id) {
      handleRfqModalOpenFromUrl(match.params.id)
    }
  }, [match.params.id])

  useEffect(() => {
    if (deletedRfq) {
      fetchRfqList(tableViewInfo)
      dispatch(onRfqDeleteConfirmed())
      setRfqModalOpen(false)
    }
  }, [deletedRfq])

  useEffect(() => {
    if (sentRfqId) {
      dispatch(onRfqSendConfirmed())
      fetchRfqList(tableViewInfo)
    }
  }, [sentRfqId])

  const handleChange = (target, values) => {
    setFilterValues({
      ...filterValues,
      [target]: values,
    })
    setTableViewInfo({
      ...tableViewInfo,
      pageIndex: 1,
    })
    setFilterChanged(true)
  }

  useEffect(() => {
    if (filterChanged) {
      fetchRfqList(tableViewInfo)
      setFilterChanged(false)
    }
  }, [filterChanged])

  const onTableViewChange = (pageIndex, numberOfRows = 25) => {
    fetchRfqList({ pageIndex, rowsCount: numberOfRows })
  }

  const openRfqModal = (rfq) => {
    setRfqModalOpen(true)
    setSelectedRfq(rfq)
    history.push(`/rfq/${rfq.id}/lines`)
  }

  const handleClose = () => {
    if (isRfqModalOpenFromURL) {
      fetchRfqList({
        pageIndex: 1,
        rowsCount: 25,
        totalRows: 1,
      })
    }
    setIsRfqModalOpenFromURL(false)
    setRfqModalOpen(false)
    setRfqModalSelectedTab('lines')
    dispatch(unsetRfqData())
    history.push('/rfq/list')
  }

  const onCheckRfq = (rows) => {
    setCheckedRfqs(rows)
  }

  const bulkActionUpdated = (updatedObj) => {
    const checkedRows = checkedRfqs
    const rowsList = rfqList

    for (let i = 0; i < checkedRows.length; i += 1) {
      for (let j = 0; j < rowsList.length; j += 1) {
        if (rowsList[j].id === checkedRows[i]) {
          rowsList[j] = {
            ...rowsList[j],
            ...updatedObj,
          }
        }
      }
    }

    setRfqList([...rowsList])
  }

  const onCreateRfqModalOpen = () => {
    setIsCreateRfq(true)
  }

  return (
    <RfqListContext.Provider
      value={{
        fetchRfqList,
        onCheckRfq,
        rows: rfqList,
        userList,
        statusList,
        suppliersList,
        onTableViewChange,
      }}
    >
      {statusList && userList && suppliersList ? (
        <RfqListContainer>
          <Box mb={6}>
            <RfqTable
              openRfqModal={openRfqModal}
              onFilterChange={handleChange}
            />
          </Box>

          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            className='table-actions'
          >
            <TableBulkActions
              checkedRfqs={checkedRfqs}
              bulkActionUpdated={bulkActionUpdated}
            />
            <Fab
              color='secondary'
              aria-label='add'
              onClick={onCreateRfqModalOpen}
            >
              <AddIcon />
            </Fab>
            <CreateRfqModal
              open={isCreateRfq}
              handleClose={() => {
                setIsCreateRfq(false)
              }}
            />
          </Box>

          <Dialog open={rfqModalOpen} onClose={handleClose}>
            <RfqReadModal
              rfq={selectedRfq}
              userList={userList}
              selectedTab={rfqModalSelectedTab}
              onClose={handleClose}
            />
          </Dialog>
        </RfqListContainer>
      ) : (
        <LoadingSpinner />
      )}
    </RfqListContext.Provider>
  )
}
export default withRouter(RfqList)
