import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

import adminActions from 'services/redux/admin/actions'
import {
  getGoogleAuthKeyAPI,
  deleteGoogleAuthCodeAPI,
  validateGoogleAuthKeyAPI,
} from 'services/helpers/apis/user'

import { ModalContent } from './index.style'

const { onApiError } = adminActions

class MfaModalContent extends Component {
  state = {
    step: 0,
    password: '',
    qrCode: '',
    mfaCode: '',
    submitPassword: false,
  }

  componentDidMount() {}

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value })
  }

  onConfirm = (event) => {
    event.preventDefault()

    const { step, password, mfaCode } = this.state
    const { mfaUpdateMode, userData } = this.props

    if (mfaUpdateMode === 'enable') {
      if (step === 0) {
        this.setState({
          submitPassword: true,
        })
        getGoogleAuthKeyAPI(password)
          .then((res) => {
            const mfaCodes = res.data.data.mfa_codes

            this.setState((prevState) => ({
              step: prevState.step + 1,
              qrCode: mfaCodes.qr_code,
              submitPassword: false,
            }))
          })
          .catch((err) => {
            this.props.onApiError(err)
            this.setState({
              submitPassword: false,
            })
          })
      } else if (step === 1) {
        this.setState((prevState) => ({
          step: prevState.step + 1,
        }))
      } else if (step === 2) {
        validateGoogleAuthKeyAPI(mfaCode, password)
          .then(() => {
            toast.success('MFA has been enabled')
            this.props.onMFAEnabled()
            this.props.onClose(mfaUpdateMode)
          })
          .catch((err) => {
            this.props.onApiError(err)
          })
      }
    } else {
      deleteGoogleAuthCodeAPI(password)
        .then(() => {
          this.setState((prevState) => ({
            step: prevState.step + 1,
          }))
          this.props.onMFARemoved()
          if (!userData.require_mfa) {
            this.props.onClose(mfaUpdateMode)
            toast.warn(
              'Multifactor Authentication has been removed from this account'
            )
          }
        })
        .catch((err) => {
          this.props.onApiError(err)
        })
    }
  }

  onCancel = () => {
    const { step, password } = this.state
    const { mfaUpdateMode } = this.props

    if (mfaUpdateMode === 'enable') {
      if (step === 0) {
        this.props.onClose(mfaUpdateMode)
      } else if (step === 1 || step === 2) {
        deleteGoogleAuthCodeAPI(password)
          .then(() => {
            toast.warn(
              'Multifactor Authentication has been removed from this account'
            )
            this.props.onClose(mfaUpdateMode)
          })
          .catch((err) => {
            this.props.onApiError(err)
          })
      }
    } else if (mfaUpdateMode === 'remove') {
      if (step === 0) {
        this.props.onClose()
      } else {
        this.props.onClose(mfaUpdateMode)
        toast.warn(
          'Multifactor Authentication has been removed from this account'
        )
      }
    }
  }

  render() {
    const { step, qrCode, password, mfaCode, submitPassword } = this.state
    const { mfaUpdateMode, userData } = this.props

    return (
      <ModalContent>
        <form onSubmit={this.onConfirm}>
          {step === 0 && (
            <div className='mfa-form'>
              <p>Please Confirm Your Password</p>
              <TextField
                label='Password'
                type='password'
                value={this.state.password}
                onChange={this.handleChange('password')}
              />
            </div>
          )}
          {step === 1 && mfaUpdateMode === 'enable' && (
            <div className='mfa-form'>
              <p>Scan this code with your MFA App</p>
              <img src={qrCode} alt='qrCode' />
            </div>
          )}
          {step === 1 && mfaUpdateMode === 'remove' && userData.require_mfa && (
            <div className='mfa-form'>
              <p>
                Multifactor Authenticaton is enabled. Because you are required
                to use MFA with this app, you will be logged out upon removing
                MFA, and you will be required to add MFA back to your account
                when you log in again.
              </p>
            </div>
          )}
          {step === 2 && mfaUpdateMode === 'enable' && (
            <div className='mfa-form'>
              <p>Enter the Code From Your MFA App</p>
              <TextField
                label='Code'
                type='text'
                value={this.state.mfaCode}
                onChange={this.handleChange('mfaCode')}
              />
            </div>
          )}
          <div className='modal-action'>
            <Button
              variant='contained'
              color='secondary'
              onClick={this.onCancel}
            >
              {mfaUpdateMode === 'remove' && step === 1 ? 'Close' : 'Cancel'}
            </Button>
            {mfaUpdateMode === 'enable' && (
              <Button
                variant='contained'
                color='primary'
                type='submit'
                disabled={
                  (step === 0 && password === '') ||
                  submitPassword ||
                  (step === 2 && mfaCode === '')
                }
              >
                {step === 0 || step === 2 ? 'Confirm' : 'Next >'}
              </Button>
            )}
            {mfaUpdateMode === 'remove' && step === 0 && (
              <Button
                variant='contained'
                color='primary'
                type='submit'
                disabled={password === ''}
              >
                Confirm
              </Button>
            )}
          </div>
        </form>
      </ModalContent>
    )
  }
}

MfaModalContent.propTypes = {
  onClose: PropTypes.func.isRequired,
  mfaUpdateMode: PropTypes.string.isRequired,
  onMFAEnabled: PropTypes.func.isRequired,
  onMFARemoved: PropTypes.func.isRequired,
  userData: PropTypes.shape().isRequired,
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ onApiError }, dispatch)

export default connect(null, mapDispatchToProps)(MfaModalContent)
