import React, { Component } from 'react'
import styled from 'styled-components'
import Chip from '@material-ui/core/Chip'

const ProfileViewContainer = styled.div`
  position: relative;

  .tip {
    position: absolute;
    right: 0;
  }

  h2 {
    margin: 0;
  }

  p.username {
    margin-bottom: 2em;
  }

  .contact-info {
    margin-bottom: 2em;
    p {
      margin: 0;
    }
  }
`

class ProfileView extends Component {
  componentDidMount() {}

  render() {
    const { userData } = this.props

    return (
      <ProfileViewContainer>
        <div className="tip">
          <Chip
            label={`${userData.active ? 'Active' : 'Inactive'}`}
            color={`${userData.active ? 'primary' : 'secondary'}`}
          />
        </div>
        <h2>
          {userData.first_name} {userData.last_name}
        </h2>
        <p className="username">{userData.username}</p>
        <div className="contact-info">
          <a href={`mailto:${userData.email}`} target="_top">
            {userData.email}
          </a>
          <p>{userData.phone}</p>
        </div>
        <p>Last Login: {userData.last_login}</p>
        <p>User Created: {userData.date_added}</p>
        <p>MFA: {userData.mfa ? 'Enabled' : 'Disabled'}</p>
      </ProfileViewContainer>
    )
  }
}

export default ProfileView
