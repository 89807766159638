import { client } from '../apiConfig'

const getCarriersList = () => {
  return client.get('/carriers/list')
}

const getCarriersServicesList = (carrierId) => {
  return client.get(
    `/carriers/services/list${carrierId ? `?carrier=${carrierId}` : ''}`
  )
}

export { getCarriersList, getCarriersServicesList }
