import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import styled from 'styled-components'
import Icon from '@material-ui/core/Icon'
import { ChevronRight } from '@material-ui/icons'
import { toast } from 'react-toastify'

import { client } from 'services/helpers/apiConfig'
import StyledButton from 'components/common/button'

const PurchaseConfirmModalContent = styled.div`
  textarea {
    padding: 10px;
    min-width: 400px;
    min-height: 300px;
  }
`

class PurchaseConfirmModal extends Component {
  state = {
    message: this.props.content,
  }

  componentDidMount() {}

  handleChange = (event) => {
    this.setState({ message: event.target.value })
  }

  confirmPurchase = async () => {
    const { poId, onPoModal } = this.props

    try {
      const res = await client.put('po/confirm', {
        po_id: poId,
        message_body: this.state.message,
      })
      toast.success(`Purchase Order ${poId} Sent to ${res.data.data.supplier}.`)

      if (onPoModal) {
        this.props.onSuccessConfirmation(res.data.data)
      }

      this.props.onClose()
    } catch (e) {
      this.props.onApiError(e)
    }
  }

  render() {
    const { open, onClose } = this.props
    const { message } = this.state

    return (
      <Dialog open={open} onClose={onClose}>
        <DialogContent>
          <PurchaseConfirmModalContent>
            <textarea value={message} onChange={this.handleChange} />
          </PurchaseConfirmModalContent>
        </DialogContent>

        <DialogActions>
          <Button variant='contained' onClick={onClose}>
            Cancel
          </Button>
          <StyledButton className='btn-orange' onClick={this.confirmPurchase}>
            Send{' '}
            <Icon>
              <ChevronRight />
            </Icon>
          </StyledButton>
        </DialogActions>
      </Dialog>
    )
  }
}

export default PurchaseConfirmModal
