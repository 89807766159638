import React from 'react'
import _find from 'lodash/find'
import styled from 'styled-components'

const StatusLabelContainer = styled.div`
  background-color: ${(props) => props.status.color};
  border-radius: 4px;
  padding: 5px;
  color: black;
  text-align: center;
  width: 120px;
`

const StatusLabel = ({ statusList, statusId, children }) => {
  const status = _find(statusList, { id: statusId })

  return <StatusLabelContainer status={status}>{children}</StatusLabelContainer>
}

export default StatusLabel
