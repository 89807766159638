/* eslint-disable camelcase */
import { client } from '../apiConfig'

const fetchCarriersServicesAPI = () => {
  return client.get('/carriers/services/list')
}

const fetchShipmentAPI = (uuid) => {
  return client.get(`po/read/${uuid}/shipment`)
}

const fetchShipmentTrackingAPI = (shipment, poUUID) => {
  const { carrier, tracking_number } = shipment

  return client.get(`shipment/${carrier}/${tracking_number}/${poUUID}`)
}

const fetchCarriersListAPI = () => {
  return client.get('/carriers/list')
}

const fetchShipmentListAPI = (status, startDate) => {
  if (status !== 'none' && startDate !== 'none') {
    return client.get(`/shipment/list?status=${status}&after=${startDate}`)
  }

  if (status !== 'none' && startDate === 'none') {
    return client.get(`/shipment/list?status=${status}`)
  }

  if (status === 'none' && startDate !== 'none') {
    return client.get(`/shipment/list?after=${startDate}`)
  }

  return client.get('/shipment/list')
}

const readShipmentAPI = (trackingNumber) => {
  return client.get(`/shipment/read/${trackingNumber}`)
}

const getManufacturerListAPI = (partNumber = null) => {
  if (partNumber) {
    return client.get(`/manufacturer/list/?part_number=${partNumber}`)
  }
  return client.get(`/manufacturer/list`)
}

export {
  fetchCarriersServicesAPI,
  fetchShipmentAPI,
  fetchShipmentTrackingAPI,
  fetchCarriersListAPI,
  fetchShipmentListAPI,
  readShipmentAPI,
  getManufacturerListAPI,
}
