import { client } from '../apiConfig'

const loginAPI = (authData) => {
  let apiString = `/user/login?username=${
    authData.userName
  }&password=${encodeURIComponent(authData.password)}`

  if (authData.googleAuthenticatorCode) {
    apiString = `${apiString}&mfa_code=${authData.googleAuthenticatorCode}`
  }

  return client.get(apiString)
}

const forgotPasswordAPI = (userName) => {
  return client.get(`/user/passwordReset?username=${userName}`)
}

const setPasswordAPI = (data) => {
  return client.put(`/user/passwordSet`, data)
}

const forceLogoutAPI = (userId) => {
  return client.get(`/user/forceLogout?user_id=${userId}`)
}

const fetchUserListAPI = () => {
  return client.get('user/list')
}

const fetchUserSupplierListAPI = (cancelToken) => {
  return client.get('/user/list/supplier', {
    cancelToken,
  })
}

const bulkUserMakeActiveAPI = (userIds) => {
  return client.put('/user/update/active', {
    user_id: userIds,
  })
}

const bulkUserMakeInactiveAPI = (userIds) => {
  return client.put('/user/update/inactive', {
    user_id: userIds,
  })
}

const bulkUserForceLogoutAPI = (userIds) => {
  return client.put('/user/update/forceLogout', {
    user_id: userIds,
  })
}

const bulkUserAddMFAAPI = (userIds) => {
  return client.put('/user/update/addMFA', {
    user_id: userIds,
  })
}

const userReadAPI = (userName) => {
  return client.get(`/user/read/${userName}`)
}

const userUpdateAPI = (userName, userData) => {
  return client.put(`/user/update/${userName}`, userData)
}

const revokeAPI = (key) => {
  if (key) {
    return client.delete(`/user/deleteToken/?key=${key}`)
  }

  return client.delete('/user/deleteToken/')
}

const getGoogleAuthKeyAPI = (password) => {
  return client.get(`/user/getGoogleAuthKey?password=${password}`)
}

const deleteGoogleAuthCodeAPI = (password, userId = null) => {
  if (userId) {
    return client.delete(
      `/user/deleteGoogleAuthCode?password=${password}&userId=${userId}`
    )
  }
  return client.delete(`/user/deleteGoogleAuthCode?password=${password}`)
}

const validateGoogleAuthKeyAPI = (mfaCode, password) => {
  return client.get(
    `/user/validateGoogleAuthKey?mfa_code=${mfaCode}&password=${password}`
  )
}

const addMFAAPI = (userId) => {
  return client.put('/user/addMFA', {
    id: userId,
  })
}

const removeMFAAPI = (userId) => {
  return client.put('/user/removeMFA', {
    id: userId,
  })
}

const deleteUserAPI = (userId) => {
  return client.delete(`/user/delete/${userId}`)
}

export {
  loginAPI,
  forgotPasswordAPI,
  setPasswordAPI,
  forceLogoutAPI,
  fetchUserListAPI,
  fetchUserSupplierListAPI,
  bulkUserMakeActiveAPI,
  bulkUserMakeInactiveAPI,
  bulkUserForceLogoutAPI,
  bulkUserAddMFAAPI,
  userReadAPI,
  userUpdateAPI,
  revokeAPI,
  getGoogleAuthKeyAPI,
  deleteGoogleAuthCodeAPI,
  validateGoogleAuthKeyAPI,
  addMFAAPI,
  removeMFAAPI,
  deleteUserAPI,
}
