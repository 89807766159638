import { client } from '../apiConfig'

const createRfqAPI = (supplierID) => {
  return client.post('/rfq/create', {
    supplier_id: supplierID,
  })
}

const updateRfqAPI = (uuid, params) => {
  if (uuid) {
    return client.put(`/rfq/update/${uuid}`, params)
  }
  return client.put(`/rfq/update`, params)
}

const createRfqLineAPI = (rfqBody) => {
  return client.post('/rfq/line/create', rfqBody)
}

const createRfqLineBatchAPI = (rfqId, requisitionIds) => {
  return client.post('/rfq/line/create/batch', {
    rfq_id: rfqId,
    requisition_ids: requisitionIds,
  })
}

const confirmRfqAPI = (rfqId, messageBody) => {
  return client.put('/rfq/confirm', {
    rfq_id: rfqId,
    message_body: messageBody,
  })
}

const batchConfirmRfqAPI = (rfqIds) => {
  return client.put('/rfq/confirm/batch', {
    id: rfqIds.toString(),
  })
}

const listRfqAPI = (query) => {
  return client.get(query ? `/rfq/list/${query}` : '/rfq/list')
}

const getRfqDraftAPI = (supplierID) => {
  return client.get(`/rfq/list/draft?supplier_id=${supplierID}`)
}

const getRfqSearchAPI = (term, assignee, supplierId) => {
  return client.get(
    `/rfq/search/?term=${term}&assignee=${assignee}&supplier_id=${supplierId}`
  )
}

const readRfqAPI = (uuid) => {
  return client.get(`/rfq/read/${uuid}`)
}

const deleteRfqAPI = (uuid) => {
  return client.delete(`/rfq/delete/${uuid}`)
}

const getRfqCorrespondenceAPI = (uuid) => {
  return client.get(`/rfq/correspondence/${uuid}`)
}

const createRfqQuoteAPI = (quoteBody) => {
  return client.post('/rfq/quote/create', quoteBody)
}

const getRfqQuoteAPI = (uuid) => {
  return client.get(`/rfq/quote/read/${uuid}`)
}

const updateRfqQuoteAPI = (quoteLineUUID, updateBody) => {
  return client.put(`rfq/quote/update/${quoteLineUUID}`, updateBody)
}

const addRfqQuoteAPI = (uuid, rfqLine) => {
  return client.post(`rfq/quote/add/${uuid}`, rfqLine)
}

const deleteRfqQuoteAPI = (quoteLineUUID) => {
  return client.delete(`rfq/quote/delete/${quoteLineUUID}`)
}

const getRfqHistoryAPI = (uuid) => {
  return client.get(`/rfq/history/${uuid}`)
}

const getRfqMessageTemplateAPI = (rfqId) => {
  return client.get(`/rfq/message_template/${rfqId}`)
}

const cancelRfqLineAPI = (uuid) => {
  return client.delete(`rfq/line/cancel/${uuid}`)
}

const deleteRfqLineAPI = (uuid) => {
  return client.delete(`/rfq/line/delete/${uuid}`)
}

const updateRfqLineAPI = (uuid, lineBody) => {
  return client.put(`/rfq/line/update/${uuid}`, lineBody)
}

const createRfqCorrespondenceAPI = (uuid) => {
  return client.post(`/rfq/correspondence/create/${uuid}`)
}

const updateRfqCorrespondenceAPI = (messageUuid, { type, subject, body }) => {
  return client.put(`/rfq/correspondence/update/${messageUuid}`, {
    type,
    subject,
    body,
  })
}

const getRfqStatusesAPI = () => {
  return client.get(`/rfq/status/list`)
}

const updateRfqStatusAPI = (uuid, statusId) => {
  return client.put(`/rfq/status/update/${uuid}`, {
    status_id: statusId,
  })
}

const batchUpdateRfqStatusAPI = (uuids, statusId) => {
  return client.put('/rfq/status/update/batch', {
    status_id: statusId,
    rfq_uuids: uuids,
  })
}

const updateRfqQuoteRatingAPI = (uuid, rating) => {
  return client.put(`/rfq/quote/rating/${rating}/${uuid}`)
}

const updateRfqQuotePauseAPI = (uuid, toggle) => {
  return client.put(`/rfq/quote/pause/${toggle}/${uuid}`)
}

const finalizeRfqQuoteAPI = (uuid) => {
  return client.put(`/rfq/quote/finalize/${uuid}`)
}

export {
  createRfqAPI,
  createRfqLineAPI,
  createRfqLineBatchAPI,
  updateRfqAPI,
  confirmRfqAPI,
  batchConfirmRfqAPI,
  listRfqAPI,
  getRfqSearchAPI,
  getRfqDraftAPI,
  readRfqAPI,
  deleteRfqAPI,
  getRfqCorrespondenceAPI,
  createRfqQuoteAPI,
  getRfqQuoteAPI,
  updateRfqQuoteAPI,
  addRfqQuoteAPI,
  deleteRfqQuoteAPI,
  getRfqHistoryAPI,
  getRfqMessageTemplateAPI,
  cancelRfqLineAPI,
  deleteRfqLineAPI,
  updateRfqLineAPI,
  createRfqCorrespondenceAPI,
  updateRfqCorrespondenceAPI,
  getRfqStatusesAPI,
  updateRfqStatusAPI,
  updateRfqQuoteRatingAPI,
  updateRfqQuotePauseAPI,
  finalizeRfqQuoteAPI,
  batchUpdateRfqStatusAPI,
}
