import React, { useContext } from 'react'
import { InputLabel, FormControl, MenuItem, Select } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { toast } from 'react-toastify'

import { GlobalContext } from 'routes'
import { updateAPI as updateSupplierNameVariantAPI } from 'services/helpers/apis/supplierNameVariant'
import { updateAPI as updateManufacturerNameVariantAPI } from 'services/helpers/apis/manufacturerNameVariant'

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: 120,
  },
}))

const SelectAssociate = ({ list, value, uuid, tableFor }) => {
  const context = useContext(GlobalContext)
  const classes = useStyles()
  const [selectedValue, setSelectedValue] = React.useState(value)

  const handleChange = async (event) => {
    setSelectedValue(event.target.value)

    try {
      if (tableFor === 'supplier') {
        await updateSupplierNameVariantAPI(uuid, {
          supplier_id: event.target.value,
        })
        toast.success('Supplier updated')
      } else {
        await updateManufacturerNameVariantAPI(uuid, {
          manufacturer_id: event.target.value,
        })
        toast.success('Manufacturer updated')
      }
    } catch (e) {
      context.onApiError(e)
      setSelectedValue(value)
    }
  }

  return (
    <FormControl variant='outlined' className={classes.formControl} fullWidth>
      <InputLabel id='select'>
        {tableFor === 'supplier' ? 'Supplier' : 'Manufacturer'}
      </InputLabel>
      <Select
        labelId='select'
        value={selectedValue}
        onChange={handleChange}
        label='Select Associate'
      >
        <MenuItem value='0'>
          <em>None</em>
        </MenuItem>
        {list.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default SelectAssociate
