import { client } from '../apiConfig'

const getListAPI = (query) => {
  return client.get(`/supplier_name_variant/list?${query}`)
}

const getServicesListAPI = () => {
  return client.get('/supplier_name_variant/services/list')
}

const searchAPI = (term) => {
  return client.get(`/supplier_name_variant/search?term=${term}`)
}

const deleteAPI = (uuid) => {
  return client.delete(`/supplier_name_variant/delete/${uuid}`)
}

const updateAPI = (uuid, body) => {
  return client.put(`/supplier_name_variant/update/${uuid}`, body)
}

export { getListAPI, getServicesListAPI, searchAPI, deleteAPI, updateAPI }
