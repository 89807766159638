import React, { useContext, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import StyledButton from 'components/common/button'
import { createRfqAPI } from 'services/helpers/apis/rfq'
import { GlobalContext } from 'routes'

import { RfqListContext } from './index'

const CreateRfqModal = ({ open, handleClose }) => {
  const rfqListContext = useContext(RfqListContext)
  const context = useContext(GlobalContext)
  const [supplierId, setSupplierId] = useState('')
  const history = useHistory()

  const handleChange = (event) => {
    setSupplierId(event.target.value)
  }

  const handleCreate = async () => {
    try {
      const res = await createRfqAPI(supplierId)
      handleClose()
      history.push(`/rfq/${res.data.data.rfq_id}/lines`)
    } catch (e) {
      context.onApiError(e)
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      open={open}
      onClose={handleClose}
      aria-labelledby='form-dialog-title'
    >
      <DialogTitle id='form-dialog-title'>Create a new RFQ</DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <InputLabel id='supplier-select-label'>Supplier</InputLabel>
          <Select
            labelId='supplier-select-label'
            id='supplier-select'
            value={supplierId}
            onChange={handleChange}
          >
            {rfqListContext.suppliersList.map((supplier) => (
              <MenuItem value={supplier.id} key={supplier.id}>
                {supplier.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <StyledButton
          variant='contained'
          onClick={handleClose}
          className='btn-orange'
        >
          Cancel
        </StyledButton>
        <Button
          variant='contained'
          onClick={handleCreate}
          color='primary'
          disabled={supplierId === ''}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CreateRfqModal
