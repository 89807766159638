import React, { useState, useContext, useEffect } from 'react'
import styled from 'styled-components'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Dialog,
} from '@material-ui/core'
import SaveIcon from '@material-ui/icons/Save'
import blue from '@material-ui/core/colors/blue'
import { toast } from 'react-toastify'

import { createPoLineAPI, deletePoLineAPI } from 'services/helpers/apis/po'
import leadTimeValues from 'utils/leadTimes'
import { GlobalContext } from 'routes'

import ChangeStatusModalContent from './components/changeStatusModalContent'
import { ModalContext } from './index'
import LineTableRow from './lineTableRow'

const LineTableContainer = styled.div`
  .mui-icon-button {
    padding: 0;
  }

  .lead-time-selector {
    min-width: 150px;
  }

  .new-line {
    background-color: ${blue[200]};

    th {
      position: relative;
      p.new-line-title {
        margin: 0;
        position: absolute;
        top: 0;
      }
    }

    .input-field {
      width: 100px;
    }
  }
`

const LineTable = ({ isAddNewLine, closeAddNewLine }) => {
  const context = useContext(GlobalContext)
  const modalContext = useContext(ModalContext)
  const [rows, setRows] = useState()
  const [inputValues, setInputValues] = useState({
    quantity: 0,
    item: '',
    unit_cost: 0,
    lead_time: '',
  })
  const [openChangeStatusModal, setOpenChangeStatusModal] = useState(false)
  const [changeStatusFor, setChangeStatusFor] = useState()
  const [selectedRowToDelete, setSelectedRowToDelete] = useState({
    row: null,
    index: null,
  })
  const { orderData } = modalContext

  useEffect(() => {
    if (orderData) {
      setRows(orderData.lines.lines)
    }
  }, [orderData])

  const onInputUpdate = (event) => {
    const { name, value } = event.target
    setInputValues({
      ...inputValues,
      [name]: value,
    })
  }

  const updateOrderData = (lines) => {
    const newOrderData = modalContext.orderData
    newOrderData.lines.lines = [...lines]
    modalContext.updateOrderData(newOrderData)
  }

  const onSaveNewLine = async () => {
    try {
      const res = await createPoLineAPI({
        ...inputValues,
        po_id: modalContext.orderData.id,
      })

      const newRows = [...rows, res.data.data]
      toast.success('New line item is added.')
      setRows(newRows)
      setInputValues({
        quantity: 0,
        item: '',
        unit_cost: 0,
      })
      updateOrderData(newRows)
      closeAddNewLine()
    } catch (e) {
      context.onApiError(e)
    }
  }

  const onDeleteLine = async (row, i) => {
    try {
      await deletePoLineAPI(row.uuid)
      const newRows = [...rows]
      newRows.splice(i, 1)
      setRows(newRows)
      updateOrderData(newRows)
      setSelectedRowToDelete({ row: null, index: null })
    } catch (e) {
      context.onApiError(e)
    }
  }

  const onCheckDeleteLinePermission = (row, i) => {
    if (!orderData.permissions.can_delete_lines) {
      setOpenChangeStatusModal(true)
      setChangeStatusFor('delete this line')
      setSelectedRowToDelete({ row, index: i })
    } else {
      onDeleteLine(row, i)
    }
  }

  const onCloseChangeStatusModal = () => {
    setOpenChangeStatusModal(false)
    setSelectedRowToDelete({ row: null, index: null })
  }

  console.log(rows)

  return (
    <LineTableContainer>
      <TableContainer component={Paper}>
        <Table size='small' aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>Quantity</TableCell>
              <TableCell>Part Number</TableCell>
              <TableCell>Supplier Part Number</TableCell>
              <TableCell>Manufacturer</TableCell>
              <TableCell>Requisition</TableCell>
              <TableCell>Unit Price</TableCell>
              <TableCell>Extended Price</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows &&
              rows.map((row, i) => (
                <LineTableRow
                  key={i}
                  row={row}
                  index={i}
                  onDeleteLine={onCheckDeleteLinePermission}
                />
              ))}
            {isAddNewLine && (
              <TableRow className='new-line'>
                <TableCell component='th' scope='row'>
                  <p className='new-line-title'>New Line Item</p>
                  <input
                    type='number'
                    name='quantity'
                    value={inputValues.quantity}
                    onChange={onInputUpdate}
                    className='input-field'
                  ></input>
                </TableCell>
                <TableCell>
                  <input
                    type='text'
                    name='item'
                    value={inputValues.item}
                    onChange={onInputUpdate}
                    className='input-field'
                  ></input>
                </TableCell>
                <TableCell>
                  <FormControl
                    variant='outlined'
                    fullWidth
                    className='lead-time-selector'
                  >
                    <InputLabel id='lead-time-select-outlined-label'>
                      Lead Time
                    </InputLabel>
                    <Select
                      labelId='lead-time-select-outlined-label'
                      id='lead-time-select-outlined'
                      name='lead_time'
                      value={inputValues.lead_time_menu}
                      onChange={onInputUpdate}
                      label='Lead Time'
                    >
                      {leadTimeValues.map((leadtime, i) => (
                        <MenuItem value={leadtime.value} key={i}>
                          {leadtime.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <input
                    type='number'
                    name='unit_cost'
                    value={inputValues.unit_cost}
                    onChange={onInputUpdate}
                    className='input-field'
                  ></input>
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <IconButton className='btn-save' onClick={onSaveNewLine}>
                    <SaveIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog onClose={onCloseChangeStatusModal} open={openChangeStatusModal}>
        <ChangeStatusModalContent
          changeStatusFor={changeStatusFor}
          onDeleteLine={() =>
            onDeleteLine(selectedRowToDelete.row, selectedRowToDelete.index)
          }
          onClose={onCloseChangeStatusModal}
        />
      </Dialog>
    </LineTableContainer>
  )
}

export default LineTable
