import React, { Component } from 'react'

import { getRequisitionInventoryAPI } from 'services/helpers/apis/requisition'

import TabInventoryContainer from './tabInventory.style'
import Inventory from './inventory'

class TabInventory extends Component {
  state = {
    inventories: [],
  }

  async componentDidMount() {
    const { uuid } = this.props

    const res = await getRequisitionInventoryAPI(uuid)

    const inventories = res.data.data

    this.setState({
      inventories,
    })
  }

  render() {
    const { inventories } = this.state

    return (
      <TabInventoryContainer>
        {inventories.length > 0 ? (
          inventories.map((inventory, i) => (
            <Inventory inventory={inventory} key={i} />
          ))
        ) : (
          <p>No inventory found</p>
        )}
      </TabInventoryContainer>
    )
  }
}

export default TabInventory
