import React, { useState, useEffect, useContext } from 'react'
import {
  Button,
  Tooltip,
  NativeSelect,
  FormControl,
  IconButton,
} from '@material-ui/core'
import ReplyIcon from '@material-ui/icons/Reply'
import NotesIcon from '@material-ui/icons/Notes'
import DeleteIcon from '@material-ui/icons/Delete'
import { Editor, EditorState } from 'draft-js'
import 'draft-js/dist/Draft.css'
import _filter from 'lodash/filter'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import { stateToHTML } from 'draft-js-export-html'
import { stateFromHTML } from 'draft-js-import-html'
import { toast } from 'react-toastify'

import { GlobalContext } from 'routes'
import { PageWrapperContext } from 'pages/pageWrapper'
import {
  sendThreadDraftAPI,
  updateThreadDraftAPI,
  deleteThreadDraftAPI,
} from 'services/helpers/apis/thread'
import { generateQuotesHTMLContent } from 'utils/common'
import usePrevious from 'utils/usePrevious'

import ReplyMessageContainer from './replyMessage.style'

const WAIT_INTERVAL = 1000
let timer

const ReplyMessage = ({
  correspondence,
  selectedLine,
  replyType,
  messageUUID,
  closeReply,
  onCorrespondenceUpdated,
}) => {
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty()
  )
  const prevEditorState = usePrevious(editorState)
  const { messages } = correspondence
  const quoteMessages = _filter(messages, function(o) {
    return !o.is_draft
  })
  const isInternal =
    replyType === 'internal_note' ? true : messages[0].is_internal
  const [replyValue, setReplyValue] = useState(
    isInternal ? 'internal' : 'supplier'
  )
  const context = useContext(GlobalContext)
  const [isShowQuoteMessages, setIsShowQuoteMessages] = useState(false)
  const pageWrapperContext = useContext(PageWrapperContext)

  useEffect(() => {
    timer = null

    const html = `<br/><br/>${pageWrapperContext.userData.email_signature_html}`

    const contentState = stateFromHTML(html)
    setEditorState(EditorState.createWithContent(contentState))
  }, [])

  const onShowTrimmedContent = () => {
    setIsShowQuoteMessages(true)
    const existingContent = stateToHTML(editorState.getCurrentContent())
    const quotesHtmlContent = generateQuotesHTMLContent(
      existingContent,
      quoteMessages
    )
    const contentState = stateFromHTML(quotesHtmlContent)

    setEditorState(EditorState.createWithContent(contentState))
  }

  const onSendReply = async () => {
    setTimeout(async () => {
      try {
        const html = stateToHTML(editorState.getCurrentContent())
        await updateThreadDraftAPI(messageUUID, {
          is_internal: replyValue === 'internal',
          body: html,
          is_quote_attached: isShowQuoteMessages,
        })
        const res = await sendThreadDraftAPI(messageUUID)

        closeReply()
        toast.success('Reply message is sent.')

        onCorrespondenceUpdated(res.data.data)
      } catch (e) {
        context.onApiError(e)
      }
    }, 1000)
  }

  const onUpdateThreadDraft = async () => {
    try {
      const html = stateToHTML(editorState.getCurrentContent())
      const res = await updateThreadDraftAPI(messageUUID, {
        is_internal: replyValue === 'internal',
        body: html,
        is_quote_attached: isShowQuoteMessages,
      })
      onCorrespondenceUpdated(res.data.data)
    } catch (e) {
      context.onApiError(e)
    }
  }

  const onChangeText = (editorState) => {
    setEditorState(editorState)
  }

  useEffect(() => {
    if (
      prevEditorState &&
      stateToHTML(prevEditorState.getCurrentContent()) !==
        stateToHTML(editorState.getCurrentContent())
    ) {
      clearTimeout(timer)
      timer = setTimeout(() => {
        onUpdateThreadDraft()
      }, WAIT_INTERVAL)
    }
  }, [editorState, prevEditorState])

  const onChangeReplyingOption = (e) => {
    setReplyValue(e.target.value)
  }

  const onDeleteDraft = async () => {
    try {
      const res = await deleteThreadDraftAPI(messageUUID)
      toast.success('Draft message is deleted')
      onCorrespondenceUpdated(res.data.data)
      closeReply()
    } catch (e) {
      context.onApiError(e)
    }
  }

  useEffect(() => {
    onUpdateThreadDraft()
  }, [replyValue])

  return (
    <ReplyMessageContainer>
      <div className='wrapper'>
        <div className='reply-header'>
          <div className='reply-to'>
            <div className='reply-option'>
              {replyValue === 'internal' ? <NotesIcon /> : <ReplyIcon />}
              <FormControl>
                <NativeSelect
                  value={replyValue}
                  name='type'
                  onChange={onChangeReplyingOption}
                  inputProps={{ 'aria-label': 'message-type' }}
                >
                  <option value='internal'>Internal Note</option>
                  <option value='supplier'>
                    Message to Supplier ({selectedLine.supplier})
                  </option>
                </NativeSelect>
              </FormControl>
            </div>
          </div>
          <span className='text-draft'>Draft</span>
        </div>
        <div className='reply-content'>
          <Editor editorState={editorState} onChange={onChangeText} />
          {quoteMessages.length > 0 && !isShowQuoteMessages && (
            <Tooltip title='Show trimmed content'>
              <Button
                variant='contained'
                onClick={onShowTrimmedContent}
                className='btn-show-quote'
              >
                <MoreHorizIcon />
              </Button>
            </Tooltip>
          )}
        </div>
        <div className='reply-buttons'>
          <IconButton aria-label='delete' onClick={onDeleteDraft}>
            <DeleteIcon />
          </IconButton>
          <Button variant='contained' color='primary' onClick={onSendReply}>
            Send
          </Button>
        </div>
      </div>
    </ReplyMessageContainer>
  )
}

export default ReplyMessage
