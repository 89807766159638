import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { FormControlLabel, Checkbox, Button, Box } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import _remove from 'lodash/remove'
import _filter from 'lodash/filter'
import { toast } from 'react-toastify'
import NumberFormat from 'react-number-format'
import numeral from 'numeral'

import { GlobalContext } from 'routes'
import {
  deleteRfqQuoteAPI,
  updateRfqQuoteAPI,
  updateRfqQuoteRatingAPI,
  updateRfqQuotePauseAPI,
  finalizeRfqQuoteAPI,
} from 'services/helpers/apis/rfq'
import { getManufacturerListAPI } from 'services/helpers/apis/other'
import rfqActions from 'services/redux/rfq/actions'
import DispatchSchedules from 'components/dispatchSchedules'

import NewTableContainer from './index.style'
import EditableInput from './editableInput'
import EditableSelect from './editableSelect'
import NewQuoteLine from './newQuoteLine'
import AddToPoButton from './addToPoButton'
import FinalizeConfirmDialog from './finalizeConfirmDialog'

const {
  resetMovingNonbidItem,
  onMoveQuotedItem,
  setIsQuoteUpdated,
} = rfqActions

const QuotedItemsTable = ({
  rows,
  rfqUUID,
  rfqId,
  permissions,
  supplierId,
}) => {
  const context = useContext(GlobalContext)
  const dispatch = useDispatch()
  const [selectAll, setSelectAll] = useState(false)
  const [manufacturerList, setManufacturerList] = useState(null)
  const [tableRows, setTableRows] = useState(null)
  const [showFinalizeModal, setShowFinalizeModal] = useState(false)
  const movingNonbidItem = useSelector((state) => state.rfq.moving_nonbid_item)
  const canEditQuote = permissions.can_edit_quote
  const canUpdateRating = permissions.can_update_rating
  const canEditLines = permissions.can_edit_lines
  const canPause = permissions.can_pause

  useEffect(() => {
    const fetchManufacturerList = async () => {
      try {
        const getManufacturerList = await getManufacturerListAPI()

        setManufacturerList(getManufacturerList.data.data)
      } catch (e) {
        context.onApiError(e)
      }
    }

    const quotedItems = rows
    quotedItems.map((item) => {
      const newItem = item
      newItem.selected = false
      return newItem
    })
    setTableRows(quotedItems)

    fetchManufacturerList()
  }, [])

  useEffect(() => {
    if (movingNonbidItem) {
      setTableRows([
        ...tableRows,
        { rfq_quote_line_uuid: movingNonbidItem.uuid, ...movingNonbidItem },
      ])
      dispatch(resetMovingNonbidItem())
    }
  }, [movingNonbidItem])

  const onSelectAllClicked = () => {
    const arr = tableRows

    arr.map((item) => {
      const newItem = item
      newItem.selected = !selectAll
      return newItem
    })

    setTableRows(arr)
    setSelectAll(!selectAll)
  }

  const updateRating = async (uuid, rating) => {
    try {
      await updateRfqQuoteRatingAPI(uuid, rating)
    } catch (e) {
      context.onApiError(e)
    }
  }

  const onClickSmile = (row, index) => {
    const arr = [...tableRows]
    arr[index].rating = arr[index].rating === 1 ? 0 : 1
    setTableRows(arr)
    updateRating(row.rfq_quote_line_uuid, arr[index].rating)
  }

  const onClickShit = (row, index) => {
    const arr = [...tableRows]
    arr[index].rating = arr[index].rating !== -1 ? -1 : 0
    setTableRows(arr)
    updateRating(row.rfq_quote_line_uuid, arr[index].rating)
  }

  const onClickPause = async (row, index) => {
    const arr = [...tableRows]
    try {
      await updateRfqQuotePauseAPI(
        row.rfq_quote_line_uuid,
        arr[index].pause ? 'off' : 'on'
      )
      arr[index].pause = !arr[index].pause
      setTableRows(arr)
    } catch (e) {
      context.onApiError(e)
    }
  }

  const onDeleteItem = async (row, isDownArrowClicked = false) => {
    try {
      await deleteRfqQuoteAPI(row.rfq_quote_line_uuid)
      const rows = tableRows
      _remove(rows, function(n) {
        return n.rfq_quote_line_uuid === row.rfq_quote_line_uuid
      })
      setTableRows([...rows])
      if (isDownArrowClicked) {
        dispatch(onMoveQuotedItem(row))
      } else {
        toast.success('Quote item is deleted.')
      }
    } catch (e) {
      context.onApiError(e)
    }
  }

  const onDownArrowClicked = (row) => {
    onDeleteItem(row, true)
  }

  const onQuoteAdded = (quoteBody) => {
    setTableRows([
      ...tableRows,
      {
        rfq_quote_line_uuid: quoteBody.uuid,
        isCustomItem: true,
        ...quoteBody,
      },
    ])
  }

  const onChangeSelectChecker = (index) => {
    const arr = tableRows
    arr[index].selected = !arr[index].selected

    setTableRows([...arr])
  }

  const onChangeCustomChecker = async (uuid, index) => {
    const arr = tableRows
    arr[index].supplier_part_number_custom = !arr[index]
      .supplier_part_number_custom

    try {
      await updateRfqQuoteAPI(uuid, {
        supplier_part_number_custom: arr[index].supplier_part_number_custom,
      })
      setTableRows([...arr])
    } catch (e) {
      context.onApiError(e)
    }
  }

  const getSelectedRows = () => {
    const selectedRows = _filter(tableRows, function(o) {
      return o.selected
    })
    return selectedRows
  }

  const onFinalizeQuote = async () => {
    try {
      await finalizeRfqQuoteAPI(rfqUUID)
      setShowFinalizeModal(true)
      dispatch(setIsQuoteUpdated(true))
    } catch (e) {
      context.onApiError(e)
    }
  }

  const onCloseFinalizeModal = () => {
    setShowFinalizeModal(false)
  }

  return (
    <NewTableContainer>
      {tableRows && tableRows.length > 0 && !canEditQuote && (
        <FormControlLabel
          control={
            <Checkbox
              checked={selectAll}
              onChange={onSelectAllClicked}
              value='selectAll'
              color='primary'
            />
          }
          label='Select all'
        />
      )}
      <div className='quoted-items'>
        {tableRows &&
          tableRows.map((row, i) => (
            <div className='quoted-item' key={i}>
              <Box>
                {!canEditQuote && (
                  <Checkbox
                    checked={row.selected}
                    value={`quote_item_${i}_checkbox`}
                    onChange={() => onChangeSelectChecker(i)}
                    color='primary'
                    inputProps={{ 'aria-label': `quote_item_${i}_checkbox` }}
                  />
                )}
                <div className='item-values'>
                  <Box className='field' mb={2}>
                    {canEditQuote ? (
                      <EditableInput
                        quoteLineUUID={row.rfq_quote_line_uuid}
                        inputValue={row.quantity}
                        inputType='quantity'
                        label='Quantity'
                      />
                    ) : (
                      <p>
                        Quantity <br /> {row.quantity}
                      </p>
                    )}
                  </Box>
                  <Box className='field' mb={2}>
                    <p>
                      Requisition #<br />
                      {!row.isCustomItem && (
                        <Link
                          to={`/requisitions/${row.rfq_line.requisition_detail.order_id}`}
                        >
                          {row.rfq_line.requisition_detail.order_id}
                        </Link>
                      )}
                    </p>
                  </Box>
                  <Box className='field' mb={2}>
                    {canEditQuote ? (
                      <EditableInput
                        quoteLineUUID={row.rfq_quote_line_uuid}
                        inputValue={row.item}
                        inputType='item'
                        className='input-string'
                        label='Part No.'
                      />
                    ) : (
                      <p>
                        Part No.
                        <br />
                        {row.item}
                      </p>
                    )}
                  </Box>
                  <Box className='field' mb={2}>
                    {canEditQuote && row.supplier_part_number_custom ? (
                      <EditableInput
                        quoteLineUUID={row.rfq_quote_line_uuid}
                        inputValue={row.supplier_part_number}
                        inputType='supplier_part_number'
                        className='input-string'
                        label='Supplier Part No'
                      />
                    ) : (
                      <p>
                        Supplier Part No
                        <br />
                        {row.supplier_part_number}
                      </p>
                    )}
                  </Box>
                  {canEditQuote && (
                    <Box className='field custom-field' mb={2}>
                      <p>Custom</p>
                      <Checkbox
                        checked={row.supplier_part_number_custom}
                        onChange={() =>
                          onChangeCustomChecker(row.rfq_quote_line_uuid, i)
                        }
                        value='secondary'
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </Box>
                  )}
                  <Box className='field' mb={2}>
                    {manufacturerList && (
                      <EditableSelect
                        quoteLineUUID={row.rfq_quote_line_uuid}
                        selectList={manufacturerList}
                        selectValue={row.manufacturer_id}
                        selectType='manufacturer_id'
                        disabled={!canEditQuote}
                      />
                    )}
                  </Box>
                  <Box className='field' mb={2}>
                    {canEditQuote ? (
                      <EditableInput
                        quoteLineUUID={row.rfq_quote_line_uuid}
                        inputValue={numeral(row.price).format('0.00')}
                        inputType='price'
                        className='input-number'
                        label='Price'
                      />
                    ) : (
                      <p>
                        Price
                        <br />
                        <NumberFormat
                          value={row.price}
                          displayType='text'
                          thousandSeparator
                          prefix='$'
                          decimalScale={3}
                        />
                      </p>
                    )}
                  </Box>
                </div>
                <DispatchSchedules line={row} scheduleFor='rfq' />
              </Box>

              {row.po_lines && row.po_lines.length > 0 && (
                <div className='associated-pos'>
                  {row.po_lines.map((poLine, i) => (
                    <Link to={`/po/${poLine.po_id}`} key={i}>
                      <span key={i}>
                        {poLine.quantity} in PO {poLine.po_id}
                      </span>
                    </Link>
                  ))}
                </div>
              )}

              <div className='row-actions'>
                {canEditQuote && !row.isCustomItem && (
                  <ArrowDownwardIcon onClick={() => onDownArrowClicked(row)} />
                )}
                {canEditQuote && row.isCustomItem && (
                  <DeleteIcon onClick={() => onDeleteItem(row)} />
                )}
                {canUpdateRating && (
                  <span
                    onClick={() => onClickShit(row, i)}
                    onKeyPress={() => {}}
                    role='button'
                    tabIndex='0'
                  >
                    {row.rating === -1 ? (
                      <span role='img' aria-label='shit'>
                        💩
                      </span>
                    ) : (
                      <span role='img' aria-label='shit' className='icon_grey'>
                        💩
                      </span>
                    )}
                  </span>
                )}
                {canUpdateRating && (
                  <span
                    onClick={() => onClickSmile(row, i)}
                    onKeyPress={() => {}}
                    role='button'
                    tabIndex='0'
                  >
                    {row.rating === 1 ? (
                      <span role='img' aria-label='happy'>
                        😀
                      </span>
                    ) : (
                      <span role='img' aria-label='happy' className='icon_grey'>
                        😀
                      </span>
                    )}
                  </span>
                )}

                {canPause && (
                  <span
                    onClick={() => onClickPause(row, i)}
                    onKeyPress={() => {}}
                    role='button'
                    tabIndex='0'
                  >
                    {row.pause ? (
                      <span role='img' aria-label='pause'>
                        ✋
                      </span>
                    ) : (
                      <span role='img' aria-label='pause' className='icon_grey'>
                        ✋
                      </span>
                    )}
                  </span>
                )}
              </div>
            </div>
          ))}
        {manufacturerList && canEditQuote && (
          <NewQuoteLine
            manufacturerList={manufacturerList}
            rfqUUID={rfqUUID}
            onQuoteAdded={onQuoteAdded}
          />
        )}
      </div>
      {!canEditQuote && !canEditLines && (
        <AddToPoButton
          selectedRows={getSelectedRows()}
          supplierId={supplierId}
          rfqUUID={rfqUUID}
        />
      )}
      {canEditQuote && (
        <div className='align-right'>
          <Button
            variant='contained'
            color='primary'
            onClick={onFinalizeQuote}
            disabled={tableRows && tableRows.length === 0}
          >
            Finalize Quote
          </Button>
        </div>
      )}
      <FinalizeConfirmDialog
        open={showFinalizeModal}
        onClose={onCloseFinalizeModal}
        rfqId={rfqId}
      />
    </NewTableContainer>
  )
}

export default QuotedItemsTable
