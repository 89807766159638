import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import adminActions from 'services/redux/admin/actions'
import UserListContainer from './index.style'
import FilterForm from './components/filterForm'
import UserListTable from './components/userListTable'

const { fetchUserList } = adminActions

const UserList = () => {
  const dispatch = useDispatch()

  const [filterOption, setFilterOption] = useState({
    userType: 'all',
    active: 'all',
    userName: '',
  })

  useEffect(() => {
    dispatch(fetchUserList())
  }, [])

  const onFilterChange = (option) => {
    setFilterOption({
      ...filterOption,
      ...option,
    })
  }

  return (
    <UserListContainer>
      <div className="wrapper">
        <FilterForm
          onFilterChange={onFilterChange}
          initialValues={filterOption}
        />
        <UserListTable filterOption={filterOption} />
      </div>
    </UserListContainer>
  )
}

export default UserList
