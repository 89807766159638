import React, { Component } from 'react'
import { Field, FormSection } from 'redux-form'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'

import { ReduxCheckBoxField } from 'components/reduxFormFields'

class UserTypes extends Component {
  componentDidMount() {}

  render() {
    const { typeInitialValue } = this.props
    const typeNames = Object.getOwnPropertyNames(typeInitialValue)

    return (
      <Card>
        <CardHeader title="User Types" />
        <CardContent>
          <FormSection name="scopes">
            <FormSection name="usertype">
              {typeNames.map((type, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <FormSection name={type} key={i}>
                  <Field
                    name="checked"
                    label={typeInitialValue.type.label}
                    component={ReduxCheckBoxField}
                  />
                </FormSection>
              ))}
            </FormSection>
          </FormSection>
        </CardContent>
      </Card>
    )
  }
}

export default UserTypes
