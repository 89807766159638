import React, { Component } from 'react'
import { reduxForm, formValueSelector } from 'redux-form'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import {
  UserTypeProfile,
  Users,
  Suppliers,
  UserTypes,
  Carriers,
} from './components'

const UpdateFormContainer = styled.form`
  > .disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`

class UpdateForm extends Component {
  componentDidMount() {}

  render() {
    const { handleSubmit, initialValues, isAdmin } = this.props

    return (
      <UpdateFormContainer onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <UserTypeProfile isAdmin={isAdmin} />
          </Grid>
        </Grid>
        <Grid container spacing={3} className={`${isAdmin ? 'disabled' : ''}`}>
          <Grid item xs={6}>
            {initialValues.scopes.user && (
              <Users userInitialValue={initialValues.scopes.user} />
            )}
            <br />
            {initialValues.scopes.suppliers && <Suppliers />}
          </Grid>
          <Grid item xs={6}>
            {initialValues.scopes.usertype && (
              <UserTypes typeInitialValue={initialValues.scopes.usertype} />
            )}
            <br />
            {initialValues.scopes.carriers && <Carriers />}
          </Grid>
        </Grid>
      </UpdateFormContainer>
    )
  }
}

let CreateReduxForm = reduxForm({
  form: 'userTypeUpdateForm',
})(UpdateForm)

const selector = formValueSelector('userTypeUpdateForm') // <-- same as form name
CreateReduxForm = connect((state) => {
  // can select values individually
  const isAdmin = selector(state, 'admin')

  return {
    isAdmin,
  }
})(CreateReduxForm)

export default withRouter(CreateReduxForm)
