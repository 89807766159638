/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useContext } from 'react'
import { Field, reduxForm } from 'redux-form'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Button, Box, Typography } from '@material-ui/core'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'

import { GlobalContext } from 'routes'
import { ReduxTextField, ReduxSelectField } from 'components/reduxFormFields'
import states from 'utils/states_titlecase'
import {
  updateSupplierProfileAPI,
  uploadSupplierLogoAPI,
  createSupplierProfileAPI,
} from 'services/helpers/apis/supplier'
import { isUrlValid, isZipcodeValid } from 'utils/fieldValidation'

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
}))

let ReduxForm = (props) => {
  const { handleSubmit } = props
  const classes = useStyles()

  const onFileUpload = async (e) => {
    if (props.uuid) {
      try {
        await uploadSupplierLogoAPI(props.uuid, e.target.files[0])
      } catch (e) {
        console.log(e)
      }
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Field
            name='name'
            label='Supplier Name'
            component={ReduxTextField}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name='street_address_1'
            label='Street Address 1'
            component={ReduxTextField}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name='street_address_2'
            label='Street Address 2'
            component={ReduxTextField}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name='city'
            label='City'
            component={ReduxTextField}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <Field
            name='web_address'
            label='Web Address'
            component={ReduxTextField}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name='state'
            component={ReduxSelectField}
            label='State'
            // nolabel
            fullWidth
          >
            <option value='' disabled />
            {states.map((state, i) => (
              <option value={state.abbreviation} key={i}>
                {state.name}
              </option>
            ))}
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            name='zip'
            label='Zipcode'
            component={ReduxTextField}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name='warehouse_zip'
            label='Warehouse Zip Code'
            component={ReduxTextField}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Box
            display='flex'
            alignItems='center'
            mb={2}
            justifyContent='flex-end'
          >
            <Box mr={2}>
              <Typography>Upload Logo</Typography>
            </Box>
            <Box>
              <input
                accept='image/*'
                className={classes.input}
                id='contained-button-file'
                type='file'
                onChange={onFileUpload}
              />
              <label htmlFor='contained-button-file'>
                <Button variant='contained' color='primary' component='span'>
                  Upload
                </Button>
              </label>
            </Box>
          </Box>
          <Typography style={{ textAlign: 'right' }}>
            Requirements: Longest size must be 200px.
          </Typography>
        </Grid>
        <Grid item xs={12} className='grid-item-justify-right'>
          <Box textAlign='right'>
            <Button variant='contained' color='primary' type='submit'>
              {props.uuid ? 'Update' : 'Create supplier'}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  )
}

const validate = (values) => {
  const errors = {}

  if (!values.name) {
    errors.name = 'Required'
  }

  if (!values.street_address_1) {
    errors.street_address_1 = 'Required'
  }

  if (!values.city) {
    errors.city = 'Required'
  }

  if (!values.web_address) {
    errors.web_address = 'Required'
  } else if (!isUrlValid(values.web_address)) {
    errors.web_address = 'Web address is not valid'
  }

  if (!values.state) {
    errors.web_address = 'Required'
  }

  if (!values.zip) {
    errors.zip = 'Required'
  } else if (!isZipcodeValid(values.zip)) {
    errors.zip = 'Zipcode is not valid'
  }

  if (!values.warehouse_zip) {
    errors.warehouse_zip = 'Required'
  } else if (!isZipcodeValid(values.warehouse_zip)) {
    errors.warehouse_zip = 'Warehouse Zip Code is not valid'
  }

  return errors
}

ReduxForm = reduxForm({
  form: 'supplier_profile_form',
  validate,
})(ReduxForm)

const SupplierProfileForm = ({ initialValues, uuid = null }) => {
  const context = useContext(GlobalContext)
  const history = useHistory()

  const submit = async (values) => {
    const formValues = values

    try {
      if (uuid) {
        await updateSupplierProfileAPI(uuid, {
          name: formValues.name,
          street_address_1: formValues.street_address_1,
          street_address_2: formValues.street_address_2,
          city: formValues.city,
          state: formValues.state,
          zip: formValues.zip,
          web_address: formValues.web_address,
          warehouse_zip: formValues.warehouse_zip,
        })
        toast.success('Supplier profile is updated')
      } else {
        const res = await createSupplierProfileAPI({
          name: formValues.name,
          street_address_1: formValues.street_address_1,
          street_address_2: formValues.street_address_2,
          city: formValues.city,
          state: formValues.state,
          zip: formValues.zip,
          web_address: formValues.web_address,
          warehouse_zip: formValues.warehouse_zip,
        })
        toast.success('New supplier profile is created')
        history.push(`/admin/supplier/update/${res.data.data.uuid}`)
      }
    } catch (e) {
      context.onApiError(e)
    }
  }

  return (
    <ReduxForm onSubmit={submit} initialValues={initialValues} uuid={uuid} />
  )
}

export default SupplierProfileForm
