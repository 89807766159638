import styled from 'styled-components'

const TimelineSliderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;

  .line {
    position: absolute;
    left: 50px;
    right: 50px;
    top: 13px;
    border: 1px solid #000;
  }

  .bold-line {
    position: absolute;
  }

  .time-marker {
    text-align: center;

    .bold-line {
      position: absolute;
      height: 4px;
      background: black;
      top: 12px;
    }

    p {
      margin: 0 0 5px;
    }

    .pointer {
      height: 30px;
      width: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      position: relative;
      z-index: 1;

      .material-icons {
        background: white;
      }

      .dot {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: black;
        display: inline-block;
      }
    }
  }
`

export default TimelineSliderContainer
