import React from 'react'
import { Grid, Icon } from '@material-ui/core'
import ReactTable from 'react-table'
import { Link } from 'react-router-dom'

import ShipmentStatusContainer from './index.style'

const ShipmentsStatus = ({ shipments }) => {
  const shipmentsColumns = [
    {
      Header: 'Quantity',
      accessor: 'total_quantity',
    },
    {
      Header: 'Item',
      accessor: 'part_number',
    },
  ]

  const renderRowSubComponent = ({ row }) => {
    const columns = [
      {
        Header: 'Quantity',
        accessor: 'quantity',
      },
      {
        Header: 'Requisition Number',
        accessor: 'requisition',
        Cell: (props) => (
          <Link to={`/requisitions/${props.value}`}>{props.value}</Link>
        ),
      },
    ]

    return (
      <div className='sub-table'>
        <ReactTable
          data={row._original.lines}
          columns={columns}
          minRows={1}
          showPagination={false}
        />
      </div>
    )
  }

  return (
    <ShipmentStatusContainer>
      {shipments.length > 0 ? (
        <Grid container spacing={3}>
          {shipments.map((shipment, i) => (
            <Grid item xs={6} key={i}>
              <div className='shipment-info'>
                <div className='info-header'>
                  <Link to={`/shipment/read/${shipment.tracking_number}`}>
                    <h2>{shipment.tracking_number}</h2>
                  </Link>
                  <span>{shipment.service.description}</span>
                </div>
                <span
                  className='shipment-status'
                  style={{
                    backgroundColor: `#${shipment.activity[0].color}`,
                  }}
                >
                  <Icon>{shipment.activity[0].icon}</Icon>&nbsp;
                  {shipment.activity[0].status}
                </span>
              </div>
              <ReactTable
                data={shipment.lines}
                columns={shipmentsColumns}
                minRows={1}
                showPagination={false}
                SubComponent={renderRowSubComponent}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <h4>No shipments found </h4>
      )}
    </ShipmentStatusContainer>
  )
}

export default ShipmentsStatus
