import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { toast } from 'react-toastify'

import { client } from 'services/helpers/apiConfig'
import adminActions from 'services/redux/admin/actions'
import UpdateForm from './updateForm'

const { onApiError } = adminActions

class UserTypeUpdate extends Component {
  state = {
    initialValues: null,
  }

  async componentDidMount() {
    const userType = this.props.match.params.type.replace('+', ' ')

    try {
      const res = await client.get(`userType/read/${userType}`)
      this.setState({
        initialValues: res.data.data,
      })
    } catch (e) {
      this.props.onApiError(e)
    }
  }

  submit = async (values) => {
    try {
      await client.put('userType/update', values)
      toast.success('User type is updated successfully')
    } catch (e) {
      this.props.onApiError(e)
    }
  }

  render() {
    const { initialValues } = this.state

    return (
      <div className='wrapper'>
        {initialValues && (
          <UpdateForm onSubmit={this.submit} initialValues={initialValues} />
        )}
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onApiError,
    },
    dispatch
  )

export default connect(null, mapDispatchToProps)(UserTypeUpdate)
