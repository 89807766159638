import React, { useState, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Button,
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import _isEmpty from 'lodash/isEmpty'

import storageActions from 'services/redux/storage/actions'
import { ShipmentListContext } from 'pages/shipment/list'
import ListTableFilterBox from 'components/listTableFilterBox'
import EnhancedTable from 'components/tables/table'
import { getComparator, stableSort } from 'components/tables/utils'
import ShipmentStatusBar from 'components/shipmentStatusBar'

const { setShipmentsTableSettings } = storageActions
const headCells = [
  {
    id: 'tracking_number',
    label: 'Tracking Number',
  },
  {
    id: 'carrier',
    label: 'Carrier',
  },
  {
    id: 'service_name',
    label: 'Service',
  },
  {
    id: 'requisition_id',
    label: 'Requisition ID',
  },
  {
    id: 'ship_date.timestamp',
    label: 'Shipped',
  },
  {
    id: 'expected_delivery_date.timestamp',
    label: 'Delivery',
  },
  {
    id: 'last_scan_date.timestamp',
    label: 'Last Scan Date',
  },
  {
    id: 'status',
    label: 'Status',
    width: 300,
  },
]

const RequisitionIdColumn = ({ lines }) => {
  const requisitionIds = lines.map((line) =>
    line.requisition ? line.requisition.order_id : null
  )

  return (
    <div>
      <Link to={`/requisitions/${requisitionIds[0]}`}>{requisitionIds[0]}</Link>{' '}
      {requisitionIds.length > 1 && (
        <Tooltip title={requisitionIds.join(', ')}>
          <Button color='primary'>{requisitionIds.length - 1} more...</Button>
        </Tooltip>
      )}
    </div>
  )
}

const ShipmentsTable = ({
  rows,
  onFilterChange,
  onDateRangeChange,
  onTableViewChange,
}) => {
  const shipmentListContext = useContext(ShipmentListContext)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('')
  const [selected, setSelected] = useState([])
  const shipmentsTableSettings = useSelector(
    (state) => state.storage.shipmentsTableSettings
  )
  const dispatch = useDispatch()
  const isSelected = (id) => selected.indexOf(id) !== -1
  const emptyRows =
    shipmentsTableSettings.tableViewInfo.rowsCount -
    Math.min(shipmentsTableSettings.tableViewInfo.rowsCount, rows.length)

  const onChangeSort = (sort) => {
    dispatch(
      setShipmentsTableSettings({
        sort,
      })
    )
  }

  const renderFilterBox = () => {
    return (
      <ListTableFilterBox
        statusList={shipmentListContext.statusList}
        filterStatuses={shipmentsTableSettings.filter.statuses}
        startDate={shipmentsTableSettings.filter.startDate}
        endDate={shipmentsTableSettings.filter.endDate}
        hasDateRange
        handleDateRangeChange={onDateRangeChange}
        handleChange={onFilterChange}
      />
    )
  }

  return (
    <EnhancedTable
      tableFor='shipments'
      hasCheckbox={false}
      headCells={headCells}
      rows={rows}
      onTableViewChange={onTableViewChange}
      order={order}
      orderBy={orderBy}
      selected={selected}
      setSelected={setSelected}
      setOrder={setOrder}
      setOrderBy={setOrderBy}
      onChangeSort={onChangeSort}
      tableSettings={shipmentsTableSettings}
      renderFilterBox={renderFilterBox}
    >
      <TableBody>
        {stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
          const isItemSelected = isSelected(row.shipment_id)
          const labelId = `enhanced-table-checkbox-${index}`

          return (
            <TableRow
              hover
              role='checkbox'
              aria-checked={isItemSelected}
              tabIndex={-1}
              key={row.shipment_id}
              selected={isItemSelected}
            >
              <TableCell component='th' id={labelId} scope='row'>
                <Link to={`/shipment/read/${row.tracking_number}`}>
                  {row.tracking_number}
                </Link>
              </TableCell>
              <TableCell>{row.carrier}</TableCell>
              <TableCell>{row.service_name}</TableCell>
              <TableCell>
                {row.lines.length > 0 && (
                  <RequisitionIdColumn lines={row.lines} />
                )}
              </TableCell>
              <TableCell>
                {!_isEmpty(row.ship_date) && (
                  <Tooltip title={row.ship_date.formatted_date}>
                    <span>{row.ship_date.human_date.relative.long}</span>
                  </Tooltip>
                )}
              </TableCell>
              <TableCell>
                {!_isEmpty(row.expected_delivery_date) && (
                  <Tooltip title={row.expected_delivery_date.formatted_date}>
                    <span>
                      {row.expected_delivery_date.human_date.relative.long}
                    </span>
                  </Tooltip>
                )}
              </TableCell>
              <TableCell>
                {!_isEmpty(row.last_scan_date) && (
                  <Tooltip title={row.last_scan_date.formatted_date}>
                    <span>{row.last_scan_date.human_date.relative.long}</span>
                  </Tooltip>
                )}
              </TableCell>
              <TableCell>
                <ShipmentStatusBar status={row.status} />
              </TableCell>
            </TableRow>
          )
        })}
        {emptyRows > 0 && (
          <TableRow style={{ height: 53 * emptyRows }}>
            <TableCell colSpan={8} />
          </TableRow>
        )}
      </TableBody>
    </EnhancedTable>
  )
}

export default ShipmentsTable
