import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'

import { updateRfqQuoteAPI } from 'services/helpers/apis/rfq'

const EditableSelect = ({
  quoteLineUUID = '',
  selectList,
  selectValue,
  selectType,
  disabled,
  isNewLine = false,
  updateSelectValue = () => {},
}) => {
  const inputLabel = React.useRef(null)
  const [labelWidth, setLabelWidth] = React.useState(0)
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth)
  }, [])
  const [value, setValue] = React.useState(selectValue)

  const onChangeSelect = async (e) => {
    setValue(e.target.value)

    if (!isNewLine) {
      try {
        const res = await updateRfqQuoteAPI(quoteLineUUID, {
          [selectType]: e.target.value,
        })

        console.log(res)
      } catch (error) {
        console.log(error)
      }
    } else {
      updateSelectValue(selectType, e.target.value)
    }
  }

  return (
    <FormControl variant="outlined" className="formControl editable-select">
      <InputLabel ref={inputLabel} htmlFor="editable-select">
        {selectType === 'lead_time' ? 'Lead Time' : 'Manufacturer'}
      </InputLabel>
      <Select
        native
        value={value}
        onChange={onChangeSelect}
        labelWidth={labelWidth}
        inputProps={{
          name: 'editable',
          id: 'editable-select',
        }}
        disabled={disabled}
      >
        <option value=""></option>
        {selectList.map((item, i) => (
          <option
            value={selectType === 'lead_time' ? item.value : item.id}
            key={i}
          >
            {selectType === 'lead_time' ? item.label : item.name}
          </option>
        ))}
      </Select>
    </FormControl>
  )
}

export default EditableSelect
