import styled from 'styled-components'

const BarChartContainer = styled.div`
  display: flex;
`

export const BarChartStack = styled.div`
  width: ${(props) => `${props.percentage}%`};
  background-color: ${(props) => props.stack.color};
  color: white;
  text-align: right;
  padding: 0 3px;
  cursor: pointer;
`

export default BarChartContainer
