import React, { useState, useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Checkbox,
  Fab,
  Tooltip,
  Dialog,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import SaveIcon from '@material-ui/icons/Save'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import _remove from 'lodash/remove'
import { withStyles } from '@material-ui/core/styles'
import { orange } from '@material-ui/core/colors'
import Alert from '@material-ui/lab/Alert'
import { toast } from 'react-toastify'

import {
  updateRfqLineAPI,
  deleteRfqLineAPI,
  deleteRfqAPI,
  getRfqMessageTemplateAPI,
  createRfqLineAPI,
} from 'services/helpers/apis/rfq'
import { getManufacturerListAPI } from 'services/helpers/apis/other'
import rfqActions from 'services/redux/rfq/actions'
import { GlobalContext } from 'routes'

import SendMessageTemplateModal from 'components/sendMessageTemplateModal'
import ManufacturerSelector from 'components/manufacturerSelector'

const { onRfqDelete } = rfqActions

let timer
const WAIT_INTERVAL = 1000

const SubmitButton = withStyles(() => ({
  root: {
    color: '#fff',
    backgroundColor: orange[500],
    '&:hover': {
      backgroundColor: orange[700],
    },
  },
}))(Fab)

const EditableInput = ({ value, line, property }) => {
  const [inputValue, setInputValue] = useState(value)
  const context = useContext(GlobalContext)

  useEffect(() => {
    timer = null
  }, [])

  const onUpdateInput = async (event) => {
    clearTimeout(timer)
    const inputPrevValue = inputValue
    const inputUpdatedValue = event.target.value
    setInputValue(inputUpdatedValue)

    timer = setTimeout(async () => {
      try {
        await updateRfqLineAPI(line.uuid, { [property]: inputUpdatedValue })
      } catch (e) {
        context.onApiError(e)
        setInputValue(inputPrevValue)
      }
    }, WAIT_INTERVAL)
  }

  return <input type='text' value={inputValue} onChange={onUpdateInput} />
}

const TabLineItems = ({ selectedRfq, onRfqDelete }) => {
  const [rows, setRows] = useState(selectedRfq.lines)
  const [rfqConfirmMessage, setRfqConfirmMessage] = useState(null)
  const [isAddNewLine, setIsAddNewLine] = useState(false)
  const [inputValues, setInputValues] = useState({
    quantity: 0,
    part_number: '',
    supplier_part_number: '',
    manufacturer_id: '',
  })
  const [manufacturerList, setManufacturerList] = useState(null)
  const context = useContext(GlobalContext)

  useEffect(() => {
    const getManufacturerList = async () => {
      try {
        const res = await getManufacturerListAPI()
        setManufacturerList(res.data.data)
      } catch (e) {
        context.onApiError(e)
      }
    }

    getManufacturerList()
  }, [])

  const onDeleteLine = async (line) => {
    try {
      await deleteRfqLineAPI(line.uuid)
      const array = rows
      _remove(array, function(n) {
        return n.uuid === line.uuid
      })
      setRows([...array])
      toast.success('Line item is deleted.')
    } catch (e) {
      context.onApiError(e)
    }
  }

  const onChangeSupplierPartNumberCustom = async (e, uuid, index) => {
    const arr = rows
    arr[index].supplier_part_number_custom = !arr[index]
      .supplier_part_number_custom

    try {
      const res = await updateRfqLineAPI(uuid, {
        supplier_part_number_custom: arr[index].supplier_part_number_custom,
      })
      context.onApiResponse(res)
      setRows([...arr])
    } catch (e) {
      context.onApiError(e)
    }
  }

  const onDeleteRfq = async () => {
    try {
      await deleteRfqAPI(selectedRfq.uuid)
      toast.success(`Rfq ${selectedRfq.id} is deleted.`)
      onRfqDelete(selectedRfq)
    } catch (e) {
      context.onApiError(e)
    }
  }

  const onSubmitRfq = async () => {
    try {
      const res = await getRfqMessageTemplateAPI(selectedRfq.id)
      setRfqConfirmMessage(res.data.data)
    } catch (e) {
      context.onApiError(e)
    }
  }

  const onCloseRfqConfirmDialog = () => {
    setRfqConfirmMessage(null)
  }

  const onAddNewLine = () => {
    setIsAddNewLine(true)
  }

  const onInputUpdate = (event) => {
    const { name, value } = event.target
    setInputValues({
      ...inputValues,
      [name]: value,
    })
  }

  const onSelectManufacturer = (value) => {
    setInputValues({
      ...inputValues,
      manufacturer_id: value,
    })
  }

  const onSaveNewLine = async () => {
    try {
      const res = await createRfqLineAPI({
        quantity: inputValues.quantity,
        item: inputValues.part_number,
        supplier_part_number: inputValues.supplier_part_number,
        manufacturer_id: inputValues.manufacturer_id,
        rfq_id: selectedRfq.id,
      })

      const newRows = [
        ...rows,
        {
          ...res.data.data,
          supplier_part_number_custom:
            res.data.data.supplier_part_number_custom !== 0,
        },
      ]
      toast.success('New line item is added.')
      setRows(newRows)
      setInputValues({
        quantity: 0,
        part_number: '',
        supplier_part_number: '',
        manufacturer_id: '',
      })
      setIsAddNewLine(false)
    } catch (e) {
      context.onApiError(e)
    }
  }

  return (
    <div className='lines-table'>
      <TableContainer component={Paper}>
        <Table size='small' aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>Quantity</TableCell>
              <TableCell>Requisition Number</TableCell>
              <TableCell>Manufacturer</TableCell>
              <TableCell>Part Number</TableCell>
              <TableCell>Supplier Part Number</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length > 0 &&
              rows.map((row, i) => (
                <TableRow key={i}>
                  <TableCell component='th' scope='row'>
                    {selectedRfq.permissions.can_edit_lines ? (
                      <EditableInput
                        value={row.quantity}
                        line={row}
                        property='quantity'
                      />
                    ) : (
                      row.quantity
                    )}
                  </TableCell>
                  <TableCell>
                    {row.requisition_detail.order_id ? (
                      <Link
                        to={`/requisitions/${row.requisition_detail.order_id}`}
                      >
                        {row.requisition_detail.order_id}
                      </Link>
                    ) : (
                      ''
                    )}
                  </TableCell>
                  <TableCell>
                    {manufacturerList && (
                      <ManufacturerSelector
                        list={manufacturerList}
                        line={row}
                        fieldType={
                          selectedRfq.permissions.can_edit_lines
                            ? 'editable'
                            : 'text'
                        }
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    {row.requisition_detail ? (
                      row.item
                    ) : (
                      <EditableInput
                        value={row.item}
                        line={row}
                        property='item'
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    <div className='supplier-table-cell'>
                      {row.supplier_part_number_custom ? (
                        <EditableInput
                          value={row.supplier_part_number}
                          line={row}
                          property='supplier_part_number'
                        />
                      ) : (
                        <span>{row.supplier_part_number}</span>
                      )}
                      {selectedRfq.permissions.can_edit_lines && (
                        <Checkbox
                          checked={row.supplier_part_number_custom}
                          onChange={(e) =>
                            onChangeSupplierPartNumberCustom(e, row.uuid, i)
                          }
                          value='secondary'
                          color='primary'
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                      )}
                    </div>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      className='btn-delete'
                      onClick={() => onDeleteLine(row)}
                      disabled={!selectedRfq.permissions.can_edit_lines}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            {isAddNewLine && (
              <TableRow className='new-line'>
                <TableCell component='th' scope='row'>
                  <p className='new-line-title'>New Line Item</p>
                  <input
                    type='number'
                    name='quantity'
                    value={inputValues.quantity}
                    onChange={onInputUpdate}
                  ></input>
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                  {manufacturerList && (
                    <ManufacturerSelector
                      list={manufacturerList}
                      fieldType='new'
                      onSelect={onSelectManufacturer}
                    />
                  )}
                </TableCell>
                <TableCell>
                  <input
                    type='text'
                    name='part_number'
                    value={inputValues.part_number}
                    onChange={onInputUpdate}
                  ></input>
                </TableCell>
                <TableCell>
                  <input
                    type='text'
                    name='supplier_part_number'
                    value={inputValues.supplier_part_number}
                    onChange={onInputUpdate}
                  ></input>
                </TableCell>
                <TableCell>
                  <IconButton className='btn-save' onClick={onSaveNewLine}>
                    <SaveIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!isAddNewLine && rows.length === 0 && (
        <Alert severity='warning'>No Line Items</Alert>
      )}

      <div className='rfq-fabs'>
        <Tooltip title='Delete RFQ' placement='top'>
          <span>
            <Fab
              size='small'
              color='primary'
              aria-label='add'
              className='btn-refresh'
              onClick={onDeleteRfq}
              disabled={!selectedRfq.permissions.can_delete}
            >
              <DeleteIcon />
            </Fab>
          </span>
        </Tooltip>
        <Tooltip title='Send' placement='top'>
          <span>
            <SubmitButton
              size='small'
              aria-label='add'
              color='primary'
              className='btn-submit'
              onClick={onSubmitRfq}
              disabled={!selectedRfq.permissions.can_submit}
            >
              <ArrowForwardIosIcon />
            </SubmitButton>
          </span>
        </Tooltip>
        <Tooltip title='Add New Line Item' placement='top'>
          <span>
            <Fab
              size='small'
              color='secondary'
              aria-label='add'
              className='btn-add'
              onClick={onAddNewLine}
              disabled={!selectedRfq.permissions.can_edit_lines}
            >
              <AddIcon />
            </Fab>
          </span>
        </Tooltip>
      </div>
      <Dialog
        onClose={onCloseRfqConfirmDialog}
        open={!!rfqConfirmMessage}
        disableBackdropClick
      >
        <SendMessageTemplateModal
          message={rfqConfirmMessage}
          id={selectedRfq.id}
          onCloseDialog={onCloseRfqConfirmDialog}
          modalFor='rfq'
        />
      </Dialog>
    </div>
  )
}

const mapStateToProps = (state) => ({
  selectedRfq: state.rfq.selected_rfq,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onRfqDelete,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(TabLineItems)
