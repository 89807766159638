import styled from 'styled-components'
import { List, ListItem } from '@material-ui/core'
import { yellow, red } from '@material-ui/core/colors'

const StyledList = styled(List)`
  padding: 0 !important;
`

const StyledListItem = styled(ListItem)`
  .MuiListItemIcon-root {
    color: ${(props) => (props.status === 'warning' ? yellow[700] : red[500])};
  }
`

export { StyledList, StyledListItem }
