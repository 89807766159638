import React, { useEffect, useState } from 'react'
import Select from '@material-ui/core/Select'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import _find from 'lodash/find'

const AssignSelector = ({
  assignedTo,
  userList,
  componentType,
  uuid,
  updateAssignee,
  updateFor,
}) => {
  const [assignedValue, setAssignedValue] = useState(assignedTo.value)
  const [anchorEl, setAnchorEl] = useState(null)
  const [assignedUser, setAssignedUser] = useState(null)

  useEffect(() => {
    setAssignedValue(assignedTo.value)
  }, [assignedTo])

  useEffect(() => {
    if (componentType === 'menu') {
      const assignedUser = _find(userList, { id: assignedTo.value })
      setAssignedUser(assignedUser)
    }
  }, [])

  const handleClose = () => {
    setAnchorEl(null)
  }

  const changeAssignee = (value) => {
    setAssignedValue(value)
    updateAssignee(uuid, updateFor, { assignee: value })
    if (componentType === 'menu') {
      const assignedUser = _find(userList, { id: value })
      handleClose()
      setAssignedUser(assignedUser)
    }
  }

  const handleChange = (event) => {
    changeAssignee(event.target.value)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleSelectMenuItem = (id) => {
    changeAssignee(id)
  }

  return (
    <div>
      {componentType === 'selector' && (
        <Select
          value={assignedValue}
          onChange={handleChange}
          inputProps={{
            name: 'assign',
            id: 'assign',
          }}
          fullWidth
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {userList.map((user, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <MenuItem value={user.id} key={i}>
              {user.name}
            </MenuItem>
          ))}
        </Select>
      )}
      {componentType === 'menu' && (
        <div>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
            variant="contained"
          >
            {assignedUser ? assignedUser.name : 'Unassigned'}
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {userList.map((user, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <MenuItem
                value={user.id}
                key={i}
                onClick={() => handleSelectMenuItem(user.id)}
              >
                {user.name}
              </MenuItem>
            ))}
          </Menu>
        </div>
      )}
    </div>
  )
}

export default AssignSelector
