import React, { useState, useEffect, useContext } from 'react'
import {
  FormControl,
  Dialog,
  InputLabel,
  IconButton,
  InputAdornment,
  Popover,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  OutlinedInput,
} from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error'
import CloseIcon from '@material-ui/icons/Close'
import Search from '@material-ui/icons/Search'
import _filter from 'lodash/filter'
import axios from 'axios'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'

import { GlobalContext } from 'routes'
import { fetchUserSupplierListAPI } from 'services/helpers/apis/user'
import { updateSupplierProfileAPI } from 'services/helpers/apis/supplier'
import adminActions from 'services/redux/admin/actions'
import StyledButton from 'components/common/button'

import CreateUser from '../../user/create'

const WAIT_INTERVAL = 1000
let timer

const { fetchUserList } = adminActions

const DialogContent = styled.div`
  padding: 40px;
  position: relative;

  .btn-close {
    position: absolute;
    right: 0;
    top: 0;
  }
`

const EmptyUserProfileForm = ({ uuid, onUserConnected }) => {
  const [searchValue, setSearchValue] = useState('')
  const [anchorEl, setAnchorEl] = useState(null)
  const [searchResults, setSearchResults] = useState([])
  const [userList, setUserList] = useState([])
  const [isAdd, setIsAdd] = useState(false)
  const context = useContext(GlobalContext)
  const signal = axios.CancelToken.source()
  const dispatch = useDispatch()

  const fetchUserSupplierList = async () => {
    try {
      const res = await fetchUserSupplierListAPI(signal.token)
      setUserList(res.data.data.users)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    fetchUserSupplierList()

    return () => {
      signal.cancel('Api is being canceled')
    }
  }, [])

  const openSuggestions = (currentTarget) => {
    setAnchorEl(currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const onSearchChange = (e) => {
    const { value } = e.target
    const { currentTarget } = e
    clearTimeout(timer)
    setSearchValue(value)
    timer = setTimeout(async () => {
      const filterUsers = _filter(userList, function(o) {
        return o.name.toUpperCase().includes(value.toUpperCase())
      })
      openSuggestions(currentTarget)
      setSearchResults(filterUsers)
    }, WAIT_INTERVAL)
  }

  const onSelectUser = async (user) => {
    setSearchValue(user.name)
    handleClose()
    try {
      await updateSupplierProfileAPI(uuid, {
        linked_user_id: user.id,
      })
      onUserConnected(user)
    } catch (e) {
      context.onApiError(e)
    }
  }

  const onUserCreated = (user) => {
    onSelectUser(user)
    dispatch(fetchUserList())
  }

  const onCloseAddUser = () => {
    setIsAdd(false)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <div>
      <FormControl fullWidth variant='outlined' style={{ marginBottom: 16 }}>
        <InputLabel htmlFor='outlined-adornment-amount'>Search</InputLabel>
        <OutlinedInput
          id='outlined-adornment-amount'
          value={searchValue}
          onChange={onSearchChange}
          startAdornment={
            <InputAdornment position='start'>
              <Search />
            </InputAdornment>
          }
          labelWidth={60}
        />
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <List
            component='nav'
            aria-label='supplier terms'
            style={{ width: 300 }}
          >
            {searchResults.length > 0 ? (
              searchResults.map((user, i) => (
                <ListItem button key={i} onClick={() => onSelectUser(user)}>
                  <ListItemText primary={user.name} />
                </ListItem>
              ))
            ) : (
              <ListItem>
                <ListItemIcon>
                  <ErrorIcon color='secondary' />
                </ListItemIcon>
                <ListItemText primary='No terms' />
              </ListItem>
            )}
          </List>
        </Popover>
      </FormControl>
      <StyledButton className='btn-cyan' onClick={() => setIsAdd(true)}>
        Add Contact
      </StyledButton>
      <Dialog
        aria-labelledby='simple-dialog-title'
        onClose={onCloseAddUser}
        open={isAdd}
        maxWidth='sm'
        fullWidth
      >
        <DialogContent>
          <IconButton
            aria-label='close'
            className='btn-close'
            onClick={onCloseAddUser}
          >
            <CloseIcon />
          </IconButton>
          <CreateUser isUpdateSupplier onUserCreated={onUserCreated} />
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default EmptyUserProfileForm
