import React from 'react'

import {
  getListAPI,
  getServicesListAPI,
  deleteAPI,
  updateAPI,
} from 'services/helpers/apis/manufacturerNameVariant'
import Table from './table'

const ManufacturerNameVariants = () => {
  return (
    <Table
      getListAPI={getListAPI}
      getServicesListAPI={getServicesListAPI}
      deleteAPI={deleteAPI}
      updateAPI={updateAPI}
      tableFor='manufacturer'
    />
  )
}

export default ManufacturerNameVariants
