import React, { useState, useContext } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Button,
  TextField,
  Typography,
} from '@material-ui/core'

import { partNumberRuleProcessAPI } from 'services/helpers/apis/partNumberRules'
import { GlobalContext } from 'routes'
import StyledButton from 'components/common/button'

const TestPartNumberRuleModal = ({ open, handleClose, uuid }) => {
  const [partNumber, setPartNumber] = useState('')
  const [processedMessages, setProcessedMessages] = useState(null)
  const [showTestButton, setShowTestButton] = useState(true)
  const context = useContext(GlobalContext)

  const onChangePartNumber = (e) => {
    setPartNumber(e.target.value)
  }

  const onTest = async () => {
    try {
      const res = await partNumberRuleProcessAPI(uuid, partNumber)
      const messages = res.data.data.message.map((line) => {
        let str = line
        str = str.replace(
          '{part_number_class}',
          res.data.data.part_number_class
        )
        str = str.replace('{part_number}', res.data.data.part_number)
        str = str.replace('{supplier_name}', res.data.data.supplier_name)
        str = str.replace(
          '{supplier_part_number}',
          res.data.data.supplier_part_number
        )

        return str
      })

      setShowTestButton(false)
      setProcessedMessages(messages)
    } catch (e) {
      context.onApiError(e)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='form-dialog-title'
      fullWidth
      maxWidth='sm'
      disableBackdropClick
    >
      <DialogTitle id='form-dialog-title'>
        Test Supplier Part Number Rule
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin='dense'
          label='Part Number Rule'
          variant='outlined'
          fullWidth
          value={partNumber}
          onChange={onChangePartNumber}
        />

        {processedMessages && (
          <Box textAlign='center' my={3}>
            {processedMessages.map((line, i) => (
              <Typography
                key={i}
                variant={i === 3 ? 'h5' : 'body1'}
                gutterBottom
              >
                {line}
              </Typography>
            ))}
          </Box>
        )}

        <Box mt={3} display='flex' justifyContent='flex-end'>
          <StyledButton
            onClick={handleClose}
            className='btn-orange'
            color='primary'
            variant='contained'
            style={{ marginRight: '1em' }}
          >
            Close
          </StyledButton>
          {showTestButton && (
            <Button onClick={onTest} color='primary' variant='contained'>
              Test
            </Button>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default TestPartNumberRuleModal
