import React, { useEffect, useState, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { Grid, Card, CardHeader, CardContent } from '@material-ui/core'

import { GlobalContext } from 'routes'
import { readSupplierAPI } from 'services/helpers/apis/supplier'
import adminActions from 'services/redux/admin/actions'

import SupplierUpdateContainer from './index.style'
import SupplierProfileForm from './supplierProfileForm'
import UserProfileForm from './userProfileForm'
import SettingsForm from './settingsForm'
import PaymentForm from './paymentForm'

const { fetchUserList } = adminActions

const SupplierUpdate = ({ match }) => {
  const [supplierData, setSupplierData] = useState(null)
  const { uuid } = match.params
  const context = useContext(GlobalContext)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchUserList())
  }, [])

  const readSupplier = async (uuid) => {
    try {
      const res = await readSupplierAPI(uuid)

      setSupplierData(res.data.data)
    } catch (e) {
      context.onApiError(e)
    }
  }

  useEffect(() => {
    readSupplier(uuid)
  }, [])

  return (
    <SupplierUpdateContainer>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Card>
            <CardHeader title='Supplier Profile' />
            <CardContent className='card-content'>
              {supplierData && (
                <SupplierProfileForm
                  initialValues={supplierData.profile}
                  uuid={uuid}
                />
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Card>
                    <CardHeader title='Associated User Profile' />
                    <CardContent className='card-content'>
                      {supplierData && (
                        <UserProfileForm
                          linkedUser={supplierData.linked_user}
                          uuid={uuid}
                        />
                      )}
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={6}>
                  <Card>
                    <CardHeader title='Settings' />
                    <CardContent className='card-content'>
                      {supplierData && (
                        <SettingsForm initialValues={supplierData.inventory} />
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Card>
                    <CardHeader title='Payment' />
                    <CardContent className='card-content'>
                      {supplierData && (
                        <PaymentForm initialValues={supplierData.payment} />
                      )}
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={6}>
                  <Card>
                    <CardHeader title='Documents' />
                    <CardContent className='card-content'></CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </SupplierUpdateContainer>
  )
}

export default SupplierUpdate
