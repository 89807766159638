import React, { useState, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  Button,
  Link,
  Tooltip,
} from '@material-ui/core'

import storageActions from 'services/redux/storage/actions'
import { RfqListContext } from 'pages/rfq/list'
import AssignDropdown from 'components/assignDropdown'
import ListTableFilterBox from 'components/listTableFilterBox'

import EnhancedTable from 'components/tables/table'
import StatusLabel from 'components/tables/statusLabel'
import { getComparator, stableSort } from 'components/tables/utils'

const { setRfqTableSettings } = storageActions
const headCells = [
  {
    id: 'id',
    label: 'RFQ',
  },
  {
    id: 'date_added.timestamp',
    label: 'Date',
  },
  {
    id: 'part_numbers',
    label: 'Part Number',
  },
  {
    id: 'requisition_ids',
    label: 'Requisition ID',
  },
  {
    id: 'supplier',
    label: 'Supplier',
  },
  {
    id: 'status',
    label: 'Status',
    width: 120,
  },
  {
    id: 'assignee',
    label: 'AssignedTo',
    width: 120,
  },
]

const RfqTable = ({ openRfqModal, onFilterChange }) => {
  const rfqListContext = useContext(RfqListContext)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('')
  const [selected, setSelected] = useState([])
  const rfqTableSettings = useSelector(
    (state) => state.storage.rfqTableSettings
  )
  const dispatch = useDispatch()
  const isSelected = (id) => selected.indexOf(id) !== -1
  const emptyRows =
    rfqTableSettings.tableViewInfo.rowsCount -
    Math.min(
      rfqTableSettings.tableViewInfo.rowsCount,
      rfqListContext.rows.length
    )

  const onChangeSort = (sort) => {
    dispatch(
      setRfqTableSettings({
        sort,
      })
    )
  }

  const renderFilterBox = () => {
    return (
      <ListTableFilterBox
        userList={rfqListContext.userList}
        statusList={rfqListContext.statusList}
        suppliersList={rfqListContext.suppliersList}
        filterUsers={rfqTableSettings.filter.users}
        filterStatuses={rfqTableSettings.filter.statuses}
        filterSuppliers={rfqTableSettings.filter.suppliers}
        handleChange={onFilterChange}
      />
    )
  }

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)
    rfqListContext.onCheckRfq(newSelected)
  }

  return (
    <EnhancedTable
      tableFor="rfq"
      headCells={headCells}
      rows={rfqListContext.rows}
      onTableViewChange={rfqListContext.onTableViewChange}
      order={order}
      orderBy={orderBy}
      selected={selected}
      setSelected={setSelected}
      setOrder={setOrder}
      setOrderBy={setOrderBy}
      onChangeSort={onChangeSort}
      tableSettings={rfqTableSettings}
      renderFilterBox={renderFilterBox}
    >
      <TableBody>
        {stableSort(rfqListContext.rows, getComparator(order, orderBy)).map(
          (row, index) => {
            const isItemSelected = isSelected(row.id)
            const labelId = `enhanced-table-checkbox-${index}`

            return (
              <TableRow
                hover
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={row.id}
                selected={isItemSelected}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={isItemSelected}
                    onChange={(event) => handleClick(event, row.id)}
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </TableCell>
                <TableCell component="th" id={labelId} scope="row">
                  <Link
                    component="button"
                    variant="body2"
                    onClick={() => openRfqModal(row)}
                  >
                    {row.id}
                  </Link>
                </TableCell>
                <TableCell>
                  <Tooltip title={row.date_added.formatted_date}>
                    <span>{row.date_added.human_date.relative.long}</span>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  {row.part_numbers[0]}{' '}
                  {row.part_numbers.length > 1 && (
                    <Tooltip title={row.part_numbers.join(', ')}>
                      <Button color="primary">
                        {row.part_numbers.length - 1} more...
                      </Button>
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell>
                  {row.requisition_ids[0]}{' '}
                  {row.requisition_ids.length > 1 && (
                    <Tooltip title={row.requisition_ids.join(', ')}>
                      <Button color="primary">
                        {row.requisition_ids.length - 1} more...
                      </Button>
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell>{row.supplier}</TableCell>
                <TableCell>
                  {rfqListContext.statusList && (
                    <StatusLabel
                      statusList={rfqListContext.statusList}
                      statusId={row.status_id}
                    >
                      {row.status}
                    </StatusLabel>
                  )}
                </TableCell>
                <TableCell>
                  <AssignDropdown
                    userList={rfqListContext.userList}
                    selected={row}
                    permissions={row.permissions}
                    updateFor="rfq"
                  />
                </TableCell>
              </TableRow>
            )
          }
        )}
        {emptyRows > 0 && (
          <TableRow style={{ height: 53 * emptyRows }}>
            <TableCell colSpan={8} />
          </TableRow>
        )}
      </TableBody>
    </EnhancedTable>
  )
}

export default RfqTable
