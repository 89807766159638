import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import ReactTable from 'react-table'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import axios from 'axios'
import { Link } from 'react-router-dom'

import adminActions from 'services/redux/admin/actions'
import { getRequisitionRfqpoAPI } from 'services/helpers/apis/requisition'

import AddToRfq from './addToRfq'
import SupplierTableCell from './supplierTableCell'

const { onApiError } = adminActions

const TabRfqContainer = styled.div`
  .paper {
    padding: 20px;

    h3 {
      margin: 0;
    }
  }
`

class TabRfq extends Component {
  signal = axios.CancelToken.source()

  state = {
    lines: null,
    partNumbers: null,
  }

  async componentDidMount() {
    const { requisition } = this.props

    const partNumbers = requisition.requisition.part_numbers

    this.setState({
      partNumbers,
    })

    this.getRequisitionRfqPo()
  }

  componentWillUnmount() {
    this.signal.cancel('Api is being canceled')
  }

  onRfqSent = () => {
    this.getRequisitionRfqPo()
  }

  getRequisitionRfqPo = async () => {
    const { uuid } = this.props

    try {
      const res = await getRequisitionRfqpoAPI(uuid, this.signal.token)

      this.setState({
        lines: res.data.data.lines,
      })
    } catch (e) {
      this.props.onApiError(e)
    }
  }

  render() {
    const { lines, partNumbers } = this.state
    const { requisition } = this.props
    const columns = [
      {
        Header: 'Reference',
        Cell: (props) => (
          <Link
            to={`/${props.original.type.toLowerCase()}/${props.original.id}`}
          >
            <span style={{ textTransform: 'uppercase' }}>
              {props.original.type}&nbsp;
              {props.original.id}
            </span>
          </Link>
        ),
      },
      {
        Header: 'Supplier',
        accessor: 'supplier',
        Cell: (row) => <SupplierTableCell supplier={row.value} />,
      },
      {
        Header: 'Date',
        accessor: 'date.formatted_date',
      },
      {
        Header: 'Status',
        accessor: 'status.term',
      },
    ]

    return (
      <TabRfqContainer>
        <Grid container spacing={3}>
          <Grid item xs={requisition.permissions.create_rfq ? 9 : 12}>
            {lines && (
              <ReactTable
                data={lines}
                columns={columns}
                minRows={3}
                showPagination={false}
              />
            )}
          </Grid>
          {requisition.permissions.create_rfq && (
            <Grid item xs={3}>
              <AddToRfq
                partNumbers={partNumbers}
                requisition={requisition}
                onRfqSent={this.onRfqSent}
              />
            </Grid>
          )}
        </Grid>
      </TabRfqContainer>
    )
  }
}

const mapStateToProps = (state) => ({
  requisition: state.requisition.selectedRequisition,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ onApiError }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(TabRfq)
