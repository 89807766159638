import React, { useState, useEffect, useContext } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { confirmRfqAPI, cancelRfqLineAPI } from 'services/helpers/apis/rfq'
import { GlobalContext } from 'routes'

const TextAreaField = styled.textarea`
  width: 100%;
  box-sizing: border-box;
`

const SendRfqModal = ({
  onRfqSent = () => {},
  messageTemplate,
  rfqId,
  rfqLineUuid,
  saveRfq,
  open,
  close,
}) => {
  const [modalMessageTemplate, setModalMessageTemplate] = useState('')
  const context = useContext(GlobalContext)
  const history = useHistory()

  useEffect(() => {
    if (messageTemplate) {
      setModalMessageTemplate(messageTemplate)
    }
  }, messageTemplate)

  const viewRfq = () => {
    history.push(`/rfq/${rfqId}`)
  }

  const sendRfq = async () => {
    try {
      const confirmRfq = await confirmRfqAPI(rfqId, modalMessageTemplate)
      toast.success(
        `RFQ ${confirmRfq.data.data.rfq_id} Sent to ${confirmRfq.data.data.supplier}`
      )
      onRfqSent()
      close()
    } catch (e) {
      context.onApiError(e)
    }
  }

  const cancelRfq = async () => {
    try {
      await cancelRfqLineAPI(rfqLineUuid)

      close()
    } catch (e) {
      context.onApiError(e)
    }
  }

  const onMessageUpdate = (e) => {
    setModalMessageTemplate(e.target.value)
  }

  return (
    <Dialog onClose={close} open={open} disableBackdropClick>
      <DialogContent>
        <TextAreaField
          rows='10'
          value={messageTemplate}
          onChange={onMessageUpdate}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={cancelRfq}>Cancel</Button>
        <Button onClick={viewRfq}>View RFQ</Button>
        <Button onClick={saveRfq}>Save RFQ as Draft</Button>
        <Button onClick={sendRfq}>Send</Button>
      </DialogActions>
    </Dialog>
  )
}

export default SendRfqModal
