import React, { useState, useEffect, createContext, useContext } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { Box, Dialog } from '@material-ui/core'
import { toast } from 'react-toastify'

import { GlobalContext } from 'routes'
import { client } from 'services/helpers/apiConfig'
import adminActions from 'services/redux/admin/actions'
import storageActions from 'services/redux/storage/actions'
import purchaseOrderActions from 'services/redux/purchaseOrder/actions'
import LoadingSpinner from 'components/common/loadingSpinner'

import PoTable from './table'
import TableBulkActions from './tableBulkActions'
import OrderModalContent from './orderModal'

const {
  setCorrespondenceThreadUUID,
  setCorrespondenceMessageUUID,
} = adminActions
const {
  unsetPoData,
  onPoSendConfirmed,
  onPoDeleteConfirmed,
} = purchaseOrderActions
const { setPoTableSettings } = storageActions
export const PoListContext = createContext()

const PurchaseOrdersList = ({
  sentPoId,
  deletedPo,
  onPoSendConfirmed,
  onPoDeleteConfirmed,
  unsetPoData,
  setCorrespondenceThreadUUID,
  setCorrespondenceMessageUUID,
  match,
  history,
}) => {
  const context = useContext(GlobalContext)
  const poTableSettings = useSelector((state) => state.storage.poTableSettings)
  const [purchaseOrdersList, setPurchaseOrdersList] = useState([])
  const [suppliersList, setSuppliersList] = useState(null)
  const [statusList, setStatusList] = useState(null)
  const [filterValues, setFilterValues] = useState({
    suppliers: [],
    statuses: [],
    users: [],
  })
  const [orderModalOpen, setOrderModalOpen] = useState(false)
  const [isOrderModalOpenFromURL, setIsOrderModalOpenFromURL] = useState(false)
  const [userList, setUserList] = useState([])
  const [selectedPo, setSelectedPo] = useState(null)
  const [selectedPoIndex, setSelectedPoIndex] = useState(null)
  const [filterChanged, setFilterChanged] = useState(false)
  const [poModalSelectedTab, setPoModalSelectedTab] = useState('lines')
  const dispatch = useDispatch()
  const [tableViewInfo, setTableViewInfo] = useState(
    (poTableSettings && poTableSettings.tableViewInfo) || {
      totalRows: 0,
      pageIndex: 1,
      rowsCount: 25,
    }
  )
  const [checkedPos, setCheckedPos] = useState([])

  const fetchPoList = async (
    viewInfo,
    filterValues = null,
    searchTerm = null,
    openModal = false
  ) => {
    try {
      let res
      if (!searchTerm) {
        res = await client.get(
          `po/list?${
            filterValues
              ? `supplier_id=${filterValues.suppliers.join()}&status_id=${filterValues.statuses.join()}&assignee=${filterValues.users.join()}`
              : ''
          }&page=${viewInfo.pageIndex}&rows=${viewInfo.rowsCount}`
        )
      } else {
        res = await client.get(
          `po/list?${
            filterValues
              ? `supplier_id=${filterValues.suppliers.join()}&status_id=${filterValues.statuses.join()}&assignee=${filterValues.users.join()}`
              : ''
          }&rows=${viewInfo.rowsCount}&search=${searchTerm}`
        )
      }

      setPurchaseOrdersList([...res.data.data.purchase_order])

      setTableViewInfo({
        pageIndex: viewInfo.pageIndex,
        rowsCount: viewInfo.rowsCount,
        totalRows: res.data.data.total_rows,
      })

      dispatch(
        setPoTableSettings({
          filter: filterValues,
          tableViewInfo: {
            pageIndex: viewInfo.pageIndex,
            rowsCount: viewInfo.rowsCount,
            totalRows: res.data.data.total_rows,
          },
        })
      )

      if (openModal) {
        const selectedPo = res.data.data.purchase_order[0]

        setSelectedPo(selectedPo || null)
        setPoModalSelectedTab(
          selectedPo && match.params.tabName ? match.params.tabName : 'lines'
        )
        setOrderModalOpen(!!selectedPo)

        if (
          match.params.tabName === 'correspondence' &&
          match.params.threadUUID
        ) {
          setCorrespondenceThreadUUID(match.params.threadUUID)
        }

        if (
          match.params.tabName === 'correspondence' &&
          match.params.messageUUID
        ) {
          setCorrespondenceMessageUUID(match.params.messageUUID)
        }

        if (!selectedPo) {
          toast.error(`PO ${match.params.id} is not available`)
        }
      }
    } catch (e) {
      context.onApiError(e)
    }
  }

  useEffect(() => {
    const fetchApis = async () => {
      try {
        if (match.params.id) {
          fetchPoList(
            {
              pageIndex: 1,
              rowsCount: 25,
              totalRows: 1,
            },
            filterValues,
            `PO+${match.params.id}`,
            true
          )
          setIsOrderModalOpenFromURL(true)
        } else {
          fetchPoList(tableViewInfo, filterValues)
        }
        const suppliersListRes = await client.get('suppliers/list')
        const statusListRes = await client.get('po/status/list')
        const userListRes = await client.get('user/assign/po')

        setSuppliersList(suppliersListRes.data.data)
        setStatusList(statusListRes.data.data)
        setUserList(userListRes.data.data)
      } catch (e) {
        context.onApiError(e)
      }
    }

    fetchApis()
  }, [])

  useEffect(() => {
    if (sentPoId) {
      fetchPoList(tableViewInfo, filterValues)
      onPoSendConfirmed()
    }
  }, [sentPoId])

  useEffect(() => {
    if (deletedPo) {
      history.push(`/po/list`)
      setOrderModalOpen(false)
      fetchPoList(tableViewInfo, filterValues)
      onPoDeleteConfirmed()
    }
  }, [deletedPo])

  const handleChange = (target, values) => {
    setFilterValues({
      ...filterValues,
      [target]: values,
    })
    setTableViewInfo({
      ...tableViewInfo,
      pageIndex: 1,
    })
    setFilterChanged(true)
  }

  useEffect(() => {
    if (filterChanged) {
      fetchPoList(tableViewInfo, filterValues)
      setFilterChanged(false)
    }
  }, [filterChanged])

  const onTableViewChange = (pageIndex, numberOfRows = 10) => {
    fetchPoList({ pageIndex, rowsCount: numberOfRows }, filterValues)
  }

  const onPurchaseOrderClick = (po, index) => {
    setOrderModalOpen(true)
    setSelectedPo(po)
    setSelectedPoIndex(index)
    history.push(`/po/${po.id}/lines`)
  }

  const updatePoStatus = (index, status) => {
    const newPurchaseOrdersList = purchaseOrdersList
    newPurchaseOrdersList[index].status = status
    setPurchaseOrdersList(newPurchaseOrdersList)
  }

  const closeModal = () => {
    if (isOrderModalOpenFromURL) {
      fetchPoList(
        {
          pageIndex: 1,
          rowsCount: 25,
          totalRows: 1,
        },
        filterValues
      )
    }
    setOrderModalOpen(false)
    setIsOrderModalOpenFromURL(false)
    unsetPoData()
    history.push('/po/list')
  }

  const onCheckRow = (rows) => {
    setCheckedPos(rows)
  }

  const bulkActionUpdated = (updatedObj) => {
    const checkedRows = checkedPos
    const rowsList = purchaseOrdersList

    for (let i = 0; i < checkedRows.length; i += 1) {
      for (let j = 0; j < rowsList.length; j += 1) {
        if (rowsList[j].id === checkedRows[i]) {
          rowsList[j] = {
            ...rowsList[j],
            ...updatedObj,
          }
        }
      }
    }

    setPurchaseOrdersList([...rowsList])
  }

  return (
    <PoListContext.Provider
      value={{
        fetchPoList,
        onCheckRow,
        rows: purchaseOrdersList,
        suppliersList,
        userList,
        statusList,
        onTableViewChange,
      }}
    >
      {suppliersList && userList && statusList ? (
        <div>
          <Box mb={3}>
            <PoTable
              openPoModal={onPurchaseOrderClick}
              onFilterChange={handleChange}
            />
          </Box>

          <TableBulkActions
            checkedPos={checkedPos}
            bulkActionUpdated={bulkActionUpdated}
          />

          <Dialog
            maxWidth='lg'
            fullWidth
            open={orderModalOpen}
            onClose={closeModal}
          >
            <OrderModalContent
              selectedPo={selectedPo}
              selectedPoIndex={selectedPoIndex}
              userList={userList}
              onClose={closeModal}
              updatePoStatus={updatePoStatus}
              selectedTab={poModalSelectedTab}
            />
          </Dialog>
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </PoListContext.Provider>
  )
}

const mapStateToProps = (state) => ({
  sentPoId: state.purchaseOrder.sent_po_id,
  deletedPo: state.purchaseOrder.deleted_po,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      unsetPoData,
      onPoSendConfirmed,
      onPoDeleteConfirmed,
      setCorrespondenceMessageUUID,
      setCorrespondenceThreadUUID,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PurchaseOrdersList))
