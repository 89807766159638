const shipmentActions = {
  FETCH_SHIPMENT_REQUEST: 'FETCH_SHIPMENT_REQUEST',
  FETCH_SHIPMENT_SUCCESSS: 'FETCH_SHIPMENT_SUCCESS',
  FETCH_SHIPMENT_REJECT: 'FETCH_SHIPMENT_REJECT',

  FETCH_SHIPMENT_LIST_REQUEST: 'FETCH_SHIPMENT_LIST_REQUEST',
  FETCH_SHIPMENT_LIST_SUCCESS: 'FETCH_SHIPMENT_LIST_SUCCESS',
  FETCH_SHIPMENT_LIST_REJECT: 'FETCH_SHIPMENT_LIST_REJECT',

  FETCH_CARRIERS_LIST_REQUEST: 'FETCH_CARRIERS_LIST_REQUEST',
  FETCH_CARRIERS_LIST_SUCCESS: 'FETCH_CARRIERS_LIST_SUCCESS',
  FETCH_CARRIERS_LIST_REJECT: 'FETCH_CARRIERS_LIST_REJECT',

  SET_REMAINING_LINES: 'SET_REMAINING_LINES',
  SET_COMPLETED_SHIPMENTS: 'SET_COMPLETED_SHIPMENTS',

  SHIPMENT_UPDATED: 'SHIPMENT_UPDATED',
  SHIPMENT_UPDATED_RESET: 'SHIPMENT_UPDATED_RESET',

  fetchShipment: (uuid) => ({
    type: shipmentActions.FETCH_SHIPMENT_REQUEST,
    payload: {
      uuid,
    },
  }),

  fetchShipmentList: (status = 'none', startDate = 'none') => ({
    type: shipmentActions.FETCH_SHIPMENT_LIST_REQUEST,
    payload: {
      status,
      startDate,
    },
  }),

  fetchCarriersList: () => ({
    type: shipmentActions.FETCH_CARRIERS_LIST_REQUEST,
  }),
}

export default shipmentActions
