import React, { useState, useContext } from 'react'
import SaveIcon from '@material-ui/icons/Save'
import { Tooltip, TextField, Checkbox, Box } from '@material-ui/core'
import { toast } from 'react-toastify'

import { GlobalContext } from 'routes'
import { createRfqQuoteAPI } from 'services/helpers/apis/rfq'
import CustomizedSnackbar from 'components/snackBar'
import leadTimeValues from 'utils/leadTimes'

import EditableSelect from './editableSelect'

function NewQuoteLine({ manufacturerList, rfqUUID, onQuoteAdded }) {
  const context = useContext(GlobalContext)
  const [inputValues, setInputValues] = useState({
    quantity: 0,
    part_number: '',
    price: 0,
    manufacturer_id: '',
    lead_time: '',
    supplier_part_number: '',
    supplier_part_number_custom: false,
  })
  const [inputErrors, setInputErrors] = useState({
    quantityError: false,
    manufacturerError: false,
    partNumberError: false,
    priceError: false,
  })
  const [formInvalid, setFormInvalid] = useState(false)

  const onSaveQuotedItem = async () => {
    if (
      inputValues.quantity > 0 &&
      inputValues.part_number !== '' &&
      inputValues.price > 0 &&
      inputValues.manufacturer_id !== '' &&
      inputValues.lead_time !== ''
    ) {
      try {
        const res = await createRfqQuoteAPI({
          rfq_uuid: rfqUUID,
          item: inputValues.part_number,
          price: inputValues.price,
          quantity: inputValues.quantity,
          manufacturer_id: inputValues.manufacturer_id,
          lead_time: inputValues.lead_time,
          supplier_part_number: inputValues.supplier_part_number,
          supplier_part_number_custom: inputValues.supplier_part_number_custom,
        })

        toast.success('New quoted item is created')
        onQuoteAdded(res.data.data)
        setInputValues({
          quantity: 0,
          part_number: '',
          supplier_part_number: '',
          supplier_part_number_custom: false,
          price: '',
          manufacturer_id: '',
          lead_time: '',
        })
      } catch (e) {
        context.onApiError(e)
      }
    } else {
      setFormInvalid(true)
    }
  }

  const onInputUpdate = (event) => {
    const { name, value } = event.target
    setFormInvalid(false)
    switch (name) {
      case 'quantity':
      case 'price':
        if (parseInt(value, 10) <= 0) {
          setInputErrors({ ...inputErrors, [`${name}Error`]: true })
        } else {
          setInputValues({ ...inputValues, [name]: value })
        }
        break
      default:
        setInputValues({ ...inputValues, [name]: value })
        break
    }
  }

  const onChangeSupplierCustom = () => {
    setInputValues({
      ...inputValues,
      supplier_part_number_custom: !inputValues.supplier_part_number_custom,
    })
  }

  const updateSelectValue = (selectName, value) => {
    setInputValues({ ...inputValues, [selectName]: value })
    setFormInvalid(false)
  }

  return (
    <div className={`${formInvalid ? 'invalid' : ''} quoted-item new`}>
      <div className="item-values">
        <Box className="field" mb={2}>
          <Tooltip
            open={inputErrors.quantityError}
            onClose={() => {
              setInputErrors({ ...inputErrors, quantityError: false })
            }}
            title="Quantity must bigger than 0."
          >
            <TextField
              required
              name="quantity"
              label="Quantity"
              value={inputValues.quantity}
              onChange={onInputUpdate}
              variant="outlined"
            />
          </Tooltip>
        </Box>
        <Box className="field" mb={2}>
          <TextField
            required
            name="part_number"
            label="Part Number"
            value={inputValues.part_number}
            onChange={onInputUpdate}
            variant="outlined"
          />
        </Box>
        <Box className="field" mb={2}>
          <TextField
            required
            name="supplier_part_number"
            label="Supplier Part Number"
            value={inputValues.supplier_part_number}
            onChange={onInputUpdate}
            variant="outlined"
          />
        </Box>
        <Box className="field custom-field" mb={2}>
          <p>Custom</p>
          <Checkbox
            checked={inputValues.supplier_part_number_custom}
            onChange={onChangeSupplierCustom}
            value="secondary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </Box>
        <Box className="field" mb={2}>
          <EditableSelect
            quoteLineUUID=""
            selectList={manufacturerList}
            selectValue={inputValues.manufacturer_id}
            selectType="manufacturer_id"
            isNewLine
            updateSelectValue={updateSelectValue}
          />
        </Box>
        <Box className="field" mb={2}>
          <EditableSelect
            quoteLineUUID=""
            selectList={leadTimeValues}
            selectValue={inputValues.lead_time}
            selectType="lead_time"
            isNewLine
            updateSelectValue={updateSelectValue}
          />
        </Box>
        <Tooltip
          open={inputErrors.priceError}
          onClose={() => {
            setInputErrors({ ...inputErrors, priceError: false })
          }}
          title="Price must bigger than 0."
        >
          <TextField
            required
            name="price"
            label="Price"
            value={inputValues.price}
            onChange={onInputUpdate}
            variant="outlined"
          />
        </Tooltip>
        <CustomizedSnackbar
          open={formInvalid}
          message="New quote form is not valid"
          messageType="error"
          onClose={() => setFormInvalid(false)}
        />
      </div>

      <div className="row-actions">
        <SaveIcon onClick={onSaveQuotedItem} />
      </div>
    </div>
  )
}

export default NewQuoteLine
