import React, { Component } from 'react'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'

class Suppliers extends Component {
  componentDidMount() {}

  render() {
    return (
      <Card>
        <CardHeader title="Suppliers" />
        <CardContent>Suppliers</CardContent>
      </Card>
    )
  }
}

export default Suppliers
