import React from 'react'
import { Switch, Route, Redirect } from 'react-router'

import RequisitionsList from './list'

const Requisitions = ({ match }) => {
  const routeURL = match.url

  return (
    <Switch>
      <Route exact path={`${routeURL}`}>
        <Redirect to={`${routeURL}/list`} />
      </Route>
      <Route
        exact
        path={[
          `${routeURL}/list`,
          `${routeURL}/:id`,
          `${routeURL}/:id/:tabName`,
        ]}
        component={RequisitionsList}
      />
    </Switch>
  )
}

export default Requisitions
