import React, { Component } from 'react'
import _indexOf from 'lodash/indexOf'
import styled from 'styled-components'
import moment from 'moment-timezone'

const HistoryContainer = styled.div`
  p {
    &.hidden {
      display: none;
    }
  }
`

class PurchaseOrderHistory extends Component {
  state = {
    historyDates: [],
    histories: [],
  }

  componentDidMount() {
    const { historyData } = this.props

    historyData.map((history) => {
      const date = moment(history.datetime.timestamp).tz(
        history.datetime.timezone_name
      )

      this.setState((prevState) => {
        if (
          _indexOf(prevState.historyDates, date.format('MMMM DD YYYY')) === -1
        ) {
          return {
            ...prevState,
            historyDates: prevState.historyDates.concat(
              date.format('MMMM DD YYYY')
            ),
            histories: prevState.histories.concat({
              date: date.format('MMMM DD YYYY'),
              time: date.format('h:m a z'),
              message: history.message,
            }),
          }
        }

        return {
          ...prevState,
          histories: prevState.histories.concat({
            date: date.format('MMMM DD YYYY'),
            time: date.format('h:m a z'),
            message: history.message,
          }),
        }
      })

      return history
    })
  }

  render() {
    const { historyDates, histories } = this.state

    return (
      <HistoryContainer>
        {historyDates.length > 0 &&
          historyDates.map((date, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={i}>
              <h3>{date}</h3>
              {histories.length > 0 &&
                histories.map((history, j) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <p key={j} className={`${history.date !== date && 'hidden'}`}>
                    {history.date === date ? (
                      <span>
                        {history.time}.{''}
                        {''} {history.message}
                      </span>
                    ) : null}
                  </p>
                ))}
            </div>
          ))}
        {historyDates.length === 0 && <p>No history found</p>}
      </HistoryContainer>
    )
  }
}

export default PurchaseOrderHistory
