import styled from 'styled-components'
import red from '@material-ui/core/colors/red'

const EditableInputContainer = styled.div`
  p.field-error {
    color: ${red[500]};
    font-size: 0.8em;
  }
`

export default EditableInputContainer
