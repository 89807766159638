import React, { useState, useContext, useEffect } from 'react'
import { Button, Typography, Box, Link, Tooltip } from '@material-ui/core'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import AttachFileIcon from '@material-ui/icons/AttachFile'

import { markReadMessageAPI } from 'services/helpers/apis/thread'
import { generateQuotesHTMLContent } from 'utils/common'
import usePrevious from 'utils/usePrevious'
import { GlobalContext } from 'routes'

import { CorrespondenceTableContext } from '../index'
import MessageBlockContainer from './messageBlock.style'

const MessageBlock = ({
  message,
  messageIndex,
  selectedLine,
  onClickAttachment,
  openDraft,
  closeDraft,
}) => {
  const context = useContext(GlobalContext)
  const correspondenceContext = useContext(CorrespondenceTableContext)
  const [isCollapsed, setIsCollapsed] = useState(true)
  const [messageObj, setMessageObj] = useState(message)
  const [quotesContent, setQuotesContent] = useState(null)
  const [showQuotes, setShowQuotes] = useState(false)
  const prevMessageObj = usePrevious(messageObj)

  const isFirstMessage =
    messageIndex === correspondenceContext.correspondence.messages.length - 1

  useEffect(() => {
    setMessageObj(message)
  }, [message])

  useEffect(() => {
    if (prevMessageObj && prevMessageObj.id !== message.id) {
      setIsCollapsed(true)
    }
  }, [messageObj])

  const onClickMessageHeader = async () => {
    if (messageObj.status.status === 'Unread') {
      try {
        const res = await markReadMessageAPI(message.uuid)

        const updatedMessageObj = messageObj
        updatedMessageObj.status = {
          status: 'Read',
          color: '#000000',
          bold: false,
        }

        setMessageObj({ ...updatedMessageObj })
        correspondenceContext.onCorrespondenceUpdated(res.data.data)
      } catch (e) {
        context.onApiError(e)
      }
    }

    if (messageObj.status.status === 'Draft' && isCollapsed) {
      openDraft(messageObj, messageIndex)
    } else if (messageObj.status.status === 'Draft' && !isCollapsed) {
      closeDraft()
    }

    setIsCollapsed(!isCollapsed)
  }

  const onShowTrimmedContent = () => {
    if (!quotesContent) {
      const { messages } = correspondenceContext.correspondence
      const quoteMessages = [...messages]
      quoteMessages.splice(0, messageIndex + 1)
      const quotesHtmlContent = generateQuotesHTMLContent(null, quoteMessages)
      setQuotesContent(quotesHtmlContent)
    }
    setShowQuotes(!showQuotes)
  }

  return (
    <MessageBlockContainer
      className={`message ${messageObj.status.status === 'Unread' &&
        'unread-status'}`}
    >
      <div
        className='message-header'
        onClick={onClickMessageHeader}
        onKeyUp={() => {}}
        role='button'
        tabIndex='0'
      >
        <p className='name'>
          {messageObj.display_name}{' '}
          <span className='message-to'>
            {messageObj.is_internal
              ? 'Internal Note'
              : `Message to ${selectedLine.supplier}`}
          </span>{' '}
          <span
            style={{
              color: messageObj.status.color,
              fontWeight: messageObj.status.bold ? 'bold' : 'normal',
            }}
          >
            {messageObj.status.status}
          </span>
        </p>
        <p className='date'>{messageObj.date_added.formatted_date}</p>
      </div>
      {!isCollapsed && messageObj.html_body && (
        <Box className='message-content'>
          <Box className='message-body' p={1}>
            <p
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: messageObj.html_body,
              }}
            />
            {!messageObj.is_quote_attached &&
              messageObj.status.status !== 'Draft' &&
              !isFirstMessage && (
                <Tooltip title='Show trimmed content'>
                  <Button
                    variant='contained'
                    onClick={onShowTrimmedContent}
                    className='btn-show-quote'
                  >
                    <MoreHorizIcon />
                  </Button>
                </Tooltip>
              )}
          </Box>
          {messageObj.attachments.length > 0 && (
            <Box className='attachments' p={1}>
              <Typography variant='body1' gutterBottom>
                <strong>Attachments</strong>
              </Typography>
              {messageObj.attachments.map((attachment) => (
                <Box key={attachment.uuid}>
                  <Link onClick={() => onClickAttachment(attachment)}>
                    <Box display='flex'>
                      {attachment.mime_type === 'application/pdf' ? (
                        <PictureAsPdfIcon />
                      ) : (
                        <AttachFileIcon />
                      )}
                      &nbsp;
                      {attachment.filename}
                    </Box>
                  </Link>
                </Box>
              ))}
            </Box>
          )}
          {showQuotes && (
            <Box className='quotes' p={1}>
              <div
                dangerouslySetInnerHTML={{
                  __html: quotesContent,
                }}
              ></div>
            </Box>
          )}
        </Box>
      )}
    </MessageBlockContainer>
  )
}

export default MessageBlock
