import React, { useState, useContext, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'

import { getRequisitionsSearchAPI } from 'services/helpers/apis/requisition'
import { getRfqSearchAPI } from 'services/helpers/apis/rfq'
import { getPoSearchAPI } from 'services/helpers/apis/po'
import { GlobalContext } from 'routes'
import { RequisitionListContext } from 'pages/requisitions/list'
import { RfqListContext } from 'pages/rfq/list'
import { PoListContext } from 'pages/purchaseOrders/list'
import { TableContext } from './table'

const WAIT_INTERVAL = 1000
let timer

export default function AutocompleteSearch() {
  const context = useContext(GlobalContext)
  const tableContext = useContext(TableContext)
  const requisitionListContext = useContext(RequisitionListContext)
  const rfqListContext = useContext(RfqListContext)
  const poListContext = useContext(PoListContext)
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  useEffect(() => {
    timer = null
  }, [])

  const onChangeSearchField = async (e) => {
    clearTimeout(timer)
    setSearchValue(e.target.value)
    const termValue = e.target.value

    const assigneeValue = tableContext.tableSettings.filter.users
      ? tableContext.tableSettings.filter.users.toString()
      : ''
    const statusValue = tableContext.tableSettings.filter.statuses
      ? tableContext.tableSettings.filter.statuses.toString()
      : ''

    timer = setTimeout(async () => {
      setLoading(true)
      try {
        let res
        if (tableContext.tableFor === 'requisition') {
          res = await getRequisitionsSearchAPI(
            termValue,
            assigneeValue,
            statusValue
          )
        } else if (tableContext.tableFor === 'rfq') {
          const supplierValue = tableContext.tableSettings.filter.suppliers.toString()
          res = await getRfqSearchAPI(termValue, assigneeValue, supplierValue)
        } else if (tableContext.tableFor === 'po') {
          const supplierValue = tableContext.tableSettings.filter.suppliers.toString()
          res = await getPoSearchAPI(termValue, assigneeValue, supplierValue)
        }
        const terms = res.data.data.search.terms.map((term) => `${term}`)
        setOptions(terms)
      } catch (e) {
        context.onApiError(e)
      } finally {
        setLoading(false)
      }
    }, WAIT_INTERVAL)
  }

  const onChangeValue = (event, value) => {
    if (tableContext.tableFor === 'requisition') {
      requisitionListContext.fetchRequisitionList(
        {
          pageIndex: 1,
          rowsCount: 25,
          totalRows: 1,
        },
        tableContext.tableSettings.filter,
        value
      )
    } else if (tableContext.tableFor === 'rfq') {
      rfqListContext.fetchRfqList(
        {
          pageIndex: 1,
          rowsCount: 25,
          totalRows: 1,
        },
        tableContext.tableSettings.filter,
        value
      )
    } else if (tableContext.tableFor === 'po') {
      poListContext.fetchPoList(
        {
          pageIndex: 1,
          rowsCount: 25,
          totalRows: 1,
        },
        tableContext.tableSettings.filter,
        value
      )
    }
  }

  return (
    <Autocomplete
      id='asynchronous-demo'
      style={{ width: 200 }}
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      getOptionSelected={(option, value) => option === value}
      options={options}
      loading={loading}
      onChange={onChangeValue}
      renderInput={(params) => (
        <TextField
          {...params}
          label='Search'
          variant='outlined'
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color='inherit' size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          value={searchValue}
          onChange={onChangeSearchField}
        />
      )}
    />
  )
}
