import React, { Component } from 'react'
import ReactTable from 'react-table'
import { Link as RouterLink } from 'react-router-dom'
import Link from '@material-ui/core/Link'

import { client } from 'services/helpers/apiConfig'

class UserTypeList extends Component {
  state = {
    userTypes: [],
  }

  async componentDidMount() {
    try {
      const res = await client.get('userType/list')
      this.setState({
        userTypes: res.data.data,
      })
    } catch (e) {
      console.log(e)
    }
  }

  render() {
    const { userTypes } = this.state

    const columns = [
      {
        Header: 'User Type',
        id: 'userType',
        accessor: 'user_type',
        headerStyle: {
          textAlign: 'left',
        },
      },
      {
        Header: '',
        id: 'typeLink',
        accessor: 'user_type',
        Cell: (row) => {
          const route = row.value.replace(' ', '+')

          return (
            <Link component={RouterLink} to={`/admin/userType/update/${route}`}>
              Edit
            </Link>
          )
        },
        maxWidth: 200,
      },
    ]

    return (
      <div className="wrapper">
        <ReactTable
          data={userTypes}
          columns={columns}
          defaultPageSize={5}
          minRows={3}
          showPagination={false}
          className="-striped -highlight"
        />
      </div>
    )
  }
}

export default UserTypeList
