import React, { useEffect, useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Checkbox,
  TablePagination,
  Paper,
  Box,
  IconButton,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { toast } from 'react-toastify'
import { fetchSuppliersAPI } from 'services/helpers/apis/supplier'
import { getManufacturerListAPI } from 'services/helpers/apis/other'
import { GlobalContext } from 'routes'
import EnhancedTableHead from 'components/common/enhancedTableHead'
import LoadingSpinner from 'components/common/loadingSpinner'
import FilterBox from './filterBox'
import SelectAssociate from './selectAssociate'
import TableActions from './tableActions'

const useStyles = makeStyles((theme) => ({
  tabContent: {
    marginBottom: 80,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}))

const headCells = [
  {
    id: 'name_variant',
    label: 'Name Variant',
  },
  { id: 'service', label: 'Service' },
  { id: 'manufacturer', label: 'Manufacturer' },
  { id: 'delete', label: '' },
]

const NameVariantsTable = ({
  getListAPI,
  getServicesListAPI,
  deleteAPI,
  updateAPI,
  tableFor,
}) => {
  const context = useContext(GlobalContext)
  const classes = useStyles()
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [page, setPage] = useState(0)
  const [loading, setLoading] = useState(true)
  const [nameVariants, setNameVariants] = useState()
  const [selectList, setSelectList] = useState()
  const [selected, setSelected] = useState([])
  const [totalRows, setTotalRows] = useState(0)
  const [services, setServices] = useState()
  const isSelected = (id) => selected.indexOf(id) !== -1
  const [defaultBulkSupplier, setDefaultBulkSupplier] = useState('')

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = nameVariants.map((n) => n.uuid)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const fetchApis = async () => {
    setLoading(true)
    try {
      const query = `rows=${rowsPerPage}&page=${page + 1}`
      const resNameVariants = await getListAPI(query)

      const resServices = await getServicesListAPI()

      setServices(resServices.data.data)
      setTotalRows(resNameVariants.data.data.total_rows)
      setNameVariants(
        tableFor === 'supplier'
          ? resNameVariants.data.data.supplier_name_variants
          : resNameVariants.data.data.manufacturer_name_variants
      )

      if (tableFor === 'supplier') {
        const resSuppliers = await fetchSuppliersAPI()
        setSelectList(resSuppliers.data.data)
      } else {
        const resManufacturers = await getManufacturerListAPI()
        setSelectList(resManufacturers.data.data)
      }
    } catch (e) {
      context.onApiError(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchApis()
  }, [])

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)
  }

  const fetchList = async (
    rows,
    page,
    showOnlyUnassignedName = false,
    service = null,
    term = null
  ) => {
    setLoading(true)
    try {
      let query = `rows=${rows}&page=${page +
        1}&unassociated=${showOnlyUnassignedName}`
      if (service) {
        query = `${query}&service=${service}`
      }
      if (term) {
        query = `${query}&search_term=${term}`
      }

      const resNameVariants = await getListAPI(query)

      if (
        resNameVariants.data.data &&
        (resNameVariants.data.data.supplier_name_variants ||
          resNameVariants.data.data.manufacturer_name_variants)
      ) {
        setNameVariants(
          tableFor === 'supplier'
            ? [...resNameVariants.data.data.supplier_name_variants]
            : [...resNameVariants.data.data.manufacturer_name_variants]
        )
      } else {
        setNameVariants([])
      }
    } catch (e) {
      context.onApiError(e)
    } finally {
      setLoading(false)
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    fetchList(rowsPerPage, newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    fetchList(parseInt(event.target.value, 10), 0)
    setPage(0)
  }

  const onChangeFilter = (
    showOnlyUnassignedName = null,
    service = null,
    term = null
  ) => {
    fetchList(rowsPerPage, page, showOnlyUnassignedName, service, term)
  }

  const onDeleteRow = async (row) => {
    try {
      await deleteAPI(row.uuid)
      toast.success(
        `${row.name_variant}/${row.service_name} successfully deleted`
      )
      fetchList(rowsPerPage, page)
    } catch (e) {
      context.onApiError(e)
    }
  }

  const onUpdateSelected = async (selectedValue) => {
    setDefaultBulkSupplier(selectedValue)
    try {
      if (tableFor === 'supplier') {
        await updateAPI('', { supplier_id: selectedValue, uuid: selected })
      } else {
        await updateAPI('', { manufacturer_id: selectedValue, uuid: selected })
      }
      toast.success(
        `${
          tableFor === 'supplier' ? 'Supplier' : 'Manufacturer'
        } updated for selected name variants`
      )
      fetchList(rowsPerPage, page)
    } catch (e) {
      context.onApiError(e)
    }
  }

  useEffect(() => {
    setDefaultBulkSupplier('')
  }, [selected.length])

  return (
    <div className={classes.tabContent}>
      <Paper className={classes.paper}>
        {services && (
          <Box display='flex' alignItems='center' justifyContent='flex-end'>
            <FilterBox
              services={services}
              onChangeFilter={onChangeFilter}
              tableFor={tableFor}
            />
          </Box>
        )}

        {loading ? (
          <Box py={5}>
            <LoadingSpinner />
          </Box>
        ) : (
          <Box>
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby='tableTitle'
                aria-label='enhanced table'
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  onSelectAllClick={handleSelectAllClick}
                  rowCount={nameVariants.length}
                  headCells={headCells}
                />
                <TableBody>
                  {nameVariants.map((row, index) => {
                    const isItemSelected = isSelected(row.uuid)
                    const labelId = `enhanced-table-checkbox-${index}`

                    return (
                      <TableRow
                        hover
                        role='checkbox'
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={index}
                        selected={isItemSelected}
                      >
                        <TableCell padding='checkbox'>
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                            onChange={(event) => handleClick(event, row.uuid)}
                          />
                        </TableCell>
                        <TableCell>{row.name_variant}</TableCell>
                        <TableCell>{row.service_name}</TableCell>
                        <TableCell>
                          <SelectAssociate
                            list={selectList}
                            value={
                              tableFor === 'supplier'
                                ? row.supplier_id
                                : row.manufacturer_id
                            }
                            uuid={row.uuid}
                            tableFor={tableFor}
                          />
                        </TableCell>
                        <TableCell align='right'>
                          <IconButton aria-label='delete'>
                            <DeleteIcon onClick={() => onDeleteRow(row)} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100, 250]}
              component='div'
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Box>
        )}
      </Paper>
      {selectList && (
        <TableActions
          defaultValue={defaultBulkSupplier}
          bulkActionDisabled={selected.length === 0}
          list={selectList}
          onUpdateSelected={onUpdateSelected}
          tableFor={tableFor}
        />
      )}
    </div>
  )
}

export default NameVariantsTable
