import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { lighten, makeStyles } from '@material-ui/core/styles'
import { Toolbar, Typography, Box } from '@material-ui/core'

import SearchBox from './searchBox'
import { TableContext } from './table'

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
}))

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles()
  const { numSelected } = props
  const tableContext = useContext(TableContext)

  return (
    <Toolbar
      className={`${classes.root} ${numSelected > 0 && classes.highlight}`}
    >
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        width={1}
      >
        {numSelected > 0 ? (
          <Typography
            className={classes.title}
            color='inherit'
            variant='subtitle1'
            component='div'
          >
            {numSelected} selected
          </Typography>
        ) : (
          <span />
        )}

        <Box display='flex' alignItems='center'>
          <Box mr={2}>
            <SearchBox />
          </Box>
          {tableContext.renderFilterBox()}
        </Box>
      </Box>
    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
}

export default EnhancedTableToolbar
