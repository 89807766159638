import React, { useState, useEffect, useContext } from 'react'
import { Field, reduxForm } from 'redux-form'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import { ChevronRight } from '@material-ui/icons'
import styled from 'styled-components'

import {
  ReduxRadioButton,
  ReduxSelectField,
  ReduxTextField,
} from 'components/reduxFormFields'
import { GlobalContext } from 'routes'
import { getRfqDraftAPI } from 'services/helpers/apis/rfq'
import { getManufacturerListAPI } from 'services/helpers/apis/other'

const FormContainer = styled.form`
  .form-action {
    margin-top: 20px;
    text-align: right;
  }
`

const AddRfqForm = ({
  initialize,
  change,
  handleSubmit,
  requisition,
  partNumbers,
  suppliersList,
}) => {
  const [rfqDrafts, setRfqDrafts] = useState(null)
  const [manufacturers, setManufacturers] = useState(null)
  const context = useContext(GlobalContext)

  useEffect(() => {
    let initializeBody = {
      quantity: requisition.requisition.quantity.outstanding,
    }

    if (partNumbers.length === 1) {
      initializeBody = {
        ...initializeBody,
        partNumber: partNumbers[0],
      }
    }

    initialize(initializeBody)
  }, [])

  const onSelectSupplier = async (event, newValue) => {
    try {
      const getRfqDraft = await getRfqDraftAPI(newValue)

      setRfqDrafts(getRfqDraft.data.data.rfq)
      if (getRfqDraft.data.data.rfq.length === 0) {
        change('rfqId', 'new_rfq')
      }
    } catch (e) {
      context.onApiError(e)
    }
  }

  const onSelectPartNumber = async (e, newValue) => {
    try {
      const res = await getManufacturerListAPI(newValue.replace(/\W/g, ''))

      setManufacturers(res.data.data)
    } catch (e) {
      context.onApiError(e)
    }
  }

  return (
    <FormContainer onSubmit={handleSubmit}>
      <h3>Add to RFQ</h3>
      <Field
        name='partNumber'
        row={false}
        component={ReduxRadioButton}
        onChange={onSelectPartNumber}
      >
        {partNumbers &&
          partNumbers.map((partNumber, i) => (
            <FormControlLabel
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              value={partNumber}
              control={<Radio color='primary' />}
              label={partNumber}
            />
          ))}
      </Field>
      {manufacturers && (
        <Field
          name='manufacturer_id'
          component={ReduxSelectField}
          label='Manufacturer'
          id='field-manufacturer'
          fullWidth
        >
          <option value='' disabled />
          {manufacturers.map((manufacturer, i) => (
            <option value={manufacturer.id} key={i}>
              {manufacturer.name}
            </option>
          ))}
        </Field>
      )}
      <Field
        name='supplierId'
        component={ReduxSelectField}
        label='Supplier'
        id='field-supplier'
        onChange={onSelectSupplier}
        fullWidth
      >
        <option value='' disabled />
        {suppliersList.map((supplier, i) => (
          <option value={supplier.id} key={i}>
            {supplier.name}
          </option>
        ))}
      </Field>
      <Field
        name='quantity'
        label='Quantity'
        component={ReduxTextField}
        fullWidth
      />
      {rfqDrafts && rfqDrafts.length === 0 && (
        <Field
          name='rfqId'
          component={ReduxSelectField}
          label='Rfq'
          id='field-rfq'
          value='new_rfq'
          fullWidth
        >
          <option value='new_rfq'>New RFQ</option>
        </Field>
      )}
      {rfqDrafts && rfqDrafts.length > 0 && (
        <Field
          name='rfqId'
          component={ReduxSelectField}
          label='Rfq'
          id='field-rfq'
          fullWidth
        >
          <option value='' disabled />
          <option value='new_rfq'>New RFQ</option>
          {rfqDrafts.map((rfq, i) => (
            <option value={rfq.id} key={i}>
              RFQ {rfq.id}
            </option>
          ))}
        </Field>
      )}
      <div className='form-action'>
        <Button
          variant='contained'
          color='primary'
          type='submit'
          disabled={!partNumbers}
        >
          Next{' '}
          <Icon>
            <ChevronRight />
          </Icon>
        </Button>
      </div>
    </FormContainer>
  )
}

const validate = (values) => {
  const errors = {}

  if (!values.partNumber) {
    errors.partNumber = 'Required'
  }

  if (!values.supplierId) {
    errors.supplierId = 'Required'
  }

  if (!values.quantity) {
    errors.quantity = 'Required'
  } else if (!/^\d+$/.test(values.quantity)) {
    errors.quantity = 'Quantity must be integer'
  }

  if (!values.rfqId) {
    errors.rfqId = 'Required'
  }

  return errors
}

const AddRfqReduxForm = reduxForm({
  form: 'addRfqForm',
  validate,
})(AddRfqForm)

export default AddRfqReduxForm
