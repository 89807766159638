import React, { useState, useContext } from 'react'
import {
  TableCell,
  TableRow,
  IconButton,
  Collapse,
  Box,
  Button,
} from '@material-ui/core'
import _isEmpty from 'lodash/isEmpty'
import DeleteIcon from '@material-ui/icons/Delete'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import NumberFormat from 'react-number-format'

import DispatchSchedules from 'components/dispatchSchedules'

import { ModalContext } from './index'

const LineTableRow = ({ row, onDeleteLine, index }) => {
  const [open, setOpen] = useState(false)
  const modalContext = useContext(ModalContext)
  const { orderData } = modalContext

  const onToggleCollapse = () => {
    setOpen(!open)
  }

  return (
    <React.Fragment>
      <TableRow>
        <TableCell component='th' scope='row'>
          {row.quantity}
        </TableCell>
        <TableCell>{row.item}</TableCell>
        <TableCell>{row.supplier_part_number}</TableCell>
        <TableCell>
          {!_isEmpty(row.manufacturer) && row.manufacturer.name}
        </TableCell>
        <TableCell>{row.requisition_detail.order_id}</TableCell>
        <TableCell>
          <NumberFormat
            value={parseFloat(row.unit_cost)}
            displayType='text'
            thousandSeparator
            prefix='$'
            decimalScale={2}
            fixedDecimalScale
          />
        </TableCell>
        <TableCell>
          <NumberFormat
            value={row.quantity * parseFloat(row.unit_cost)}
            displayType='text'
            thousandSeparator
            prefix='$'
            decimalScale={2}
            fixedDecimalScale
          />
        </TableCell>
        <TableCell>
          <IconButton
            aria-label='delete'
            onClick={() => onDeleteLine(row, index)}
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={8} component='th' scope='row'>
          <Button
            startIcon={open ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
            onClick={onToggleCollapse}
          >
            Dispatch schedule
          </Button>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box margin={1}>
              <DispatchSchedules
                line={row}
                scheduleFor='po'
                showExpectedScheduleDate={!orderData.permissions.can_edit}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

export default LineTableRow
