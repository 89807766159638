import React from 'react'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'

import RenderFromHelper from './renderFormHelper'

const ReduxSelectField = ({
  input,
  label,
  meta: { touched, error },
  children,
  fullWidth,
  ...custom
}) => {
  return (
    <FormControl error={touched && error} fullWidth={fullWidth}>
      {!custom.nolabel && <InputLabel htmlFor={custom.id}>{label}</InputLabel>}
      <Select
        native
        {...input}
        {...custom}
        inputProps={{
          name: input.name,
          id: custom.id,
        }}
      >
        {children}
      </Select>
      <RenderFromHelper touched={touched} error={error} />
    </FormControl>
  )
}

export default ReduxSelectField
