import React, { useEffect, useState, useContext } from 'react'
import {
  TextField,
  Grid,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Fab,
} from '@material-ui/core'
import { useParams, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add'
import CheckIcon from '@material-ui/icons/Check'
import _remove from 'lodash/remove'
import { toast } from 'react-toastify'

import { GlobalContext } from 'routes'
import {
  readPartNumberRulesAPI,
  getStringEvaluationTypesAPI,
  getPartNumberRulesClassListAPI,
  deletePartNumberRulesAPI,
  updatePartNumberRuleAPI,
} from 'services/helpers/apis/partNumberRules'
import { fetchSuppliersAPI } from 'services/helpers/apis/supplier'
import { listingLogicFunctionsAPI } from 'services/helpers/apis/sourcingRules'
import LoadingSpinner from 'components/common/loadingSpinner'

import TestPartNumberRuleModal from './testPartNumberRuleModal'
import EditLogicModal from './editLogicModal'
import NewLogicModal from './newLogicModal'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  list: {
    '& .MuiListItem-root': {
      alignItems: 'flex-start',
    },
  },
}))

const SupplierPartNumberEdit = () => {
  const { uuid } = useParams()
  const history = useHistory()
  const classes = useStyles()
  const [partNumberInfo, setPartNumberInfo] = useState({
    name: '',
    string_evaluation_type_id: '',
    evaluation_string: '',
    supplier_id: '',
    notes: '',
    uuid: '',
    logic: {
      workflow: [],
    },
  })
  const [tempPartNumberInfo, setTempPartNumberInfo] = useState(null)
  const [stringEvaluationTypes, setStringEvaluationTypes] = useState(null)
  const [partNumberClasses, setPartNumberClasses] = useState(null)
  const [suppliers, setSuppliers] = useState(null)
  const [optionsLoading, setOptionsLoading] = useState(false)
  const context = useContext(GlobalContext)
  const [showTestModal, setShowTestModal] = useState(false)
  const [showEditLogicModal, setShowEditLogicModal] = useState(false)
  const [showAddLogicModal, setShowAddLogicModal] = useState(false)
  const [logicId, setLogicId] = useState('')
  const [logicIndex, setLogicIndex] = useState(null)
  const [needUpdateApi, setNeedUpdateApi] = useState(false)
  const [logics, setLogics] = useState(null)

  const fetchOptions = async () => {
    setOptionsLoading(true)
    try {
      const res1 = await getStringEvaluationTypesAPI()
      const res2 = await getPartNumberRulesClassListAPI()
      const res3 = await fetchSuppliersAPI()
      setStringEvaluationTypes(res1.data.data)
      setPartNumberClasses(res2.data.data)
      setSuppliers(res3.data.data)
      setOptionsLoading(false)
    } catch (e) {
      context.onApiError(e)
    }
  }

  const fetchLogicFunctions = async () => {
    try {
      const res = await listingLogicFunctionsAPI()

      setLogics(res.data.data)
    } catch (e) {
      context.onApiError(e)
    }
  }

  const fetchPartNumberRuleInfo = async () => {
    try {
      const res = await readPartNumberRulesAPI(uuid)
      setPartNumberInfo(res.data.data)
      setTempPartNumberInfo(res.data.data)
    } catch (e) {
      context.onApiError(e)
    }
  }

  useEffect(() => {
    fetchOptions()
    fetchLogicFunctions()
    if (uuid) {
      fetchPartNumberRuleInfo()
    }
  }, [])

  const updatePartNumber = async () => {
    const updateObject = {
      logic_function_objects: partNumberInfo.logic.workflow,
      name: partNumberInfo.name,
      string: partNumberInfo.evaluation_string,
      string_evaluation_type_id: partNumberInfo.string_evaluation_type_id,
      part_number_class_id: partNumberInfo.part_number_class_id,
      supplier_id: partNumberInfo.supplier_id,
      notes: partNumberInfo.notes,
    }

    try {
      await updatePartNumberRuleAPI(partNumberInfo.uuid, updateObject)
      fetchPartNumberRuleInfo()
      setShowEditLogicModal(false)
      setShowAddLogicModal(false)
    } catch (e) {
      setPartNumberInfo(tempPartNumberInfo)
      context.onApiError(e)
    }
  }

  const onFieldChange = (optionName, e) => {
    setPartNumberInfo({
      ...partNumberInfo,
      [optionName]: e.target.value,
    })
    if (
      optionName === 'string_evaluation_type_id' ||
      optionName === 'part_number_class_id' ||
      optionName === 'supplier_id'
    ) {
      setNeedUpdateApi(true)
    }
  }

  useEffect(() => {
    if (needUpdateApi) {
      updatePartNumber()
      setNeedUpdateApi(false)
    }
  }, [needUpdateApi])

  const onBlurTextField = () => {
    updatePartNumber()
  }

  const onDelete = async () => {
    try {
      await deletePartNumberRulesAPI(uuid)
      toast.success('Part Number Deleted Successfully')
      history.push('/admin/supplier_part_number_rules')
    } catch (e) {
      context.onApiError(e)
    }
  }

  const onCheckBtnClicked = () => {
    setShowTestModal(true)
  }

  const onCloseTestModal = () => {
    setShowTestModal(false)
  }

  const onEditLogicBtnClicked = (logicId, index) => {
    setLogicId(logicId)
    setLogicIndex(index)
    setShowEditLogicModal(true)
  }

  const onCloseEditLogicModal = () => {
    setShowEditLogicModal(false)
  }

  const onDeleteLogic = (logicId) => {
    const logics = _remove(partNumberInfo.logic.workflow, (n) => {
      return n.logic_function_id !== logicId
    })

    setPartNumberInfo({
      ...partNumberInfo,
      logic: {
        workflow: logics,
      },
    })
    setNeedUpdateApi(true)
  }

  const onUpdateLogic = (workflow, logicIndex) => {
    const logics = [...partNumberInfo.logic.workflow]
    logics[logicIndex] = { ...workflow }
    setPartNumberInfo({
      ...partNumberInfo,
      logic: {
        workflow: logics,
      },
    })

    setNeedUpdateApi(true)
  }

  const onAddLogicBtnClicked = () => {
    setShowAddLogicModal(true)
  }

  const onCloseAddLogicModal = () => {
    setShowAddLogicModal(false)
  }

  const onAddLogic = (workflow) => {
    let logics = [...partNumberInfo.logic.workflow]
    logics = [...logics, workflow]
    setPartNumberInfo({
      ...partNumberInfo,
      logic: {
        workflow: logics,
      },
    })
    setNeedUpdateApi(true)
  }

  return optionsLoading ? (
    <LoadingSpinner />
  ) : (
    <Grid container spacing={3}>
      <Grid item xs={8}>
        <Box mb={1}>
          <TextField
            id='name-for-rule'
            label='Name for Rule'
            variant='outlined'
            value={partNumberInfo.name}
            onChange={(e) => onFieldChange('name', e)}
            onBlur={onBlurTextField}
            fullWidth
          />
        </Box>
        <Box display='flex' alignItems='center'>
          <Box
            width='60%'
            display='flex'
            alignItems='center'
            justifyContent='flex-end'
          >
            <Typography>For any part number that</Typography>
            {stringEvaluationTypes && (
              <FormControl variant='outlined' className={classes.formControl}>
                <InputLabel id='string-evaluation-types-select-filled-label'>
                  String Evaluation Type
                </InputLabel>
                <Select
                  labelId='string-evaluation-types-select-filled-label'
                  id='string-evaluation-types-select-filled'
                  value={partNumberInfo.string_evaluation_type_id}
                  onChange={(e) =>
                    onFieldChange('string_evaluation_type_id', e)
                  }
                  label='String Evaluation Type'
                >
                  <MenuItem value=''>
                    <em>None</em>
                  </MenuItem>
                  {stringEvaluationTypes.map((type) => (
                    <MenuItem value={type.id} key={type.id}>
                      {type.description}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Box>
          <Box flex='1'>
            <TextField
              id='search-text'
              label='add search text here...'
              variant='outlined'
              value={partNumberInfo.evaluation_string}
              onChange={(e) => onFieldChange('evaluation_string', e)}
              onBlur={onBlurTextField}
              fullWidth
            />
          </Box>
        </Box>
        <Box display='flex' alignItems='center'>
          <Box
            width='60%'
            display='flex'
            alignItems='center'
            justifyContent='flex-end'
          >
            <Typography>and is a</Typography>
            {partNumberClasses && (
              <FormControl variant='outlined' className={classes.formControl}>
                <InputLabel id='part-number-class-select-filled-label'>
                  Part Number Class
                </InputLabel>
                <Select
                  labelId='part-number-class-select-filled-label'
                  id='part-number-class-select-filled'
                  value={partNumberInfo.part_number_class_id}
                  onChange={(e) => onFieldChange('part_number_class_id', e)}
                  label='Part Number Class'
                >
                  <MenuItem value=''>
                    <em>None</em>
                  </MenuItem>
                  {partNumberClasses.map((item) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.description}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Box>

          <Typography>part</Typography>
        </Box>
        <Box display='flex' alignItems='center'>
          <Box
            width='60%'
            display='flex'
            alignItems='center'
            justifyContent='flex-end'
          >
            <Typography>that is purchased from</Typography>
            {suppliers && (
              <FormControl variant='outlined' className={classes.formControl}>
                <InputLabel id='supplier-select-filled-label'>
                  Supplier
                </InputLabel>
                <Select
                  labelId='supplier-select-filled-label'
                  id='supplier-select-filled'
                  value={partNumberInfo.supplier_id}
                  onChange={(e) => onFieldChange('supplier_id', e)}
                  label='Part Number Class'
                >
                  <MenuItem value=''>
                    <em>None</em>
                  </MenuItem>
                  {suppliers.map((item) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Box>
        </Box>
        <Typography>apply the following supplier part number rules:</Typography>
        <List className={classes.list}>
          {partNumberInfo.logic &&
            partNumberInfo.logic.workflow.map((flow, index) => (
              <ListItem button key={flow.logic_function_id}>
                <ListItemText primary={flow.display_text} />
                <ListItemSecondaryAction>
                  <IconButton
                    aria-label='delete'
                    onClick={() =>
                      onEditLogicBtnClicked(flow.logic_function_id, index)
                    }
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    aria-label='delete'
                    onClick={() => onDeleteLogic(flow.logic_function_id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
        </List>
        <Box display='flex' justifyContent='flex-end' mt={3}>
          <Fab
            color='primary'
            aria-label='add'
            style={{ marginRight: '1em' }}
            onClick={onAddLogicBtnClicked}
          >
            <AddIcon />
          </Fab>
          <Fab
            color='primary'
            aria-label='edit'
            style={{ marginRight: '1em' }}
            onClick={onDelete}
          >
            <DeleteIcon />
          </Fab>
          <Fab color='primary' aria-label='delete' onClick={onCheckBtnClicked}>
            <CheckIcon />
          </Fab>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <TextField
          id='outlined-multiline-static'
          label='Notes...'
          multiline
          rows={20}
          defaultValue='Default Value'
          variant='outlined'
          fullWidth
          value={partNumberInfo.notes}
          onBlur={onBlurTextField}
          onChange={(e) => onFieldChange('notes', e)}
        />
      </Grid>
      <TestPartNumberRuleModal
        open={showTestModal}
        handleClose={onCloseTestModal}
        uuid={partNumberInfo.uuid}
      />
      <EditLogicModal
        open={showEditLogicModal}
        handleClose={onCloseEditLogicModal}
        logicId={logicId}
        logicIndex={logicIndex}
        workflow={partNumberInfo.logic.workflow}
        onUpdateLogic={onUpdateLogic}
        logics={logics}
      />
      <NewLogicModal
        open={showAddLogicModal}
        handleClose={onCloseAddLogicModal}
        logics={logics}
        onAddLogic={onAddLogic}
      />
    </Grid>
  )
}

export default SupplierPartNumberEdit
