import styled from 'styled-components'

const RfqListContainer = styled.div`
  .table-actions {
    position: fixed;
    left: 170px;
    bottom: 60px;
    right: 20px;
    background-color: white;
  }
`

export default RfqListContainer
