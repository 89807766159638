import React, { useContext, useState, useEffect } from 'react'
import {
  DialogContent,
  DialogActions,
  Button,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import _find from 'lodash/find'
import { toast } from 'react-toastify'

import { GlobalContext } from 'routes'
import { updatePoStatusAPI, deletePoAPI } from 'services/helpers/apis/po'
import { PoListContext } from '../../list'
import { ModalContext } from '../index'

const ChangeStatusModalContent = ({
  changeStatusFor,
  onClose,
  onPoDelete,
  onDeleteLine,
}) => {
  const context = useContext(GlobalContext)
  const poListContext = useContext(PoListContext)
  const modalContext = useContext(ModalContext)
  const [loading, setLoading] = useState(false)
  const [statusToUpdate, setStatusToUpdate] = useState()

  useEffect(() => {
    const currentStatusIndex = poListContext.statusList.findIndex(
      (el) => el.id === modalContext.orderData.status_id
    )

    const arr1 = poListContext.statusList.slice(0, currentStatusIndex)
    const arr2 = poListContext.statusList.slice(
      currentStatusIndex + 1,
      poListContext.statusList.length + 1
    )
    const statusListForSearch = [...arr1.reverse(), ...arr2.reverse()]

    if (changeStatusFor === 'delete this purchase order') {
      setStatusToUpdate(
        _find(statusListForSearch, function(x) {
          return x.permissions.can_delete === true
        })
      )
    } else if (changeStatusFor === 'edit this purchase order') {
      setStatusToUpdate(
        _find(statusListForSearch, function(x) {
          return x.permissions.can_edit === true
        })
      )
    } else if (changeStatusFor === 'delete this line') {
      setStatusToUpdate(
        _find(statusListForSearch, function(x) {
          return x.permissions.can_delete_lines === true
        })
      )
    }
  }, [])

  const onChangeStatus = async () => {
    try {
      setLoading(true)
      if (changeStatusFor === 'delete this purchase order') {
        await updatePoStatusAPI(modalContext.orderData.uuid, statusToUpdate.id)
        await deletePoAPI(modalContext.orderData.uuid)
        onPoDelete(modalContext.orderData)
        toast.success(`Po ${modalContext.orderData.id} is deleted.`)
      } else if (changeStatusFor === 'edit this purchase order') {
        await updatePoStatusAPI(modalContext.orderData.uuid, statusToUpdate.id)
        modalContext.onStatusUpdated()
        toast.success(`Po ${modalContext.orderData.id} is updated.`)
      } else if (changeStatusFor === 'delete this line') {
        await updatePoStatusAPI(modalContext.orderData.uuid, statusToUpdate.id)
        modalContext.onStatusUpdated()
        onDeleteLine()
      }
      setLoading(false)
      onClose()
    } catch (e) {
      setLoading(false)
      context.onApiError(e)
    }
  }

  return (
    <div>
      <DialogContent>
        <Typography>
          To {changeStatusFor}, you need to change the status
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' color='secondary' onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={onChangeStatus}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size={25} />
          ) : (
            `Change status to "${statusToUpdate && statusToUpdate.status}"`
          )}
        </Button>
      </DialogActions>
    </div>
  )
}

export default ChangeStatusModalContent
