import React, { useEffect, useState } from 'react'
import {
  Box,
  Paper,
  Typography,
  ListItemIcon,
  ListItemSecondaryAction,
  IconButton,
  Grid,
  Icon,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { fetchShipmentExpeditingListAPI } from 'services/helpers/apis/shipment'
import { StyledList, StyledListItem } from './index.style'

const Home = () => {
  const [expeditingData, setExpeditingData] = useState()

  const fetchShipmentExpediting = async () => {
    try {
      const res = await fetchShipmentExpeditingListAPI()
      setExpeditingData(res.data.data.expediting)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    fetchShipmentExpediting()
  }, [])

  return (
    <Paper>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <Box p={5}>
            <Typography variant='h5' gutterBottom>
              Expediting
            </Typography>
            <Box boxShadow={1} borderRadius={8} style={{ overflow: 'hidden' }}>
              <StyledList>
                {expeditingData &&
                  expeditingData.map((item) => (
                    <StyledListItem
                      style={{ backgroundColor: `#${item.background_color}` }}
                      key={item.uuid}
                    >
                      <ListItemIcon>
                        <Icon
                          fontSize='large'
                          style={{ color: `#${item.icon.color}` }}
                        >
                          {item.icon.icon}
                        </Icon>
                      </ListItemIcon>
                      <Box
                        className='list-item-content'
                        display='flex'
                        flex='1'
                      >
                        <Grid container spacing={3} alignItems='center'>
                          <Grid item xs={3}>
                            <Typography>{item.column1.text}</Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography>{item.column2.text}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography>{item.column3.text}</Typography>
                          </Grid>
                        </Grid>
                      </Box>
                      <ListItemSecondaryAction>
                        <IconButton edge='end' aria-label='delete'>
                          <CloseIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </StyledListItem>
                  ))}
              </StyledList>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default Home
