import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import RadioGroup from '@material-ui/core/RadioGroup'

import RenderFromHelper from './renderFormHelper'

const ReduxRadioButton = ({
  input,
  children,
  row = true,
  meta: { touched, error },
  ...rest
}) => {
  return (
    <FormControl error={touched && error}>
      <RenderFromHelper touched={touched} error={error} />
      <RadioGroup {...input} row={row} {...rest}>
        {children}
      </RadioGroup>
    </FormControl>
  )
}

export default ReduxRadioButton
