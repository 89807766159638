import auth from './auth/reducer'
import admin from './admin/reducer'
import requisition from './requisition/reducer'
import purchaseOrder from './purchaseOrder/reducer'
import shipment from './shipment/reducer'
import rfq from './rfq/reducer'
import storage from './storage/reducer'

export default {
  auth,
  admin,
  requisition,
  purchaseOrder,
  shipment,
  rfq,
  storage,
}
