import React from 'react'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { createMuiTheme } from '@material-ui/core/styles'
import { LocalizeProvider } from 'react-localize-redux'
import { PersistGate } from 'redux-persist/integration/react'

import './App.css'

// Components
import PublicRoutes from './routes'

// Redux
import { store, persistor, history } from './services/redux/store'

const theme = createMuiTheme()

const App = () => (
  <LocalizeProvider>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <PublicRoutes history={history} />
        </PersistGate>
      </Provider>
    </ThemeProvider>
  </LocalizeProvider>
)

export default App
