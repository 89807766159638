import React, { useState, useEffect, createContext, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router'
import { Box, Popover, Typography } from '@material-ui/core'
import { toast } from 'react-toastify'

import { GlobalContext } from 'routes'
import { client } from 'services/helpers/apiConfig'
import storageActions from 'services/redux/storage/actions'
import Dialog from 'components/common/dialog'
import LoadingSpinner from 'components/common/loadingSpinner'
import RequisitionTable from 'components/tables/requisitionTable'

import TableBulkActions from './components/tableBulkActions'
import RequisitionReadModal from './readModal'
import GlancePopoverContent from './index.style'

const { setRequisitionTableSettings } = storageActions

export const RequisitionListContext = createContext()

const RequisitionsList = ({ match, history }) => {
  const context = useContext(GlobalContext)
  const requisitionTableSettings = useSelector(
    (state) => state.storage.requisitionTableSettings
  )
  const [quantitiesColors, setQuantitiesColors] = useState(null)
  const [requisitionsList, setRequisitionsList] = useState([])
  const [userList, setUserList] = useState(null)
  const [statusList, setStatusList] = useState(null)
  const [requisitionModalOpen, setRequisitionModalOpen] = useState(false)
  const [
    isRequisitionModalOpenFromURL,
    setIsRequisitionModalOpenFromURL,
  ] = useState(false)
  const [selectedRequisition, setSelectedRequisition] = useState(null)
  const [filterValues, setFilterValues] = useState(
    requisitionTableSettings.filter || {
      statuses: [],
      users: [],
    }
  )
  const [filterChanged, setFilterChanged] = useState(false)
  const [
    requisitionModalSelectedTab,
    setRequisitionModalSelectedTab,
  ] = useState('pricing')
  const [tableViewInfo, setTableViewInfo] = useState(
    requisitionTableSettings.tableViewInfo || {
      totalRows: 0,
      pageIndex: 1,
      rowsCount: 25,
    }
  )
  const [searchValue, setSearchValue] = useState(null)
  const [checkedRequisitions, setCheckedRequisitions] = useState([])
  const dispatch = useDispatch()
  const [glancePopoverData, setGlancePopoverData] = useState(null)
  const [glancePopoverAnchorEl, setGlancePopoverAnchorEl] = useState(null)
  const [loadingTable, setLoadingTable] = useState(false)

  const fetchRequisitionList = async (
    viewInfo,
    filterValues = null,
    searchTerm = null,
    openModal = false
  ) => {
    setLoadingTable(true)

    try {
      let res
      if (!searchTerm) {
        res = await client.get(
          `requisitions/list?${
            filterValues
              ? `status_id=${filterValues.statuses.join()}&assignee=${filterValues.users.join()}`
              : ''
          }&page=${viewInfo.pageIndex}&rows=${viewInfo.rowsCount}`
        )
      } else {
        res = await client.get(
          `requisitions/list?${
            filterValues
              ? `status_id=${filterValues.statuses.join()}&assignee=${filterValues.users.join()}`
              : ''
          }&page=${viewInfo.pageIndex}&rows=${
            viewInfo.rowsCount
          }&search=${searchTerm}`
        )
        setSearchValue(searchTerm)
      }

      setRequisitionsList([...res.data.data.requisition])
      setQuantitiesColors(res.data.data.quantities_colors)

      setTableViewInfo({
        pageIndex: viewInfo.pageIndex,
        rowsCount: viewInfo.rowsCount,
        totalRows: res.data.data.total_rows,
      })

      dispatch(
        setRequisitionTableSettings({
          filter: filterValues,
          tableViewInfo: {
            pageIndex: viewInfo.pageIndex,
            rowsCount: viewInfo.rowsCount,
            totalRows: res.data.data.total_rows,
          },
        })
      )

      if (openModal) {
        const selectedRequisition = res.data.data.requisition[0]

        if (selectedRequisition) {
          setSelectedRequisition(selectedRequisition || null)
          setRequisitionModalOpen(!!selectedRequisition)
          setRequisitionModalSelectedTab(
            match.params.tabName ? match.params.tabName : 'pricing'
          )
        } else {
          toast.success(`Requisition ${match.params.id} is not available`)
        }
      }
    } catch (e) {
      context.onApiError(e)
    } finally {
      setLoadingTable(false)
    }
  }

  useEffect(() => {
    const fetchApis = async () => {
      try {
        if (match.params.id) {
          fetchRequisitionList(
            {
              pageIndex: 1,
              rowsCount: 25,
              totalRows: 1,
            },
            filterValues,
            match.params.id,
            true
          )
          setIsRequisitionModalOpenFromURL(true)
        } else {
          fetchRequisitionList(tableViewInfo, requisitionTableSettings.filter)
        }

        setLoadingTable(true)

        const userListRes = await client.get('user/assign/requisition')
        const statusListRes = await client.get('requisitions/status/list')
        setUserList(userListRes.data.data)
        setStatusList(statusListRes.data.data)
      } catch (e) {
        context.onApiError(e)
      } finally {
        setLoadingTable(false)
      }
    }

    fetchApis()
  }, [])

  const handleChange = (target, values) => {
    setFilterValues({
      ...filterValues,
      [target]: values,
    })
    setTableViewInfo({
      ...tableViewInfo,
      pageIndex: 1,
    })
    setFilterChanged(true)
  }

  useEffect(() => {
    if (filterChanged) {
      fetchRequisitionList(tableViewInfo, filterValues, searchValue)
      setFilterChanged(false)
    }
  }, [filterChanged])

  const handleClose = () => {
    if (isRequisitionModalOpenFromURL) {
      fetchRequisitionList(
        {
          pageIndex: 1,
          rowsCount: 25,
          totalRows: 1,
        },
        filterValues,
        searchValue
      )
    }
    setRequisitionModalOpen(false)
    setIsRequisitionModalOpenFromURL(false)
    history.push('/requisitions/list')
  }

  const openRequisitionModal = (requisition) => {
    setRequisitionModalOpen(true)
    setSelectedRequisition(requisition)
    history.push(`/requisitions/${requisition.order_id}/pricing`)
  }

  const onTableViewChange = (pageIndex, numberOfRows = 10) => {
    fetchRequisitionList(
      { pageIndex, rowsCount: numberOfRows },
      filterValues,
      searchValue
    )
  }

  const onCheckRequisition = (rows) => {
    setCheckedRequisitions(rows)
  }

  const bulkActionUpdated = async (updatedObj) => {
    const checkedRows = checkedRequisitions
    const rowsList = requisitionsList

    for (let i = 0; i < checkedRows.length; i += 1) {
      for (let j = 0; j < rowsList.length; j += 1) {
        if (rowsList[j].order_id === checkedRows[i]) {
          rowsList[j] = {
            ...rowsList[j],
            ...updatedObj,
          }
        }
      }
    }

    setRequisitionsList([...rowsList])
  }

  const onDeletedRequisitions = () => {
    setCheckedRequisitions([])
    fetchRequisitionList(
      tableViewInfo,
      requisitionTableSettings.filter,
      searchValue
    )
  }

  const handleGlancePopoverOpen = (event, popoverData) => {
    setGlancePopoverAnchorEl(event.currentTarget)
    setGlancePopoverData(popoverData)
  }

  const handleGlancePopoverClose = () => {
    setGlancePopoverAnchorEl(null)
    setGlancePopoverData(null)
  }

  const glanceOpen = Boolean(glancePopoverAnchorEl)

  return (
    <RequisitionListContext.Provider
      value={{
        fetchRequisitionList,
        onCheckRequisition,
        handleGlancePopoverOpen,
        rows: requisitionsList,
        userList,
        statusList,
      }}
    >
      <div className='wrapper'>
        {statusList && userList && !loadingTable && (
          <div>
            <Box mb={3}>
              <RequisitionTable
                quantitiesColors={quantitiesColors}
                openRequisitionModal={openRequisitionModal}
                onFilterChange={handleChange}
                onTableViewChange={onTableViewChange}
              />
              <Popover
                id='glance-popovver'
                open={glanceOpen}
                anchorEl={glancePopoverAnchorEl}
                onClose={handleGlancePopoverClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                {glancePopoverData && (
                  <GlancePopoverContent>
                    <Box p={2}>
                      <Typography variant='h5' gutterBottom>
                        {glancePopoverData.title}
                      </Typography>
                      {glancePopoverData.type === 'pn_icon' &&
                        glancePopoverData.part_numbers &&
                        glancePopoverData.part_numbers.length > 0 &&
                        glancePopoverData.part_numbers.map((pn, i) => (
                          <Box key={i}>
                            <Typography variant='h6'>
                              {pn.part_number}
                            </Typography>
                            <ul>
                              {pn.part_number_rules &&
                              pn.part_number_rules.length > 0 ? (
                                pn.part_number_rules.map((pnRule, j) => (
                                  <li key={j}>
                                    <Typography variant='h6'>
                                      {pnRule.name}
                                    </Typography>
                                    {pnRule.steps &&
                                      pnRule.steps.map((step, k) => (
                                        <Typography key={k}>{step}</Typography>
                                      ))}
                                  </li>
                                ))
                              ) : (
                                <Typography>None</Typography>
                              )}
                            </ul>
                          </Box>
                        ))}
                      {glancePopoverData.type === 'sp_icon' &&
                        glancePopoverData.part_numbers &&
                        glancePopoverData.part_numbers.length > 0 &&
                        glancePopoverData.part_numbers.map((pn, i) => (
                          <Box key={i}>
                            <Typography variant='h6'>
                              {pn.part_number}
                            </Typography>
                            <ul>
                              {pn.supplier_preference_rules &&
                              pn.supplier_preference_rules.length > 0 ? (
                                pn.supplier_preference_rules.map(
                                  (spRule, j) => (
                                    <li key={j}>
                                      <Typography variant='h6'>
                                        {spRule.name}
                                      </Typography>
                                      {spRule.preferences &&
                                        spRule.preferences.map(
                                          (preference, k) => (
                                            <Typography key={k}>
                                              {preference}
                                            </Typography>
                                          )
                                        )}
                                    </li>
                                  )
                                )
                              ) : (
                                <Typography>None</Typography>
                              )}
                            </ul>
                          </Box>
                        ))}
                    </Box>
                  </GlancePopoverContent>
                )}
              </Popover>
            </Box>

            <TableBulkActions
              checkedRequisitions={checkedRequisitions}
              bulkActionUpdated={bulkActionUpdated}
              onDeletedRequisitions={onDeletedRequisitions}
            />

            <Dialog open={requisitionModalOpen} onClose={handleClose}>
              <RequisitionReadModal
                requisition={selectedRequisition}
                selectedTab={requisitionModalSelectedTab}
                userList={userList}
                onClose={handleClose}
              />
            </Dialog>
          </div>
        )}
        {loadingTable && <LoadingSpinner />}
      </div>
    </RequisitionListContext.Provider>
  )
}

export default withRouter(RequisitionsList)
