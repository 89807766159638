import React, { useState, useEffect, useContext, useRef } from 'react'
import {
  NativeSelect,
  IconButton,
  FormControl,
  Button,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import { Editor, EditorState } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { stateFromHTML } from 'draft-js-import-html'
import { toast } from 'react-toastify'

import { GlobalContext } from 'routes'
import { PageWrapperContext } from 'pages/pageWrapper'
import { updateRfqCorrespondenceAPI } from 'services/helpers/apis/rfq'
import { updatePoCorrespondenceAPI } from 'services/helpers/apis/po'
import {
  deleteThreadDraftAPI,
  sendThreadDraftAPI,
} from 'services/helpers/apis/thread'
import MessageContainer from './message.style'
import { CorrespondenceTableContext } from '../index'

const WAIT_INTERVAL = 1000
let timer

const NewMessage = ({
  selectedLine,
  createdCorrespondence,
  correspondenceFor,
  onClose,
}) => {
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty()
  )
  const [newMessage, setNewMessage] = useState({
    type: 'internal',
    subject: '',
    body: '',
  })
  const context = useContext(GlobalContext)
  const correspondenceContext = useContext(CorrespondenceTableContext)
  const pageWrapperContext = useContext(PageWrapperContext)
  const textInput = useRef(null)
  const subjectInput = useRef(null)
  const [isDraftSaved, setIsDraftSaved] = useState(false)

  useEffect(() => {
    timer = null
    // textInput.current.focus()
    subjectInput.current.focus()

    const html = `<br/><br/>${pageWrapperContext.userData.email_signature_html}`

    const contentState = stateFromHTML(html)
    setEditorState(EditorState.createWithContent(contentState))
    setNewMessage({
      ...newMessage,
      body: html,
    })
  }, [])

  const saveDraftMessage = async () => {
    try {
      const res =
        correspondenceFor === 'rfq'
          ? await updateRfqCorrespondenceAPI(
              createdCorrespondence.message.uuid,
              newMessage
            )
          : await updatePoCorrespondenceAPI(
              createdCorrespondence.message.uuid,
              newMessage
            )

      correspondenceContext.onCorrespondenceUpdated(res.data.data)
      setIsDraftSaved(true)
    } catch (e) {
      context.onApiError(e)
    }
  }

  const onChangeMessageContent = (fieldName, event) => {
    const { value } = event.target

    setNewMessage({
      ...newMessage,
      [fieldName]: value,
    })
  }

  const onChangeText = (editorState) => {
    clearTimeout(timer)
    setEditorState(editorState)
    const html = stateToHTML(editorState.getCurrentContent())

    setNewMessage({
      ...newMessage,
      body: html,
    })
  }

  useEffect(() => {
    timer = setTimeout(() => {
      saveDraftMessage()
    }, WAIT_INTERVAL)
  }, [newMessage])

  const sendMessage = async () => {
    try {
      if (correspondenceFor === 'rfq') {
        await updateRfqCorrespondenceAPI(
          createdCorrespondence.message.uuid,
          newMessage
        )
      } else {
        await updatePoCorrespondenceAPI(
          createdCorrespondence.message.uuid,
          newMessage
        )
      }
      const res = await sendThreadDraftAPI(createdCorrespondence.message.uuid)

      correspondenceContext.onCorrespondenceUpdated(res.data.data)

      setNewMessage({
        type: 'internal',
        subject: '',
        body: '',
      })

      onClose()
    } catch (e) {
      context.onApiError(e)
    }
  }

  const onCloseMessage = async () => {
    try {
      if (newMessage.subject === '' && newMessage.body === '') {
        await deleteThreadDraftAPI(createdCorrespondence.message.uuid)
      }
      onClose()
    } catch (e) {
      context.onApiError(e)
    }
  }

  const onDeleteDraft = async () => {
    try {
      const res = await deleteThreadDraftAPI(createdCorrespondence.message.uuid)
      toast.success('Draft message is deleted')
      onClose()
      correspondenceContext.onCorrespondenceUpdated(res.data.data)
    } catch (e) {
      context.onApiError(e)
    }
  }

  return (
    <MessageContainer>
      <div className='box-header'>
        <div className='message-title'>New Message</div>
        <div className='box-actions'>
          <span
            role='button'
            tabIndex='0'
            onKeyPress={() => {}}
            onClick={onCloseMessage}
          >
            <CloseIcon />
          </span>
        </div>
      </div>
      <div className='message-modal-content'>
        <form>
          <div className='form-content'>
            <div className='field select-type'>
              <span>Type</span>
              <FormControl>
                <NativeSelect
                  value={newMessage.type}
                  name='type'
                  onChange={(e) => onChangeMessageContent('type', e)}
                  inputProps={{ 'aria-label': 'message-type' }}
                >
                  <option value='internal'>Internal Note</option>
                  <option value='supplier'>
                    Message to Supplier ({selectedLine.supplier})
                  </option>
                </NativeSelect>
              </FormControl>
            </div>
            <div className='field'>
              <input
                type='text'
                placeholder='Subject'
                onChange={(e) => onChangeMessageContent('subject', e)}
                ref={subjectInput}
              ></input>
            </div>
            <div className='field'>
              <Editor
                editorState={editorState}
                onChange={onChangeText}
                ref={textInput}
              />
            </div>
          </div>
          <div className='form-actions'>
            <IconButton
              aria-label='delete'
              onClick={onDeleteDraft}
              disabled={!isDraftSaved}
            >
              <DeleteIcon />
            </IconButton>
            <Button variant='contained' color='primary' onClick={sendMessage}>
              Send
            </Button>
          </div>
        </form>
      </div>
    </MessageContainer>
  )
}

export default NewMessage
