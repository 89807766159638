import React, { Component } from 'react'

import { getRfqHistoryAPI } from 'services/helpers/apis/rfq'
import PurchaseOrderHistory from 'components/purchaseOrderHistory'

class TabHistory extends Component {
  state = {
    history: null,
  }

  async componentDidMount() {
    const { uuid } = this.props

    try {
      const getRfqHistory = await getRfqHistoryAPI(uuid)

      this.setState({
        history: getRfqHistory.data.data.history,
      })
    } catch (e) {
      console.log(e)
    }
  }

  render() {
    const { history } = this.state

    return (
      <div>{history && <PurchaseOrderHistory historyData={history} />}</div>
    )
  }
}

export default TabHistory
