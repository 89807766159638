import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Button from '@material-ui/core/Button'
import ReactDOM from 'react-dom'
import { Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'

import adminActions from 'services/redux/admin/actions'

const { userBulkAction } = adminActions

class TableActions extends Component {
  static propTypes = {
    userBulkAction: PropTypes.func.isRequired,
    bulkLoading: PropTypes.bool.isRequired,
    addUser: PropTypes.bool.isRequired,
  }

  state = {
    bulkAction: '',
    labelWidth: 0,
  }

  componentDidMount() {
    this.setState({
      // eslint-disable-next-line react/no-find-dom-node
      labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
    })
  }

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value })
  }

  applyBulkAction = () => {
    this.props.userBulkAction(this.state.bulkAction, this.props.checkedKeys)
  }

  render() {
    const { checkedKeys, bulkLoading, addUser } = this.props
    const { bulkAction } = this.state

    return (
      <div className="table-actions">
        <div className="wrapper">
          <div className="bulk-actions">
            <FormControl variant="outlined">
              <InputLabel
                ref={(ref) => {
                  this.InputLabelRef = ref
                }}
                htmlFor="outlined-bulk-actions"
              >
                Bulk Actions
              </InputLabel>
              <Select
                native
                value={this.state.bulkAction}
                onChange={this.handleChange('bulkAction')}
                input={
                  <OutlinedInput
                    name="bulkAction"
                    labelWidth={this.state.labelWidth}
                    id="outlined-bulk-actions"
                  />
                }
              >
                <option value="" />
                <option value="makeActive">Make Active</option>
                <option value="makeInactive">Make Inactive</option>
                <option value="forceLogout">Force Logout</option>
                <option value="requireMFA">Require MFA</option>
              </Select>
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              disabled={
                checkedKeys.length === 0 || bulkAction === '' || bulkLoading
              }
              onClick={this.applyBulkAction}
            >
              {bulkLoading ? (
                <CircularProgress size={20} thickness={5} />
              ) : (
                'Apply'
              )}
            </Button>
          </div>
          {addUser && (
            <Button
              component={Link}
              to="/admin/user/create"
              variant="contained"
            >
              + Add User
            </Button>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  bulkLoading: state.admin.bulkLoading,
  addUser: state.admin.userList.addUser,
})

const mapDispatchToProps = (dispatch) => ({
  userBulkAction: bindActionCreators(userBulkAction, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(TableActions)
