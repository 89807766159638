import React, { useContext, useEffect, useState } from 'react'
import _find from 'lodash/find'
import { toast } from 'react-toastify'

import { GlobalContext } from 'routes'
import { client } from 'services/helpers/apiConfig'

import CreateReduxForm from './createForm'

const CreateUser = ({ history, isUpdateSupplier = false, onUserCreated }) => {
  const context = useContext(GlobalContext)
  const [supplierUser, setSupplierUser] = useState()

  const fetchUserTypes = async () => {
    try {
      const userTypeList = await client.get('userType/list')
      setSupplierUser(_find(userTypeList.data.data, { user_type: 'supplier' }))
    } catch (e) {
      context.onApiError(e)
    }
  }

  useEffect(() => {
    if (isUpdateSupplier) {
      fetchUserTypes()
    }
  }, [])

  const onCreateUser = async (values) => {
    let userData = {
      first_name: values.firstname,
      last_name: values.lastname,
      email: values.email,
      phone: values.phone,
    }

    if (!isUpdateSupplier) {
      userData = {
        ...userData,
        user_type_id: values.userType,
        password: values.password,
        force_password_change: values.forceChangePassword,
        email_password: values.emailUserNewPassword,
        require_mfa: values.requireMFA,
        active: values.activeUser,
      }
    } else {
      userData = {
        ...userData,
        user_type_id: supplierUser.id,
      }
    }

    try {
      const user = await client.post('/user/create', userData)

      toast.success(`user ${userData.username} was created successfully`)
      if (!isUpdateSupplier) {
        history.push('/admin/user/list')
      } else {
        onUserCreated({
          id: user.data.data.id,
          name: `${userData.first_name} ${userData.last_name}`,
          ...userData,
        })
      }
    } catch (err) {
      context.onApiError(err)
    }
  }

  const securityFormInitValues = {
    forceChangePassword: true,
    emailUserNewPassword: true,
    activeUser: true,
  }

  return (
    <div>
      <CreateReduxForm
        onSubmit={onCreateUser}
        initialValues={securityFormInitValues}
        isUpdateSupplier={isUpdateSupplier}
      />
    </div>
  )
}

export default CreateUser
