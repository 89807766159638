import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Field, reduxForm } from 'redux-form'

import { ReduxTextField } from '../../../components/reduxFormFields'

class ForgotPasswordForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
  }

  componentDidMount() {}

  render() {
    const { handleSubmit, loading } = this.props

    return (
      <form onSubmit={handleSubmit}>
        <Field
          name="userName"
          component={ReduxTextField}
          placeholder="User Name"
          helperText="Enter your username and receive a temporary password via email"
          fullWidth
        />

        <div className="card-actions">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
            className="btn-submit"
          >
            {loading ? (
              <CircularProgress size={20} thickness={5} />
            ) : (
              <span>Submit</span>
            )}
          </Button>
        </div>
      </form>
    )
  }
}

const validate = (values) => {
  const errors = {}

  if (!values.userName) {
    errors.userName = 'Required'
  }

  return errors
}

const ForgotPasswordReduxForm = reduxForm({
  form: 'forgotPassword',
  validate,
})(ForgotPasswordForm)

export default ForgotPasswordReduxForm
