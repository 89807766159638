import React, { useEffect, useState, useContext } from 'react'
import {
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { toast } from 'react-toastify'
import _find from 'lodash/find'

import { GlobalContext } from 'routes'
import leadTimeValues from 'utils/leadTimes'
import { updateRfqQuoteAPI } from 'services/helpers/apis/rfq'
import { updatePoLineAPI } from 'services/helpers/apis/po'

import Container from './index.style'

const ScheduleField = ({
  schedule,
  index,
  onChangeQuantity,
  onChangeLeadTime,
  onDeleteSchedule,
  showExpectedDate = true,
}) => {
  const [scheduleValues, setScheduleValues] = useState(schedule)

  useEffect(() => {
    setScheduleValues({ ...schedule })
  }, [schedule])

  const onChangeInput = (e) => {
    setScheduleValues({ ...scheduleValues, quantity: e.target.value })
    if (parseInt(e.target.value, 10) === 0) {
      if (index === 0) {
        toast.error(
          'The first line of the dispatch schedule must be greater than zero.'
        )
      } else {
        onDeleteSchedule(index)

        return
      }
    }

    onChangeQuantity(e.target.value, index)
  }

  const onChangSelect = (e) => {
    setScheduleValues({ ...scheduleValues, lead_time_menu: e.target.value })

    onChangeLeadTime(index, e.target.value)
  }

  return (
    <Box display='flex' alignItems='center' mb={2}>
      <Box className='field-group'>
        <TextField
          type='number'
          required
          id='outlined-required'
          label='Quantity'
          value={scheduleValues.quantity}
          onChange={onChangeInput}
          variant='outlined'
          fullWidth
        />
      </Box>
      <Box className='field-group'>
        <FormControl variant='outlined' fullWidth>
          <InputLabel id='demo-simple-select-outlined-label'>
            Lead Time
          </InputLabel>
          <Select
            labelId='demo-simple-select-outlined-label'
            id='demo-simple-select-outlined'
            value={scheduleValues.lead_time_menu}
            onChange={onChangSelect}
            label='Lead Time'
          >
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            {leadTimeValues.map((item, i) => (
              <MenuItem value={item.value} key={i}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      {showExpectedDate &&
        schedule.expected_ship_date &&
        schedule.expected_ship_date.human_date && (
          <Box className='field-group'>
            <Typography>
              {schedule.expected_ship_date.human_date.precise.long.dayte}
            </Typography>
          </Box>
        )}
      {index !== 0 && (
        <Box>
          <IconButton
            aria-label='delete'
            onClick={() => onDeleteSchedule(index)}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  )
}

const DispatchSchedules = ({
  line,
  scheduleFor,
  showExpectedScheduleDate = true,
}) => {
  const [schedules, setSchedules] = useState(line.dispatch_schedule)
  const context = useContext(GlobalContext)

  useEffect(() => {
    setSchedules(line.dispatch_schedule)
  }, [line])

  const updateQuote = async (updatedSchedules) => {
    try {
      if (scheduleFor === 'rfq') {
        const lineWithEmptyLeadTime = _find(updatedSchedules, {
          lead_time_menu: '',
        })
        const lineWithEmptyQuantity = _find(updatedSchedules, {
          quantity: NaN,
        })
        if (!lineWithEmptyLeadTime && !lineWithEmptyQuantity) {
          await updateRfqQuoteAPI(line.rfq_quote_line_uuid, {
            dispatch_schedule: updatedSchedules,
          })
          toast.success('Dispatch schedule is updated successfully')
        }
      } else if (scheduleFor === 'po') {
        const poDispatchScheduleBody = updatedSchedules.map((schedule) => ({
          quantity: schedule.quantity,
          lead_time: schedule.lead_time_menu,
        }))
        const lineWithEmptyLeadTime = _find(poDispatchScheduleBody, {
          lead_time: '',
        })
        const lineWithEmptyQuantity = _find(poDispatchScheduleBody, {
          quantity: NaN,
        })
        if (!lineWithEmptyLeadTime && !lineWithEmptyQuantity) {
          const res = await updatePoLineAPI(line.uuid, {
            dispatch_schedule: poDispatchScheduleBody,
          })

          setSchedules(res.data.data.line.dispatch_schedule)
          toast.success('Dispatch schedule is updated successfully')
        }
      }
    } catch (e) {
      context.onApiError(e)
    }
  }

  const onChangeQuantity = (quantity, key) => {
    let newSchedules = [...schedules]
    newSchedules[key].quantity = parseInt(quantity, 10)

    let totalQuantity = 0

    if (newSchedules.length > 1) {
      newSchedules.map((schedule, index) => {
        if (key === newSchedules.length - 1) {
          totalQuantity += schedule.quantity
        } else if (index < newSchedules.length - 1) {
          totalQuantity += schedule.quantity
        }

        return totalQuantity
      })
    } else {
      totalQuantity += newSchedules[0].quantity
    }

    if (totalQuantity > line.quantity) {
      toast.error(
        'The total quantity in the dispatch schedule is greater than the quoted quantity of 5,500. Please correct the quantities.'
      )
    } else if (totalQuantity < line.quantity) {
      const remainingQuantity = line.quantity - totalQuantity
      if (key === newSchedules.length - 1) {
        const newSchedule = { quantity: remainingQuantity, lead_time_menu: '' }
        newSchedules = [...newSchedules, newSchedule]
      } else {
        newSchedules[newSchedules.length - 1] = {
          quantity: remainingQuantity,
          lead_time_menu: '',
        }
      }
    }

    setSchedules(newSchedules)
    updateQuote(newSchedules)
  }

  const onDeleteSchedule = (key) => {
    const newSchedules = [...schedules]

    newSchedules[key - 1] = {
      ...newSchedules[key - 1],
      quantity: newSchedules[key - 1].quantity + newSchedules[key].quantity,
    }
    newSchedules.splice(key, 1)
    setSchedules(newSchedules)
    updateQuote(newSchedules)
  }

  const onChangeLeadTime = (key, leadTime) => {
    const newSchedules = [...schedules]
    newSchedules[key].lead_time_menu = leadTime
    setSchedules(newSchedules)
    updateQuote(newSchedules)
  }

  return (
    <Container>
      {scheduleFor === 'rfq' && (
        <Typography variant='h6' gutterBottom>
          Dispatch Schedule
        </Typography>
      )}
      <Box>
        {schedules &&
          schedules.map((schedule, i) => (
            <ScheduleField
              schedule={schedule}
              onChangeQuantity={onChangeQuantity}
              onChangeLeadTime={onChangeLeadTime}
              onDeleteSchedule={onDeleteSchedule}
              showExpectedDate={showExpectedScheduleDate}
              index={i}
              key={i}
            />
          ))}
      </Box>
    </Container>
  )
}

export default DispatchSchedules
