import React from 'react'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { withStyles } from '@material-ui/core/styles'
import Viewer, { Worker } from '@phuocng/react-pdf-viewer'
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: {
    minHeight: 150,
  },
})

const FileViewerModal = withStyles(styles)((props) => {
  const { onClose, open, attachment, classes } = props

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby='simple-dialog-title'
      open={open}
      maxWidth='lg'
      fullWidth
    >
      {attachment && (
        <>
          <DialogTitle id='simple-dialog-title'>
            {attachment.filename}
            <IconButton
              aria-label='close'
              className={classes.closeButton}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            {(attachment.mime_type === 'image/jpeg' ||
              attachment.mime_type === 'image/png') && (
              <img src={attachment.fileUrl} alt={attachment.filename} />
            )}
            {attachment.mime_type === 'application/pdf' && (
              <Worker workerUrl='https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js'>
                <Viewer fileUrl={attachment.fileUrl} />
              </Worker>
            )}
          </DialogContent>
        </>
      )}
    </Dialog>
  )
})

export default FileViewerModal
