import React, { createContext, useState, useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Alert from '@material-ui/lab/Alert'
import { Typography, Grid, Button } from '@material-ui/core'

import { getRfqQuoteAPI, updateRfqStatusAPI } from 'services/helpers/apis/rfq'
import rfqActions from 'services/redux/rfq/actions'
import LoadingSpinner from 'components/common/loadingSpinner'
import { GlobalContext } from 'routes'

import QuotedItemsTable from './quotedItemsTable'
import NonBidItemsTable from './nonBidItemsTable'
import TabQuoteContainer from './tabQuote.style'

const { setIsQuoteUpdated } = rfqActions

export const TabQuoteContext = createContext()

const TabQuote = ({ quotedCorrespondence, selectedRfq, setIsQuoteUpdated }) => {
  const [quotedItems, setQuotedItems] = useState(null)
  const [nonBidItems, setNonBidItems] = useState(null)
  const [quoteData, setQuoteData] = useState(null)
  const [loading, setLoading] = useState(false)
  const context = useContext(GlobalContext)

  const fetchQuoteItems = async () => {
    setQuotedItems(null)
    setLoading(true)

    try {
      const getQuote = await getRfqQuoteAPI(selectedRfq.uuid)

      if (selectedRfq.permissions.can_view_quote) {
        const rfqItems = getQuote.data.data.quoted_items.rfq_items || []
        const customItems = getQuote.data.data.quoted_items.custom_items || []

        rfqItems.map((item) => {
          const x = item
          x.isCustomItem = false
          return x
        })

        customItems.map((item) => {
          const x = item
          x.isCustomItem = true
          return x
        })

        setQuotedItems([...rfqItems, ...customItems])
        setNonBidItems(getQuote.data.data.non_bid_items)
      }

      setQuoteData(getQuote.data.data)
    } catch (e) {
      context.onApiError(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchQuoteItems()
  }, [selectedRfq.permissions.can_view_quote])

  const onDisplayMessageButtonClicked = async (statusId) => {
    try {
      await updateRfqStatusAPI(selectedRfq.uuid, statusId)
      setIsQuoteUpdated(true)
    } catch (e) {
      context.onApiError(e)
    }
  }

  return (
    <TabQuoteContext.Provider value={{ fetchQuoteItems }}>
      <TabQuoteContainer>
        {selectedRfq.permissions.can_view_quote ? (
          <Grid container spacing={3}>
            <Grid item xs={!quotedCorrespondence ? 12 : 8}>
              <Typography variant='h5' gutterBottom>
                Quoted Items
              </Typography>
              {quotedItems && selectedRfq && (
                <QuotedItemsTable
                  rows={quotedItems}
                  permissions={selectedRfq.permissions}
                  rfqUUID={selectedRfq.uuid}
                  rfqId={selectedRfq.id}
                  supplierId={selectedRfq.supplier_id}
                />
              )}
              {!quotedItems && !loading && (
                <Alert severity='warning'>No Items</Alert>
              )}
              {!quotedItems && loading && <LoadingSpinner />}
              <Typography variant='h5' gutterBottom>
                Non-Bid Items
              </Typography>
              {nonBidItems && nonBidItems.length > 0 ? (
                <NonBidItemsTable rows={nonBidItems} />
              ) : (
                <Alert severity='warning'>No Items</Alert>
              )}
            </Grid>
            {quotedCorrespondence && (
              <Grid item xs={4} className='saved-quote'>
                <div className='subject'>
                  <h2>{quotedCorrespondence.thread.subject}</h2>
                </div>
                <div className='messages'>
                  {quotedCorrespondence.messages.map((message, i) => (
                    <div className='message' key={i}>
                      <div className='sender'>
                        <p className='name'>{message.display_name}</p>
                        <p className='date'>
                          {message.date_added.formatted_date}
                        </p>
                      </div>
                      {message.html_body && (
                        <p
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{
                            __html: message.html_body,
                          }}
                        />
                      )}
                    </div>
                  ))}
                </div>
              </Grid>
            )}
          </Grid>
        ) : (
          <div className='nothing-content'>
            {quoteData && (
              <div>
                <p>
                  {quoteData.display_message &&
                    quoteData.display_message.message}
                </p>
                {quoteData.display_message &&
                  quoteData.display_message.button.display && (
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() =>
                        onDisplayMessageButtonClicked(
                          quoteData.display_message.button.status_id
                        )
                      }
                    >
                      {quoteData.display_message.button.language}
                    </Button>
                  )}
              </div>
            )}
          </div>
        )}
      </TabQuoteContainer>
    </TabQuoteContext.Provider>
  )
}

const mapStateToProps = (state) => ({
  quotedCorrespondence: state.rfq.quoted_correspondence,
  selectedRfq: state.rfq.selected_rfq,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setIsQuoteUpdated,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(TabQuote)
