import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Dialog,
  DialogContent,
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  OutlinedInput,
} from '@material-ui/core'

import StyledButton from 'components/common/button'

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      maxWidth: 800,
    },
  },
  formControl: {
    width: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const EditLogicModal = ({
  open,
  handleClose,
  suppliers,
  manufacturers,
  logic = null,
  onUpdateLogic,
}) => {
  const classes = useStyles()
  const [rank, setRank] = useState('')
  const [buyFrom, setBuyFrom] = useState('')
  const [selectedSuppliers, setSelectedSuppliers] = useState([])
  const [manufacturer, setManufacturer] = useState('')
  const [selectedManufacturers, setSelectedManufacturers] = useState([])

  useEffect(() => {
    if (open === false) {
      setRank('')
      setBuyFrom('')
      setSelectedSuppliers([])
      setManufacturer('')
      setSelectedManufacturers([])
    }
  }, [open])

  useEffect(() => {
    if (logic) {
      setRank(logic.rank)
      if (logic.supplier_ids === 'all') {
        setBuyFrom('any_supplier')
      } else {
        setBuyFrom('specific_supplier')
        setSelectedSuppliers(logic.supplier_ids)
      }

      if (logic.manufacturer_ids === 'all') {
        setManufacturer('any_manufacturer')
      } else {
        setManufacturer('specific_manufacturer')
        setSelectedManufacturers(logic.manufacturer_ids)
      }
    }
  }, [logic])

  const onChangeRank = (e) => {
    setRank(e.target.value)
  }

  const onChangeBuyFrom = (e) => {
    setBuyFrom(e.target.value)
  }

  const onChangeManufacturer = (e) => {
    setManufacturer(e.target.value)
  }

  const onChangeSupplier = (e) => {
    setSelectedSuppliers(e.target.value)
  }

  const onChangeManufacturers = (e) => {
    setSelectedManufacturers(e.target.value)
  }

  const onUpdate = () => {
    const updateObj = {
      supplier_ids: buyFrom === 'any_supplier' ? 'all' : selectedSuppliers,
      manufacturer_ids:
        manufacturer === 'any_manufacturer' ? 'all' : selectedManufacturers,
      rank,
    }
    onUpdateLogic(updateObj)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='form-dialog-title'
      className={classes.dialog}
    >
      <DialogContent>
        <Box display='flex' mb={2} justifyContent='center'>
          <Box mr={2}>
            <FormControl variant='outlined' className={classes.formControl}>
              <InputLabel id='rank-select-outlined-label'>Rank</InputLabel>
              <Select
                labelId='rank-select-outlined-label'
                id='rank-select-outlined'
                value={rank}
                onChange={onChangeRank}
                label='Rank'
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={-1}>-1</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box mr={2} display='flex' flexDirection='column'>
            <FormControl component='fieldset'>
              <FormLabel component='legend'>Buy From:</FormLabel>
              <RadioGroup
                aria-label='buy from'
                name='buy from'
                value={buyFrom}
                onChange={onChangeBuyFrom}
              >
                <FormControlLabel
                  value='any_supplier'
                  control={<Radio />}
                  label='Any Supplier'
                />
                <FormControlLabel
                  value='specific_supplier'
                  control={<Radio />}
                  label='Specific Supplier(s)'
                />
              </RadioGroup>
            </FormControl>
            <FormControl
              variant='outlined'
              className={classes.formControl}
              disabled={buyFrom !== 'specific_supplier'}
            >
              <InputLabel htmlFor='select-outlined' id='supplier-select-label'>
                Suppliers
              </InputLabel>
              <Select
                labelId='supplier-select-label'
                id='supplier-select'
                multiple
                value={selectedSuppliers}
                onChange={onChangeSupplier}
                input={<OutlinedInput id='select-outlined' label='Suppliers' />}
                MenuProps={MenuProps}
              >
                {suppliers &&
                  suppliers.map((supplier) => (
                    <MenuItem key={supplier.id} value={supplier.id}>
                      {supplier.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
          <Box display='flex' flexDirection='column'>
            <FormControl component='fieldset'>
              <FormLabel component='legend'>In:</FormLabel>
              <RadioGroup
                aria-label='in'
                name='in'
                value={manufacturer}
                onChange={onChangeManufacturer}
              >
                <FormControlLabel
                  value='any_manufacturer'
                  control={<Radio />}
                  label='Any Manufacturer'
                />
                <FormControlLabel
                  value='specific_manufacturer'
                  control={<Radio />}
                  label='Specific Manufacturer(s)'
                />
              </RadioGroup>
            </FormControl>
            <FormControl
              variant='outlined'
              className={classes.formControl}
              disabled={manufacturer !== 'specific_manufacturer'}
            >
              <InputLabel
                htmlFor='manufacturer-select-outlined'
                id='manufacturer-select-label'
              >
                Manufacturers
              </InputLabel>
              <Select
                labelId='manufacturer-select-label'
                id='manufacturer-select'
                multiple
                value={selectedManufacturers}
                onChange={onChangeManufacturers}
                input={
                  <OutlinedInput
                    id='manufacturer-select-outlined'
                    label='Manufacturers'
                  />
                }
                MenuProps={MenuProps}
              >
                {manufacturers &&
                  manufacturers.map((manufacturer) => (
                    <MenuItem key={manufacturer.id} value={manufacturer.id}>
                      {manufacturer.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box display='flex' justifyContent='flex-end'>
          <StyledButton
            onClick={handleClose}
            className='btn-orange'
            color='primary'
            variant='contained'
            style={{ marginRight: '1em' }}
          >
            Cancel
          </StyledButton>

          <Button
            color='primary'
            variant='contained'
            onClick={onUpdate}
            // disabled={!selectedLogic}
          >
            {logic ? 'Update' : 'Create'}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default EditLogicModal
