import React, { Component } from 'react'
import ReactTable from 'react-table'
import NumberFormat from 'react-number-format'

class PurchaseHistoryTable extends Component {
  componentDidMount() {}

  render() {
    const { priceHistory } = this.props

    const columns = [
      {
        Header: 'Date',
        accessor: 'date_bought.formatted_date',
        minWidth: 150,
      },
      {
        Header: 'Quantity',
        accessor: 'order_qty',
      },
      {
        Header: 'Supplier',
        accessor: 'company_name',
      },
      {
        Header: 'Unit Cost',
        accessor: 'unit_cost',
        Cell: (props) => (
          <span>
            <NumberFormat
              value={parseFloat(props.value)}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
              decimalScale={3}
            />
          </span>
        ),
      },
    ]
    let propertyNames = []

    if (priceHistory) {
      propertyNames = Object.getOwnPropertyNames(priceHistory)
    }

    return (
      <div>
        {propertyNames.length > 0 &&
          propertyNames.map((propertyName, i) => (
            <div key={i}>
              <p>{propertyName}</p>
              {priceHistory[propertyName] && (
                <ReactTable
                  data={priceHistory[propertyName]}
                  columns={columns}
                  defaultPageSize={5}
                  minRows={3}
                  className='-striped -highlight'
                />
              )}
            </div>
          ))}
      </div>
    )
  }
}

export default PurchaseHistoryTable
