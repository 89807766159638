import { client } from '../apiConfig'

const getListAPI = (query) => {
  return client.get(`/supplier_preference_rules/list?${query}`)
}

const getSequenceListAPI = () => {
  return client.get('/supplier_preference_rules/sequence/list')
}

const updateSequenceAPI = (sequence) => {
  return client.put('/supplier_preference_rules/sequence/update', {
    sequence,
  })
}

const getPartNumberClassListAPI = () => {
  return client.get('/supplier_preference_rules/part_number_class/list')
}

const deleteRulesAPI = (uuids) => {
  return client.delete(`/supplier_preference_rules/delete/${uuids}`)
}

const searchRulesAPI = (term, partNumberClassIds) => {
  return client.get(
    `/supplier_preference_rules/search?term=${term}&part_number_class_id=${partNumberClassIds}`
  )
}

const getStringEvaluationTypesAPI = () => {
  return client.get('/supplier_preference_rules/string_evaluation_types/list')
}

const readRuleAPI = (uuid) => {
  return client.get(`/supplier_preference_rules/read/${uuid}`)
}

const updateRuleAPI = (uuid, body) => {
  return client.put(`/supplier_preference_rules/update/${uuid}`, body)
}

const createRuleAPI = (body) => {
  return client.post('/supplier_preference_rules/create', body)
}

const processRuleAPI = (uuid, partNumber) => {
  return client.get(
    `/supplier_preference_rules/test/${uuid}?part_number=${partNumber}`
  )
}

export {
  getListAPI,
  getSequenceListAPI,
  updateSequenceAPI,
  getPartNumberClassListAPI,
  deleteRulesAPI,
  searchRulesAPI,
  getStringEvaluationTypesAPI,
  readRuleAPI,
  updateRuleAPI,
  createRuleAPI,
  processRuleAPI,
}
