import React, { Component } from 'react'

import { getRequisitionShipmentsAPI } from 'services/helpers/apis/requisition'
import { fetchShipmentTrackingAPI } from 'services/helpers/apis/other'
import ShipmentsStatus from 'components/shipmentsStatus'

class TabShipment extends Component {
  state = {
    shipmentDetails: [],
  }

  async componentDidMount() {
    const { uuid } = this.props

    try {
      const res = await getRequisitionShipmentsAPI(uuid)
      const { shipments } = res.data.data

      if (shipments.length > 0) {
        shipments.map(async (shipment) => {
          const res = await fetchShipmentTrackingAPI(shipment)
          const shipmentDetail = res.data.data

          this.setState((prevState) => ({
            shipmentDetails: [...prevState.shipmentDetails, shipmentDetail],
          }))
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  render() {
    const { shipmentDetails } = this.state

    return (
      <div>
        <h2>Shipments</h2>
        {shipmentDetails.length > 0 ? (
          <ShipmentsStatus shipments={shipmentDetails} />
        ) : (
          <p>No shipments found</p>
        )}
      </div>
    )
  }
}

export default TabShipment
