import styled from 'styled-components'

const PageWrapperStyle = styled.div`
  overflow: auto;

  .toolbar-logo {
    margin-right: 1em;

    img {
      height: 30px;
    }
  }
  .logo {
    text-align: center;
    padding: 20px;

    img {
      max-width: 100px;
    }
  }
  .MuiDrawer-root {
    .MuiListItem-root {
      flex-direction: column;
      color: white;
      text-transform: uppercase;
      padding-top: 15px;
      padding-bottom: 15px;

      &.Mui-selected {
        position: relative;

        &:after {
          content: '';
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 7.5px 8px 7.5px 0;
          border-color: transparent #ffffff transparent transparent;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .MuiListItemIcon-root {
        min-width: initial;
        color: white;
      }
    }
  }

  .dashboard-main {
    height: 100vh;

    .dashboard-content {
      height: calc(100vh - 60px);
      padding: 80px 20px;
      overflow: auto;

      h4 {
        text-transform: capitalize;
      }

      ${(props) => props.theme.breakpoints.up('md')} {
        padding: 20px;
      }
    }

    .dashboard-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 60px;
      padding: 0 20px;
    }
  }
`

export default PageWrapperStyle
