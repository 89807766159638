import React, { Component } from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

import adminActions from 'services/redux/admin/actions'

const { revokeBrowser, revokeAllBrowser } = adminActions

const Container = styled.div`
  .browser {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn-revoke-all {
      width: 120px;
      flex-shrink: 0;
    }

    .btn-revoke {
      width: 100px;
      flex-shrink: 0;
    }

    p {
      span {
        color: red;
      }
    }
  }
`

class AuthenticatedBrowsers extends Component {
  static propTypes = {
    self: PropTypes.bool,
    editable: PropTypes.bool,
  }

  static defaultProps = {
    self: false,
    editable: true,
  }

  componentDidMount() {}

  revokeAll = () => {
    const { self } = this.props
    this.props.revokeAllBrowser(this.props.userId, self)
  }

  revoke = (tokenObject) => {
    this.props.revokeBrowser(tokenObject)
  }

  render() {
    const { tokenObject, editable } = this.props

    return (
      <Container>
        {editable && tokenObject && tokenObject.length > 0 && (
          <div className='browser'>
            <p>Force logout from all browsers</p>
            <Button
              variant='contained'
              color='primary'
              className='btn-revoke-all'
              onClick={this.revokeAll}
            >
              Revoke All
            </Button>
          </div>
        )}
        {tokenObject &&
          tokenObject.map((object) => (
            <div className='browser' key={object.token}>
              <p>
                {object.current_token && <span>current session </span>}
                {object.browser_user_agent}
                <br />
                IP Address: {object.ip_address}
              </p>
              {editable && (
                <Button
                  variant='contained'
                  color='primary'
                  className='btn-revoke'
                  onClick={() => this.revoke(object)}
                  disabled={object.current_token}
                >
                  Revoke
                </Button>
              )}
            </div>
          ))}
      </Container>
    )
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ revokeBrowser, revokeAllBrowser }, dispatch)

export default connect(null, mapDispatchToProps)(AuthenticatedBrowsers)
