import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'

const QuantityInput = ({ line, onChange }) => {
  const [value, setValue] = useState(line.total_difference)
  const [inputError, setInputError] = useState(false)

  const onChangeInput = (e) => {
    if (
      (parseInt(e.target.value, 10) >= 0 &&
        parseInt(e.target.value, 10) <= line.total_difference) ||
      e.target.value === ''
    ) {
      setValue(e.target.value)
      setInputError(false)
      onChange({ ...line, quantity: parseInt(e.target.value, 10) })
    } else {
      setInputError(true)
    }
  }

  return (
    <div>
      <TextField
        label='quantity'
        variant='outlined'
        onChange={onChangeInput}
        value={value}
        size='small'
        error={inputError}
        helperText={`Maximum quantity is ${line.total_difference}`}
      />
    </div>
  )
}

export default QuantityInput
