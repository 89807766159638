import React from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const PaymentForm = ({ initialValues }) => {
  const handleChange = () => {}

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextField
          name="internal_account_number"
          label="Internal Account Number"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="select-payment">Payment Terms</InputLabel>
          <Select
            labelId="select-payment"
            id="select-payment"
            value={10}
            onChange={handleChange}
          >
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="select-credit-card">
            Send Credit Card Info by
          </InputLabel>
          <Select
            labelId="select-credit-card"
            id="select-credit-card"
            value={10}
            onChange={handleChange}
          >
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={false}
              onChange={handleChange}
              name="storeCreditCardInfo"
              color="primary"
            />
          }
          label="Supplier will store credit card info"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="standard-full-width"
          label="Email"
          placeholder="bob@powell.com"
          helperText="Email Address to send Credit Card Info"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
    </Grid>
  )
}

export default PaymentForm
