import React from 'react'
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import _isEmpty from 'lodash/isEmpty'

import { updateRfqLineAPI } from 'services/helpers/apis/rfq'

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

const ManufacturerSelector = ({ list, line, fieldType, onSelect }) => {
  const classes = useStyles()
  const [selectedManufacturer, setSelectedManufacturer] = React.useState(
    fieldType !== 'new' && !_isEmpty(line.manufacturer)
      ? line.manufacturer.id
      : ''
  )

  const handleChange = async (event) => {
    setSelectedManufacturer(event.target.value)
    if (fieldType === 'editable') {
      try {
        await updateRfqLineAPI(line.uuid, {
          manufacturer_id: event.target.value,
        })
      } catch (e) {
        console.log(e)
      }
    }
    if (fieldType === 'new') {
      onSelect(event.target.value)
    }
  }

  return (
    <div>
      {fieldType === 'text' && <span>{line.manufacturer.name}</span>}
      {fieldType !== 'text' && (
        <FormControl variant='outlined' className={classes.formControl}>
          <InputLabel id='manufacturer-select-outlined-label'>
            Manufacturer
          </InputLabel>
          <Select
            labelId='manufacturer-select-outlined-label'
            id='manufacturer-select-outlined'
            value={selectedManufacturer}
            onChange={handleChange}
            label='Manufacturer'
          >
            {list.map((item) => (
              <MenuItem value={item.id} key={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </div>
  )
}

export default ManufacturerSelector
