import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { toast } from 'react-toastify'

import adminActions from 'services/redux/admin/actions'
import { deleteGoogleAuthCodeAPI } from 'services/helpers/apis/user'

import { ModalContent } from './index.style'

const { onApiError } = adminActions

class DisableMFAModalContent extends Component {
  state = {
    password: '',
  }

  componentDidMount() {}

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value })
  }

  onCancel = () => {
    this.props.onClose()
  }

  onConfirm = (event) => {
    const { userId } = this.props
    const { password } = this.state

    event.preventDefault()

    deleteGoogleAuthCodeAPI(password, userId)
      .then(() => {
        toast.success('MFA is diabled')
        this.props.onClose()
      })
      .catch((err) => {
        this.props.onApiError(err)
      })
  }

  render() {
    const { password } = this.state

    return (
      <ModalContent>
        <form onSubmit={this.onConfirm}>
          <div className='mfa-form'>
            <p>Please Confirm Your Password</p>
            <TextField
              label='Password'
              type='password'
              value={this.state.password}
              onChange={this.handleChange('password')}
            />
          </div>
          <div className='modal-action'>
            <Button
              variant='contained'
              color='secondary'
              onClick={this.onCancel}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              color='primary'
              type='submit'
              disabled={password === ''}
            >
              Confirm
            </Button>
          </div>
        </form>
      </ModalContent>
    )
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ onApiError }, dispatch)

export default connect(null, mapDispatchToProps)(DisableMFAModalContent)
