import React, { useState, useEffect } from 'react'
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  tableActions: {
    position: 'fixed',
    left: 170,
    bottom: 60,
    right: 20,
    backgroundColor: 'white',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

const TableActions = ({
  defaultValue,
  list,
  bulkActionDisabled,
  onUpdateSelected,
  tableFor,
}) => {
  const classes = useStyles()
  const [selectedValue, setSelectedValue] = useState(defaultValue)

  useEffect(() => {
    setSelectedValue(defaultValue)
  }, [defaultValue])

  const handleChange = async (event) => {
    setSelectedValue(event.target.value)
    onUpdateSelected(event.target.value)
  }

  return (
    <Box
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      className={classes.tableActions}
    >
      <Box>
        <FormControl variant='outlined' className={classes.formControl}>
          <InputLabel id='select-label'>
            {tableFor === 'supplier' ? 'Supplier' : 'Manufacturer'}
          </InputLabel>
          <Select
            labelId='select-label'
            id='select-outlined'
            label={tableFor === 'supplier' ? 'Supplier' : 'Manufacturer'}
            value={selectedValue}
            onChange={handleChange}
            disabled={bulkActionDisabled}
          >
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            {list.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  )
}

export default TableActions
