import React from 'react'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import styled from 'styled-components'
import { reduxForm } from 'redux-form'

import ProfileForm from './components/profileForm'
import SecurityForm from './components/securityForm'

const CreateFormContainer = styled.form`
  .wrapper {
    .card {
      height: 100%;
    }

    .form-action {
      text-align: right;
      margin-top: 10px;
    }
  }
`

const CreateForm = ({ handleSubmit, isUpdateSupplier }) => {
  return (
    <CreateFormContainer onSubmit={handleSubmit}>
      <div className="wrapper">
        <Grid container spacing={3}>
          <Grid item xs={isUpdateSupplier ? 12 : 6}>
            <Card className="card">
              <CardHeader title="User Profile" />
              <CardContent className="card-content">
                <ProfileForm isUpdateSupplier={isUpdateSupplier} />
              </CardContent>
            </Card>
          </Grid>
          {!isUpdateSupplier && (
            <Grid item xs={6}>
              <Card className="card">
                <CardHeader title="Password and Security" />
                <CardContent className="card-content">
                  <SecurityForm />
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>

        <div className="form-action">
          <Button variant="contained" color="primary" type="submit">
            Create
          </Button>
        </div>
      </div>
    </CreateFormContainer>
  )
}

const validate = (values, props) => {
  const errors = {}
  const strongRegex = new RegExp(
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'
  )

  if (!values.email) {
    errors.email = 'Email is required'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address'
  }

  if (!values.firstname) {
    errors.firstname = 'First name is required'
  }

  if (!values.lastname) {
    errors.lastname = 'Last name is required'
  }

  if (!props.isUpdateSupplier && !values.userType) {
    errors.userType = 'User type is Required'
  }

  if (!values.phone) {
    errors.phone = 'Phone is Required'
  }

  if (!props.isUpdateSupplier && !values.password) {
    errors.password = 'Password is required'
  } else if (!strongRegex.test(values.password)) {
    errors.password =
      'The password does not meet the minimum password requirements'
  }

  return errors
}

const CreateReduxForm = reduxForm({
  form: 'createUserForm',
  validate,
})(CreateForm)

export default CreateReduxForm
