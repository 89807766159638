import styled from 'styled-components'

const CorrespondenceContainer = styled.div`
  position: relative;

  .ReactTable {
    max-height: 500px;
  }
  .correspondence-actions {
    position: absolute;
    right: 20px;
    bottom: 20px;
    display: flex;
    flex-direction: column;

    .btn-refresh {
      margin-bottom: 15px;
    }
  }
`

export default CorrespondenceContainer
