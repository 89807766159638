import React, { useEffect, useState, useContext } from 'react'
import { Box, IconButton, Grid } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _find from 'lodash/find'

import { readRfqAPI } from 'services/helpers/apis/rfq'
import rfqActions from 'services/redux/rfq/actions'
import AssignDropdown from 'components/assignDropdown'
import TimelineSlider from 'components/timelineSlider'
import StatusDropdown from 'components/modalStatusDropdown'
import SupplierPopover from 'components/supplierPopover'
import { GlobalContext } from 'routes'

import ReadModalContainer from './readModal.style'
import RfqTabs from './components/rfqTabs'
import { RfqListContext } from './index'

const { setRfqData, onRfqSendConfirmed, setIsQuoteUpdated } = rfqActions

const RfqReadModal = ({
  userList,
  rfq,
  sentRfqId,
  isQuoteUpdated,
  selectedTab,
  onClose,
  onRfqSendConfirmed,
  setRfqData,
  setIsQuoteUpdated,
}) => {
  const rfqListContext = useContext(RfqListContext)
  const context = useContext(GlobalContext)
  const [modalRfqData, setModalRfqData] = useState(null)
  const [loadingRfqStatuses, setLoadingRfqStatuses] = useState(null)
  const [supplier, setSupplier] = useState(null)
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const callApis = async () => {
    setLoadingRfqStatuses(true)

    try {
      const res = await readRfqAPI(rfq.uuid)

      setRfqData(res.data.data)
      setModalRfqData(res.data.data)

      const selectedSupplier = _find(rfqListContext.suppliersList, {
        id: res.data.data.supplier_id,
      })

      setSupplier(selectedSupplier)
    } catch (e) {
      context.onApiError(e)
    } finally {
      setLoadingRfqStatuses(false)
    }
  }

  useEffect(() => {
    callApis()
  }, [])

  useEffect(() => {
    if (sentRfqId) {
      callApis()
      onRfqSendConfirmed()
    }
  }, [sentRfqId])

  useEffect(() => {
    if (isQuoteUpdated) {
      callApis()
      setIsQuoteUpdated(false)
    }
  }, [isQuoteUpdated])

  const onStatusUpdated = () => {
    callApis()
  }

  return (
    <ReadModalContainer>
      <div className='title'>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Box display='flex' alignItems='center'>
            <h3>RFQ {rfq.id}</h3>
            <AssignDropdown
              userList={userList}
              selected={rfq}
              permissions={rfq.permissions}
              componentType='menu'
              updateFor='rfq'
            />
          </Box>
          <Box display='flex' alignItems='center'>
            {supplier && (
              <>
                <div
                  className='supplier-label'
                  aria-owns={open ? 'mouse-over-popover' : undefined}
                  aria-haspopup='true'
                  onMouseEnter={handlePopoverOpen}
                  onMouseLeave={handlePopoverClose}
                >
                  {supplier.name}
                </div>
                <SupplierPopover
                  open={open}
                  anchorEl={anchorEl}
                  handlePopoverClose={handlePopoverClose}
                  supplier={supplier}
                />
              </>
            )}
            {modalRfqData && (
              <StatusDropdown
                statuses={modalRfqData.status_list}
                statusId={modalRfqData.status_id}
                uuid={modalRfqData.uuid}
                onStatusUpdated={onStatusUpdated}
                loadingStatuses={loadingRfqStatuses}
                statusFor='rfq'
              />
            )}
            <IconButton
              className='btn-close'
              aria-label='Close'
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </div>
      {modalRfqData && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TimelineSlider timeline={modalRfqData.timeline} />
          </Grid>
          <Grid item xs={12}>
            <RfqTabs selectedTab={selectedTab} />
          </Grid>
        </Grid>
      )}
    </ReadModalContainer>
  )
}

const mapStateToProps = (state) => ({
  sentRfqId: state.rfq.sent_rfq_id,
  isQuoteUpdated: state.rfq.is_quote_updated,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setRfqData,
      onRfqSendConfirmed,
      setIsQuoteUpdated,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(RfqReadModal)
