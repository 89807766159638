import { createStore, applyMiddleware, combineReducers } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import storage from 'redux-persist/lib/storage'
import { reducer as formReducer } from 'redux-form'
import { composeWithDevTools } from 'redux-devtools-extension'
import { routerReducer, routerMiddleware } from 'react-router-redux'
import createSagaMiddleware from 'redux-saga'
import reducers from './reducers'
import rootSaga from './sagas'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['storage'],
  stateReconciler: autoMergeLevel2,
}

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    ...reducers,
    router: routerReducer,
    form: formReducer,
  })
)

const createHistory = require('history').createBrowserHistory

const history = createHistory()
const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const middlewares = [sagaMiddleware, routeMiddleware]

const store = createStore(
  persistedReducer,
  process.env.NODE_ENV !== 'production'
    ? composeWithDevTools(applyMiddleware(...middlewares))
    : applyMiddleware(...middlewares)
)

const persistor = persistStore(store)

sagaMiddleware.run(rootSaga)
export { store, persistor, history }
