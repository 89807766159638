import React, { useState, useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ReactTable from 'react-table'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'
import _findIndex from 'lodash/findIndex'
import RefreshIcon from '@material-ui/icons/Refresh'
import { withRouter } from 'react-router'
import { toast } from 'react-toastify'

import { GlobalContext } from 'routes'
import adminActions from 'services/redux/admin/actions'
import {
  getRfqCorrespondenceAPI,
  createRfqCorrespondenceAPI,
} from 'services/helpers/apis/rfq'
import {
  getPoCorrespondenceAPI,
  createPoCorrespondenceAPI,
} from 'services/helpers/apis/po'

import CorrespondenceContainer from './index.style'
import NewMessage from './messages/newMessage'
import ReadMessage from './messages/readMessage'

const { unSetCorrespondenceThreadUUID } = adminActions

export const CorrespondenceTableContext = React.createContext()

const CorrespondenceTable = ({
  selectedThreadUUID,
  selectedMessageUUID,
  onSavedQuote = () => {},
  selectedRfq,
  selectedPo,
  correspondenceFor = 'rfq',
  unSetCorrespondenceThreadUUID,
  history,
}) => {
  const [correspondence, setCorrespondence] = useState(null)
  const [newMessageModal, setNewMessageModal] = useState(false)
  const [createdCorrespondence, setCreatedCorrespondence] = useState(null)
  const [selectedCorrespondence, setSelectedCorrespondence] = useState(null)
  const [
    selectedCorrespondenceIndex,
    setSelectedCorrespondenceIndex,
  ] = useState(null)
  const [refreshing, setRefreshing] = useState(false)
  const [addingNewMessage, setAddingNewMessage] = useState(false)
  const context = useContext(GlobalContext)

  const onGetCorrespondence = async () => {
    setRefreshing(true)

    try {
      const getCorrespondence =
        correspondenceFor === 'rfq'
          ? await getRfqCorrespondenceAPI(selectedRfq.uuid)
          : await getPoCorrespondenceAPI(selectedPo.uuid)

      context.onApiResponse(getCorrespondence)
      setCorrespondence(getCorrespondence.data.data)

      if (selectedThreadUUID) {
        const selectedThreadIndex = _findIndex(
          getCorrespondence.data.data.threads,
          function(o) {
            return o.thread.uuid === selectedThreadUUID
          }
        )

        setSelectedCorrespondence(
          getCorrespondence.data.data.threads[selectedThreadIndex]
        )
        setSelectedCorrespondenceIndex(selectedThreadIndex)
        unSetCorrespondenceThreadUUID()
      }

      if (selectedMessageUUID) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < getCorrespondence.data.data.threads.length; i++) {
          const thread = getCorrespondence.data.data.threads[i]
          const messageIndex = _findIndex(thread.messages, function(o) {
            return o.uuid === selectedMessageUUID
          })
          if (messageIndex >= 0) {
            setSelectedCorrespondence(getCorrespondence.data.data.threads[i])
            setSelectedCorrespondenceIndex(i)
            break
          }
        }
      }
    } catch (e) {
      context.onApiError(e)
    } finally {
      setRefreshing(false)
    }
  }

  useEffect(() => {
    onGetCorrespondence()

    const interval = setInterval(() => {
      onGetCorrespondence()
    }, 300000)
    return () => clearInterval(interval)
  }, [])

  const unselectCorrespondence = () => {
    setSelectedCorrespondence(null)
    setSelectedCorrespondenceIndex(null)
    history.push(
      `/${correspondenceFor}/${
        correspondenceFor === 'rfq' ? selectedRfq.id : selectedPo.id
      }/correspondence`
    )
  }

  const onCorrespondenceUpdated = (updatedCorrespondence) => {
    const updatedCorrespondenceIndex = _findIndex(
      correspondence.threads,
      (o) => {
        return o.thread.id === updatedCorrespondence.thread.id
      }
    )

    if (updatedCorrespondenceIndex >= 0) {
      const newCorrespondence = Object.assign({}, correspondence)
      if (updatedCorrespondence.thread.deleted) {
        newCorrespondence.threads.splice(updatedCorrespondenceIndex, 1)
        unselectCorrespondence()
      } else {
        newCorrespondence.threads[
          updatedCorrespondenceIndex
        ] = updatedCorrespondence
        setCorrespondence(newCorrespondence)
        setSelectedCorrespondence(
          selectedCorrespondenceIndex !== null
            ? newCorrespondence.threads[selectedCorrespondenceIndex]
            : null
        )
      }
    } else {
      const threadsArr = [updatedCorrespondence, ...correspondence.threads]
      const newCorrespondence = Object.assign({}, correspondence)
      newCorrespondence.threads = threadsArr
      setCorrespondence(newCorrespondence)
    }
  }

  const createNewMessage = async () => {
    setAddingNewMessage(true)

    try {
      const createThreadDraft =
        correspondenceFor === 'rfq'
          ? await createRfqCorrespondenceAPI(selectedRfq.uuid)
          : await createPoCorrespondenceAPI(selectedPo.uuid)

      toast.success('Thread draft is created.')
      setNewMessageModal(true)
      setCreatedCorrespondence(createThreadDraft.data.data)
    } catch (e) {
      context.onApiError(e)
    } finally {
      setAddingNewMessage(false)
    }
  }

  const onCloseNewMessage = () => {
    setCreatedCorrespondence(null)
    setNewMessageModal(false)
  }

  const onShowMessage = (correspondence, index) => {
    setSelectedCorrespondence(correspondence)
    setSelectedCorrespondenceIndex(index)

    history.push(
      `/${correspondenceFor}/${
        correspondenceFor === 'rfq' ? selectedRfq.id : selectedPo.id
      }/correspondence/thread/${correspondence.thread.uuid}`
    )
  }

  const columns = [
    {
      Header: 'Participants',
      accessor: 'thread.participants',
      Cell: (row) => {
        return (
          <Button
            color='primary'
            onClick={() => onShowMessage(row.original, row.index)}
          >
            {row.value.map((participant, i) => (
              <span key={i}>
                {participant}
                {i < row.value.length - 1 && ','}
              </span>
            ))}
          </Button>
        )
      },
    },
    {
      Header: 'Subject',
      accessor: 'thread.subject',
    },
    {
      Header: 'Status',
      accessor: 'thread.status',
      Cell: (row) => {
        return (
          <span
            style={{
              color: row.value.color,
              fontWeight: row.value.bold ? 'bold' : 'normal',
            }}
          >
            {row.value.status}
          </span>
        )
      },
    },
    {
      Header: 'Date',
      accessor: 'thread.date_last_updated.formatted_date',
    },
  ]

  return (
    <CorrespondenceTableContext.Provider
      value={{
        onCorrespondenceUpdated,
        correspondence: selectedCorrespondence,
      }}
    >
      <CorrespondenceContainer>
        {correspondence && (
          <ReactTable
            data={correspondence.threads}
            columns={columns}
          ></ReactTable>
        )}
        <div className='correspondence-actions'>
          <Fab
            size='small'
            color='primary'
            aria-label='add'
            className='btn-refresh'
            onClick={onGetCorrespondence}
            disabled={refreshing}
          >
            <RefreshIcon />
          </Fab>
          <Fab
            size='small'
            color='secondary'
            aria-label='add'
            className='btn-plus'
            onClick={createNewMessage}
            disabled={addingNewMessage}
          >
            <AddIcon />
          </Fab>
        </div>
        {newMessageModal && createdCorrespondence && (
          <NewMessage
            selectedLine={
              correspondenceFor === 'rfq' ? selectedRfq : selectedPo
            }
            onClose={onCloseNewMessage}
            correspondenceFor={correspondenceFor}
            createdCorrespondence={createdCorrespondence}
          />
        )}
        {selectedCorrespondence && (
          <ReadMessage
            selectedLine={
              correspondenceFor === 'rfq' ? selectedRfq : selectedPo
            }
            correspondence={selectedCorrespondence}
            onClose={unselectCorrespondence}
            onCorrespondenceUpdated={onCorrespondenceUpdated}
            onSavedQuote={onSavedQuote}
          />
        )}
      </CorrespondenceContainer>
    </CorrespondenceTableContext.Provider>
  )
}

const mapStateToProps = (state) => ({
  selectedRfq: state.rfq.selected_rfq,
  selectedPo: state.purchaseOrder.selected_po,
  selectedThreadUUID: state.admin.correspondence_thread_uuid,
  selectedMessageUUID: state.admin.correspondence_messsage_uuid,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      unSetCorrespondenceThreadUUID,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CorrespondenceTable))
