import React from 'react'
import { Switch, Route, withRouter } from 'react-router'

import { UserList, CreateUser, EditUser } from './user'
import { UserTypeList, UserTypeUpdate } from './userType'
import SupplierList from './supplier/list'
import SupplierUpdate from './supplier/update'
import SupplierCreate from './supplier/create'
import SupplierPartNumberRulesList from './supplierPartNumberRules/list'
import SupplierPartNumberEdit from './supplierPartNumberRules/edit'
import SuppplierPreferenceRulesList from './supplierPreferenceRules/list'
import SupplierPreferenceRuleEdit from './supplierPreferenceRules/edit'
import SupplierNameVariants from './nameVariants/supplierNameVariants'
import ManufacturerNameVariants from './nameVariants/manufacturerNameVariants'

const AdminRoutes = ({ match }) => {
  const routeURL = match.url

  return (
    <Switch>
      {/* Routes for User Pages */}
      <Route path={`${routeURL}/user/list`} component={UserList} />
      <Route path={`${routeURL}/user/create`} component={CreateUser} />
      <Route
        path={`${routeURL}/user/update/self`}
        component={() => <EditUser self fromRoute />}
      />
      <Route
        path={`${routeURL}/user/update/:userName`}
        component={() => <EditUser fromRoute />}
      />
      <Route
        path={`${routeURL}/user/read/:userName`}
        component={() => <EditUser editable={false} fromRoute />}
      />
      <Route path={`${routeURL}/userType/list`} component={UserTypeList} />
      <Route
        path={`${routeURL}/userType/update/:type`}
        component={UserTypeUpdate}
      />
      {/* Routes for Supplier */}
      <Route
        exact
        path={`${routeURL}/supplier/list`}
        component={SupplierList}
      />
      <Route
        exact
        path={`${routeURL}/supplier/update/:uuid`}
        component={SupplierUpdate}
      />
      <Route
        exact
        path={`${routeURL}/supplier/create`}
        component={SupplierCreate}
      />
      {/* Routes for Supplier PartNumber Rules */}
      <Route
        exact
        path={`${routeURL}/supplier_part_number_rules`}
        component={SupplierPartNumberRulesList}
      />
      <Route
        exact
        path={`${routeURL}/supplier_part_number_rules/update/:uuid`}
        component={SupplierPartNumberEdit}
      />
      {/* Routes for Supplier Preference Rules */}
      <Route
        exact
        path={`${routeURL}/supplier_preference_rules`}
        component={SuppplierPreferenceRulesList}
      />
      <Route
        exact
        path={`${routeURL}/supplier_preference_rules/update/:uuid`}
        component={SupplierPreferenceRuleEdit}
      />
      <Route
        exact
        path={`${routeURL}/supplier_name_variants`}
        component={SupplierNameVariants}
      />
      <Route
        exact
        path={`${routeURL}/manufacturer_name_variants`}
        component={ManufacturerNameVariants}
      />
    </Switch>
  )
}

export default withRouter(AdminRoutes)
