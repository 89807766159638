import React, { Component } from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Collapse from '@material-ui/core/Collapse'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import styled from 'styled-components'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import _filter from 'lodash/filter'

const TreeCheckBoxFieldContainer = styled.div`
  li {
    padding: 0 5px;

    &.nested {
      padding-left: 80px;
    }
  }
`

class TreeCheckBoxField extends Component {
  state = {
    open: false,
    indeterminate: false,
    children: [],
    parentLabel: '',
    scopeCheck: false,
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {
    if (!nextProps.input.value.equals(this.props.input.value)) {
      this.setState(
        {
          children: nextProps.input.value.children,
          parentLabel: nextProps.input.value.label,
        },
        () => {
          this.setScopeCheckValue()
        }
      )
    }
  }

  handleChange = (name) => (event) => {
    const { children } = this.state

    if (typeof name === 'number') {
      this.changeCheckBoxValue(name, event.target.checked)
    } else {
      this.setState({ [name]: event.target.checked, indeterminate: false })
      if (!event.target.checked) {
        for (let i = 0; i < children.length; i += 1) {
          this.changeCheckBoxValue(i, false)
        }
      } else {
        for (let i = 0; i < children.length; i += 1) {
          this.changeCheckBoxValue(i, true)
        }
      }
    }
  }

  changeCheckBoxValue = (index, checked) => {
    const { children } = this.state
    const { onChange, value } = this.props.input

    children[index].checked = checked

    onChange({
      ...value,
      children,
    })
    this.setScopeCheckValue()
  }

  setScopeCheckValue = () => {
    const { children } = this.state
    if (_filter(children, { checked: true }).length === children.length) {
      this.setState({
        scopeCheck: true,
        indeterminate: false,
      })
    } else if (_filter(children, { checked: true }).length === 0) {
      this.setState({
        scopeCheck: false,
        indeterminate: false,
      })
    } else {
      this.setState({
        scopeCheck: true,
        indeterminate: true,
      })
    }
  }

  handleClick = () => {
    this.setState((prevState) => ({
      open: !prevState.open,
    }))
  }

  render() {
    const {
      open,
      children,
      parentLabel,
      scopeCheck,
      indeterminate,
    } = this.state

    return (
      <TreeCheckBoxFieldContainer>
        <ListItem>
          <ListItemIcon onClick={this.handleClick}>
            {!open ? <ChevronRightIcon /> : <ExpandMoreIcon />}
          </ListItemIcon>
          <FormControlLabel
            control={
              <Checkbox
                checked={scopeCheck}
                onChange={this.handleChange('scopeCheck')}
                color="primary"
                indeterminate={indeterminate}
                inputProps={{
                  'aria-label': 'indeterminate checkbox',
                }}
              />
            }
            label={parentLabel}
          />
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <ListItem className="nested" key={i}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={item.checked}
                      onChange={this.handleChange(i)}
                      value={item.label}
                      color="primary"
                    />
                  }
                  label={item.label}
                />
              </ListItem>
            ))}
          </List>
        </Collapse>
      </TreeCheckBoxFieldContainer>
    )
  }
}

export default TreeCheckBoxField
