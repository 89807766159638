import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import NumberFormat from 'react-number-format'

import AssignDropdown from 'components/assignDropdown'
import QuantityChart from 'components/tables/quantityChart'
import { readRequisitionAPI } from 'services/helpers/apis/requisition'
import requisitionActions from 'services/redux/requisition/actions'

import RequisitionTabs from './components/requisitionTabs'
import ReadModalContainer from './readModal.style'

const { setSelectedRequisition } = requisitionActions

const RequisitionReadModal = ({
  requisition,
  selectedTab,
  userList,
  priceHistory,
  onClose,
  setSelectedRequisition,
}) => {
  const [requisitionData, setRequisitionData] = useState(null)

  useEffect(() => {
    const fetchApis = async () => {
      try {
        const res = await readRequisitionAPI(requisition.uuid)
        setRequisitionData(res.data.data)
      } catch (err) {
        console.log(err)
      }
    }

    fetchApis()
  }, [])

  useEffect(() => {
    if (requisitionData) {
      setSelectedRequisition(requisitionData)
    }
  }, [requisitionData])

  return (
    <ReadModalContainer>
      <div className='title'>
        <Box display='flex' alignItems='center'>
          <h3>Requisition ID: {requisition.order_id} </h3>
          {requisitionData && (
            <AssignDropdown
              userList={userList}
              selected={requisitionData.requisition}
              permissions={requisitionData.permissions}
              componentType='menu'
              updateFor='requisition'
            />
          )}
        </Box>
      </div>
      <IconButton className='btn-close' aria-label='Close' onClick={onClose}>
        <CloseIcon />
      </IconButton>

      {requisitionData && (
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Card className='card'>
              <CardContent className='card-content'>
                <h5 className='title'>Part Number</h5>
                <div className='summary'>
                  {requisitionData.requisition.part_numbers.map((number, i) => (
                    <p key={i} className={`${i === 0 && 'first'}`}>
                      {number}
                    </p>
                  ))}
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card className='card'>
              <CardContent className='card-content'>
                <QuantityChart
                  requisition={requisitionData.requisition}
                  quantitiesColors={requisitionData.quantities_colors}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card className='card'>
              <CardContent className='card-content'>
                <h5 className='title'>Pricing</h5>
                <div className='status'>
                  <h3>
                    <NumberFormat
                      value={requisitionData.requisition.high_target}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                      decimalScale={3}
                    />
                  </h3>
                  <p>High Target</p>
                </div>
                {priceHistory && priceHistory.historical_average && (
                  <div className='status'>
                    <h3>
                      <NumberFormat
                        value={priceHistory.historical_average}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                        decimalScale={3}
                      />
                    </h3>
                    <p>Historical Average</p>
                  </div>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            {requisitionData && (
              <RequisitionTabs
                orderId={requisition.order_id}
                uuid={requisition.uuid}
                selectedTab={selectedTab}
              />
            )}
          </Grid>
        </Grid>
      )}
    </ReadModalContainer>
  )
}

const mapStateToProps = (state) => ({
  priceHistory: state.requisition.priceHistory,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSelectedRequisition,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequisitionReadModal)
