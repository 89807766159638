import React, { useEffect, useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Checkbox,
  TablePagination,
  Paper,
  Box,
} from '@material-ui/core'
import _find from 'lodash/find'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Link } from 'react-router-dom'

import {
  getListAPI,
  getSequenceListAPI,
  updateSequenceAPI,
  getPartNumberClassListAPI,
  deleteRulesAPI,
} from 'services/helpers/apis/supplierPreferenceRules'
import EnhancedTableHead from 'components/common/enhancedTableHead'
import LoadingSpinner from 'components/common/loadingSpinner'
import { GlobalContext } from 'routes'

import FilterBox from './filterBox'
import TableActions from './tableActions'

const headCells = [
  {
    id: 'name',
    label: 'Name',
  },
  { id: 'part_number_class', label: 'Part Number Class' },
]

const useStyles = makeStyles((theme) => ({
  tabContent: {
    marginBottom: 80,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}))

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const getItemStyle = (isDragging, draggableStyle) => ({
  // styles we need to apply on draggables
  ...draggableStyle,

  ...(isDragging && {
    background: 'rgb(235,235,235)',
  }),
})

const DraggableComponent = (id, index) => (props) => {
  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => (
        <TableRow
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
          {...props}
        >
          {props.children}
        </TableRow>
      )}
    </Draggable>
  )
}

const DroppableComponent = (
  onDragEnd: (result, provided) => void,
  isDraggable
) => (props) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId='1'
        direction='vertical'
        isDropDisabled={!isDraggable}
      >
        {(provided) => {
          return (
            <TableBody
              ref={provided.innerRef}
              {...provided.droppableProps}
              {...props}
            >
              {props.children}
              {provided.placeholder}
            </TableBody>
          )
        }}
      </Droppable>
    </DragDropContext>
  )
}

const SupplierPreferenceRules = () => {
  const [preferenceRules, setPreferenceRules] = useState(null)
  const [partNumberClasses, setPartNumberClasses] = useState(null)
  const classes = useStyles()
  const [selected, setSelected] = React.useState([])
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(25)
  const [totalRows, setTotalRows] = useState(0)
  const [sequences, setSequences] = useState(null)
  const [isDraggable, setIsDraggable] = useState(true)
  const [loading, setLoading] = useState(true)
  const context = useContext(GlobalContext)

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = preferenceRules.map((n) => n.uuid)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)
  }

  const isSelected = (name) => selected.indexOf(name) !== -1

  const fetchRulesList = async (
    rows,
    page,
    partNumberClass = null,
    term = null
  ) => {
    setLoading(true)
    try {
      let query = `rows=${rows}&page=${page + 1}`
      if (partNumberClass) {
        query = `${query}&part_number_class=${partNumberClass}`
      }
      if (term) {
        query = `${query}&search_term=${term}`
      }
      if (!partNumberClass && !term) {
        setIsDraggable(true)
      }

      const resPreferenceRules = await getListAPI(query)

      if (
        resPreferenceRules.data.data &&
        resPreferenceRules.data.data.supplier_preference_rules
      ) {
        const preferenceRulesArr =
          resPreferenceRules.data.data.supplier_preference_rules
        preferenceRulesArr.forEach((element, index) => {
          // eslint-disable-next-line no-param-reassign
          element.draggableId = `${index}`
        })
        setPreferenceRules([...preferenceRulesArr])
      } else {
        setPreferenceRules([])
      }
    } catch (e) {
      context.onApiError(e)
    } finally {
      setLoading(false)
    }
  }

  const fetchApis = async () => {
    setLoading(true)
    try {
      const query = `rows=${rowsPerPage}&page=${page + 1}`
      const resPreferenceRules = await getListAPI(query)
      const resPartNumberClasses = await getPartNumberClassListAPI()
      const resPreferenceRulesSequences = await getSequenceListAPI()

      const sequences = resPreferenceRulesSequences.data.data

      const preferenceRulesArr =
        resPreferenceRules.data.data.supplier_preference_rules
      setTotalRows(resPreferenceRules.data.data.total_rows)
      setSequences(sequences)

      preferenceRulesArr.forEach((element, index) => {
        // eslint-disable-next-line no-param-reassign
        element.draggableId = `${index}`
      })

      setPreferenceRules([...preferenceRulesArr])
      setPartNumberClasses(resPartNumberClasses.data.data)
    } catch (e) {
      context.onApiError(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchApis()
  }, [])

  const renderPartNumberClass = (partNumberClassId) => {
    const partNumberClass = _find(partNumberClasses, { id: partNumberClassId })
    return partNumberClass ? partNumberClass.description : ''
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    fetchRulesList(rowsPerPage, newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    fetchRulesList(parseInt(event.target.value, 10), 0)
    setPage(0)
  }

  const onDragEnd = async (result) => {
    // dropped outside the list
    if (!result.destination || !isDraggable) {
      return
    }

    const items = reorder(
      preferenceRules,
      result.source.index,
      result.destination.index
    )

    const orderArray = items.map((item) => item.id)
    setPreferenceRules(items)

    sequences.splice(page * rowsPerPage, 0, ...orderArray)
    sequences.splice((page + 1) * rowsPerPage, rowsPerPage)

    setSequences(sequences)

    try {
      await updateSequenceAPI(sequences)
    } catch (e) {
      context.onApiError(e)
    }
  }

  const onChangeFilter = (partNumberClass = null, term = null) => {
    setIsDraggable(false)
    fetchRulesList(rowsPerPage, page, partNumberClass.toString(), term)
  }

  const onDeleteSelected = async () => {
    const uuids = selected.toString()

    try {
      await deleteRulesAPI(uuids)
      setSelected([])
      fetchRulesList(rowsPerPage, page)
    } catch (e) {
      context.onApiError(e)
    }
  }

  const emptyRows = preferenceRules
    ? rowsPerPage - Math.min(rowsPerPage, preferenceRules.length)
    : 0

  return (
    <div className={classes.tabContent}>
      <Paper className={classes.paper}>
        {partNumberClasses && (
          <Box display='flex' alignItems='center' justifyContent='flex-end'>
            <FilterBox
              partNumberClasses={partNumberClasses}
              onChangeFilter={onChangeFilter}
            />
          </Box>
        )}

        {loading ? (
          <Box py={5}>
            <LoadingSpinner />
          </Box>
        ) : (
          <Box>
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby='tableTitle'
                aria-label='enhanced table'
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  onSelectAllClick={handleSelectAllClick}
                  rowCount={preferenceRules.length}
                  headCells={headCells}
                />
                <TableBody
                  component={DroppableComponent(onDragEnd, isDraggable)}
                >
                  {preferenceRules
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.uuid)
                      const labelId = `enhanced-table-checkbox-${index}`

                      return (
                        <TableRow
                          component={DraggableComponent(
                            row.draggableId,
                            index,
                            page,
                            rowsPerPage
                          )}
                          hover
                          //
                          role='checkbox'
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.draggableId}
                          selected={isItemSelected}
                        >
                          <TableCell padding='checkbox'>
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ 'aria-labelledby': labelId }}
                              onChange={(event) => handleClick(event, row.uuid)}
                            />
                          </TableCell>
                          <TableCell scope='row'>
                            <Link
                              to={`/admin/supplier_preference_rules/update/${row.uuid}`}
                            >
                              {row.name}
                            </Link>
                          </TableCell>
                          <TableCell>
                            {renderPartNumberClass(row.part_number_class_id)}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100, 250]}
              component='div'
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Box>
        )}
      </Paper>
      <TableActions
        onDelete={onDeleteSelected}
        bulkActionDisabled={selected.length === 0}
      />
    </div>
  )
}

export default SupplierPreferenceRules
