import React from 'react'
import { Link } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import AuthWrapper from '../authWrapper'

const LoginLink = (props) => <Link to="/login" {...props} />

const ForgotPasswordConfirm = () => (
  <AuthWrapper>
    <div noprops="true">
      <Typography variant="body1" align="left" gutterBottom>
        A temporary password has been sent to your email. Please use it to
        login.
      </Typography>

      <div className="card-actions">
        <Button component={LoginLink} variant="contained" color="primary">
          Login
        </Button>
      </div>
    </div>
  </AuthWrapper>
)

export default ForgotPasswordConfirm
