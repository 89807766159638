import React, { useEffect, useContext, createContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Table,
  TableContainer,
  TablePagination,
  Paper,
} from '@material-ui/core'

import { RequisitionListContext } from 'pages/requisitions/list'
import { RfqListContext } from 'pages/rfq/list'

import EnhancedTableToolbar from './tableToolbar'
import EnhancedTableHead from './tableHead'
import TablePaginationActions from './tablePaginationActions'

export const TableContext = createContext()

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),

    '& .MuiTablePagination-toolbar': {
      minHeight: 80,
    },
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  popover: {
    pointerEvents: 'none',
    marginTop: 20,
  },
}))

export default function EnhancedTable({
  tableFor = 'requisition',
  hasCheckbox = true,
  rows,
  headCells,
  onTableViewChange,
  order,
  orderBy,
  selected,
  setOrder,
  setOrderBy,
  setSelected,
  tableSettings,
  onChangeSort,
  renderFilterBox,
  children,
}) {
  const requisitionListContext = useContext(RequisitionListContext)
  const rfqListContext = useContext(RfqListContext)
  const classes = useStyles()

  useEffect(() => {
    if (tableSettings !== {} && tableSettings.sort) {
      setOrder(tableSettings.sort.direction ? 'desc' : 'asc')
      setOrderBy(tableSettings.sort.property)
    }
  }, [])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    onChangeSort({
      direction: isAsc,
      property,
    })
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds =
        tableFor === 'requisition'
          ? rows.map((n) => n.order_id)
          : rows.map((n) => n.id)
      setSelected(newSelecteds)
      if (tableFor === 'requisition') {
        requisitionListContext.onCheckRequisition(newSelecteds)
      } else if (tableFor === 'rfq') {
        rfqListContext.onCheckRfq(newSelecteds)
      }

      return
    }

    setSelected([])
    if (tableFor === 'requisition') {
      requisitionListContext.onCheckRequisition([])
    } else if (tableFor === 'rfq') {
      rfqListContext.onCheckRfq([])
    }
  }

  const handleChangePage = (event, newPage) => {
    onTableViewChange(newPage + 1, tableSettings.tableViewInfo.rowsCount)
  }

  const handleChangeRowsPerPage = (event) => {
    onTableViewChange(1, parseInt(event.target.value, 10))
  }

  return (
    <TableContext.Provider
      value={{ tableFor, hasCheckbox, tableSettings, renderFilterBox }}
    >
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <EnhancedTableToolbar numSelected={selected.length} />
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby='tableTitle'
              aria-label='enhanced table'
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                headCells={headCells}
              />
              {children}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[25, 50, 100, 250]}
            component='div'
            count={tableSettings.tableViewInfo.totalRows}
            rowsPerPage={tableSettings.tableViewInfo.rowsCount}
            page={tableSettings.tableViewInfo.pageIndex - 1}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </Paper>
      </div>
    </TableContext.Provider>
  )
}
