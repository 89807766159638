import styled from 'styled-components'

const TabInventoryContainer = styled.div`
  .inventory {
    position: relative;
    border: 1px solid grey;
    border-radius: 10px;
    padding: 10px 20px;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    .inventory-header {
      display: flex;
      align-items: center;

      h2 {
        margin-right: 15px;
      }
    }

    .inventory-bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
`

export default TabInventoryContainer
