import React, { Component } from 'react'
import Tooltip from '@material-ui/core/Tooltip'

class EditableInput extends Component {
  state = {
    qty: this.props.line.quantity_required,
    error: false,
  }

  componentDidMount() {}

  handleChangeQuantity = (e) => {
    const { line, maxValue } = this.props

    if (
      parseInt(e.target.value, 10) > maxValue ||
      parseInt(e.target.value, 10) <= 0
    ) {
      this.setState({
        error: true,
      })
    } else {
      this.setState({
        qty: e.target.value,
        error: false,
      })

      this.props.onChange({ ...line, quantity_required: e.target.value })
    }
  }

  handleTooltipClose = () => {
    this.setState({
      error: false,
    })
  }

  render() {
    const { qty, error } = this.state

    return (
      <Tooltip
        open={error}
        title="Quantity should be less than remaining quantity and bigger than 0"
        onClose={this.handleTooltipClose}
      >
        <input
          type="number"
          value={qty}
          className={`input-quantity ${error && 'error'}`}
          onChange={this.handleChangeQuantity}
        />
      </Tooltip>
    )
  }
}

export default EditableInput
