import React from 'react'
import { Button, DialogTitle, Dialog, DialogActions } from '@material-ui/core'

const FinalizeConfirmDialog = (props) => {
  const { onClose, open, rfqId } = props

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby='finalize-confirm-dialog-title'
      open={open}
    >
      <DialogTitle id='finalize-confirm-dialog-title'>
        Congratulations! You have finalized the quote for RFQ {rfqId}
      </DialogTitle>
      <DialogActions>
        <Button onClick={handleClose} color='secondary' variant='contained'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default FinalizeConfirmDialog
