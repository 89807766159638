import React, { useEffect, useState, useContext } from 'react'
import ReactTable from 'react-table'
import { Link } from 'react-router-dom'
import {
  Input,
  InputLabel,
  InputAdornment,
  FormControl,
  Popover,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Box,
} from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error'
import Search from '@material-ui/icons/Search'
import { makeStyles } from '@material-ui/core/styles'

import FilterBoxContainer from 'components/listTableFilterBox/index.style'
import {
  fetchSuppliersAPI,
  searchSupplierAPI,
} from 'services/helpers/apis/supplier'
import { GlobalContext } from 'routes'
import TableActions from './tableActions'

const WAIT_INTERVAL = 1000
let timer

const useStyles = makeStyles(() => ({
  content: {
    marginBottom: 80,
  },
}))

const SupplierList = () => {
  const [supplierList, setSupplierList] = useState(null)
  const [fullSupplierList, setFullSupplierList] = useState(null)
  const [searchValue, setSearchValue] = useState('')
  const [anchorEl, setAnchorEl] = useState(null)
  const [searchResults, setSearchResults] = useState([])
  const context = useContext(GlobalContext)
  const classes = useStyles()

  const openSuggestions = (currentTarget) => {
    setAnchorEl(currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const fetchSupplierList = async () => {
    try {
      const res = await fetchSuppliersAPI()
      setSupplierList(res.data.data)
      setFullSupplierList(res.data.data)
    } catch (e) {
      context.onApiError(e)
    }
  }

  useEffect(() => {
    fetchSupplierList()
  }, [])

  useEffect(() => {
    timer = null
  }, [])

  const onSearchChange = (e) => {
    const term = e.target.value
    const { currentTarget } = e
    clearTimeout(timer)
    setSearchValue(term)
    timer = setTimeout(async () => {
      if (term === '' && fullSupplierList) {
        setSupplierList(fullSupplierList)
      } else {
        try {
          const res = await searchSupplierAPI(term)
          setSearchResults(res.data.data)
          openSuggestions(currentTarget)
        } catch (e) {
          context.onApiError(e)
        }
      }
    }, WAIT_INTERVAL)
  }

  const onSelectTerm = (termIndex) => {
    setSupplierList([searchResults[termIndex].supplier])
  }

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: (props) => (
        <Link to={`/admin/supplier/update/${props.original.uuid}`}>
          {props.value}
        </Link>
      ),
    },
    {
      Header: 'City/State',
      accessor: 'city',
      Cell: (props) => (
        <span>
          {props.value}, {props.original.state}
        </span>
      ),
    },
  ]

  return (
    <div className={classes.content}>
      {supplierList && (
        <div>
          <FilterBoxContainer>
            <FormControl>
              <InputLabel htmlFor='input-with-icon-adornment'>
                Search
              </InputLabel>
              <Input
                aria-describedby={id}
                id='input-with-icon-adornment'
                startAdornment={
                  <InputAdornment position='start'>
                    <Search />
                  </InputAdornment>
                }
                value={searchValue}
                onChange={onSearchChange}
              />
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <List
                  component='nav'
                  aria-label='supplier terms'
                  style={{ width: 300 }}
                >
                  {searchResults.length > 0 ? (
                    searchResults.map((result, i) => (
                      <ListItem button key={i} onClick={() => onSelectTerm(i)}>
                        <ListItemText primary={result.search_term} />
                      </ListItem>
                    ))
                  ) : (
                    <ListItem>
                      <ListItemIcon>
                        <ErrorIcon color='secondary' />
                      </ListItemIcon>
                      <ListItemText primary='No terms' />
                    </ListItem>
                  )}
                </List>
              </Popover>
            </FormControl>
          </FilterBoxContainer>
          <ReactTable data={supplierList} columns={columns}></ReactTable>
        </div>
      )}
      <TableActions />
    </div>
  )
}

export default SupplierList
