import React, { Component } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import { Button } from '@material-ui/core'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import ReplyIcon from '@material-ui/icons/Reply'
import NotesIcon from '@material-ui/icons/Notes'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _isEqual from 'lodash/isEqual'
import { toast } from 'react-toastify'

import { createThreadDraftAPI } from 'services/helpers/apis/thread'
import { getFileUrl } from 'services/helpers/apis/file'
import adminActions from 'services/redux/admin/actions'
import rfqActions from 'services/redux/rfq/actions'

import MessageBlock from './messageBlock'
import MessageContainer from './message.style'
import ReplyMessage from './replyMessage'
import DraftMessage from './draftMessage'
import FileViewerModal from '../../fileViewerModal'

const { onApiError, unsetCorrespondenceMessageUUID } = adminActions
const { saveAsQuote } = rfqActions

class ReadMessage extends Component {
  state = {
    replying: false,
    replyType: null,
    replyMessageUUID: null,
    correspondence: this.props.correspondence,
    isEditDraft: false,
    draftMessage: null,
    attachmentToOpen: null,
    fileViewModalOpen: false,
    selectedMessageIndex: null,
  }

  componentDidMount() {}

  onReply = async (replyType) => {
    this.closeDraft()

    try {
      const res = await this.createThreadDraft(replyType)
      toast.success('Reply message draft is created.')

      this.setState({
        replying: true,
        replyType,
        replyMessageUUID: res.data.data.uuid,
      })
    } catch (e) {
      onApiError(e)
    }
  }

  closeReply = () => {
    this.setState({
      replying: false,
      replyType: null,
      replyMessageUUID: null,
    })
  }

  openDraft = (message, messageIndex) => {
    this.setState(
      {
        isEditDraft: false,
        draftMessage: null,
      },
      () => {
        this.setState({
          isEditDraft: true,
          draftMessage: message,
          replying: false,
          replyType: null,
          replyMessageUUID: null,
          selectedMessageIndex: messageIndex,
        })
      }
    )
  }

  closeDraft = () => {
    this.setState({
      isEditDraft: false,
      draftMessage: null,
    })
  }

  createThreadDraft = (replyType) => {
    const { correspondence } = this.state
    const { messages } = correspondence

    const { subject } = correspondence.thread
    const isInternal =
      replyType === 'internal_note' ? true : messages[0].is_internal
    const replyTo = messages[0].uuid
    const toEmail = messages[0].to_email
    const authorId = messages[0].author_id
    return createThreadDraftAPI(
      isInternal,
      subject,
      replyTo,
      '',
      toEmail,
      authorId
    )
  }

  onSaveAsQuote = () => {
    const { correspondence } = this.state
    this.props.saveAsQuote(correspondence)
    toast.info('Saved as a quote.')
    this.props.onClose()
    this.props.onSavedQuote()
  }

  onClickAttachment = async (attachment) => {
    try {
      const res = await getFileUrl(attachment.uuid)
      const fileUrl = res.data.data.url
      const attachmentObj = {
        ...attachment,
        fileUrl,
      }
      this.setState({
        attachmentToOpen: attachmentObj,
      })

      if (
        attachment.mime_type === 'application/pdf' ||
        attachment.mime_type === 'image/jpeg' ||
        attachment.mime_type === 'image/png'
      ) {
        this.setState({
          fileViewModalOpen: true,
        })
      } else {
        window.open(fileUrl)
      }
    } catch (e) {
      console.log(e)
    }
  }

  onCloseFileViewModal = () => {
    this.setState({
      attachmentToOpen: null,
      fileViewModalOpen: false,
    })
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.correspondence.thread.id !==
        this.props.correspondence.thread.id ||
      !_isEqual(
        nextProps.correspondence.messages,
        this.props.correspondence.messages
      )
    ) {
      this.setState({
        correspondence: nextProps.correspondence,
      })
    }
  }

  render() {
    const { selectedLine, onCorrespondenceUpdated } = this.props
    const {
      replying,
      replyType,
      replyMessageUUID,
      correspondence,
      isEditDraft,
      draftMessage,
      attachmentToOpen,
      fileViewModalOpen,
      selectedMessageIndex,
    } = this.state

    return (
      <MessageContainer>
        <div className='box-header'>
          <div className='message-title'>{correspondence.thread.subject}</div>
          <div className='box-actions'>
            <span
              role='button'
              tabIndex='0'
              onKeyPress={() => {}}
              onClick={this.props.onClose}
            >
              <CloseIcon />
            </span>
          </div>
        </div>
        <div className='message-modal-content read-message-content'>
          <div className='messages'>
            {correspondence.messages &&
              correspondence.messages.map((message, i) => (
                <MessageBlock
                  key={i}
                  message={message}
                  messageIndex={i}
                  selectedLine={selectedLine}
                  onClickAttachment={this.onClickAttachment}
                  openDraft={this.openDraft}
                  closeDraft={this.closeDraft}
                />
              ))}
          </div>
          {replying ? (
            <ReplyMessage
              correspondence={correspondence}
              selectedLine={selectedLine}
              replyType={replyType}
              onCorrespondenceUpdated={onCorrespondenceUpdated}
              messageUUID={replyMessageUUID}
              closeReply={this.closeReply}
            />
          ) : (
            <div className='message-actions'>
              <Button variant='outlined' onClick={this.onSaveAsQuote}>
                <OpenInNewIcon />
                &nbsp;Convert to Quote
              </Button>
              <Button variant='outlined' onClick={() => this.onReply('reply')}>
                <ReplyIcon />
                &nbsp;Reply
              </Button>
              <Button
                variant='outlined'
                onClick={() => this.onReply('internal_note')}
              >
                <NotesIcon />
                &nbsp;Internal Note
              </Button>
            </div>
          )}
          {isEditDraft && (
            <DraftMessage
              message={draftMessage}
              selectedMessageIndex={selectedMessageIndex}
              selectedLine={selectedLine}
              onCorrespondenceUpdated={onCorrespondenceUpdated}
              closeDraft={this.closeDraft}
            />
          )}
        </div>
        <FileViewerModal
          open={fileViewModalOpen}
          attachment={attachmentToOpen}
          onClose={this.onCloseFileViewModal}
        />
      </MessageContainer>
    )
  }
}

const mapStateToProps = (state) => ({
  selectedMessageUUID: state.admin.correspondence_messsage_uuid,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onApiError,
      saveAsQuote,
      unsetCorrespondenceMessageUUID,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(ReadMessage)
