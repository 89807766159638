import React, { useState, useEffect, useContext } from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import AppBar from '@material-ui/core/AppBar'
import Icon from '@material-ui/core/Icon'
import { toast } from 'react-toastify'

import { GlobalContext } from 'routes'
import TabContainer, { a11yProps } from 'components/tabContainer'
import { readShipmentAPI } from 'services/helpers/apis/other'

import { Overview, Tracking, Contents } from './components'
import ShipmentReadContainer from './index.style'

const ShipmentRead = ({ match, history }) => {
  const [tabValue, setTabValue] = useState(0)
  const [shipmentData, setShipmentData] = useState(null)
  const context = useContext(GlobalContext)

  useEffect(() => {
    const { trackingNumber, tabName } = match.params
    const fetchApis = async () => {
      try {
        const res = await readShipmentAPI(trackingNumber)
        setShipmentData(res.data.data)

        if (tabName) {
          switch (tabName) {
            case 'overview':
              setTabValue(0)
              break
            case 'tracking':
              setTabValue(1)
              break
            case 'contents':
              setTabValue(2)
              break
            default:
              break
          }
        }

        toast.success('Shipment data is loaded successfully')
      } catch (err) {
        context.onApiError(err)
      }
    }

    fetchApis()
  }, [])

  const handleTabChange = (event, newValue) => {
    const { trackingNumber } = match.params

    switch (newValue) {
      case 0:
        history.push(`/shipment/read/${trackingNumber}/overview`)
        break
      case 1:
        history.push(`/shipment/read/${trackingNumber}/tracking`)
        break
      case 2:
        history.push(`/shipment/read/${trackingNumber}/contents`)
        break
      default:
        break
    }
    setTabValue(newValue)
  }

  return (
    <ShipmentReadContainer>
      {shipmentData && (
        <div className='shipment-information'>
          <div className='status'>
            <p style={{ backgroundColor: `#${shipmentData.status.color}` }}>
              <Icon>{shipmentData.status.icon}</Icon>
              {shipmentData.status.standardized_term}
            </p>
          </div>
          <AppBar position='static' color='default'>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label='Shipment Tabs'
            >
              <Tab label='Overview' {...a11yProps(0)} />
              <Tab label='Tracking' {...a11yProps(1)} />
              <Tab label='Contents' {...a11yProps(2)} />
            </Tabs>
          </AppBar>
          {tabValue === 0 && (
            <TabContainer>
              <Overview
                data={shipmentData}
                trackingNumber={match.params.trackingNumber}
              />
            </TabContainer>
          )}
          {tabValue === 1 && (
            <TabContainer>
              <Tracking data={shipmentData} />
            </TabContainer>
          )}
          {tabValue === 2 && (
            <TabContainer>
              <Contents data={shipmentData} />
            </TabContainer>
          )}
        </div>
      )}
    </ShipmentReadContainer>
  )
}

export default ShipmentRead
