import React from 'react'
import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'

const SpinnerContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 50px 0;

  p {
    margin-left: 10px;
  }
`

const LoadingSpinner = () => {
  return (
    <SpinnerContainer>
      <CircularProgress />
      <p>Loading...</p>
    </SpinnerContainer>
  )
}

export default LoadingSpinner
