import React, { Component } from 'react'

import LineTable from './lineTable'

class Contents extends Component {
  state = {
    lineItemNames: [],
    totalPieces: 0,
  }

  componentDidMount() {
    this.setLineItems(this.props.data.lines)
  }

  setLineItems = (lines) => {
    const propertyNames = Object.getOwnPropertyNames(lines)

    propertyNames.map((name) => {
      this.setState((prevState) => ({
        lineItemNames: prevState.lineItemNames.concat(name),
      }))

      return name
    })
  }

  setTotalPieces = (lineQuantity) => {
    this.setState((prevState) => ({
      totalPieces: prevState.totalPieces + lineQuantity,
    }))
  }

  render() {
    const { lineItemNames, totalPieces } = this.state
    const { lines } = this.props.data

    return (
      <div className="tab-content">
        {lineItemNames.length > 0 &&
          lineItemNames.map((name, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <LineTable
              key={index}
              name={name}
              data={lines[name]}
              setTotalPieces={this.setTotalPieces}
            />
          ))}
        <p className="total-pieces">Total Pieces: {totalPieces}</p>
      </div>
    )
  }
}

export default Contents
