import React, { useState, useEffect, useContext, createContext } from 'react'
import {
  DialogContent,
  Tabs,
  Tab,
  AppBar,
  IconButton,
  Icon,
  Box,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { ChevronRight } from '@material-ui/icons'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import _find from 'lodash/find'

import { GlobalContext } from 'routes'
import { client } from 'services/helpers/apiConfig'
import { getPoHistoryAPI, readPoAPI } from 'services/helpers/apis/po'
import purchaseOrderActions from 'services/redux/purchaseOrder/actions'

import AssignDropdown from 'components/assignDropdown'
import TimelineSlider from 'components/timelineSlider'
import StyledButton from 'components/common/button'
import PurchaseConfirmModal from 'components/purchaseConfirmationModal'
import TabContainer, { a11yProps } from 'components/tabContainer'
import PurchaseOrderHistory from 'components/purchaseOrderHistory'
import CorrespondenceTable from 'components/correspondenceTable'
import StatusDropdown from 'components/modalStatusDropdown'
import LoadingSpinner from 'components/common/loadingSpinner'
import SupplierPopover from 'components/supplierPopover'

import { PoListContext } from '../list'
import LineItems from './lineItems'
import Shipments from './shipments'
import OrderModalContainer from './index.style'

const { setPoData, onPoSendConfirmed } = purchaseOrderActions

export const ModalContext = createContext()

const OrderModalContent = ({
  selectedPoIndex,
  selectedTab,
  selectedPo,
  userList,
  sentPoId,
  setPoData,
  updatePoStatus,
  onClose,
  onPoSendConfirmed,
  history,
}) => {
  const context = useContext(GlobalContext)
  const poListContext = useContext(PoListContext)
  const [orderData, setOrderData] = useState(null)
  const [tabValue, setTabValue] = useState(0)
  const [showSendPurchaseModal, setShowSendPurchaseModal] = useState(false)
  const [purchaseMessageTemplate, SetPurchaseMessageTemplate] = useState('')
  const [poHistory, setPoHistory] = useState(null)
  const [loadingPoStatuses, setLoadingPoStatuses] = useState(false)
  const [isStatusUpdated, setIsStatusUpdated] = useState(false)
  const [supplier, setSupplier] = useState(null)
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const fetchPoApis = async (statusUpdated = false) => {
    setLoadingPoStatuses(true)

    try {
      const getPoData = await readPoAPI(selectedPo.uuid)
      const getPoHistory = await getPoHistoryAPI(selectedPo.uuid)
      const selectedSupplier = _find(poListContext.suppliersList, {
        id: getPoData.data.data.supplier_id,
      })

      setSupplier(selectedSupplier)
      setPoData(getPoData.data.data)
      setOrderData(getPoData.data.data)
      setPoHistory(getPoHistory.data.data.history)
      if (statusUpdated) {
        setIsStatusUpdated(true)
      }
    } catch (e) {
      context.onApiError(e)
    } finally {
      setLoadingPoStatuses(false)
    }
  }

  const updateOrderData = (newOrderData) => {
    setOrderData(newOrderData)
  }

  useEffect(() => {
    fetchPoApis()
    switch (selectedTab) {
      case 'lines':
        setTabValue(0)
        break
      case 'correspondence':
        setTabValue(1)
        break
      case 'activity':
        setTabValue(2)
        break
      case 'shipments':
        setTabValue(3)
        break
      default:
        break
    }
  }, [])

  useEffect(() => {
    if (sentPoId) {
      fetchPoApis()
      onPoSendConfirmed()
    }
  }, sentPoId)

  const handleClose = () => {
    setOrderData(null)
    setTabValue(0)
    onClose()
  }

  const handleTabChange = (event, newValue) => {
    switch (newValue) {
      case 0:
        history.push(`/po/${selectedPo.id}/lines`)
        break
      case 1:
        history.push(`/po/${selectedPo.id}/correspondence`)
        break
      case 2:
        history.push(`/po/${selectedPo.id}/activity`)
        break
      case 3:
        history.push(`/po/${selectedPo.id}/shipments`)
        break
      default:
        break
    }
    setTabValue(newValue)
  }

  const onSendPurchase = async () => {
    try {
      const res = await client.get(
        `po/message_template?po_id=${parseInt(orderData.id, 10)}`
      )

      setShowSendPurchaseModal(true)
      SetPurchaseMessageTemplate(res.data.data)
    } catch (e) {
      context.onApiError(e)
    }
  }

  const toggleSendPurchaseModal = (visible) => {
    setShowSendPurchaseModal(visible)
  }

  const onSuccessConfirmation = (poInfo) => {
    setOrderData({
      ...orderData,
      sendPO: poInfo.sendPo,
      status: poInfo.status,
    })

    updatePoStatus(selectedPoIndex, poInfo.status)
  }

  const onStatusUpdated = () => {
    fetchPoApis(true)
  }

  const resetIsStatusUpdated = () => {
    setIsStatusUpdated(false)
  }

  return (
    <ModalContext.Provider
      value={{ orderData, updateOrderData, onStatusUpdated, fetchPoApis }}
    >
      <DialogContent>
        {orderData && poHistory ? (
          <OrderModalContainer>
            <div className='title'>
              <Box
                display='flex'
                alignItems='center'
                justifyContent='space-between'
              >
                <Box display='flex' alignItems='center'>
                  <h3>Purchase Order {orderData.id} </h3>
                  <AssignDropdown
                    userList={userList}
                    selected={selectedPo}
                    permissions={selectedPo.permissions}
                    componentType='menu'
                    updateFor='po'
                  />
                </Box>
                <Box display='flex' alignItems='center'>
                  {supplier && (
                    <>
                      <div
                        className='supplier-label'
                        aria-owns={open ? 'mouse-over-popover' : undefined}
                        aria-haspopup='true'
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={handlePopoverClose}
                      >
                        {orderData.supplier}
                      </div>
                      <SupplierPopover
                        open={open}
                        anchorEl={anchorEl}
                        handlePopoverClose={handlePopoverClose}
                        supplier={supplier}
                      />
                    </>
                  )}

                  <StatusDropdown
                    statuses={orderData.status_list}
                    statusId={orderData.status_id}
                    uuid={orderData.uuid}
                    onStatusUpdated={onStatusUpdated}
                    loadingStatuses={loadingPoStatuses}
                    statusFor='po'
                  />
                  <IconButton
                    className='btn-close'
                    aria-label='Close'
                    onClick={handleClose}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>
            </div>
            <span className='status'></span>
            <TimelineSlider timeline={orderData.timeline} />
            <div className='detail-tabs'>
              <AppBar position='static' color='default'>
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  aria-label='Simple tabs example'
                >
                  <Tab label='Line Items' {...a11yProps(0)} />
                  <Tab label='Correspondence' {...a11yProps(1)} />
                  <Tab label='History' {...a11yProps(2)} />
                  <Tab label='Shipments' {...a11yProps(3)} />
                </Tabs>
              </AppBar>
              {tabValue === 0 && (
                <TabContainer>
                  <LineItems />
                </TabContainer>
              )}
              {tabValue === 1 && (
                <TabContainer>
                  <CorrespondenceTable correspondenceFor='po' />
                </TabContainer>
              )}
              {tabValue === 2 && (
                <TabContainer>
                  <PurchaseOrderHistory historyData={poHistory} />
                </TabContainer>
              )}
              {tabValue === 3 && (
                <TabContainer>
                  <Shipments
                    uuid={selectedPo.uuid}
                    onStatusUpdated={onStatusUpdated}
                    isStatusUpdated={isStatusUpdated}
                    resetIsStatusUpdated={resetIsStatusUpdated}
                  />
                </TabContainer>
              )}
            </div>
            {orderData.sendPO && (
              <div style={{ textAlign: 'right' }}>
                <StyledButton className='btn-orange' onClick={onSendPurchase}>
                  Send{' '}
                  <Icon>
                    <ChevronRight />
                  </Icon>
                </StyledButton>
              </div>
            )}
            {purchaseMessageTemplate !== '' && (
              <PurchaseConfirmModal
                content={purchaseMessageTemplate}
                open={showSendPurchaseModal}
                onPoModal
                onSuccessConfirmation={onSuccessConfirmation}
                onClose={() => toggleSendPurchaseModal(false)}
                poId={orderData.id}
                onApiError={context.onApiError}
              />
            )}
          </OrderModalContainer>
        ) : (
          <LoadingSpinner />
        )}
      </DialogContent>
    </ModalContext.Provider>
  )
}

const mapStateToProps = (state) => ({
  sentPoId: state.purchaseOrder.sent_po_id,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setPoData,
      onPoSendConfirmed,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OrderModalContent))
