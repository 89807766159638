import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import Button from '@material-ui/core/Button'

import { ReduxTextField } from '../../../../../components/reduxFormFields'
import UpdateContentContainer from './index.style'

class ProfileForm extends Component {
  componentDidMount() {}

  render() {
    const { handleSubmit, pristine } = this.props

    return (
      <UpdateContentContainer>
        <form onSubmit={handleSubmit}>
          <Field
            name="first_name"
            label="First Name"
            component={ReduxTextField}
            fullWidth
          />
          <Field
            name="last_name"
            label="Last Name"
            component={ReduxTextField}
            fullWidth
          />
          <Field
            name="email"
            label="Email"
            component={ReduxTextField}
            fullWidth
            type="email"
          />
          <Field
            name="phone"
            label="Phone"
            component={ReduxTextField}
            fullWidth
            type="tel"
          />
          <div className="form-action">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="btn-submit"
              disabled={pristine}
            >
              Update
            </Button>
          </div>
        </form>
      </UpdateContentContainer>
    )
  }
}

const validate = (values) => {
  const errors = {}

  if (!values.email) {
    errors.email = 'Required'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address'
  }

  return errors
}

const ProfileReduxForm = reduxForm({
  form: 'profileForm',
  enableReinitialize: true,
  validate,
})(ProfileForm)

export default ProfileReduxForm
