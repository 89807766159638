import React, { useState, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  Button,
  Box,
  Link,
  Tooltip,
  Popover,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { RequisitionListContext } from 'pages/requisitions/list'
import BarChart from 'components/barChart'
import AssignDropdown from 'components/assignDropdown'
import ListTableFilterBox from 'components/listTableFilterBox'
import storageActions from 'services/redux/storage/actions'

import EnhancedTable from './table'
import QuantityChart from './quantityChart'
import StatusLabel from './statusLabel'
import { getComparator, stableSort } from './utils'
import { PopoverContent } from './style'
import RequisitionQuickGlance from './requisitionQuickGlance'

const { setRequisitionTableSettings } = storageActions
const headCells = [
  {
    id: 'order_id',
    label: 'Requisition ID',
  },
  {
    id: 'date.timestamp',
    label: 'Date',
  },
  {
    id: 'part_numbers',
    label: 'Part Number',
    width: 300,
  },
  {
    id: 'quick_glance',
    label: '',
  },
  {
    id: 'bar_chart',
    label: 'Qty Required',
    width: 400,
  },
  { id: 'status', label: 'Status' },
  {
    id: 'assignee.name',
    label: 'AssignedTo',
  },
]

const useStyles = makeStyles(() => ({
  popover: {
    pointerEvents: 'none',
    marginTop: 20,
  },
}))

const RequisitionTable = ({
  quantitiesColors,
  openRequisitionModal,
  onFilterChange,
  onTableViewChange,
}) => {
  const requisitionListContext = useContext(RequisitionListContext)
  const classes = useStyles()
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('')
  const [selected, setSelected] = useState([])
  const [anchorEl, setAnchorEl] = useState(null)
  const [popoverId, setPopoverId] = useState(null)

  const dispatch = useDispatch()
  const requisitionTableSettings = useSelector(
    (state) => state.storage.requisitionTableSettings
  )
  const isSelected = (id) => selected.indexOf(id) !== -1
  const emptyRows =
    requisitionTableSettings.tableViewInfo.rowsCount -
    Math.min(
      requisitionTableSettings.tableViewInfo.rowsCount,
      requisitionListContext.rows.length
    )

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)
    requisitionListContext.onCheckRequisition(newSelected)
  }

  const handlePopoverOpen = (event, rowId) => {
    setPopoverId(rowId)
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
    setPopoverId(null)
  }

  const onChangeSort = (sort) => {
    dispatch(
      setRequisitionTableSettings({
        sort,
      })
    )
  }

  const renderFilterBox = () => {
    return (
      <ListTableFilterBox
        userList={requisitionListContext.userList}
        statusList={requisitionListContext.statusList}
        filterUsers={requisitionTableSettings.filter.users}
        filterStatuses={requisitionTableSettings.filter.statuses}
        handleChange={onFilterChange}
      />
    )
  }

  const open = Boolean(anchorEl)

  return (
    <EnhancedTable
      tableFor='requisition'
      headCells={headCells}
      rows={requisitionListContext.rows}
      onTableViewChange={onTableViewChange}
      order={order}
      orderBy={orderBy}
      selected={selected}
      setOrder={setOrder}
      setOrderBy={setOrderBy}
      setSelected={setSelected}
      onChangeSort={onChangeSort}
      tableSettings={requisitionTableSettings}
      renderFilterBox={renderFilterBox}
    >
      <TableBody>
        {stableSort(
          requisitionListContext.rows,
          getComparator(order, orderBy)
        ).map((row, index) => {
          const isItemSelected = isSelected(row.order_id)
          const labelId = `enhanced-table-checkbox-${index}`

          return (
            <TableRow
              hover
              role='checkbox'
              aria-checked={isItemSelected}
              tabIndex={-1}
              key={row.order_id}
              selected={isItemSelected}
            >
              <TableCell padding='checkbox'>
                <Checkbox
                  checked={isItemSelected}
                  onChange={(event) => handleClick(event, row.order_id)}
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </TableCell>
              <TableCell component='th' id={labelId} scope='row'>
                <Link
                  component='button'
                  variant='body2'
                  onClick={() => openRequisitionModal(row)}
                >
                  {row.order_id}
                  {row.expedite && '🔥'}
                </Link>
              </TableCell>
              <TableCell>
                <Tooltip title={row.date.formatted_date}>
                  <span>{row.date.human_date.relative.long}</span>
                </Tooltip>
              </TableCell>
              <TableCell>
                {row.part_numbers[0]}{' '}
                {row.part_numbers.length > 1 && (
                  <Tooltip title={row.part_numbers.join(', ')}>
                    <Button color='primary'>
                      {row.part_numbers.length - 1} more...
                    </Button>
                  </Tooltip>
                )}
              </TableCell>
              <TableCell>
                <RequisitionQuickGlance row={row} />
              </TableCell>
              <TableCell>
                <Box
                  aria-owns={open ? `mouse-over-popover-${row.id}` : undefined}
                  aria-haspopup='true'
                  onMouseEnter={(e) => handlePopoverOpen(e, row.id)}
                  onMouseLeave={handlePopoverClose}
                >
                  <BarChart chartData={row.bar_chart} />
                </Box>
                <Popover
                  id={`mouse-over-popover-${row.id}`}
                  className={classes.popover}
                  open={popoverId === row.id}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  onClose={handlePopoverClose}
                  disableRestoreFocus
                >
                  <PopoverContent>
                    <QuantityChart
                      requisition={row}
                      quantitiesColors={quantitiesColors}
                    />
                  </PopoverContent>
                </Popover>
              </TableCell>
              <TableCell>
                <StatusLabel
                  statusList={requisitionListContext.statusList}
                  statusId={row.status_id}
                >
                  {row.status}
                </StatusLabel>
              </TableCell>
              <TableCell>
                <AssignDropdown
                  userList={requisitionListContext.userList}
                  selected={row}
                  permissions={row.permissions}
                  updateFor='requisition'
                />
              </TableCell>
            </TableRow>
          )
        })}
        {emptyRows > 0 && (
          <TableRow style={{ height: 53 * emptyRows }}>
            <TableCell colSpan={7} />
          </TableRow>
        )}
      </TableBody>
    </EnhancedTable>
  )
}

export default RequisitionTable
