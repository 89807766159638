import moment from 'moment'
import { client } from '../apiConfig'

const fetchShipmentListAPI = (viewInfo, filterValues = null) => {
  let apiUrl = `/shipment/list?page=${viewInfo.pageIndex}&rows=${viewInfo.rowsCount}`

  if (filterValues) {
    apiUrl = `${apiUrl}&status=${filterValues.statuses.join()}`
    if (filterValues.startDate || filterValues.endDate) {
      const startDateStr = moment(filterValues.startDate).format('YYYY-MM-DD')
      const endDateStr = moment(filterValues.endDate).format('YYYY-MM-DD')
      apiUrl = `${apiUrl}&after=${startDateStr}&before=${endDateStr}`
    }
  }

  return client.get(apiUrl)
}

const fetchShipmentStatusListAPI = () => {
  return client.get('/shipment/status/list')
}

const createShipmentAPI = (shipmentData) => {
  return client.post('/shipment/create', shipmentData)
}

const fetchShipmentExpeditingListAPI = () => {
  return client.get('/shipment/expediting/list')
}

export {
  fetchShipmentListAPI,
  fetchShipmentStatusListAPI,
  createShipmentAPI,
  fetchShipmentExpeditingListAPI,
}
