import styled from 'styled-components'

const OrderModalContainer = styled.div`
  position: relative;
  min-height: 300px;

  &.loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title {
    margin-bottom: ${(props) => props.theme.spacing()}px;
    h3 {
      margin-right: 20px;
    }
  }

  span.status {
    position: absolute;
    right: 30px;
    top: 0px;
    color: white;
  }

  .detail-tabs {
    margin-top: 50px;
  }

  .supplier-label {
    &:hover {
      cursor: pointer;
    }
  }
`

export default OrderModalContainer
