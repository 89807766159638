import React, { Component } from 'react'
import { Switch, Route } from 'react-router'

import ShipmentList from './list'
import ShipmentRead from './read'

class Shipment extends Component {
  componentDidMount() {}

  render() {
    const routeURL = this.props.match.url

    return (
      <div className='wrapper'>
        <Switch>
          <Route exact path={`${routeURL}/list`} component={ShipmentList} />
          <Route
            exact
            path={[
              `${routeURL}/read/:trackingNumber`,
              `${routeURL}/read/:trackingNumber/:tabName`,
            ]}
            component={ShipmentRead}
          />
        </Switch>
      </div>
    )
  }
}

export default Shipment
