import React, { useState, useContext } from 'react'
import { useDispatch } from 'react-redux'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import styled from 'styled-components'
import { toast } from 'react-toastify'

import rfqActions from 'services/redux/rfq/actions'
import purchaseOrderActions from 'services/redux/purchaseOrder/actions'
import { confirmPoAPI } from 'services/helpers/apis/po'
import { confirmRfqAPI } from 'services/helpers/apis/rfq'
import { GlobalContext } from 'routes'

const { onRfqSend } = rfqActions
const { onPoSend } = purchaseOrderActions

const TextAreaField = styled.textarea`
  width: 100%;
  box-sizing: border-box;
`

const ModalContainer = styled.div`
  min-width: 500px;
`

const SendMessageTemplateModal = ({
  message,
  id,
  onCloseDialog,
  modalFor = 'rfq',
}) => {
  const dispatch = useDispatch()
  const context = useContext(GlobalContext)
  const [messageTemplate, setMessageTemplate] = useState(message)

  const onSendMessage = async () => {
    try {
      let res
      if (modalFor === 'rfq') {
        res = await confirmRfqAPI(id, messageTemplate)
        dispatch(onRfqSend(id))
      } else {
        res = await confirmPoAPI(id, messageTemplate)
        dispatch(onPoSend(id))
      }

      toast.success(
        `${modalFor === 'rfq' ? 'RFQ' : 'PO'} ${
          modalFor === 'rfq' ? res.data.data.rfq_id : res.data.data.po_id
        } Sent to ${res.data.data.supplier}`
      )
      onCloseDialog()
    } catch (e) {
      context.onApiError(e)
    }
  }

  const onCancel = async () => {
    onCloseDialog()
  }

  const onMessageUpdate = (e) => {
    setMessageTemplate(e.target.value)
  }

  return (
    <ModalContainer>
      <DialogContent>
        <TextAreaField
          rows='10'
          value={messageTemplate}
          onChange={onMessageUpdate}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={onSendMessage}>Send</Button>
      </DialogActions>
    </ModalContainer>
  )
}

export default SendMessageTemplateModal
