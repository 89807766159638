import React from 'react'
import { Field, reduxForm } from 'redux-form'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import { ReduxSelectField } from 'components/reduxFormFields'

let ReduxForm = (props) => {
  const { handleSubmit } = props

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Field
            name="rfq_contact_method"
            component={ReduxSelectField}
            label="RFQ Contact Method"
            fullWidth
          >
            <option value="" disabled />
            <option value="email">Email</option>
            <option value="phone">Phone</option>
          </Field>
        </Grid>
        <Grid item xs={12}>
          <Field
            name="default_shipping_method"
            component={ReduxSelectField}
            label="Default Shipping Method"
            fullWidth
          >
            <option value="" disabled />
            <option value="email">Email</option>
            <option value="phone">Phone</option>
          </Field>
        </Grid>

        <Grid item xs={12} className="grid-item-justify-right">
          <Button variant="contained" color="primary" type="submit">
            Update
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

ReduxForm = reduxForm({
  form: 'settings_form',
})(ReduxForm)

const SettingsForm = ({ initialValues }) => {
  const submit = (values) => {
    console.log(values)
  }

  return <ReduxForm onSubmit={submit} initialValues={initialValues} />
}

export default SettingsForm
