import React, { Component } from 'react'
import Chart from 'react-apexcharts'

class DonutChart extends Component {
  state = {
    options: {
      chart: {
        width: '100%',
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      labels: this.props.chartData.labels,
      colors: this.props.chartData.colors,
    },
  }

  componentDidMount() {}

  render() {
    const { options } = this.state
    const { chartData } = this.props

    return (
      <div id="chart">
        <Chart options={options} series={chartData.series} type="donut" />
      </div>
    )
  }
}

export default DonutChart
