import React from 'react'
import { Field } from 'redux-form'
import styled from 'styled-components'

import { ReduxTextField, ReduxCheckBoxField } from 'components/reduxFormFields'

const SecurityFormContainer = styled.div`
  span.separator {
    display: block;
    height: 1px;
    background: lightgrey;
  }
`

const SecurityForm = () => {
  return (
    <SecurityFormContainer>
      <Field
        name="password"
        label="Password"
        component={ReduxTextField}
        fullWidth
        type="password"
      />
      <Field
        name="forceChangePassword"
        label="Force User to Change Password"
        component={ReduxCheckBoxField}
        fullWidth
      />
      <Field
        name="emailUserNewPassword"
        label="Email user new password"
        component={ReduxCheckBoxField}
        fullWidth
      />
      <span className="separator" />
      <Field
        name="requireMFA"
        label="Require MFA"
        component={ReduxCheckBoxField}
        fullWidth
      />
      <Field
        name="activeUser"
        label="Active"
        component={ReduxCheckBoxField}
        fullWidth
      />
    </SecurityFormContainer>
  )
}

export default SecurityForm
