import React from 'react'
import FormHelperText from '@material-ui/core/FormHelperText'

const RenderFromHelper = ({ touched, error }) => {
  if (!(touched && error)) {
    return true
  }
  return <FormHelperText>{touched && error}</FormHelperText>
}

export default RenderFromHelper
