import React from 'react'

import BarChartContainer, { BarChartStack } from './index.style'

const BarChart = ({ chartData }) => {
  return (
    <BarChartContainer>
      {chartData.length > 0 &&
        chartData.map((stack, i) => (
          <BarChartStack
            percentage={stack.end - stack.start}
            stack={stack}
            key={i}
          >
            {stack.label}
          </BarChartStack>
        ))}
    </BarChartContainer>
  )
}

export default BarChart
