import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Popover, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
    marginTop: 20,
  },
  paper: {
    padding: theme.spacing(2),
  },
}))

const SupplierPopover = ({ open, anchorEl, handlePopoverClose, supplier }) => {
  const classes = useStyles()

  return (
    <Popover
      id='mouse-over-popover'
      className={classes.popover}
      classes={{
        paper: classes.paper,
      }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      onClose={handlePopoverClose}
      disableRestoreFocus
    >
      <Typography variant='h5' gutterBottom>
        {supplier.name}
      </Typography>
      <Typography variant='h6' gutterBottom>
        {supplier.first_name} {supplier.last_name}
      </Typography>
      <Typography variant='h6' gutterBottom>
        {supplier.city}, {supplier.state}
      </Typography>
      <Typography gutterBottom>{supplier.email}</Typography>
      <Typography>{supplier.phone}</Typography>
    </Popover>
  )
}

export default SupplierPopover
