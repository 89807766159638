import React from 'react'
import Icon from '@material-ui/core/Icon'

import TimelineSliderContainer from './index.style'

const TimelineSlider = ({ timeline }) => {
  return (
    <TimelineSliderContainer timeLength={100 / timeline.length}>
      <span className='line' />
      {timeline.map((time, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className='time-marker' key={index}>
          <div className='pointer'>
            {time.icon ? <Icon>{time.icon}</Icon> : <span className='dot' />}
          </div>
          <p>{time.line1}</p>
          <p>{time.line2}</p>
          <p>{time.line3}</p>
          {time.line_bold && (
            <span
              className='bold-line'
              style={{
                width: `calc(${100 / (timeline.length - 1)}% - ${100 /
                  (timeline.length - 1)}px)`,
              }}
            />
          )}
        </div>
      ))}
    </TimelineSliderContainer>
  )
}

export default TimelineSlider
