import styled from 'styled-components'
import blue from '@material-ui/core/colors/blue'

import RequisitionReadModalContainer from '../../requisitions/list/readModal.style'

const ReadModalContainer = styled(RequisitionReadModalContainer)`
  min-height: 500px;
  position: relative;

  .supplier-label {
    &:hover {
      cursor: pointer;
    }
  }

  .lines-table {
    max-width: 1000px;
    margin: 0 auto 50px;

    .rt-tr {
      align-items: center;
    }

    .supplier-table-cell {
      display: flex;
      align-items: center;
    }

    .rfq-fabs {
      position: absolute;
      right: 20px;
      bottom: 20px;

      .btn-submit {
        margin-left: 10px;
        margin-right: 10px;
      }
    }

    .new-line {
      background-color: ${blue[200]};

      th {
        position: relative;
        p.new-line-title {
          margin: 0;
          position: absolute;
          top: 0;
        }
      }
    }
  }

  .ReactTable .rt-tr {
    align-items: center;
  }
`

export default ReadModalContainer
