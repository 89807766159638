import styled from 'styled-components'

const TabQuoteContainer = styled.div`
  .ReactTable .rt-tbody .rt-td input {
    font-size: 16px;
    width: 100%;
    outline: none;
  }

  .saved-quote {
    .messages {
      .message {
        border-bottom: 1px solid lightgrey;
      }
    }
  }

  .nothing-content {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`

export default TabQuoteContainer
