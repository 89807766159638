import styled from 'styled-components'

const AdminContainer = styled.div`
  flex-grow: 1;

  .content {
    .tab-content {
      padding-top: 20px;
    }
  }
`

export default AdminContainer
