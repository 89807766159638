import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { Link } from 'react-router-dom'
import { Translate } from 'react-localize-redux'

import { ReduxTextField } from '../../../components/reduxFormFields'

class LoginForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    userName: PropTypes.string,
  }

  static defaultProps = {
    userName: '',
  }

  componentDidMount() {}

  render() {
    const { handleSubmit, loading, userName } = this.props

    return (
      <Translate>
        {({ translate }) => (
          <form onSubmit={handleSubmit}>
            <Field
              name="userName"
              component={ReduxTextField}
              label={translate('auth.username')}
              fullWidth
            />

            <Field
              name="password"
              type="password"
              component={ReduxTextField}
              label={translate('auth.password')}
              fullWidth
            />

            <div className="card-actions">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading}
                className="btn-submit"
              >
                {loading ? (
                  <CircularProgress size={20} thickness={5} />
                ) : (
                  <span>{translate('auth.login')}</span>
                )}
              </Button>
              <div>
                <Link to={{ pathname: '/forgotPassword', state: { userName } }}>
                  {translate('auth.forgot_password')}
                </Link>
              </div>
            </div>
          </form>
        )}
      </Translate>
    )
  }
}

const validate = (values) => {
  const errors = {}

  if (!values.username) {
    errors.username = 'Required'
  }

  if (!values.password) {
    errors.password = 'Required'
  }

  return errors
}

const LoginReduxForm = reduxForm({
  form: 'login',
  validate,
})(LoginForm)

const selector = formValueSelector('login') // <-- same as form name
export default connect((state) => {
  const userName = selector(state, 'userName')

  return { userName }
})(LoginReduxForm)
