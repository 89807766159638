import React, { useEffect, useContext } from 'react'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'

import { GlobalContext } from 'routes'

import { getPoListAPI, createBatchPoLineAPI } from 'services/helpers/apis/po'
import { TabQuoteContext } from '../tabQuote'

const PoConfirmDialog = (props) => {
  const { onClose, open, addedLine } = props
  const tabQuoteContext = useContext(TabQuoteContext)

  const handleClose = () => {
    onClose()
    tabQuoteContext.fetchQuoteItems()
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby='po-confirm-dialog-title'
      open={open}
    >
      <DialogTitle id='po-confirm-dialog-title'>
        The lines were successfully added to{' '}
        {addedLine ? `PO ${addedLine.po.id}` : `new PO`}
      </DialogTitle>
      <DialogActions>
        {addedLine && (
          <Button
            variant='contained'
            color='primary'
            href={`/po/${addedLine.po.id}`}
          >
            GO TO PO {addedLine.po.id}
          </Button>
        )}
        <Button variant='contained' onClick={handleClose} color='secondary'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const AddToPoButton = ({ selectedRows, supplierId }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [poList, setPoList] = React.useState([])
  const [modalOpen, setModalOpen] = React.useState(false)
  const [addedLine, setAddedLine] = React.useState(null)
  const context = useContext(GlobalContext)

  useEffect(() => {
    const fetchPoList = async () => {
      try {
        const res = await getPoListAPI('draft', supplierId)

        setPoList(res.data.data.purchase_order)
      } catch (e) {
        context.onApiError(e)
      }
    }

    fetchPoList()
  }, [])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const onClickItem = async (po = null) => {
    let rfqQuoteLines = []
    selectedRows.map((line) => {
      rfqQuoteLines = [...rfqQuoteLines, line.rfq_quote_line_uuid]
      return rfqQuoteLines
    })

    try {
      const res = await createBatchPoLineAPI(
        rfqQuoteLines,
        po !== null ? po.id : null
      )
      setModalOpen(true)
      setAddedLine(res.data.data)
    } catch (e) {
      context.onApiError(e)
    }
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  return (
    <div className='align-right'>
      <Button
        variant='contained'
        color='primary'
        aria-controls='simple-menu'
        aria-haspopup='true'
        onClick={handleClick}
        disabled={selectedRows.length === 0}
      >
        Add to po
      </Button>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => onClickItem()}>New Po</MenuItem>
        {poList.map((po, i) => (
          <MenuItem onClick={() => onClickItem(po)} key={i}>
            Po {po.id}
          </MenuItem>
        ))}
      </Menu>

      <PoConfirmDialog
        open={modalOpen}
        onClose={handleCloseModal}
        addedLine={addedLine}
      />
    </div>
  )
}

export default AddToPoButton
