import React, { useEffect, useState, useContext } from 'react'
import {
  TextField,
  Grid,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Fab,
} from '@material-ui/core'
import { useParams, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add'
import CheckIcon from '@material-ui/icons/Check'
import { toast } from 'react-toastify'

import { GlobalContext } from 'routes'
import {
  getStringEvaluationTypesAPI,
  getPartNumberClassListAPI,
  readRuleAPI,
  updateRuleAPI,
  deleteRulesAPI,
} from 'services/helpers/apis/supplierPreferenceRules'
import { fetchSuppliersAPI } from 'services/helpers/apis/supplier'
import { getManufacturerListAPI } from 'services/helpers/apis/other'
import LoadingSpinner from 'components/common/loadingSpinner'
import TestPreferenceRuleModal from './testRuleModal'
import EditLogicModal from './editLogicModal'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  list: {
    '& .MuiListItem-root': {
      alignItems: 'flex-start',
    },
  },
}))

const SupplierPreferenceEdit = () => {
  const { uuid } = useParams()
  const history = useHistory()
  const classes = useStyles()
  const [ruleInfo, setRuleInfo] = useState({
    name: '',
    string_evaluation_type_id: '',
    evaluation_string: '',
    supplier_id: '',
    notes: '',
    uuid: '',
    logic: {
      workflow: [],
    },
    part_number_class_id: '',
  })
  const [tempRuleInfo, setTempRuleInfo] = useState(null)
  const [stringEvaluationTypes, setStringEvaluationTypes] = useState(null)
  const [partNumberClasses, setPartNumberClasses] = useState(null)
  const [suppliers, setSuppliers] = useState(null)
  const [manufacturers, setManufacturers] = useState(null)
  const [optionsLoading, setOptionsLoading] = useState(false)
  const context = useContext(GlobalContext)
  const [showTestModal, setShowTestModal] = useState(false)
  const [showEditLogicModal, setShowEditLogicModal] = useState(false)
  const [logicIndex, setLogicIndex] = useState(null)
  const [needUpdateApi, setNeedUpdateApi] = useState(false)
  const [selectedLogic, setSelectedLogic] = useState(null)

  const fetchOptions = async () => {
    setOptionsLoading(true)
    try {
      const res1 = await getStringEvaluationTypesAPI()
      const res2 = await getPartNumberClassListAPI()
      const res3 = await fetchSuppliersAPI()
      const res4 = await getManufacturerListAPI()
      setStringEvaluationTypes(res1.data.data)
      setPartNumberClasses(res2.data.data)
      setSuppliers(res3.data.data)
      setManufacturers(res4.data.data)
      setOptionsLoading(false)
    } catch (e) {
      context.onApiError(e)
    }
  }

  const fetchRuleInfo = async () => {
    try {
      const res = await readRuleAPI(uuid)
      setRuleInfo(res.data.data)
      setTempRuleInfo(res.data.data)
    } catch (e) {
      context.onApiError(e)
    }
  }

  useEffect(() => {
    fetchOptions()
    if (uuid) {
      fetchRuleInfo()
    }
  }, [])

  const updateRule = async () => {
    const updateObject = {
      name: ruleInfo.name,
      string: ruleInfo.evaluation_string,
      string_evaluation_type_id: ruleInfo.string_evaluation_type_id,
      part_number_class_id: ruleInfo.part_number_class_id,
      logic_objects: ruleInfo.logic_object,
      notes: ruleInfo.notes,
    }

    try {
      await updateRuleAPI(ruleInfo.uuid, updateObject)
      fetchRuleInfo()
      setShowEditLogicModal(false)
      setLogicIndex(null)
    } catch (e) {
      setRuleInfo(tempRuleInfo)
      context.onApiError(e)
    }
  }

  const onFieldChange = (optionName, e) => {
    setRuleInfo({
      ...ruleInfo,
      [optionName]: e.target.value,
    })
    if (
      optionName === 'string_evaluation_type_id' ||
      optionName === 'part_number_class_id' ||
      optionName === 'supplier_id'
    ) {
      setNeedUpdateApi(true)
    }
  }

  useEffect(() => {
    if (needUpdateApi) {
      updateRule()
      setNeedUpdateApi(false)
    }
  }, [needUpdateApi])

  const onBlurTextField = () => {
    updateRule()
  }

  const onDelete = async () => {
    try {
      await deleteRulesAPI(uuid)
      toast.success('Part Number Deleted Successfully')
      history.push('/admin/supplier_preference_rules')
    } catch (e) {
      context.onApiError(e)
    }
  }

  const onCheckBtnClicked = () => {
    setShowTestModal(true)
  }

  const onCloseTestModal = () => {
    setShowTestModal(false)
  }

  const onEditLogicBtnClicked = (logicObj, index) => {
    setSelectedLogic(logicObj)
    setLogicIndex(index)
    setShowEditLogicModal(true)
  }

  const onCloseEditLogicModal = () => {
    setSelectedLogic(null)
    setLogicIndex(null)
    setShowEditLogicModal(false)
  }

  const onDeleteLogic = (logicIndex) => {
    const logics = [...ruleInfo.logic_object]
    logics.splice(logicIndex, 1)

    setRuleInfo({
      ...ruleInfo,
      logic_object: logics,
    })
    setNeedUpdateApi(true)
  }

  const onUpdateLogic = (logicObj) => {
    let logics = ruleInfo.logic_object ? [...ruleInfo.logic_object] : []
    if (selectedLogic !== null && logicIndex !== null) {
      logics[logicIndex] = { ...logicObj }
    } else {
      logics = [...logics, logicObj]
    }

    setRuleInfo({
      ...ruleInfo,
      logic_object: logics,
    })

    setNeedUpdateApi(true)
  }

  const onAddLogicBtnClicked = () => {
    setShowEditLogicModal(true)
  }

  return optionsLoading ? (
    <LoadingSpinner />
  ) : (
    <Grid container spacing={3}>
      <Grid item xs={8}>
        <Box mb={1}>
          <TextField
            id='name-for-rule'
            label='Name for Rule'
            variant='outlined'
            value={ruleInfo.name}
            onChange={(e) => onFieldChange('name', e)}
            onBlur={onBlurTextField}
            fullWidth
          />
        </Box>
        <Box display='flex' alignItems='center'>
          <Box
            width='60%'
            display='flex'
            alignItems='center'
            justifyContent='flex-end'
          >
            <Typography>For any part number that</Typography>
            {stringEvaluationTypes && (
              <FormControl variant='outlined' className={classes.formControl}>
                <InputLabel id='string-evaluation-types-select-filled-label'>
                  String Evaluation Type
                </InputLabel>
                <Select
                  labelId='string-evaluation-types-select-filled-label'
                  id='string-evaluation-types-select-filled'
                  value={ruleInfo.string_evaluation_type_id}
                  onChange={(e) =>
                    onFieldChange('string_evaluation_type_id', e)
                  }
                  label='String Evaluation Type'
                >
                  <MenuItem value=''>
                    <em>None</em>
                  </MenuItem>
                  {stringEvaluationTypes.map((type) => (
                    <MenuItem value={type.id} key={type.id}>
                      {type.description}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Box>
          <Box flex='1'>
            <TextField
              id='search-text'
              label='add search text here...'
              variant='outlined'
              value={ruleInfo.evaluation_string}
              onChange={(e) => onFieldChange('evaluation_string', e)}
              onBlur={onBlurTextField}
              fullWidth
            />
          </Box>
        </Box>
        <Box display='flex' alignItems='center'>
          <Box
            width='60%'
            display='flex'
            alignItems='center'
            justifyContent='flex-end'
          >
            <Typography>and is a</Typography>
            {partNumberClasses && (
              <FormControl variant='outlined' className={classes.formControl}>
                <InputLabel id='part-number-class-select-filled-label'>
                  Part Number Class
                </InputLabel>
                <Select
                  labelId='part-number-class-select-filled-label'
                  id='part-number-class-select-filled'
                  value={ruleInfo.part_number_class_id}
                  onChange={(e) => onFieldChange('part_number_class_id', e)}
                  label='Part Number Class'
                >
                  <MenuItem value=''>
                    <em>None</em>
                  </MenuItem>
                  {partNumberClasses.map((item) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.description}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Box>

          <Typography>part</Typography>
        </Box>
        <List className={classes.list}>
          {ruleInfo.logic_object &&
            ruleInfo.logic_object.map((obj, index) => (
              <ListItem button key={index}>
                <ListItemText primary={obj.display_text} />
                <ListItemSecondaryAction>
                  <IconButton
                    aria-label='delete'
                    onClick={() => onEditLogicBtnClicked(obj, index)}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    aria-label='delete'
                    onClick={() => onDeleteLogic(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
        </List>
        <Box display='flex' justifyContent='flex-end' mt={3}>
          <Fab
            color='primary'
            aria-label='add'
            style={{ marginRight: '1em' }}
            onClick={onAddLogicBtnClicked}
          >
            <AddIcon />
          </Fab>
          <Fab
            color='primary'
            aria-label='edit'
            style={{ marginRight: '1em' }}
            onClick={onDelete}
          >
            <DeleteIcon />
          </Fab>
          <Fab color='primary' aria-label='delete' onClick={onCheckBtnClicked}>
            <CheckIcon />
          </Fab>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <TextField
          id='outlined-multiline-static'
          label='Notes...'
          multiline
          rows={20}
          defaultValue='Default Value'
          variant='outlined'
          fullWidth
          value={ruleInfo.notes}
          onBlur={onBlurTextField}
          onChange={(e) => onFieldChange('notes', e)}
        />
      </Grid>
      <TestPreferenceRuleModal
        open={showTestModal}
        handleClose={onCloseTestModal}
        uuid={ruleInfo.uuid}
      />
      <EditLogicModal
        open={showEditLogicModal}
        handleClose={onCloseEditLogicModal}
        suppliers={suppliers}
        manufacturers={manufacturers}
        logic={selectedLogic}
        onUpdateLogic={onUpdateLogic}
      />
    </Grid>
  )
}

export default SupplierPreferenceEdit
