import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import { orange, cyan } from '@material-ui/core/colors'

const StyledButton = styled(Button)`
  &.btn-orange {
    background: ${orange[500]};
    color: white;

    &:hover {
      background-color: ${orange[700]};
    }
  }

  &.btn-cyan {
    background: ${cyan[500]};
    color: white;

    &:hover {
      background-color: ${cyan[700]};
    }
  }
`

export default StyledButton
