import React, { useEffect, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  CircularProgress,
} from '@material-ui/core'
import styled from 'styled-components'
import _find from 'lodash/find'
import { toast } from 'react-toastify'

import { fetchSuppliersAPI } from 'services/helpers/apis/supplier'
import {
  updateRequisitionAPI,
  batchUpdateStatusAPI,
  deleteRequisitionsAPI,
} from 'services/helpers/apis/requisition'
import {
  createRfqAPI,
  createRfqLineBatchAPI,
  listRfqAPI,
} from 'services/helpers/apis/rfq'
import { GlobalContext } from 'routes'
import { RequisitionListContext } from 'pages/requisitions/list'

const BulkActionsFormControl = styled(FormControl)`
  width: 200px;
`

const TableBulkActions = ({
  checkedRequisitions,
  bulkActionUpdated,
  onDeletedRequisitions,
}) => {
  const history = useHistory()
  const requisitionListContext = useContext(RequisitionListContext)
  const [supplierList, setSupplierList] = useState([])
  const [bulkAction, setBulkAction] = useState('')
  const [rfqList, setRfqList] = useState([])
  const [assignUser, setAssignUser] = useState('')
  const [status, setStatus] = useState('')
  const [supplier, setSupplier] = useState('')
  const [openRfqAddedModal, setOpenRfqAddedModal] = useState(false)
  const [
    openDeleteRequisitionsModal,
    setOpenDeleteRequisitionsModal,
  ] = useState(false)
  const [rfqAddedMessage, setRfqAddedMessage] = useState('')
  const [rfqValue, setRfqValue] = useState('')
  const [createdRfqId, setCreatedRfqId] = useState()
  const [isDeleting, setIsDeleting] = useState(false)
  const context = useContext(GlobalContext)

  useEffect(() => {
    const fetchSuppliers = async () => {
      try {
        const res = await fetchSuppliersAPI()
        setSupplierList(res.data.data)
      } catch (e) {
        context.onApiError(e)
      }
    }

    fetchSuppliers()
  }, [])

  const resetSelectors = () => {
    setBulkAction('')
    setAssignUser('')
    setStatus('')
    setSupplier('')
  }

  const unAssignUser = async () => {
    try {
      await updateRequisitionAPI(null, {
        assignee: 0,
        id: checkedRequisitions,
      })
      bulkActionUpdated({ assignee: null })
      resetSelectors()
      toast.success('Unassigned user successfully')
    } catch (e) {
      context.onApiError(e)
    }
  }

  const onBulkActionChange = (e) => {
    setBulkAction(e.target.value)
    if (e.target.value === 'unassign') {
      unAssignUser()
    } else if (e.target.value === 'delete') {
      setOpenDeleteRequisitionsModal(true)
    }
  }

  const getAssigneeObject = (id) => {
    const user = _find(requisitionListContext.userList, { id })
    return {
      user_id: user.id,
      name: user.name,
    }
  }

  const onAssignUser = async (e) => {
    const { value } = e.target
    try {
      await updateRequisitionAPI(null, {
        assignee: value,
        id: checkedRequisitions,
      })
      bulkActionUpdated({ assignee: getAssigneeObject(value) })
      resetSelectors()
      toast.success('Assigned to user successfully')
    } catch (e) {
      context.onApiError(e)
    }
    setAssignUser(e.target.value)
  }

  const onChangeStatus = async (e) => {
    const { value } = e.target
    setStatus(value)

    try {
      await batchUpdateStatusAPI(checkedRequisitions.toString(), value)
      const status = _find(requisitionListContext.statusList, { id: value })
      bulkActionUpdated({ status_id: value, status: status.status })
      resetSelectors()
      toast.success('Changed status successfully')
    } catch (e) {
      context.onApiError(e)
    }
  }

  const onChangeSupplier = async (e) => {
    const { value } = e.target
    setSupplier(value)

    try {
      const res = await listRfqAPI(`?supplier_id=${value}`)
      setRfqList(res.data.data.rfq)
      setRfqValue('')
    } catch (e) {
      context.onApiError(e)
    }
  }

  const onAddRfq = async (e) => {
    const { value } = e.target

    try {
      if (value === 'new_rfq') {
        const res = await createRfqAPI(supplier)
        const rfqId = res.data.data.rfq_id
        setCreatedRfqId(rfqId)
        await createRfqLineBatchAPI(rfqId, checkedRequisitions)
        setRfqAddedMessage(
          `You created a RFQ and added the selected requisition lines`
        )
      } else {
        await createRfqLineBatchAPI(value, checkedRequisitions)
        setRfqAddedMessage(
          `You added the selected requisition lines to RFQ ${value}`
        )
      }
      setOpenRfqAddedModal(true)
      setRfqValue(value)
      resetSelectors()
      // setBulkAction('')
    } catch (e) {
      context.onApiError(e)
    }
  }

  const handleClose = () => {
    setOpenRfqAddedModal(false)
    setRfqAddedMessage('')
    setCreatedRfqId(null)
  }

  const onDeleteRequisitions = async () => {
    setIsDeleting(true)
    try {
      await deleteRequisitionsAPI(checkedRequisitions)
      setOpenDeleteRequisitionsModal(false)
      resetSelectors()
      onDeletedRequisitions()
    } catch (err) {
      context.onApiError(err)
    } finally {
      setIsDeleting(false)
    }
  }

  return (
    <Box display='flex'>
      <Box mr={1}>
        <BulkActionsFormControl variant='outlined'>
          <InputLabel id='select-bulkactions-label'>Bulk Actions</InputLabel>
          <Select
            labelId='select-bulkactions-label'
            id='select-bulkactions-outlined'
            value={bulkAction}
            onChange={onBulkActionChange}
            label='Bulk Actions'
            disabled={checkedRequisitions.length === 0}
          >
            <MenuItem value=''>None</MenuItem>
            <MenuItem value='assign'>Assign to user</MenuItem>
            <MenuItem value='unassign'>Unassign</MenuItem>
            <MenuItem value='addRfq'>Add to RFQ</MenuItem>
            <MenuItem value='status'>Set Status</MenuItem>
            <MenuItem value='delete'>Delete Requisitions</MenuItem>
          </Select>
        </BulkActionsFormControl>
      </Box>

      {bulkAction === 'assign' && (
        <Box mr={1}>
          <BulkActionsFormControl variant='outlined'>
            <InputLabel id='select-userlist-label'>User List</InputLabel>
            <Select
              labelId='select-userlist-label'
              id='select-userlist-outlined'
              value={assignUser}
              onChange={onAssignUser}
              label='User List'
              disabled={checkedRequisitions.length === 0}
            >
              {requisitionListContext.userList.map((user) => (
                <MenuItem value={user.id} key={user.id}>
                  {user.name}
                </MenuItem>
              ))}
            </Select>
          </BulkActionsFormControl>
        </Box>
      )}

      {bulkAction === 'status' && (
        <Box mr={1}>
          <BulkActionsFormControl variant='outlined'>
            <InputLabel id='select-statuslist-label'>Status List</InputLabel>
            <Select
              labelId='select-statuslist-label'
              id='select-statuslist-outlined'
              value={status}
              onChange={onChangeStatus}
              label='Status List'
              disabled={checkedRequisitions.length === 0}
            >
              <MenuItem value=''>None</MenuItem>
              {requisitionListContext.statusList.map((status) => (
                <MenuItem value={status.id} key={status.id}>
                  {status.status}
                </MenuItem>
              ))}
            </Select>
          </BulkActionsFormControl>
        </Box>
      )}

      {bulkAction === 'addRfq' && (
        <Box mr={1}>
          <BulkActionsFormControl variant='outlined'>
            <InputLabel id='select-supplierlist-label'>
              Supplier List
            </InputLabel>
            <Select
              labelId='select-supplierlist-label'
              id='select-supplierlist-outlined'
              value={supplier}
              onChange={onChangeSupplier}
              label='Supplier List'
              disabled={checkedRequisitions.length === 0}
            >
              {supplierList.map((supplier) => (
                <MenuItem value={supplier.id} key={supplier.id}>
                  {supplier.name}
                </MenuItem>
              ))}
            </Select>
          </BulkActionsFormControl>
        </Box>
      )}

      {supplier !== '' && (
        <Box>
          <BulkActionsFormControl variant='outlined'>
            <InputLabel id='select-supplierlist-label'>Rfq List</InputLabel>
            <Select
              labelId='select-rfqlist-label'
              id='select-rfqlist-outlined'
              value={rfqValue}
              onChange={onAddRfq}
              label='Rfq List'
              disabled={checkedRequisitions.length === 0}
            >
              <MenuItem value='new_rfq'>New Rfq</MenuItem>
              {rfqList.map((rfq) => (
                <MenuItem value={rfq.id} key={rfq.id}>
                  {rfq.id}
                </MenuItem>
              ))}
            </Select>
          </BulkActionsFormControl>
        </Box>
      )}

      <Dialog onClose={handleClose} open={openRfqAddedModal}>
        <DialogTitle>{rfqAddedMessage}</DialogTitle>
        <DialogActions>
          {createdRfqId && (
            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                history.push(`/rfq/${createdRfqId}`)
              }}
            >
              Go to RFQ {createdRfqId}
            </Button>
          )}

          <Button variant='contained' color='secondary' onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={() => {
          setOpenDeleteRequisitionsModal(false)
        }}
        open={openDeleteRequisitionsModal}
      >
        <DialogTitle>
          Are you sure you want to delete {checkedRequisitions.length}{' '}
          requisition lines?
        </DialogTitle>
        <DialogActions>
          <Button
            variant='contained'
            color='secondary'
            onClick={() => {
              setOpenDeleteRequisitionsModal(false)
            }}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={onDeleteRequisitions}
            disabled={isDeleting}
          >
            {isDeleting ? <CircularProgress size={25} /> : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default TableBulkActions
