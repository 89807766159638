import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import AppBar from '@material-ui/core/AppBar'
import { withRouter } from 'react-router'

import TabContainer, { a11yProps } from 'components/tabContainer'
import CorrespondenceTable from 'components/correspondenceTable'

import TabLineItems from './tabLineItems'
import TabHistory from './tabHistory'
import TabQuote from './tabQuote'

const RfqTabs = ({ selectedRfq, selectedTab, history }) => {
  const [tabValue, setTabValue] = useState(0)

  useEffect(() => {
    switch (selectedTab) {
      case 'lines':
        setTabValue(0)
        break
      case 'correspondence':
        setTabValue(1)
        break
      case 'quotes':
        setTabValue(2)
        break
      case 'activity':
        setTabValue(3)
        break
      default:
        break
    }
  }, [])

  const handleTabChange = (event, newValue) => {
    switch (newValue) {
      case 0:
        history.push(`/rfq/${selectedRfq.id}/lines`)
        break
      case 1:
        history.push(`/rfq/${selectedRfq.id}/correspondence`)
        break
      case 2:
        history.push(`/rfq/${selectedRfq.id}/quotes`)
        break
      case 3:
        history.push(`/rfq/${selectedRfq.id}/activity`)
        break
      default:
        break
    }
    setTabValue(newValue)
  }

  const onSavedQuote = () => {
    setTabValue(2)
  }

  return selectedRfq ? (
    <div>
      <AppBar position="static" color="default">
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="Shipment Tabs"
        >
          <Tab label="Line Items" {...a11yProps(0)} />
          <Tab label="Correspondence" {...a11yProps(1)} />
          <Tab label="Quote" {...a11yProps(2)} />
          <Tab label="History" {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      {tabValue === 0 && (
        <TabContainer>
          <TabLineItems />
        </TabContainer>
      )}
      {tabValue === 1 && (
        <TabContainer>
          <CorrespondenceTable onSavedQuote={onSavedQuote} />
        </TabContainer>
      )}
      {tabValue === 2 && (
        <TabContainer>
          <TabQuote />
        </TabContainer>
      )}
      {tabValue === 3 && (
        <TabContainer>
          <TabHistory uuid={selectedRfq.uuid} />
        </TabContainer>
      )}
    </div>
  ) : null
}

const mapStateToProps = (state) => ({
  selectedRfq: state.rfq.selected_rfq,
})

export default connect(mapStateToProps)(withRouter(RfqTabs))
