import React, { useState, useContext, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  Box,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  Fab,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import EditIcon from '@material-ui/icons/Edit'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { orange, lightGreen } from '@material-ui/core/colors'
import _isEmpty from 'lodash/isEmpty'
import { toast } from 'react-toastify'
import NumberFormat from 'react-number-format'
import moment from 'moment'

import { GlobalContext } from 'routes'
import {
  deletePoAPI,
  getPoMessageTemplateAPI,
  updatePoAPI,
} from 'services/helpers/apis/po'
import {
  getCarriersList,
  getCarriersServicesList,
} from 'services/helpers/apis/carrier'
import purchaseOrderActions from 'services/redux/purchaseOrder/actions'
import SendMessageTemplateModal from 'components/sendMessageTemplateModal'

import ConfirmedDateTime from './components/confirmtedDateTime'
import ChangeStatusModalContent from './components/changeStatusModalContent'
import LineTable from './lineTable'
import LineItemsContainer from './lineItems.style'
import { ModalContext } from './index'

const { onPoDelete } = purchaseOrderActions

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,

    '& #service-select-outlined-label': {
      background: 'white',
      padding: '0 4px',
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

const SubmitButton = withStyles(() => ({
  root: {
    color: '#fff',
    backgroundColor: orange[500],
    '&:hover': {
      backgroundColor: orange[700],
    },
  },
}))(Fab)

const EditButton = withStyles(() => ({
  root: {
    color: '#fff',
    backgroundColor: lightGreen[500],
    '&:hover': {
      backgroundColor: lightGreen[700],
    },
  },
}))(Fab)

const LineItems = ({ onPoDelete }) => {
  const context = useContext(GlobalContext)
  const modalContext = useContext(ModalContext)
  const [poConfirmMessage, setPoConfirmMessage] = useState(null)
  const [isAddNewLine, setIsAddNewLine] = useState(false)
  const [carriersList, setCarriersList] = useState(null)
  const [carriersServiceList, setCarriersServiceList] = useState(null)
  const [openChangeStatusModal, setOpenChangeStatusModal] = useState(false)
  const [changeStatusFor, setChangeStatusFor] = useState()
  const classes = useStyles()
  const { orderData } = modalContext

  const [selectedCarrierService, setSelectedCarrierService] = React.useState('')
  const [selectedCarrier, setSelectedCarrier] = React.useState('')

  const fetchCarriersService = async (carrierId) => {
    try {
      const res = await getCarriersServicesList(carrierId)

      setCarriersServiceList(res.data.data)

      if (!_isEmpty(orderData.carrier_service)) {
        setSelectedCarrierService(orderData.carrier_service.id)
      }
    } catch (e) {
      context.onApiError(e)
    }
  }

  useEffect(() => {
    const fetchCarriers = async () => {
      try {
        const res = await getCarriersList()

        setCarriersList(res.data.data)

        if (!_isEmpty(orderData.carrier_service)) {
          setSelectedCarrier(orderData.carrier_service.carrier_id)
          fetchCarriersService(orderData.carrier_service.carrier_id)
        }
      } catch (e) {
        context.onApiError(e)
      }
    }

    fetchCarriers()
  }, [])

  const handleChange = async (event, field) => {
    if (field === 'carrier') {
      setSelectedCarrier(event.target.value)
      fetchCarriersService(event.target.value)
    } else {
      setSelectedCarrierService(event.target.value)

      if (event.target.value !== '') {
        try {
          await updatePoAPI(orderData.uuid, {
            carrier_service_id: event.target.value,
          })
          toast.success('Success')
        } catch (e) {
          context.onApiError(e)
        }
      }
    }
  }

  const onDeleteClicked = async () => {
    if (orderData.permissions.can_delete) {
      try {
        await deletePoAPI(orderData.uuid)
        toast.success(`Po ${orderData.id} is deleted.`)
        onPoDelete(orderData)
      } catch (e) {
        context.onApiError(e)
      }
    } else {
      setOpenChangeStatusModal(true)
      setChangeStatusFor('delete this purchase order')
    }
  }

  const onEditClicked = () => {
    setOpenChangeStatusModal(true)
    setChangeStatusFor('edit this purchase order')
  }

  const onSubmitPo = async () => {
    try {
      const res = await getPoMessageTemplateAPI(orderData.uuid)
      setPoConfirmMessage(res.data.data)
    } catch (e) {
      context.onApiError(e)
    }
  }

  const onClosePoConfirmDialog = () => {
    setPoConfirmMessage(null)
  }

  const onAddNewLine = () => {
    setIsAddNewLine(true)
  }

  const onCloseAddNewLine = () => {
    setIsAddNewLine(false)
  }

  const onCloseChangeStatusModal = () => {
    setOpenChangeStatusModal(false)
    setChangeStatusFor(null)
  }

  const onUpdateDate = async (date) => {
    try {
      await updatePoAPI(orderData.uuid, {
        date_confirmed: moment(date).format('YYYY-MM-DD HH:mm:ss'),
      })
      modalContext.fetchPoApis()
      toast.success('Confirmed Date is updated')
    } catch (e) {
      context.onApiError(e)
    }
  }

  return (
    <LineItemsContainer>
      <div className='lineItem'>
        <LineTable
          isAddNewLine={isAddNewLine}
          closeAddNewLine={onCloseAddNewLine}
        />
      </div>

      <Box
        className='lines-table-bottom'
        display='flex'
        justifyContent='space-between'
        alignItems='flex-start'
        mt={1}
      >
        <Box display='flex' alignItems='center'>
          {carriersList && (
            <FormControl variant='outlined' className={classes.formControl}>
              <InputLabel id='carrier-select-outlined-label'>
                Carrier
              </InputLabel>
              <Select
                labelId='carrier-select-outlined-label'
                id='carrier-select-outlined'
                value={selectedCarrier}
                onChange={(e) => handleChange(e, 'carrier')}
                label='Carrier'
              >
                {carriersList.map((carrier) => (
                  <MenuItem value={carrier.id} key={carrier.id}>
                    {carrier.carrier}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {carriersServiceList && (
            <FormControl
              variant='outlined'
              className={classes.formControl}
              disabled={selectedCarrier === ''}
            >
              <InputLabel shrink id='service-select-outlined-label'>
                Service
              </InputLabel>
              <Select
                labelId='service-select-outlined-label'
                id='service-select-outlined'
                value={selectedCarrierService}
                onChange={(e) => handleChange(e, 'service')}
                label='Service'
                labelWidth={100}
                displayEmpty
              >
                <MenuItem value='' disabled>
                  <em>Select...</em>
                </MenuItem>
                {carriersServiceList.map((service) => (
                  <MenuItem value={service.id} key={service.id}>
                    {service.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {Object.keys(orderData.date_confirmed).length !== 0 &&
            !orderData.permissions.can_edit && (
              <Box ml={1}>
                <ConfirmedDateTime
                  date={orderData.date_confirmed}
                  disabled={!orderData.permissions.can_edit_date_confirmed}
                  onUpdateDate={onUpdateDate}
                />
              </Box>
            )}
        </Box>
        <Box className='total'>
          <p>
            <span>Total Pieces:</span>{' '}
            <span>{orderData.lines.total_pieces}</span>
          </p>
          <p>
            <span>Total Price:</span>{' '}
            <span>
              <NumberFormat
                value={parseFloat(orderData.lines.total_price)}
                displayType='text'
                thousandSeparator
                prefix='$'
                decimalScale={3}
              />
            </span>
          </p>
        </Box>
      </Box>

      <div className='po-fabs'>
        {!orderData.permissions.can_edit && (
          <Tooltip title='Edit PO' placement='top'>
            <span>
              <EditButton
                size='small'
                aria-label='edit'
                className='btn-edit'
                onClick={onEditClicked}
                // disabled={!orderData.permissions.can_delete}
              >
                <EditIcon />
              </EditButton>
            </span>
          </Tooltip>
        )}
        <Tooltip title='Delete PO' placement='top'>
          <span>
            <Fab
              size='small'
              color='secondary'
              aria-label='add'
              className='btn-delete'
              onClick={onDeleteClicked}
            >
              <DeleteIcon />
            </Fab>
          </span>
        </Tooltip>
        <Tooltip title='Send' placement='top'>
          <span>
            <SubmitButton
              size='small'
              aria-label='add'
              className='btn-submit'
              onClick={onSubmitPo}
              disabled={!orderData.permissions.can_submit}
            >
              <ArrowForwardIosIcon />
            </SubmitButton>
          </span>
        </Tooltip>
        <Tooltip title='Add New Line Item' placement='top'>
          <span>
            <Fab
              size='small'
              color='primary'
              aria-label='add'
              className='btn-add'
              onClick={onAddNewLine}
              disabled={!orderData.permissions.can_edit_lines}
            >
              <AddIcon />
            </Fab>
          </span>
        </Tooltip>
      </div>
      <Dialog
        onClose={onClosePoConfirmDialog}
        open={!!poConfirmMessage}
        disableBackdropClick
      >
        <SendMessageTemplateModal
          message={poConfirmMessage}
          id={orderData.id}
          onCloseDialog={onClosePoConfirmDialog}
          modalFor='po'
        />
      </Dialog>
      <Dialog onClose={onCloseChangeStatusModal} open={openChangeStatusModal}>
        <ChangeStatusModalContent
          changeStatusFor={changeStatusFor}
          onPoDelete={onPoDelete}
          onClose={onCloseChangeStatusModal}
        />
      </Dialog>
    </LineItemsContainer>
  )
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onPoDelete,
    },
    dispatch
  )

export default connect(null, mapDispatchToProps)(LineItems)
