import styled from 'styled-components'

const ShipmentStatusContainer = styled.div`
  .shipment-info {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .info-header {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      h2 {
        margin-right: 15px;
      }
    }

    span.shipment-status {
      color: white;
      padding: 5px 10px;
      display: flex;
      align-items: center;
    }
  }
`

export default ShipmentStatusContainer
