import styled from 'styled-components'

const LineItemsContainer = styled.div`
  position: relative;
  padding-bottom: 100px;

  .lines-table-bottom {
    .total {
      width: 200px;

      p {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .po-fabs {
    position: absolute;
    right: 0;
    bottom: 20px;

    > span {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
`

export default LineItemsContainer
