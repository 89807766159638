/* eslint-disable jsx-a11y/anchor-is-valid, no-alert */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import { Field, reduxForm } from 'redux-form'
import Link from '@material-ui/core/Link'
import Tooltip from '@material-ui/core/Tooltip'

import { ReduxTextField } from 'components/reduxFormFields'

class ChangePasswordForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
  }

  componentDidMount() {}

  render() {
    const { handleSubmit, loading } = this.props
    const longText = `
Password must have at least one upper case letter, one lower case letter, one number and one special symbol.
`

    return (
      <form onSubmit={handleSubmit}>
        <Typography variant="body1" align="center">
          Please change your password
        </Typography>

        <Field
          name="newPassword"
          component={ReduxTextField}
          type="password"
          label="New Password"
          fullWidth
        />

        <Field
          name="confirmPassword"
          component={ReduxTextField}
          type="password"
          label="Confirm Password"
          fullWidth
        />

        <div className="card-actions">
          <Tooltip title={longText}>
            <Link
              component="button"
              variant="body2"
              className="tooltip-password"
            >
              Password Requirements
            </Link>
          </Tooltip>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
            className="btn-submit"
          >
            {loading ? (
              <CircularProgress size={20} thickness={5} />
            ) : (
              <span>Submit</span>
            )}
          </Button>
        </div>
      </form>
    )
  }
}

const validate = (values) => {
  const errors = {}
  const strongRegex = new RegExp(
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'
  )

  if (!values.newPassword) {
    errors.newPassword = 'New password is required'
  } else if (!strongRegex.test(values.newPassword)) {
    errors.newPassword =
      'The password does not meet the minimum password requirements'
  } else if (values.newPassword === values.oldPassword) {
    errors.newPassword = 'New password must be different than old password'
  }

  if (!values.confirmPassword) {
    errors.confirmPassword = 'Confirm password is required'
  } else if (values.newPassword !== values.confirmPassword) {
    errors.confirmPassword = 'Confirm password does not match'
  }

  return errors
}

const ChangePasswordReduxForm = reduxForm({
  form: 'changePassword',
  validate,
})(ChangePasswordForm)

export default ChangePasswordReduxForm
