import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Router, Route, Redirect, Switch } from 'react-router-dom'
import { renderToStaticMarkup } from 'react-dom/server'
import { withLocalize } from 'react-localize-redux'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer, toast } from 'react-toastify'

import { clearToken } from 'services/helpers/utility'
import authActions from 'services/redux/auth/actions'
import Login from './pages/auth/login'
import ForgotPassword from './pages/auth/forgotPassword'
import ForgotPasswordConfirm from './pages/auth/forgotPassword/confirm'
import GoogleAuthenticator from './pages/auth/googleAuthenticator'
import GoogleAuthenticatorQRCode from './pages/auth/googleAuthenticator/qrCode'
import ChangePassword from './pages/auth/changePassword'
import Admin from './pages/admin'
import PurchaseOrders from './pages/purchaseOrders'
import Home from './pages/home'
import Requisitions from './pages/requisitions'
import Shipment from './pages/shipment'
import globalTranslations from './translations/global.json'
import NoMatchPage from './pages/noMatch'
import Rfq from './pages/rfq'
import PageWrapper from './pages/pageWrapper'

export const GlobalContext = React.createContext()

// eslint-disable-next-line no-shadow
const RestrictedRoute = ({ component: Component, loginCondition, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      loginCondition ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      )
    }
  />
)

const PublicRoutes = ({ initialize, history, hasLoginStatus, isLoggedIn }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    initialize({
      languages: [{ name: 'English', code: 'en' }],
      translation: globalTranslations,
      options: { renderToStaticMarkup },
    })
  }, [])

  const onApiError = (err) => {
    let errorMessage

    if (err && err.response) {
      if (err.response.data.code === 401) {
        clearToken()
        dispatch({
          type: authActions.LOGOUT_SUCCESS,
        })
      } else {
        errorMessage = err.response.data.message
        toast.error(errorMessage)
      }
    }
  }

  const onApiResponse = (res) => {
    if (res.data.code === 200 || res.data.code === 201) {
      toast.success('Success')
    } else if (res.data.code >= 400 && res.data.code < 600) {
      toast.warning(res.data.message)
    }
  }

  return (
    <GlobalContext.Provider value={{ onApiError, onApiResponse }}>
      <ToastContainer />
      <Router history={history}>
        <PageWrapper isLoggedIn={isLoggedIn}>
          <Switch>
            <Route exact path='/'>
              <Redirect to='/home' />
            </Route>
            <Route exact path='/login' component={Login} />
            <Route exact path='/forgotPassword' component={ForgotPassword} />
            <Route
              exact
              path='/forgotPasswordConfirm'
              component={ForgotPasswordConfirm}
            />
            <RestrictedRoute
              exact
              path='/googleAuthenticatorCode'
              component={GoogleAuthenticator}
              loginCondition={hasLoginStatus}
            />
            <RestrictedRoute
              exact
              path='/googleAuthenticatorQRCode'
              component={GoogleAuthenticatorQRCode}
              loginCondition={hasLoginStatus}
            />
            <RestrictedRoute
              exact
              path='/changePassword'
              component={ChangePassword}
              loginCondition={hasLoginStatus}
            />
            <RestrictedRoute
              path='/admin'
              component={Admin}
              loginCondition={isLoggedIn}
            />
            <RestrictedRoute
              path='/home'
              component={Home}
              loginCondition={isLoggedIn}
            />
            <RestrictedRoute
              path='/requisitions'
              component={Requisitions}
              loginCondition={isLoggedIn}
            />
            <RestrictedRoute
              path='/po'
              component={PurchaseOrders}
              loginCondition={isLoggedIn}
            />
            <RestrictedRoute
              path='/shipment'
              component={Shipment}
              loginCondition={isLoggedIn}
            />
            <RestrictedRoute
              path='/rfq'
              component={Rfq}
              loginCondition={isLoggedIn}
            />
            <Route component={NoMatchPage} />
          </Switch>
        </PageWrapper>
      </Router>
    </GlobalContext.Provider>
  )
}

export default connect((state) => ({
  hasLoginStatus: state.auth.loginStatus !== null,
  isLoggedIn: state.auth.loggedIn,
}))(withLocalize(PublicRoutes))
