import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Tooltip } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Checkbox from '@material-ui/core/Checkbox'
import _filter from 'lodash/filter'
import _findIndex from 'lodash/findIndex'
import _isEqual from 'lodash/isEqual'

import EnhancedTableHead from './tableHead'
import TableContainer from './index.style'
import EditableInput from './editableInput'

let counter = 0
function createData(line) {
  counter += 1
  return {
    id: counter,
    ...line,
    selected: false,
  }
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy)
}

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
})

class InventoryTable extends React.Component {
  state = {
    order: 'asc',
    orderBy: 'partNumber',
    selected: [],
    data: [],
    availableData: [],
    page: 0,
    rowsPerPage: 5,
  }

  componentDidMount() {
    const { data } = this.props

    this.setTableData(data)
  }

  componentWillReceiveProps(nextProps) {
    if (!_isEqual(nextProps.data, this.props.data)) {
      this.setTableData(nextProps.data)
    }
  }

  setTableData = (data) => {
    this.setState(
      {
        data: [],
        availableData: [],
      },
      () => {
        data.map((item) => {
          return this.setState(
            (prevState) => ({
              data: prevState.data.concat(createData(item)),
            }),
            () => {
              this.setState((prevState) => ({
                availableData: _filter(prevState.data, function(o) {
                  return o.quantity_required !== 0
                }),
              }))
            }
          )
        })
      }
    )
  }

  handleRequestSort = (event, property) => {
    const orderBy = property
    let order = 'desc'

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc'
    }

    this.setState({ order, orderBy })
  }

  handleSelectAllClick = (event) => {
    if (event.target.checked) {
      this.setState(
        (state) => ({
          selected: state.availableData.map((n) => n.id),
        }),
        () => {
          this.setSelectedPropertyInData()
        }
      )
      return
    }
    this.setState({ selected: [] }, () => {
      this.setSelectedPropertyInData()
    })
  }

  handleClick = (event, n) => {
    const { selected } = this.state
    const selectedIndex = selected.indexOf(n.id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, n.id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    if (n.quantity_required > 0) {
      this.setState({ selected: newSelected }, () => {
        this.setSelectedPropertyInData()
      })
    }
  }

  handleChangePage = (event, page) => {
    this.setState({ page })
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value })
  }

  handleChangeQuantity = (n) => {
    const { data } = this.state
    const index = _findIndex(data, { id: n.id })

    this.setState(
      (prevState) => {
        // eslint-disable-next-line no-param-reassign
        prevState.data[index].quantity_required = n.quantity_required

        return prevState
      },
      () => {
        this.setSelectedPropertyInData()
      }
    )
  }

  isSelected = (id) => this.state.selected.indexOf(id) !== -1

  setSelectedPropertyInData = () => {
    const { data, selected } = this.state

    data.map((line, i) => {
      return this.setState((prevState) => {
        // eslint-disable-next-line no-param-reassign
        prevState.data[i].selected = false
      })
    })

    this.setState(
      (prevState) => {
        selected.map((id) => {
          const index = _findIndex(data, { id })
          // eslint-disable-next-line no-param-reassign
          prevState.data[index].selected = true
          return id
        })

        return prevState
      },
      () => {
        this.props.onSelectLine(data)
      }
    )
  }

  render() {
    const { classes } = this.props
    const {
      data,
      availableData,
      order,
      orderBy,
      selected,
      rowsPerPage,
      page,
    } = this.state
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage)

    return (
      <TableContainer>
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={availableData.length}
            />
            <TableBody>
              {stableSort(data, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((n) => {
                  const isSelected = this.isSelected(n.id)
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={n.id}
                      selected={isSelected}
                    >
                      <TableCell
                        padding="checkbox"
                        onClick={(event) => this.handleClick(event, n)}
                      >
                        <Checkbox
                          checked={isSelected}
                          disabled={n.quantity_available === 0}
                        />
                      </TableCell>
                      <TableCell>
                        {n.quantity_available !== 0 ? (
                          <EditableInput
                            line={n}
                            maxValue={n.quantity_available}
                            onChange={this.handleChangeQuantity}
                          />
                        ) : (
                          0
                        )}
                      </TableCell>
                      <TableCell>
                        {n.manufacturer && (
                          <Tooltip title={n.manufacturer.name}>
                            <span>{n.manufacturer.abbreviation}</span>
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell>{n.quantity_available}</TableCell>
                      <TableCell component="th" scope="row" padding="none">
                        {n.part_number}
                      </TableCell>
                      <TableCell component="th" scope="row" padding="none">
                        {n.source}
                      </TableCell>
                      <TableCell>{n.lead_time}</TableCell>
                      <TableCell>
                        {n.fetched ? n.fetched.human_date.relative.long : ''}
                      </TableCell>
                    </TableRow>
                  )
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={8} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        {data.length > 5 && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        )}
      </TableContainer>
    )
  }
}

InventoryTable.propTypes = {
  classes: PropTypes.shape().isRequired,
}

export default withStyles(styles)(InventoryTable)
