import React, { useState, useEffect, useContext } from 'react'
import { Field } from 'redux-form'

import { GlobalContext } from 'routes'
import { ReduxTextField, ReduxSelectField } from 'components/reduxFormFields'
import { client } from 'services/helpers/apiConfig'

const ProfileForm = ({ isUpdateSupplier }) => {
  const context = useContext(GlobalContext)
  const [userTypes, setUserTypes] = useState([])

  const fetchUserTypes = async () => {
    try {
      const userTypeList = await client.get('userType/list')
      setUserTypes(userTypeList.data.data)
    } catch (e) {
      context.onApiError(e)
    }
  }

  useEffect(() => {
    if (!isUpdateSupplier) {
      fetchUserTypes()
    }
  }, [])

  return (
    <div>
      <Field
        name="firstname"
        label="First Name"
        component={ReduxTextField}
        fullWidth
      />
      <Field
        name="lastname"
        label="Last Name"
        component={ReduxTextField}
        fullWidth
      />
      <Field
        name="email"
        label="Email"
        component={ReduxTextField}
        fullWidth
        type="email"
      />
      <Field
        name="phone"
        label="Phone"
        component={ReduxTextField}
        fullWidth
        type="tel"
      />
      {!isUpdateSupplier && userTypes.length > 0 && (
        <Field
          name="userType"
          component={ReduxSelectField}
          label="User Type"
          id="field-user-type"
          onChange={() => {}}
        >
          <option value="" disabled />
          {userTypes.map((type) => (
            <option value={type.id} key={type.id}>
              {type.user_type}
            </option>
          ))}
        </Field>
      )}
    </div>
  )
}

export default ProfileForm
