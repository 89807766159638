import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Modal from '@material-ui/core/Modal'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@material-ui/core/Checkbox'
import { toast } from 'react-toastify'

import { client } from 'services/helpers/apiConfig'
import {
  addMFAAPI,
  removeMFAAPI,
  bulkUserMakeActiveAPI,
  bulkUserMakeInactiveAPI,
} from 'services/helpers/apis/user'

import adminActions from 'services/redux/admin/actions'

import UpdateContentContainer, { ModalContent } from './index.style'
import MfaModalContent from './mfaModalContent'
import DisableMfaModalContent from './disableMfaModalContent'

const { updateUser, onApiError } = adminActions

class MetaData extends Component {
  static propTypes = {
    userData: PropTypes.shape().isRequired,
    userUpdated: PropTypes.bool.isRequired,
  }

  state = {
    mfaModalOpen: false,
    userTypeChangeModalOpen: false,
    mfaEnabled: this.props.userData.mfa,
    requireMFA: this.props.userData.require_mfa,
    active: this.props.userData.active,
    userTypes: [],
    userType: this.props.userData.user_type_id,
    mfaUpdateMode: '',
    disableMFAModalOpen: false,
  }

  async componentDidMount() {
    try {
      const userTypeList = await client.get('userType/list')
      this.setState({
        userTypes: userTypeList.data.data,
      })
    } catch (e) {
      this.props.onApiError(e)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.userUpdated === true && this.props.userUpdated === false) {
      this.setState({
        userTypeChangeModalOpen: false,
      })
    }
  }

  onUpdateMFA = (updateMode) => {
    this.setState({ mfaModalOpen: true, mfaUpdateMode: updateMode })
  }

  handleClose = (name) => {
    this.setState({ [name]: false })
  }

  onMFAEnabled = () => {
    this.setState({
      mfaEnabled: true,
    })
  }

  onMFARemoved = () => {
    this.setState({
      mfaEnabled: false,
    })
  }

  onMFADisable = () => {
    this.setState({
      disableMFAModalOpen: true,
    })
  }

  onUserTypeSelect = (event) => {
    const { userData } = this.props

    this.setState({ userType: event.target.value }, () => {
      if (userData.scope_customizations) {
        this.setState({
          userTypeChangeModalOpen: true,
        })
      } else {
        this.props.updateUser(userData.username, {
          user_type_id: parseInt(this.state.userType, 10),
        })
      }
    })
  }

  onContinueChangeUserType = () => {
    const { userData } = this.props
    const { userType } = this.state

    this.props.updateUser(userData.username, {
      user_type_id: parseInt(userType, 10),
    })
  }

  handleChange = (name) => (event) => {
    const { userId } = this.props

    this.setState({ [name]: event.target.checked }, () => {
      if (name === 'requireMFA') {
        if (this.state.requireMFA) {
          addMFAAPI(userId)
            .then((res) => {
              if (res.data.code === 201) {
                toast.success('MFA Enabled')
              } else {
                toast.warn(res.data.message)
              }
            })
            .catch((err) => {
              this.props.onApiError(err)
            })
        } else {
          removeMFAAPI(userId)
            .then((res) => {
              if (res.data.code === 201) {
                toast.success('MFA Disabled')
              } else {
                toast.warn(res.data.message)
              }
            })
            .catch((err) => {
              this.props.onApiError(err)
            })
        }
      } else if (name === 'active') {
        if (this.state.active) {
          bulkUserMakeActiveAPI([userId])
            .then((res) => {
              if (res.data.code === 201 || res.data.code === 202) {
                toast.success('User is now active')
              } else {
                toast.warn(res.data.message)
              }
            })
            .catch((err) => {
              this.props.onApiError(err)
            })
        } else {
          bulkUserMakeInactiveAPI([userId])
            .then((res) => {
              if (res.data.code === 201 || res.data.code === 202) {
                toast.success('User is now inactive')
              } else {
                toast.warn(res.data.message)
              }
            })
            .catch((err) => {
              this.props.onApiError(err)
            })
        }
      }
    })
  }

  render() {
    const { userData, userId, self } = this.props
    const {
      mfaModalOpen,
      mfaEnabled,
      userTypes,
      userTypeChangeModalOpen,
      requireMFA,
      mfaUpdateMode,
      disableMFAModalOpen,
    } = this.state

    return (
      <UpdateContentContainer>
        <p>Last Login: {userData.last_login}</p>
        <p>User Created: {userData.date_added}</p>
        {!self && userTypes.length > 0 && (
          <FormControl className='select-user-type'>
            <InputLabel htmlFor='age-simple'>User Type</InputLabel>
            <Select
              value={this.state.userType}
              onChange={this.onUserTypeSelect}
              inputProps={{
                name: 'userType',
                id: 'user-type',
              }}
            >
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              {userTypes.map((type) => (
                <MenuItem value={type.id} key={type.id}>
                  {type.user_type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <br />
        <p>
          Multrifactor Authentication is{' '}
          {!userData.mfa_required && !mfaEnabled ? 'not' : ''} enabled
          {mfaEnabled && (
            <Button
              variant='contained'
              color='primary'
              style={{ marginLeft: '20px' }}
              onClick={this.onMFADisable}
            >
              Disable
            </Button>
          )}
        </p>
        {!self && (
          <div>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      this.state.requireMFA || userData.disable_mfa_checkbox
                    }
                    onChange={this.handleChange('requireMFA')}
                    value='requireMFA'
                    color='primary'
                    disabled={userData.disable_mfa_checkbox}
                  />
                }
                label='Require MFA'
              />
            </div>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.active}
                    onChange={this.handleChange('active')}
                    value='active'
                    color='primary'
                  />
                }
                label='Active'
              />
            </div>
          </div>
        )}
        {self && !mfaEnabled && (
          <div className='form-action'>
            <Button
              variant='contained'
              color='primary'
              onClick={() => this.onUpdateMFA('enable')}
            >
              Enable MFA
            </Button>
          </div>
        )}
        {self && mfaEnabled && !requireMFA && (
          <div className='form-action'>
            <Button
              variant='contained'
              color='primary'
              onClick={() => this.onUpdateMFA('remove')}
            >
              Remove MFA
            </Button>
          </div>
        )}
        <Modal
          open={disableMFAModalOpen}
          onClose={() => this.handleClose('disableMFAModalOpen')}
          disableBackdropClick
        >
          <DisableMfaModalContent
            onClose={() => {
              this.handleClose('disableMFAModalOpen')
            }}
            userId={userId}
          ></DisableMfaModalContent>
        </Modal>
        <Modal
          aria-labelledby='Mfa modal'
          aria-describedby='mfa-modal'
          open={mfaModalOpen}
          onClose={() => this.handleClose('mfaModalOpen')}
          disableBackdropClick
        >
          <MfaModalContent
            onClose={() => {
              this.handleClose('mfaModalOpen')
            }}
            mfaUpdateMode={mfaUpdateMode}
            onMFAEnabled={this.onMFAEnabled}
            onMFARemoved={this.onMFARemoved}
            userData={userData}
          />
        </Modal>
        <Modal
          aria-labelledby='user-type-change-modal'
          aria-describedby='user-type-change'
          open={userTypeChangeModalOpen}
          onClose={() => this.handleClose('userTypeChangeModalOpen')}
        >
          <ModalContent>
            <p>
              This user has customized Scope settings, which will be permanently
              deleted when changing User Type. <br /> <br /> Do you want to
              continue?
            </p>
            <div className='modal-action'>
              <Button
                variant='contained'
                color='secondary'
                onClick={() => this.handleClose('userTypeChangeModalOpen')}
              >
                Cancel
              </Button>
              <Button
                variant='contained'
                color='primary'
                onClick={this.onContinueChangeUserType}
              >
                Continue
              </Button>
            </div>
          </ModalContent>
        </Modal>
      </UpdateContentContainer>
    )
  }
}

const mapStateToProps = (state) => ({
  userUpdated: state.admin.userUpdated,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUser,
      onApiError,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(MetaData)
