import React, { useState, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  Button,
  Link,
  Tooltip,
} from '@material-ui/core'

import storageActions from 'services/redux/storage/actions'
import { PoListContext } from 'pages/purchaseOrders/list'
import AssignDropdown from 'components/assignDropdown'
import LoadingSpinner from 'components/common/loadingSpinner'
import ListTableFilterBox from 'components/listTableFilterBox'

import EnhancedTable from 'components/tables/table'
import StatusLabel from 'components/tables/statusLabel'
import { getComparator, stableSort } from 'components/tables/utils'

const { setPoTableSettings } = storageActions
const headCells = [
  {
    id: 'po_id',
    label: 'PO',
  },
  {
    id: 'date',
    label: 'Date',
  },
  {
    id: 'part_number',
    label: 'Part Number',
  },
  {
    id: 'requisition_id',
    label: 'Requisition ID',
  },
  {
    id: 'supplier',
    label: 'Supplier',
  },
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'assignedTo',
    label: 'AssignedTo',
  },
]

const PoTable = ({ openPoModal, onFilterChange }) => {
  const poListContext = useContext(PoListContext)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('')
  const [selected, setSelected] = useState([])
  const poTableSettings = useSelector((state) => state.storage.poTableSettings)
  const dispatch = useDispatch()
  const isSelected = (id) => selected.indexOf(id) !== -1
  const emptyRows =
    poTableSettings.tableViewInfo.rowsCount -
    Math.min(poTableSettings.tableViewInfo.rowsCount, poListContext.rows.length)

  const onChangeSort = (sort) => {
    dispatch(
      setPoTableSettings({
        sort,
      })
    )
  }

  const renderFilterBox = () => {
    return (
      <ListTableFilterBox
        userList={poListContext.userList}
        statusList={poListContext.statusList}
        suppliersList={poListContext.suppliersList}
        filterUsers={poTableSettings.filter.users}
        filterStatuses={poTableSettings.filter.statuses}
        filterSuppliers={poTableSettings.filter.suppliers}
        handleChange={onFilterChange}
      />
    )
  }

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)
    poListContext.onCheckRow(newSelected)
  }

  return (
    <div>
      {poListContext.statusList && poListContext.suppliersList ? (
        <EnhancedTable
          tableFor="po"
          headCells={headCells}
          rows={poListContext.rows}
          onTableViewChange={poListContext.onTableViewChange}
          order={order}
          orderBy={orderBy}
          selected={selected}
          setSelected={setSelected}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          onChangeSort={onChangeSort}
          tableSettings={poTableSettings}
          renderFilterBox={renderFilterBox}
        >
          <TableBody>
            {stableSort(poListContext.rows, getComparator(order, orderBy)).map(
              (row, index) => {
                const isItemSelected = isSelected(row.id)
                const labelId = `enhanced-table-checkbox-${index}`

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        onChange={(event) => handleClick(event, row.id)}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </TableCell>
                    <TableCell component="th" id={labelId} scope="row">
                      <Link
                        component="button"
                        variant="body2"
                        onClick={() => openPoModal(row, index)}
                      >
                        {row.id}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Tooltip title={row.date_added.formatted_date}>
                        <span>{row.date_added.human_date.relative.long}</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      {row.part_numbers[0]}{' '}
                      {row.part_numbers.length > 1 && (
                        <Tooltip title={row.part_numbers.join(', ')}>
                          <Button color="primary">
                            {row.part_numbers.length - 1} more...
                          </Button>
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell>
                      {row.requisition_ids[0]}{' '}
                      {row.requisition_ids.length > 1 && (
                        <Tooltip title={row.requisition_ids.join(', ')}>
                          <Button color="primary">
                            {row.requisition_ids.length - 1} more...
                          </Button>
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell>{row.supplier}</TableCell>
                    <TableCell>
                      {poListContext.statusList && (
                        <StatusLabel
                          statusList={poListContext.statusList}
                          statusId={row.status_id}
                        >
                          {row.status}
                        </StatusLabel>
                      )}
                    </TableCell>
                    <TableCell>
                      <AssignDropdown
                        userList={poListContext.userList}
                        selected={row}
                        permissions={row.permissions}
                        updateFor="po"
                      />
                    </TableCell>
                  </TableRow>
                )
              }
            )}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={8} />
              </TableRow>
            )}
          </TableBody>
        </EnhancedTable>
      ) : (
        <LoadingSpinner />
      )}
    </div>
  )
}

export default PoTable
