import React, { useState, useContext } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Button,
  TextField,
  Typography,
} from '@material-ui/core'

import { GlobalContext } from 'routes'
import { processRuleAPI } from 'services/helpers/apis/supplierPreferenceRules'
import StyledButton from 'components/common/button'

const TestPreferenceRuleModal = ({ open, handleClose, uuid }) => {
  const [partNumber, setPartNumber] = useState('')
  const [processedMessages, setProcessedMessages] = useState(null)
  const [showTestButton, setShowTestButton] = useState(true)
  const context = useContext(GlobalContext)

  const onChangePartNumber = (e) => {
    setPartNumber(e.target.value)
  }

  const onTest = async () => {
    try {
      const res = await processRuleAPI(uuid, partNumber)

      setProcessedMessages(res.data.data)
      setShowTestButton(false)
    } catch (e) {
      context.onApiError(e)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='form-dialog-title'
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle id='form-dialog-title'>
        Test Supplier Part Number Rule
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin='dense'
          label='Part Number Rule'
          variant='outlined'
          fullWidth
          value={partNumber}
          onChange={onChangePartNumber}
        />

        {processedMessages && processedMessages.pass && (
          <Box textAlign='center' my={3}>
            <Typography variant='body1' gutterBottom>
              A {processedMessages.part_number_class} part with the part number{' '}
            </Typography>
            <Typography variant='body1' gutterBottom>
              {processedMessages.part_number}
            </Typography>
            <Typography variant='body1' gutterBottom>
              will have supplier preference rules applied in the following
              order:
            </Typography>
            <ul style={{ textAlign: 'left' }}>
              {processedMessages.display_text.map((rank, i) => (
                <li key={i}>
                  <Typography variant='body1' gutterBottom>
                    {rank}
                  </Typography>
                </li>
              ))}
            </ul>
          </Box>
        )}

        {processedMessages && !processedMessages.pass && (
          <Box textAlign='center' my={3}>
            <Typography variant='body1' gutterBottom>
              This part number rule does not match part number
            </Typography>
            <Typography variant='body1' gutterBottom>
              {processedMessages.part_number}
            </Typography>
          </Box>
        )}

        <Box mt={3} display='flex' justifyContent='flex-end'>
          <StyledButton
            onClick={handleClose}
            className='btn-orange'
            color='primary'
            variant='contained'
            style={{ marginRight: '1em' }}
          >
            Close
          </StyledButton>
          {showTestButton && (
            <Button onClick={onTest} color='primary' variant='contained'>
              Test
            </Button>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default TestPreferenceRuleModal
