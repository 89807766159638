import React, { useState, useEffect, useContext } from 'react'
import {
  Box,
  Fab,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import CheckIcon from '@material-ui/icons/Check'

import { GlobalContext } from 'routes'
import StyledButton from 'components/common/button'
import { createPartNumberRuleAPI } from 'services/helpers/apis/partNumberRules'

const useStyles = makeStyles((theme) => ({
  tableActions: {
    position: 'fixed',
    left: 170,
    bottom: 60,
    right: 20,
    backgroundColor: 'white',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

const TableActions = ({ onDelete, bulkActionDisabled }) => {
  const context = useContext(GlobalContext)
  const [action, setAction] = useState('')
  const [openCreatePartNumberModal, setOpenCreatePartNumberModal] = useState(
    false
  )
  const [partNumberName, setPartNumberName] = useState('')
  const classes = useStyles()
  const history = useHistory()

  const onChangeBulkAction = (e) => {
    setAction(e.target.value)
    if (e.target.value === 'delete') {
      onDelete()
    }
  }

  useEffect(() => {
    if (bulkActionDisabled) {
      setAction('')
    }
  }, [bulkActionDisabled])

  const onCloseModal = () => {
    setPartNumberName('')
    setOpenCreatePartNumberModal(false)
  }

  const onChangeName = (e) => {
    setPartNumberName(e.target.value)
  }

  const onCreatePartNumberRule = async () => {
    try {
      const res = await createPartNumberRuleAPI({
        name: partNumberName,
      })

      history.push(
        `/admin/supplier_part_number_rules/update/${res.data.data.uuid}`
      )

      onCloseModal()
    } catch (e) {
      context.onApiError(e)
    }
  }

  return (
    <Box
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      className={classes.tableActions}
    >
      <Box>
        <FormControl variant='outlined' className={classes.formControl}>
          <InputLabel id='bulk-actions-select-label'>Bulk Actions</InputLabel>
          <Select
            labelId='bulk-actions-select-label'
            id='bulk-actions-select-outlined'
            value={action}
            onChange={onChangeBulkAction}
            label='Bulk Actions'
            disabled={bulkActionDisabled}
          >
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            <MenuItem value='delete'>Delete</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box>
        <Fab color='primary' aria-label='check' style={{ marginRight: '1rem' }}>
          <CheckIcon />
        </Fab>
        <Fab
          color='primary'
          aria-label='add'
          onClick={() => {
            setOpenCreatePartNumberModal(true)
          }}
        >
          <AddIcon />
        </Fab>
      </Box>
      <Dialog
        open={openCreatePartNumberModal}
        onClose={onCloseModal}
        aria-labelledby='form-dialog-title'
        disableBackdropClick
      >
        <DialogTitle id='form-dialog-title'>
          Create a new supplier part number rule
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin='dense'
            id='name'
            label='Name'
            fullWidth
            value={partNumberName}
            onChange={onChangeName}
          />
        </DialogContent>
        <DialogActions>
          <StyledButton
            onClick={onCloseModal}
            className='btn-orange'
            color='primary'
            variant='contained'
          >
            Cancel
          </StyledButton>
          <Button
            color='primary'
            variant='contained'
            disabled={partNumberName === ''}
            onClick={onCreatePartNumberRule}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default TableActions
