import React from 'react'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'

const Tracking = ({ data }) => {
  return (
    <div className='tab-content' style={{ textAlign: 'center' }}>
      <h1>{data.delivery_summary}</h1>
      <p>{data.delivery_summary_subtext}</p>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <h3>Status</h3>
        </Grid>
        <Grid item xs={4}>
          <h3>Location</h3>
        </Grid>
        <Grid item xs={4}>
          <h3>Local Time</h3>
        </Grid>
      </Grid>
      {data.activity.map((activity, index) => (
        <Grid container key={index}>
          <Grid item xs={4}>
            <p className='status-text'>
              <Icon style={{ color: `#${activity.color}` }}>
                {activity.icon}
              </Icon>{' '}
              {activity.status}
            </p>
          </Grid>
          <Grid item xs={4}>
            <p>
              {activity.address.city}, {activity.address.state}
              {activity.address.country_code
                ? `, ${activity.address.country_code}`
                : ''}
            </p>
          </Grid>
          <Grid item xs={4}>
            <p>{activity.timestamp.formatted_date}</p>
          </Grid>
        </Grid>
      ))}
    </div>
  )
}

export default Tracking
