import React, { useState } from 'react'
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import SearchBox from './searchBox'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

const FilterBox = ({ services, onChangeFilter, tableFor }) => {
  const classes = useStyles()
  const [service, setService] = useState('')
  const [term, setTerm] = useState()
  const [showOnlyUnassignedName, setShowOnlyUnassignedName] = useState(false)

  const onChangeTerm = (value) => {
    setTerm(value)
    onChangeFilter(showOnlyUnassignedName, service, value)
  }

  const onChangeService = (event) => {
    setService(event.target.value)
    onChangeFilter(showOnlyUnassignedName, event.target.value, term)
  }

  const handleChange = (event) => {
    setShowOnlyUnassignedName(event.target.checked)
    onChangeFilter(event.target.checked, service, term)
  }

  return (
    <Box display='flex' alignItems='center'>
      <FormControlLabel
        control={
          <Checkbox
            name='checkedB'
            color='primary'
            onChange={handleChange}
            checked={showOnlyUnassignedName}
          />
        }
        label='Show only unassigned name variants'
      />
      <Box mr={3}>
        <SearchBox onChangeTerm={onChangeTerm} tableFor={tableFor} />
      </Box>
      <Typography style={{ marginRight: '1rem' }}>Filter By:</Typography>
      <FormControl variant='outlined' className={classes.formControl}>
        <InputLabel id='service-select-label'>Service</InputLabel>
        <Select
          labelId='service-select-label'
          value={service}
          onChange={onChangeService}
          label='Service'
        >
          <MenuItem value=''>
            <em>None</em>
          </MenuItem>
          {services.map((service, i) => (
            <MenuItem value={service} key={i}>
              {service}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

export default FilterBox
