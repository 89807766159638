import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import styled from 'styled-components'
import _find from 'lodash/find'
import { toast } from 'react-toastify'

import { setPasswordAPI } from 'services/helpers/apis/user'
import adminActions from 'services/redux/admin/actions'
import {
  ProfileForm,
  ProfileView,
  ChangePasswordForm,
  AuthenticatedBrowsers,
  MetaData,
} from './components'

const { readUser, updateUser, changePassword, onApiError } = adminActions

const EditUserContainer = styled.div`
  .wrapper {
    .card {
      height: 100%;

      .card-content {
        max-height: 300px;
        overflow-y: auto;
      }
    }
  }
`

class EditUser extends Component {
  static propTypes = {
    self: PropTypes.bool,
    userData: PropTypes.shape(),
    editable: PropTypes.bool,
    fromRoute: PropTypes.bool,
    userName: PropTypes.string,
  }

  static defaultProps = {
    self: false,
    userData: null,
    editable: true,
    fromRoute: false,
    userName: null,
  }

  state = {
    editUserName: null,
    userId: null,
  }

  componentDidMount() {
    const { self, users, fromRoute } = this.props

    if (users.length === 0) {
      this.props.history.push('/admin/user/list')
    } else {
      // eslint-disable-next-line func-names
      const username = this.props.fromRoute
        ? this.props.match.params.userName
        : this.props.userName

      const user = _find(users, function(x) {
        if (self) {
          return x.self === true
        }

        return x.username === username
      })

      this.setState({
        userId: user.id,
      })
    }

    if (self) {
      this.props.readUser('self')
      this.setState({
        editUserName: 'self',
      })
    } else {
      const userName = fromRoute
        ? this.props.match.params.userName
        : this.props.userName
      this.setState({
        editUserName: userName,
      })
      this.props.readUser(userName)
    }
  }

  profileFormSubmit = (values) => {
    const { editUserName } = this.state
    const userData = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      phone: values.phone,
    }

    this.props.updateUser(editUserName, userData)
  }

  onChangePassword = (values) => {
    const { editUserName, userId } = this.state
    const { self } = this.props

    if (self) {
      const passwords = {
        old_password: values.oldPassword,
        new_password: values.newPassword,
      }

      this.props.changePassword(editUserName, passwords)
    } else {
      const updateData = {
        user_id: userId,
        password: values.newPassword,
        send_email: values.emailUserNewPassword,
        force_password_change: values.forceChangePassword,
      }

      setPasswordAPI(updateData)
        .then((res) => {
          if (res.data.code === 201) {
            if (res.data.message) {
              toast.success(res.data.message)
            } else {
              toast.success('Password Changed')
            }
          } else {
            toast.warn(res.data.message)
          }
        })
        .catch((err) => {
          this.props.onApiError(err)
        })
    }
  }

  render() {
    const { userData, self, editable } = this.props
    const { userId } = this.state
    const changePasswordValues = {
      newPassword: '',
      emailUserNewPassword: false,
      forceChangePassword: false,
    }

    return (
      <EditUserContainer>
        {userData && userId && (
          <div className='wrapper'>
            {editable ? (
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Card className='card'>
                    <CardHeader title={`User Profile: ${userData.username}`} />
                    <CardContent className='card-content'>
                      <ProfileForm
                        initialValues={userData}
                        onSubmit={this.profileFormSubmit}
                      />
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={6}>
                  <Card className='card'>
                    <CardHeader title='Change Password' />
                    <CardContent className='card-content'>
                      <ChangePasswordForm
                        self={self}
                        onSubmit={this.onChangePassword}
                        initialValues={changePasswordValues}
                      />
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={6}>
                  <Card className='card'>
                    <CardHeader title='Authenticated Browser' />
                    <CardContent className='card-content'>
                      <AuthenticatedBrowsers
                        tokenObject={userData.token_object}
                        userId={userId}
                        self={self}
                      />
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={6}>
                  <Card className='card'>
                    <CardHeader title='User Metadata' />
                    <CardContent className='card-content'>
                      <MetaData
                        userData={userData}
                        userId={userId}
                        self={self}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Card className='card'>
                    <CardHeader title={`User Profile: ${userData.username}`} />
                    <CardContent className='card-content'>
                      <ProfileView userData={userData} editable={false} />
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={6}>
                  <Card className='card'>
                    <CardHeader title='Authenticated Browser' />
                    <CardContent className='card-content'>
                      <AuthenticatedBrowsers
                        tokenObject={userData.token_object}
                        userId={userId}
                        self={self}
                        editable={false}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            )}
          </div>
        )}
      </EditUserContainer>
    )
  }
}

const mapStateToProps = (state) => ({
  userData: state.admin.userData,
  users: state.admin.userList.users,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      readUser,
      updateUser,
      changePassword,
      onApiError,
    },
    dispatch
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditUser)
)
