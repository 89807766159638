import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import _remove from 'lodash/remove'

import rfqActions from 'services/redux/rfq/actions'
import { addRfqQuoteAPI } from 'services/helpers/apis/rfq'
import { GlobalContext } from 'routes'

const { onMoveNonbidItem, resetMovingQuotedItem } = rfqActions

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  btnUpArrow: {
    cursor: 'pointer',
  },
})

function NonBidItemsTable({
  rows,
  onMoveNonbidItem,
  movingQuotedItem,
  resetMovingQuotedItem,
}) {
  const [tableRows, setTableRows] = useState(rows)
  const classes = useStyles()
  const context = useContext(GlobalContext)

  useEffect(() => {
    if (movingQuotedItem) {
      setTableRows([
        ...tableRows,
        {
          uuid: movingQuotedItem.rfq_line.uuid,
          quantity: movingQuotedItem.quantity,
          supplier_part_number: movingQuotedItem.supplier_part_number,
          requisition_detail: movingQuotedItem.rfq_line.requisition_detail,
          item: movingQuotedItem.item,
        },
      ])
      resetMovingQuotedItem()
    }
  }, [movingQuotedItem])

  const onUpArrowClicked = async (rfqLine) => {
    try {
      const res = await addRfqQuoteAPI(rfqLine.uuid, null)
      const rows = tableRows
      _remove(rows, function(n) {
        return n.uuid === rfqLine.uuid
      })
      setTableRows([...rows])
      onMoveNonbidItem(res.data.data)
    } catch (e) {
      context.onApiError(e)
    }
  }

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell>Quantity</TableCell>
            <TableCell>Requisition Number</TableCell>
            <TableCell>Part Number</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableRows.map((row) => (
            <TableRow key={row.item}>
              <TableCell component='th' scope='row'>
                {row.quantity}
              </TableCell>
              <TableCell>
                {row.requisition_detail ? (
                  <Link to={`/requisitions/${row.requisition_detail.order_id}`}>
                    {row.requisition_detail.order_id}
                  </Link>
                ) : (
                  ''
                )}
              </TableCell>
              <TableCell>{row.item}</TableCell>
              <TableCell align='right'>
                <ArrowUpwardIcon
                  className={classes.btnUpArrow}
                  onClick={() => onUpArrowClicked(row)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const mapStateToProps = (state) => ({
  movingQuotedItem: state.rfq.moving_quoted_item,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ onMoveNonbidItem, resetMovingQuotedItem }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(NonBidItemsTable)
