import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import styled from 'styled-components'

const ReduxCheckBoxFieldContainer = styled.div`
  label.disabled {
    pointer-events: none;
  }
`

const ReduxCheckBoxField = ({ input, label, disabled, clickable = true }) => (
  <ReduxCheckBoxFieldContainer>
    <FormControlLabel
      className={`${!clickable ? 'disabled' : ''}`}
      control={
        <Checkbox
          color="primary"
          checked={!!input.value}
          onChange={input.onChange}
          disabled={disabled}
        />
      }
      label={label}
    />
  </ReduxCheckBoxFieldContainer>
)

export default ReduxCheckBoxField
