import React, { Component } from 'react'
import { Field, FormSection } from 'redux-form'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'

import { ReduxTreeCheckboxField } from 'components/reduxFormFields'

class Users extends Component {
  componentDidMount() {}

  render() {
    const { userInitialValue } = this.props
    const userScopes = Object.getOwnPropertyNames(userInitialValue)

    return (
      <Card>
        <CardHeader title="Users" />
        <CardContent>
          <FormSection name="scopes">
            <FormSection name="user">
              {userScopes.map((scope, i) => (
                <Field
                  component={ReduxTreeCheckboxField}
                  fullWidth
                  name={scope}
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                />
              ))}
            </FormSection>
          </FormSection>
        </CardContent>
      </Card>
    )
  }
}

export default Users
