import React from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'

function SimpleDialog(props) {
  const { onClose, selectedValue, open, title } = props

  function handleClose() {
    onClose(selectedValue)
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby='simple-dialog-title'
      open={open}
      maxWidth='lg'
      fullWidth
    >
      {title && <DialogTitle id='simple-dialog-title'>{title}</DialogTitle>}
      <DialogContent>{props.children}</DialogContent>
    </Dialog>
  )
}

export default SimpleDialog
