import { client } from '../apiConfig'

const fetchSuppliersAPI = () => {
  return client.get('/suppliers/list')
}

const readSupplierAPI = (uuid) => {
  return client.get(`/supplier/read/${uuid}`)
}

const updateSupplierProfileAPI = (uuid, profileObj) => {
  return client.put(`/supplier/update/${uuid}`, profileObj)
}

const createSupplierProfileAPI = (profileObj) => {
  return client.post('/supplier/create', profileObj)
}

const searchSupplierAPI = (str) => {
  return client.get(`/supplier/search?term=${str}`)
}

const uploadSupplierLogoAPI = (uuid, file) => {
  const formData = new FormData()
  formData.append('file', file)
  const config = {
    headers: {
      'content-type': 'application/octet-stream',
    },
  }
  return client.post(`/suppliers/logo/${uuid}/upload`, formData, config)
}

const unlinkUserSupplierAPI = (uuid) => {
  return client.put(`/supplier/unlink_user/${uuid}`)
}

export {
  fetchSuppliersAPI,
  readSupplierAPI,
  updateSupplierProfileAPI,
  createSupplierProfileAPI,
  searchSupplierAPI,
  uploadSupplierLogoAPI,
  unlinkUserSupplierAPI,
}
