import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import Grid from '@material-ui/core/Grid'
import Search from '@material-ui/icons/Search'
import InputAdornment from '@material-ui/core/InputAdornment'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import { client } from 'services/helpers/apiConfig'

import {
  ReduxSelectField,
  ReduxTextField,
  ReduxRadioButton,
} from 'components/reduxFormFields'

class FilterForm extends Component {
  state = {
    userTypes: [],
  }

  componentDidMount() {
    client
      .get('userType/list')
      .then((res) => {
        res.data.data.map((type, index) => {
          return this.setState((prevState) => ({
            userTypes: prevState.userTypes.concat({
              name: type.user_type,
              key: index,
            }),
          }))
        })
      })
      .catch(() => {})
  }

  filterFieldChange = (event, newValue) => {
    const { onFilterChange } = this.props

    onFilterChange({ [event.target.name]: newValue })
  }

  render() {
    const { userTypes } = this.state
    const { handleSubmit } = this.props

    return (
      <form onSubmit={handleSubmit}>
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <Grid container spacing={3} alignItems="center">
              <Grid item>
                {userTypes.length > 0 && (
                  <Field
                    name="userType"
                    component={ReduxSelectField}
                    label="User Type"
                    id="field-user-type"
                    className="mb-12"
                    onChange={this.filterFieldChange}
                    // nolabel
                  >
                    <option value="" disabled />
                    <option value="all">All</option>
                    {userTypes.map((type) => (
                      <option value={type.name} key={type.key}>
                        {type.name}
                      </option>
                    ))}
                  </Field>
                )}
              </Grid>
              <Grid item>
                <Field
                  name="active"
                  component={ReduxRadioButton}
                  onChange={this.filterFieldChange}
                >
                  <FormControlLabel
                    value="all"
                    control={<Radio />}
                    label="All"
                  />
                  <FormControlLabel
                    value="active"
                    control={<Radio />}
                    label="Active"
                  />
                  <FormControlLabel
                    value="inactive"
                    control={<Radio />}
                    label="Inactive"
                  />
                </Field>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container alignItems="flex-end" justify="flex-end">
              <Grid item>
                <Field
                  name="userName"
                  className="mb-12"
                  component={ReduxTextField}
                  label="Search"
                  fullWidth
                  onChange={this.filterFieldChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    )
  }
}

const FilterReduxForm = reduxForm({
  form: 'userListFilterForm',
})(FilterForm)

export default FilterReduxForm
