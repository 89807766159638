import { client } from '../apiConfig'

const readRequisitionAPI = (uuid) => {
  return client.get(`/requisitions/read/${uuid}`)
}

const getRequisitionsSearchAPI = (term, assignee, status) => {
  return client.get(
    `/requisitions/search/?term=${term}&assignee=${assignee}&status=${status}`
  )
}

const updateRequisitionAPI = (uuid, params) => {
  if (uuid) {
    return client.put(`/requisitions/update/${uuid}`, params)
  }
  return client.put(`/requisitions/update`, params)
}

const batchUpdateStatusAPI = (uuids, statusId) => {
  return client.put('/requisitions/status/update/batch', {
    status_id: statusId,
    requisition_uuids: uuids,
  })
}

const getRequisitionPriceHistoryAPI = (uuid, cancelToken) => {
  return client.get(`/requisitions/price_history/${uuid}`, {
    cancelToken,
  })
}

const getRequisitionInventoryAPI = (uuid) => {
  return client.get(`/requisitions/inventory/${uuid}`)
}

const getRequisitionRfqpoAPI = (uuid, cancelToken) => {
  return client.get(`/requisitions/rfqpo/${uuid}`, {
    cancelToken,
  })
}

const getRequisitionShipmentsAPI = (uuid) => {
  return client.get(`/requisitions/shipments/${uuid}`)
}

const getRequisitionHistoryAPI = (uuid) => {
  return client.get(`/requisitions/history/${uuid}`)
}

const deleteRequisitionsAPI = (orderIds) => {
  return client.delete(`/requisitions/delete/${orderIds.toString()}`)
}

export {
  readRequisitionAPI,
  getRequisitionsSearchAPI,
  updateRequisitionAPI,
  getRequisitionPriceHistoryAPI,
  getRequisitionInventoryAPI,
  getRequisitionRfqpoAPI,
  getRequisitionShipmentsAPI,
  getRequisitionHistoryAPI,
  batchUpdateStatusAPI,
  deleteRequisitionsAPI
}
