import React, { useState, useEffect, useContext, useRef } from 'react'
import { Editor, EditorState } from 'draft-js'
import 'draft-js/dist/Draft.css'
import { stateToHTML } from 'draft-js-export-html'
import { stateFromHTML } from 'draft-js-import-html'
import _filter from 'lodash/filter'
import {
  NativeSelect,
  Tooltip,
  FormControl,
  Button,
  IconButton,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import { toast } from 'react-toastify'

import {
  sendThreadDraftAPI,
  updateThreadDraftAPI,
  deleteThreadDraftAPI,
} from 'services/helpers/apis/thread'
import { GlobalContext } from 'routes'
import { generateQuotesHTMLContent } from 'utils/common'
import usePrevious from 'utils/usePrevious'

import ReplyMessageContainer from './replyMessage.style'
import { CorrespondenceTableContext } from '../index'

const WAIT_INTERVAL = 1000
let timer

const DraftMessage = ({
  message,
  selectedMessageIndex,
  selectedLine,
  onCorrespondenceUpdated,
  closeDraft,
}) => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  )
  const prevEditorState = usePrevious(editorState)
  const [isShowQuoteMessages, setIsShowQuoteMessages] = useState(false)
  const [isFirstLoadedContent, setIsFirstLoadedContent] = useState(false)
  const [replyTypeValue, setReplyTypeValue] = useState(
    message.is_internal ? 'internal' : 'supplier'
  )
  const context = useContext(GlobalContext)
  const correspondenceContext = useContext(CorrespondenceTableContext)
  const textInput = useRef(null)

  const isFirstMessage =
    selectedMessageIndex ===
    correspondenceContext.correspondence.messages.length - 1

  useEffect(() => {
    timer = null
    const contentState = stateFromHTML(message.html_body)

    setIsFirstLoadedContent(true)
    setEditorState(EditorState.createWithContent(contentState))
  }, [])

  useEffect(() => {
    setReplyTypeValue(message.is_internal ? 'internal' : 'supplier')
  }, [message])

  const onUpdateThreadDraft = async () => {
    try {
      const html = stateToHTML(editorState.getCurrentContent())
      const res = await updateThreadDraftAPI(message.uuid, {
        is_internal: replyTypeValue === 'internal',
        body: html,
        is_quote_attached: isShowQuoteMessages,
      })
      onCorrespondenceUpdated(res.data.data)
    } catch (e) {
      context.onApiError(e)
    }
  }

  const onChangeReplyingTypeOption = (e) => {
    setReplyTypeValue(e.target.value)
    onUpdateThreadDraft()
  }

  const onMessageContentUpdated = (editorState) => {
    if (isFirstLoadedContent) {
      setIsFirstLoadedContent(false)
    } else {
      setEditorState(editorState)
    }
  }

  useEffect(() => {
    const isEditorStateChanged =
      prevEditorState &&
      stateToHTML(prevEditorState.getCurrentContent()) !==
        stateToHTML(editorState.getCurrentContent())

    if (!isFirstLoadedContent && isEditorStateChanged) {
      clearTimeout(timer)
      timer = setTimeout(() => {
        onUpdateThreadDraft()
      }, WAIT_INTERVAL)
    }

    if (isShowQuoteMessages && isEditorStateChanged) {
      onUpdateThreadDraft()
    }
  }, [editorState, prevEditorState])

  const onSendReply = async () => {
    setTimeout(async () => {
      try {
        const html = stateToHTML(editorState.getCurrentContent())
        await updateThreadDraftAPI(message.uuid, {
          is_internal: replyTypeValue === 'internal',
          body: html,
          is_quote_attached: isShowQuoteMessages,
        })
        const res = await sendThreadDraftAPI(message.uuid)

        onCorrespondenceUpdated(res.data.data)
        closeDraft()
        toast.success('Message is sent.')
      } catch (e) {
        context.onApiError(e)
      }
    }, 1000)
  }

  const onDeleteDraft = async () => {
    try {
      const res = await deleteThreadDraftAPI(message.uuid)
      toast.success('Draft message is deleted')
      onCorrespondenceUpdated(res.data.data)
      closeDraft()
    } catch (e) {
      context.onApiError(e)
    }
  }

  const onShowTrimmedContent = () => {
    setIsShowQuoteMessages(true)
    const existingContent = stateToHTML(editorState.getCurrentContent())
    const { messages } = correspondenceContext.correspondence
    messages.splice(0, selectedMessageIndex + 1)
    const quoteMessages = _filter(messages, function(o) {
      return !o.is_draft
    })

    const quotesHtmlContent = generateQuotesHTMLContent(
      existingContent,
      quoteMessages
    )
    const contentState = stateFromHTML(quotesHtmlContent)

    setEditorState(EditorState.createWithContent(contentState))
  }

  return (
    <ReplyMessageContainer>
      <div className='wrapper'>
        <div className='reply-header'>
          <div className='reply-to'>
            <div className='reply-option'>
              <FormControl>
                <NativeSelect
                  value={replyTypeValue}
                  name='type'
                  onChange={onChangeReplyingTypeOption}
                  inputProps={{ 'aria-label': 'message-type' }}
                >
                  <option value='internal'>Internal Note</option>
                  <option value='supplier'>
                    Message to Supplier ({selectedLine.supplier})
                  </option>
                </NativeSelect>
              </FormControl>
            </div>
          </div>
          <span className='text-draft'>Draft</span>
        </div>
        <div className='reply-content'>
          <Editor
            editorState={editorState}
            onChange={onMessageContentUpdated}
            ref={textInput}
          />
          {!message.is_quote_attached &&
            !isFirstMessage &&
            !isShowQuoteMessages && (
              <Tooltip title='Show trimmed content'>
                <Button
                  variant='contained'
                  onClick={onShowTrimmedContent}
                  className='btn-show-quote'
                >
                  <MoreHorizIcon />
                </Button>
              </Tooltip>
            )}
        </div>
        <div className='reply-buttons'>
          <IconButton aria-label='delete' onClick={onDeleteDraft}>
            <DeleteIcon />
          </IconButton>
          <Button variant='contained' color='primary' onClick={onSendReply}>
            Send
          </Button>
        </div>
      </div>
    </ReplyMessageContainer>
  )
}

export default DraftMessage
