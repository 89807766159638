import React, { useEffect } from 'react'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

import { updateRfqStatusAPI } from 'services/helpers/apis/rfq'
import { updatePoStatusAPI } from 'services/helpers/apis/po'

import StatusDropdownContainer from './index.style'

const StyledMenuItem = withStyles(() => ({
  root: {
    '&.disabled': {
      pointerEvents: 'none',
      opacity: '0.3',
    },
  },
}))(MenuItem)

export default function StatusDropdown({
  statuses,
  statusId,
  uuid,
  onStatusUpdated,
  loadingStatuses,
  statusFor,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [selectedStatus, setSelectedStatus] = React.useState(
    statuses[statusId - 1]
  )

  useEffect(() => {
    setSelectedStatus(statuses[statusId - 1])
  }, [statusId])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const onSelectStatus = async (status) => {
    setAnchorEl(null)
    try {
      if (statusFor === 'rfq') await updateRfqStatusAPI(uuid, status.id)
      else await updatePoStatusAPI(uuid, status.id)
      setSelectedStatus(status)
      onStatusUpdated()
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <StatusDropdownContainer>
      {loadingStatuses ? (
        <Button variant="contained" disabled>
          <CircularProgress size={25} />
        </Button>
      ) : (
        <div>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
            style={{ backgroundColor: selectedStatus.color, color: '#fff' }}
          >
            {selectedStatus.status}
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            {statuses.map((status, i) => (
              <StyledMenuItem
                onClick={() => onSelectStatus(status)}
                key={i}
                className={`${status.disabled && 'disabled'}`}
              >
                {status.status}
              </StyledMenuItem>
            ))}
          </Menu>
        </div>
      )}
    </StatusDropdownContainer>
  )
}
