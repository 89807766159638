import React, { Component } from 'react'
import { Field } from 'redux-form'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import { ReduxTextField, ReduxCheckBoxField } from 'components/reduxFormFields'

class UserTypeProfile extends Component {
  componentDidMount() {}

  render() {
    const { isAdmin } = this.props

    return (
      <Card>
        <CardHeader title="User Type Profile" />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Field
                name="user_type"
                label="User Type Name"
                component={ReduxTextField}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                name="admin"
                label="Administrator"
                component={ReduxCheckBoxField}
                fullWidth
                disabled={!isAdmin}
                clickable={false}
              />
            </Grid>
            <Grid item xs={3}>
              <Button type="submit" variant="contained" color="primary">
                Update
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    )
  }
}

export default UserTypeProfile
