import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import CardContent from '@material-ui/core/CardContent'

import logo from 'assets/images/logo.svg'
import authActions from 'services/redux/auth/actions'

import AuthContainer, { AuthCardContainer } from './index.style'

const { login, forgotPassword, updatePassword } = authActions

class AuthWrapper extends Component {
  static propTypes = {
    onLogin: PropTypes.func.isRequired,
    onForgotPassword: PropTypes.func.isRequired,
    onUpdatePassword: PropTypes.func.isRequired,
    children: PropTypes.element.isRequired,
    auth: PropTypes.shape().isRequired,
  }

  componentDidMount() {
    const { auth } = this.props
    const isLoggedIn = auth.loggedIn

    if (isLoggedIn) {
      this.props.history.push('/')
    }
  }

  submit = (values) => {
    const {
      auth,
      onLogin,
      onForgotPassword,
      onUpdatePassword,
      location,
    } = this.props

    switch (location.pathname) {
      case '/login':
        onLogin(values)
        break
      case '/forgotPassword':
        onForgotPassword(values.userName)
        break
      case '/googleAuthenticatorCode':
        if (auth.authData) {
          onLogin({ ...auth.authData, ...values })
        } else {
          console.log('null')
        }
        break
      case '/changePassword':
        onUpdatePassword(auth.loginStatus.data.user.username, values)
        break
      default:
        break
    }
  }

  render() {
    const { auth, location } = this.props

    let oldPassword = ''
    if (location.pathname === '/changePassword') {
      oldPassword = auth.authData.password
    }

    const children = React.Children.map(this.props.children, (child) => {
      if (!child.props.noprops) {
        return React.cloneElement(child, {
          loading: auth.loading,
          onSubmit: this.submit,
          initialValues:
            oldPassword !== ''
              ? { ...child.props.initialValues, oldPassword }
              : { ...child.props.initialValues },
        })
      }

      return React.cloneElement(child)
    })

    return (
      <AuthContainer>
        <AuthCardContainer>
          <CardContent className='card-content'>
            <img src={logo} className='logo' alt='Turnerfox Purchasing' />
            {children}
          </CardContent>
        </AuthCardContainer>
      </AuthContainer>
    )
  }
}

const mapStateToProps = (state) => ({ auth: state.auth })

const mapDispatchToProps = (dispatch) => ({
  onLogin: bindActionCreators(login, dispatch),
  onForgotPassword: bindActionCreators(forgotPassword, dispatch),
  onUpdatePassword: bindActionCreators(updatePassword, dispatch),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AuthWrapper)
)
