import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import Link from '@material-ui/core/Link'
import ReactTable from 'react-table'
import selectTableHOC from 'react-table/lib/hoc/selectTable'
import { Done, Close } from '@material-ui/icons'
import Checkbox from '@material-ui/core/Checkbox'
import _filter from 'lodash/filter'
import _isEqual from 'lodash/isEqual'
import PropTypes from 'prop-types'

import TableActions from './tableActions'

const SelectTable = selectTableHOC(ReactTable)

class UserListTable extends Component {
  static propTypes = {
    users: PropTypes.shape().isRequired,
  }

  constructor(props) {
    super(props)

    const allAvailableKeys = []
    props.users.map((item) => {
      if (item.can_edit && !item.self) {
        allAvailableKeys.push(item.id)
      }
      return item
    })

    this.state = {
      checkAllChecked: false,
      allAvailableKeys,
      checkedKeys: [],
      tableData: props.users,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!_isEqual(nextProps.users, this.props.users)) {
      this.props = nextProps

      this.setState({
        tableData: nextProps.users,
      })

      if (nextProps.users.length > 0) {
        const allAvailableKeys = []
        nextProps.users.map((item) => {
          if (item.can_edit && !item.self) {
            allAvailableKeys.push(item.id)
          }
          return item
        })

        this.setState({
          tableData: nextProps.users,
          allAvailableKeys,
        })
      }
    }

    if (!_isEqual(nextProps.filterOption, this.props.filterOption)) {
      const { filterOption } = nextProps
      let filterQuery = {}

      if (filterOption.userType !== 'all') {
        filterQuery = {
          ...filterQuery,
          user_type: filterOption.userType,
        }
      }

      if (filterOption.active !== 'all') {
        filterQuery = {
          ...filterQuery,
          active: filterOption.active === 'active',
        }
      }

      this.setState(
        {
          tableData: _filter(nextProps.users, filterQuery),
        },
        () => {
          if (filterOption.userName !== '') {
            this.setState((prevState) => ({
              tableData: _filter(prevState.tableData, (data) => {
                return (
                  data.name
                    .toLowerCase()
                    .includes(filterOption.userName.toLowerCase()) ||
                  data.username
                    .toLowerCase()
                    .includes(filterOption.userName.toLowerCase())
                )
              }),
            }))
          }
        }
      )
    }

    if (nextProps.bulkUpdated === true) {
      this.setState({
        checkedKeys: [],
        checkAllChecked: false,
      })
    }
  }

  render() {
    const { allAvailableKeys } = this.state
    const { tableData, checkedKeys, checkAllChecked } = this.state
    const columns = [
      {
        Header: 'Name',
        accessor: 'name', // String-based value accessors!
      },
      {
        Header: 'Username',
        accessor: 'username',
      },
      {
        Header: 'User Type',
        accessor: 'user_type', // Custom value accessors!
      },
      {
        Header: 'Last Login', // Custom header components!
        accessor: 'last_login.formatted_date',
      },
      {
        id: 'active',
        Header: 'Active',
        accessor: 'active',
        maxWidth: 200,
        Cell: (row) => (row.value ? <Done /> : <Close />),
      },
      {
        id: 'edit',
        Header: '',
        accessor: 'can_edit',
        Cell: (row) => {
          const linkTo = row.original.self
            ? '/admin/user/update/self'
            : `${
                row.original.can_edit
                  ? `/admin/user/update/${row.original.username}`
                  : `/admin/user/read/${row.original.username}`
              }`

          return (
            <Link
              component={RouterLink}
              variant="body2"
              to={linkTo}
              style={{ color: row.original.self ? 'white' : '' }}
            >
              {row.value ? 'Edit' : 'View'}
            </Link>
          )
        },
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
      },
    ]

    return (
      <div className="userlist-table">
        <SelectTable
          data={tableData}
          columns={columns}
          defaultPageSize={5}
          keyField="id"
          selectType="checkbox"
          selectAll
          selectWidth={60}
          minRows={3}
          getTrProps={(state, rowInfo) => {
            if (rowInfo) {
              return {
                style: {
                  background: rowInfo.row._original.self ? '#3f51b5' : '',
                  color: rowInfo.row._original.self ? 'white' : '',
                },
              }
            }

            return {}
          }}
          isSelected={(key) => {
            if (checkAllChecked || checkedKeys.indexOf(`${key}`) > -1) {
              return true
            }

            return false
          }}
          toggleSelection={(e) => {
            const key = e.target.value

            if (e.target.checked) {
              if (checkedKeys.indexOf(key) === -1) {
                this.setState(
                  (prevState) => ({
                    checkedKeys: prevState.checkedKeys.concat(key),
                  }),
                  () => {
                    if (checkedKeys.length === allAvailableKeys.length) {
                      this.setState({
                        checkAllChecked: true,
                      })
                    }
                  }
                )
              }
            } else if (!e.target.checked && checkedKeys.indexOf(key) > -1) {
              // eslint-disable-next-line react/no-access-state-in-setstate
              const array = [...checkedKeys] // make a separate copy of the array
              const index = array.indexOf(key)

              if (index !== -1) {
                array.splice(index, 1)
                this.setState({
                  checkAllChecked: false,
                  checkedKeys: array,
                })
              }
            }
          }}
          toggleAll={() => {
            this.setState((prevState) => ({
              checkedKeys: prevState.checkAllChecked
                ? []
                : [...allAvailableKeys],
              checkAllChecked: !prevState.checkAllChecked,
            }))
          }}
          SelectAllInputComponent={(props) => {
            return (
              <Checkbox
                checked={checkAllChecked}
                onChange={props.onClick}
                value="checkAll"
              />
            )
          }}
          SelectInputComponent={(props) => {
            return (
              <Checkbox
                disabled={!props.row.can_edit || props.row.self}
                checked={
                  !props.row.can_edit || props.row.self ? false : props.checked
                }
                onChange={props.onClick}
                value={props.id}
              />
            )
          }}
        />
        <TableActions checkedKeys={checkedKeys} />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  users: state.admin.userList.users,
  bulkUpdated: state.admin.bulkUpdated,
})

export default connect(mapStateToProps)(UserListTable)
