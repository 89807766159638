import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { getRequisitionHistoryAPI } from 'services/helpers/apis/requisition'
import adminActions from 'services/redux/admin/actions'
import PurchaseOrderHistory from 'components/purchaseOrderHistory'

const { onApiError } = adminActions

class TabHistory extends Component {
  state = {
    historyData: null,
  }

  componentDidMount() {
    this.getRequisitionHistory()
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.assigneeUpdating === true &&
      nextProps.assigneeUpdating === false
    ) {
      this.getRequisitionHistory()
    }
  }

  getRequisitionHistory = async () => {
    const { uuid } = this.props

    this.setState({
      historyData: null,
    })

    try {
      const res = await getRequisitionHistoryAPI(uuid)

      this.setState({
        historyData: res.data.data.history,
      })
    } catch (e) {
      this.props.onApiError(e)
    }
  }

  render() {
    const { historyData } = this.state

    return (
      <div>
        {historyData && <PurchaseOrderHistory historyData={historyData} />}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  assigneeUpdating: state.admin.assigneeUpdating,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ onApiError }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(TabHistory)
