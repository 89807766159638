import React, { useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import AdminContainer from './index.style'
import AdminRoutes from './routes'

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#1890ff',
  },
})(Tabs)

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$selected': {
      color: '#1890ff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#40a9ff',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />)

const Admin = ({ history, match }) => {
  const [value, setValue] = React.useState(0)

  useEffect(() => {
    const location = history.location.pathname
    const pageRoute = location.split('/')[2]

    switch (pageRoute) {
      case 'user':
        setValue(0)
        break
      case 'supplier':
        setValue(1)
        break
      case 'supplier_part_number_rules':
        setValue(2)
        break
      case 'supplier_preference_rules':
        setValue(3)
        break
      case 'supplier_name_variants':
        setValue(4)
        break
      case 'manufacturer_name_variants':
        setValue(5)
        break
      default:
        break
    }
  }, [])

  const handleChange = (event, newValue) => {
    const routeURL = match.url
    setValue(newValue)

    if (newValue === 0) {
      history.push(`${routeURL}/user/list`)
    } else if (newValue === 1) {
      history.push(`${routeURL}/supplier/list`)
    } else if (newValue === 2) {
      history.push(`${routeURL}/supplier_part_number_rules`)
    } else if (newValue === 3) {
      history.push(`${routeURL}/supplier_preference_rules`)
    } else if (newValue === 4) {
      history.push(`${routeURL}/supplier_name_variants`)
    } else if (newValue === 5) {
      history.push(`${routeURL}/manufacturer_name_variants`)
    }
  }

  return (
    <AdminContainer>
      <div className='content'>
        <AntTabs value={value} onChange={handleChange} aria-label='ant example'>
          <AntTab label='Users' />
          <AntTab label='Suppliers' />
          <AntTab label='Supplier Part Number Rules' />
          <AntTab label='Supplier Preference Rules' />
          <AntTab label='Supplier Name Variants' />
          <AntTab label='Manufacturer Name Variants' />
        </AntTabs>

        <div className='tab-content'>
          <AdminRoutes />
        </div>
      </div>
    </AdminContainer>
  )
}

export default Admin
