import React from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Fab } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  tableActions: {
    position: 'fixed',
    left: 170,
    bottom: 60,
    right: 20,
    backgroundColor: 'white',
    textAlign: 'right',
  },
}))

const TableActions = () => {
  const history = useHistory()
  const classes = useStyles()

  return (
    <Box className={classes.tableActions}>
      <Fab
        color='primary'
        aria-label='add'
        onClick={() => {
          history.push('/admin/supplier/create')
        }}
      >
        <AddIcon />
      </Fab>
    </Box>
  )
}

export default TableActions
