import React, { useContext, useState } from 'react'
import {
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
} from '@material-ui/core'
import styled from 'styled-components'
import _find from 'lodash/find'
import { toast } from 'react-toastify'

import {
  updateRfqAPI,
  batchUpdateRfqStatusAPI,
  batchConfirmRfqAPI,
} from 'services/helpers/apis/rfq'
import { GlobalContext } from 'routes'
import { RfqListContext } from '../index'

const BulkActionsFormControl = styled(FormControl)`
  width: 200px;
`

const TableBulkActions = ({ checkedRfqs, bulkActionUpdated }) => {
  const [bulkAction, setBulkAction] = useState('')
  const [assignUser, setAssignUser] = useState('')
  const [status, setStatus] = useState('')
  const [openRfqSendModal, setOpenRfqSendModal] = useState(false)
  const context = useContext(GlobalContext)
  const rfqListContext = useContext(RfqListContext)

  const resetSelectors = () => {
    setBulkAction('')
    setAssignUser('')
    setStatus('')
  }

  const unAssignUser = async () => {
    try {
      await updateRfqAPI(null, {
        assignee: 0,
        id: checkedRfqs,
      })
      bulkActionUpdated({ assignee: null })
      resetSelectors()
      toast.success('Unassigned user successfully')
    } catch (e) {
      context.onApiError(e)
    }
  }

  const onSendRfq = async () => {
    try {
      await batchConfirmRfqAPI(checkedRfqs)
      setOpenRfqSendModal(false)
    } catch (e) {
      context.onApiError(e)
    }
  }

  const onBulkActionChange = (e) => {
    setBulkAction(e.target.value)
    if (e.target.value === 'unassign') {
      unAssignUser()
    } else if (e.target.value === 'sendRfq') {
      setOpenRfqSendModal(true)
      // onSendRfq()
    }
  }

  const getAssigneeObject = (id) => {
    const user = _find(rfqListContext.userList, { id })
    return {
      user_id: user.id,
      name: user.name,
    }
  }

  const onAssignUser = async (e) => {
    const { value } = e.target
    try {
      await updateRfqAPI(null, {
        assignee: value,
        id: checkedRfqs,
      })
      bulkActionUpdated({ assignee: getAssigneeObject(value) })
      resetSelectors()
      toast.success('Assigned to user successfully')
    } catch (e) {
      context.onApiError(e)
    }
    setAssignUser(e.target.value)
  }

  const onChangeStatus = async (e) => {
    const { value } = e.target
    setStatus(value)

    try {
      await batchUpdateRfqStatusAPI(checkedRfqs.toString(), value)
      const status = _find(rfqListContext.statusList, { id: value })
      bulkActionUpdated({ status_id: value, status: status.status })
      resetSelectors()
      toast.success('Changed status successfully')
    } catch (e) {
      context.onApiError(e)
    }
  }

  const handleClose = () => {
    setOpenRfqSendModal(false)
  }

  return (
    <Box display='flex'>
      <Box mr={1}>
        <BulkActionsFormControl variant='outlined'>
          <InputLabel id='select-bulkactions-label'>Bulk Actions</InputLabel>
          <Select
            labelId='select-bulkactions-label'
            id='select-bulkactions-outlined'
            value={bulkAction}
            onChange={onBulkActionChange}
            label='Bulk Actions'
            disabled={checkedRfqs.length === 0}
          >
            <MenuItem value=''>None</MenuItem>
            <MenuItem value='assign'>Assign to user</MenuItem>
            <MenuItem value='unassign'>Unassign</MenuItem>
            <MenuItem value='sendRfq'>Send RFQs</MenuItem>
            <MenuItem value='status'>Set Status</MenuItem>
          </Select>
        </BulkActionsFormControl>
      </Box>

      {bulkAction === 'assign' && (
        <Box mr={1}>
          <BulkActionsFormControl variant='outlined'>
            <InputLabel id='select-userlist-label'>User List</InputLabel>
            <Select
              labelId='select-userlist-label'
              id='select-userlist-outlined'
              value={assignUser}
              onChange={onAssignUser}
              label='User List'
              disabled={checkedRfqs.length === 0}
            >
              {rfqListContext.userList.map((user) => (
                <MenuItem value={user.id} key={user.id}>
                  {user.name}
                </MenuItem>
              ))}
            </Select>
          </BulkActionsFormControl>
        </Box>
      )}

      {bulkAction === 'status' && (
        <Box mr={1}>
          <BulkActionsFormControl variant='outlined'>
            <InputLabel id='select-statuslist-label'>Status List</InputLabel>
            <Select
              labelId='select-statuslist-label'
              id='select-statuslist-outlined'
              value={status}
              onChange={onChangeStatus}
              label='Status List'
              disabled={checkedRfqs.length === 0}
            >
              <MenuItem value=''>None</MenuItem>
              {rfqListContext.statusList.map((status) => (
                <MenuItem value={status.id} key={status.id}>
                  {status.status}
                </MenuItem>
              ))}
            </Select>
          </BulkActionsFormControl>
        </Box>
      )}

      <Dialog onClose={handleClose} open={openRfqSendModal}>
        <DialogTitle>
          You are about to send RFQs {checkedRfqs.toString()} to the suppliers.
          Are you sure you want to do this?
        </DialogTitle>
        <DialogActions>
          <Button variant='contained' color='secondary' onClick={handleClose}>
            Cancel
          </Button>
          <Button variant='contained' color='primary' onClick={onSendRfq}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default TableBulkActions
