import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'

import TimelineSlider from 'components/timelineSlider'

const Overview = ({ data, trackingNumber }) => {
  const [contentsSum, setContentsSum] = useState(0)

  useEffect(() => {
    const tracks = Object.values(data.lines)
    let sum = contentsSum

    tracks.map((track) => {
      track.map((line) => {
        sum += parseInt(line.shipped_quantity, 10)
        return line
      })

      setContentsSum(sum)
      return track
    })
  }, [])

  return (
    <div className='tab-content'>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <div className='shipment-summary'>
            <h3>{trackingNumber}</h3>
            <img src={data.carrier_logo_url} alt='logo' />
            <h4>{data.service.description}</h4>
            <a href={data.track_url}>Track via {data.carrier}</a>
          </div>
        </Grid>
        <Grid item xs={9}>
          <div className='timeline'>
            <TimelineSlider timeline={data.timeline} />
          </div>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Card className='card'>
                <CardContent className='card-content'>
                  <h5 className='title'>Shipped Form</h5>
                  <div className='summary'>
                    <h3>{data.from_address.postal_code}</h3>
                    <p>
                      {data.from_address.city},{' '}
                      {data.from_address.state_province},{' '}
                      {data.from_address.country}
                    </p>
                    <img src={data.from_address.image_url} alt='shipped from' />
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card className='card'>
                <CardContent className='card-content'>
                  <h5 className='title'>Shipped To</h5>
                  <div className='summary'>
                    <h3>{data.to_address.postal_code}</h3>
                    <p>
                      {data.to_address.city}, {data.to_address.state_province},{' '}
                      {data.to_address.country}
                    </p>
                    <img src={data.to_address.image_url} alt='shipped to' />
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card className='card'>
                <CardContent className='card-content'>
                  <h5 className='title'>Weight</h5>
                  <div className='summary'>
                    <h1>{data.dimensions.weight}</h1>
                    <p>POUNDS</p>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card className='card'>
                <CardContent className='card-content'>
                  <h5 className='title'>Contents</h5>
                  <div className='summary'>
                    <h1>{contentsSum}</h1>
                    <p>ITEMS</p>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default Overview
