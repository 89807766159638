import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from '@material-ui/core'
import _find from 'lodash/find'

import StyledButton from 'components/common/button'

const EditLogicModal = ({
  open,
  handleClose,
  logics,
  logicId,
  logicIndex,
  workflow,
  onUpdateLogic,
}) => {
  const [selectedLogicId, setSelectedLogicId] = useState('')
  const [selectedLogic, setSelectedLogic] = useState(null)
  const [argumentValues, setArgumentValues] = useState(null)
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    if (logicId !== '') {
      setSelectedLogicId(logicId)
      setSelectedLogic(_find(logics, { id: logicId }))
      const selectedWorkflow = _find(workflow, { logic_function_id: logicId })
      setArgumentValues(selectedWorkflow.arguments)
    }
  }, [logicId])

  const onChangeTextField = (index, e) => {
    setHasError(false)
    const updatedArguementValues = argumentValues
    updatedArguementValues[index] = e.target.value

    setArgumentValues([...updatedArguementValues])
  }

  const onUpdate = () => {
    setHasError(false)
    let isFormValid = true
    argumentValues.map((value) => {
      isFormValid = isFormValid && value !== ''
    })

    if (isFormValid) {
      const workflow = {
        logic_function_id: selectedLogic.id,
        arguments: argumentValues,
      }

      onUpdateLogic(workflow, logicIndex)
    } else {
      setHasError(true)
    }
  }

  const onSelectLogic = (e) => {
    setSelectedLogicId(e.target.value)
    const logic = _find(logics, { id: e.target.value })
    setSelectedLogic(logic)
    if (e.target.value !== logicId) {
      let emptyValues = []
      logic.arguments.map((argument) => {
        emptyValues = [...emptyValues, '']
        return argument
      })
      setArgumentValues(emptyValues)
    } else {
      const selectedWorkflow = _find(workflow, {
        logic_function_id: e.target.value,
      })
      setArgumentValues(selectedWorkflow.arguments)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='form-dialog-title'
      fullWidth
      maxWidth='xs'
      disableBackdropClick
    >
      <DialogTitle id='form-dialog-title'>
        Supplier Part Number Rule
      </DialogTitle>
      <DialogContent>
        {logics && (
          <Box mb={1}>
            <FormControl variant='outlined' fullWidth>
              <InputLabel id='logic-select-outlined-label'>Logic</InputLabel>
              <Select
                labelId='logic-select-outlined-label'
                id='logic-select-outlined'
                value={selectedLogicId}
                onChange={onSelectLogic}
                label='Logic'
              >
                {logics.map((logic) => (
                  <MenuItem value={logic.id} key={logic.id}>
                    {logic.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}

        {selectedLogic &&
          selectedLogic.arguments.map((argument, i) => (
            <Box mb={1} key={i}>
              <TextField
                variant='outlined'
                fullWidth
                label={argument}
                value={argumentValues[i]}
                onChange={(e) => onChangeTextField(i, e)}
              />
            </Box>
          ))}

        {hasError && <Typography color='error'>Fill all the values</Typography>}

        <Box display='flex' justifyContent='flex-end'>
          <StyledButton
            onClick={handleClose}
            className='btn-orange'
            color='primary'
            variant='contained'
            style={{ marginRight: '1em' }}
          >
            Cancel
          </StyledButton>
          <Button
            color='primary'
            variant='contained'
            onClick={onUpdate}
            disabled={!selectedLogic}
          >
            Update
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default EditLogicModal
