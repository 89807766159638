import React, { useEffect, useState, createContext, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import { GlobalContext } from 'routes'
import {
  fetchShipmentListAPI,
  fetchShipmentStatusListAPI,
} from 'services/helpers/apis/shipment'
import storageActions from 'services/redux/storage/actions'
import ShipmentsTable from './table'

const { setShipmentsTableSettings } = storageActions
export const ShipmentListContext = createContext()

const ShipmentList = () => {
  const context = useContext(GlobalContext)
  const tableSettings = useSelector(
    (state) => state.storage.shipmentsTableSettings
  )
  const [shipments, setShipments] = useState(null)
  const [statusList, setStatusList] = useState(null)
  const [filterValues, setFilterValues] = useState(
    (tableSettings && tableSettings.filter) || {
      statuses: [],
      startDate: moment().subtract(1, 'years'),
      endDate: moment(),
    }
  )
  const [tableViewInfo, setTableViewInfo] = useState(
    (tableSettings && tableSettings.tableViewInfo) || {
      totalRows: 0,
      pageIndex: 1,
      rowsCount: 25,
    }
  )
  const [filterChanged, setFilterChanged] = useState(false)
  const dispatch = useDispatch()

  const fetchShipmentList = async (
    viewInfo,
    filterValues = null,
    searchTerm = null
  ) => {
    try {
      let res

      if (!searchTerm) {
        res = await fetchShipmentListAPI(viewInfo, filterValues)
      }

      dispatch(
        setShipmentsTableSettings({
          filter: filterValues,
          tableViewInfo: {
            pageIndex: viewInfo.pageIndex,
            rowsCount: viewInfo.rowsCount,
            totalRows: res.data.data.total_rows,
          },
        })
      )

      setShipments([...res.data.data.shipments])

      setTableViewInfo({
        pageIndex: viewInfo.pageIndex,
        rowsCount: viewInfo.rowsCount,
        totalRows: res.data.data.total_rows,
      })
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    const fetchApis = async () => {
      try {
        fetchShipmentList(tableViewInfo, filterValues)

        const statusListRes = await fetchShipmentStatusListAPI()
        setStatusList(statusListRes.data.data)
      } catch (e) {
        context.onApiError(e)
      }
    }

    fetchApis()
  }, [])

  const onFilterChange = (target, values) => {
    setFilterValues({
      ...filterValues,
      [target]: values,
    })
    setTableViewInfo({
      ...tableViewInfo,
      pageIndex: 1,
    })
    setFilterChanged(true)
  }

  const onDateRangeChange = (startDate, endDate) => {
    setFilterValues({
      ...filterValues,
      startDate,
      endDate,
    })
    setTableViewInfo({
      ...tableViewInfo,
      pageIndex: 1,
    })
    setFilterChanged(true)
  }

  useEffect(() => {
    if (filterChanged) {
      fetchShipmentList(tableViewInfo, filterValues)
      setFilterChanged(false)
    }
  }, [filterChanged])

  const onTableViewChange = (pageIndex, numberOfRows = 10) => {
    fetchShipmentList({ pageIndex, rowsCount: numberOfRows }, filterValues)
  }

  return (
    <ShipmentListContext.Provider value={{ statusList }}>
      {shipments && statusList && (
        <ShipmentsTable
          rows={shipments}
          onFilterChange={onFilterChange}
          onDateRangeChange={onDateRangeChange}
          onTableViewChange={onTableViewChange}
        />
      )}
    </ShipmentListContext.Provider>
  )
}

export default ShipmentList
