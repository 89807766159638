import styled from 'styled-components'

const UserListContainer = styled.div`
  height: 100%;
  position: relative;

  .ReactTable {
    margin-bottom: 80px;

    .rt-thead .rt-th,
    .rt-thead .rt-td {
      display: flex;
      align-items: center;
    }

    .rt-tbody .rt-td {
      display: flex;
      align-items: center;
    }
  }

  .table-actions {
    .wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    .bulk-actions {
      display: flex;
      align-items: center;

      button {
        margin-left: 10px;
      }
    }
  }
`

export default UserListContainer
